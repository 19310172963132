import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


const RfFieldDescription = ({ field, position = 'reverse', sx }) => {
  const { t } = useTranslation()
  const description = field.description || (field.descriptionKey ? t(`${field.label}.${field.descriptionKey}`, '') : null)
  if (!description) return null
  const content = (
    <Box sx={{
      color: 'dark.400',
      mt: 1,
      fontSize: 'sm',
      textTransform: 'initial',
      ':first-letter': {
        textTransform: 'uppercase'
      },
      ...sx
    }}>
      {t(description)}
    </Box>
  )

  if (position === 'reverse') {
    return (
      <Flex sx={{
        flexDirection: 'row-reverse',
      }}>
        {content}
      </Flex>
    )
  } else if (position === 'default') {
    return content
  } else {
    return content
  }
}

export default RfFieldDescription
import React from 'react'

// Vendor
import stringfy from 'json-stable-stringify'
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { useFormState, useForm } from 'react-final-form'
import dotProp from 'dot-prop'
import { setIn, getIn } from 'final-form'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'
import ReactorModal from 'reactor-ui/components/Modal'

// RunicForm
import RfFieldDescription from './RfFieldDescription'
import RfRichTextField from './RfRichTextField'
import RfTextField from './RfTextField'
import RfCheckboxField from './RfCheckboxField'
import RfCheckboxMapField from './RfCheckboxMapField'
import RfCheckboxGroupField from './RfCheckboxGroupField'
import RfCheckboxRadio from './RfCheckboxRadio'
import RfFileField from './RfFileField'
import RfRadioField from './RfRadioField'
import RfEntityField from './RfEntityField'
import RfSelectField from './RfSelectField'
import RfDateField from './RfDateField'
// import RfDateTimeField from './RfDateTimeField'
import RfTimeField from './RfTimeField'
import RfTimeRangeField from './RfTimeRangeField'
import RfContractField from './RfContractField'
import RfDynamicField from './RfDynamicField'
import RfRecurrenceField from './RfRecurrenceField'
import RfInfo from './RfInfo'
import RfFilterGroupField from './RfFilterGroupField'
import RfColorField from './RfColorField'

import RfFormFields from './RfFormFields'

// import RfImageField from './RfImageField'
// import RfDateTimeField from './RfDateTimeField'
// import RfDropdownField from './RfDropdownField'
// import RfLookupField from './RfLookupField'
// import RfMultiCheckbox from './RfMultiCheckbox'
// import RfMultiCheckboxGroup from './RfMultiCheckboxGroup'
// import RfImageSelectField from './RfImageSelectField'
// import RfContentImageField from './RfContentImageField'
// import RfLongFormTextField from './RfLongFormTextField'
// import RfVideoField from './RfVideoField'
// import RfGraphLookupField from './RfGraphLookupField'
// import RfResponsiveImageField from './RfResponsiveImageField'

const defaultFieldMap = {
  richText: (field, autoFocus) => <RfRichTextField name={field.name} field={field} autoFocus={autoFocus} />,
  text: (field, autoFocus) => <RfTextField name={field.name} field={field} autoFocus={autoFocus} />,
  password: (field, autoFocus) => <RfTextField type='password' name={field.name} field={field} autoFocus={autoFocus} />,
  textArea: (field, autoFocus) => <RfTextField name={field.name} multiLine field={field} autoFocus={autoFocus} />,
  textarea: (field, autoFocus) => <RfTextField name={field.name} multiLine field={field} autoFocus={autoFocus} />,
  markdownRichText: (field, autoFocus) => <RfTextField name={field.name} multiLine field={field} autoFocus={autoFocus} />,
  imageSelect: (field, autoFocus) => <RfImageSelectField name={field.name} field={field} autoFocus={autoFocus} />,
  contentImage: (field, autoFocus) => <RfContentImageField name={field.name} field={field} autoFocus={autoFocus} />,
  contentImageUpload: (field, autoFocus) => <RfContentImageField name={field.name} field={field} autoFocus={autoFocus} upload={true} />,
  responsiveImage: (field, autoFocus) => <RfResponsiveImageField name={field.name} field={field} autoFocus={autoFocus} upload={true} />,
  timeRange: (field, autoFocus) => <RfTimeRangeField name={field.name} field={field} autoFocus={autoFocus} upload={true} />,

  dateTime: (field, autoFocus) => <RfDateTimeField name={field.name} field={field} autoFocus={autoFocus} />,
  dropdown: (field, autoFocus) => <RfDropdownField name={field.name} options={field.options} field={field} autoFocus={autoFocus} />,
  number: (field, autoFocus) => <RfTextField name={field.name} type='number' field={field} autoFocus={autoFocus} />,
  recurrence: (field, autoFocus) => <RfRecurrenceField name={field.name} field={field} autoFocus={autoFocus} />,
  relationship: (field, autoFocus) => <RfLookupField name={field.name} field={field} autoFocus={autoFocus} />,
  graphEntity: (field, autoFocus) => <RfGraphLookupField name={field.name} field={field} autoFocus={autoFocus} />,
  bool: (field, autoFocus) => <RfCheckboxField name={field.name} field={field} autoFocus={autoFocus} />,
  boolean: (field, autoFocus) => <RfCheckboxField name={field.name} field={field} autoFocus={autoFocus} />,
  checkbox: (field, autoFocus) => <RfCheckboxField name={field.name} field={field} autoFocus={autoFocus} />,
  checklist: (field, autoFocus) => <RfMultiCheckbox name={field.name} field={field} autoFocus={autoFocus} />,
  multicheckbox: (field, autoFocus) => <RfMultiCheckboxGroup name={field.name} field={field} autoFocus={autoFocus} />,
  longFormText: (field, autoFocus) => <RfLongFormTextField name={field.name} field={field} autoFocus={autoFocus} />,
  video: (field, autoFocus) => <RfVideoField name={field.name} field={field} autoFocus={autoFocus} />,
  videoUpload: (field, autoFocus) => <RfVideoField name={field.name} field={field} autoFocus={autoFocus} upload={true} />,
  custom: (field, autoFocus) => React.cloneElement(field.element || field.options?.element, { name: field.name, field: field }),

  radio: (field, autoFocus) => <RfRadioField name={field.name} field={field} autoFocus={autoFocus} upload={true} />,
  entity: (field, autoFocus) => <RfEntityField name={field.name} field={field} autoFocus={autoFocus} upload={true} />,
  checkboxGroup: (field, autoFocus) => <RfCheckboxGroupField name={field.name} field={field} autoFocus={autoFocus} />,
  checkboxMap: (field, autoFocus) => <RfCheckboxMapField name={field.name} field={field} autoFocus={autoFocus} />,
  checkboxRadio: (field, autoFocus) => <RfCheckboxRadio name={field.name} field={field} autoFocus={autoFocus} />,
  select: (field, autoFocus) => <RfSelectField name={field.name} field={field} autoFocus={autoFocus} />,
  file: (field, autoFocus) => <RfFileField name={field.name} field={field} autoFocus={autoFocus} />,
  date: (field, autoFocus) => <RfDateField name={field.name} field={field} autoFocus={autoFocus} />,
  time: (field, autoFocus) => <RfTimeField name={field.name} field={field} autoFocus={autoFocus} />,
  // image:  (field, autoFocus) => <RfImageField name={field.name} field={field} autoFocus={autoFocus} />,
  contract: (field, autoFocus) => <RfContractField name={field.name} field={field} autoFocus={autoFocus} />,
  dynamic: (field, autoFocus) => <RfDynamicField name={field.name} field={field} autoFocus={autoFocus} />,
  info: (field, autoFocus) => <RfInfo name={field.name} field={field} autoFocus={autoFocus} />,

  filterGroup: (field, autoFocus) => <RfFilterGroupField name={field.name} field={field} autoFocus={autoFocus} />,
  color: (field, autoFocus) => <RfColorField name={field.name} field={field} autoFocus={autoFocus} />,
}

export const customFieldMap = {}

import { ReactorFormContext } from './RfForm'

import useShowIf from '../hooks/useShowIf'

const initialArrayValue = [{}]

const RfArrayField = ({ field, group, FieldComponent, ...rest }) => {
  const { t } = useTranslation()
  const [collapsed, collapsedSet] = React.useState(false)
  const toggleCollapse = React.useCallback(() => collapsedSet(v => !v))
  const { fields, ...r } = useFieldArray(field.name, {
    initialValue: field.defaultCount ? initialArrayValue : undefined
  })

  return (
    <Box sx={{
      mt: 3
    }}>
      <Box sx={{
        // fontWeight: 'bold',
        color: 'brand.500',
        mb: 2,
        ...rest?.labelSx
      }}>
        <Flex sx={{
          justifyContent: 'space-between'
        }}>
          <Box sx={{
            textTransform: 'capitalize'
          }}>
            {t(field.label || field.name)}
          </Box>
          <Flex>
            <Box as='a' sx={{
              mx: 1
            }} onClick={toggleCollapse}><Icon color='brand.500' name={collapsed ? 'chevron-last-down' : 'chevron-last-up'} /></Box>

            {!field.options?.disableAdd && (!field.limit || parseInt(field.limit) > fields.length) && <Box as='a' sx={{
              mx: 1
            }} onClick={() => fields.push(group ? {} : null)}><Icon color='brand.500' name={'circle-plus'} /></Box>}

            {field.options?.quickEntry && (
              <ArrayFieldQuickEntry field={field} fields={fields} />
            )}

          </Flex>
        </Flex>
      </Box>

      {field.kind === 'group' && field.description && <RfFieldDescription sx={{ mb: 4 }} position='default' field={field} />}
      {!collapsed ? fields.map((name, dx) => {
        return (
          <Box sx={{
            p: 3,
            border: '1px solid #000',
            borderColor: 'brand.500',
            mb: 3
          }} key={dx}>
            <Flex sx={{
              mb: 2,
              justifyContent: 'space-between'
            }}>
              <Box>
                ( {dx + 1} )
              </Box>
              <Flex alignItems='center'>
                {!field.options?.disableUpdateOrder && dx !== 0 && <Box as='a' sx={{
                  mx: 1
                }} onClick={() => fields.swap(dx, dx - 1)}><Icon color='brand.500' name='chevron-up' /></Box>}
                {!field.options?.disableUpdateOrder && dx !== fields.length - 1 && <Box as='a' sx={{
                  mx: 1
                }} onClick={() => fields.swap(dx, dx + 1)}><Icon color='brand.500' name='chevron-down' /></Box>}
                {!field.options?.disableRemove && <Box as='a' sx={{
                  mx: 1
                }} onClick={() => fields.remove(dx)}><Icon color='brand.500' name='circle-remove' /></Box>}
              </Flex>
            </Flex>
            {group ? (
              <RfFormFields FieldComponent={FieldComponent} fields={field.fields.map((f, fdx) => {
                return (
                  // { ...f, label: t(f.label), name: `${name}.${f.name}`, parentPath: name }
                  { ...f, name: `${name}.${f.name}`, parentPath: name }
                )
              })} />
            ) : (
              <FieldComponent FieldComponent={FieldComponent} field={{ ...field, label: t(field.label), name: name, isMultiple: false, parentPath: name }} {...rest} />
            )}
          </Box>
        )
      }) : fields.map((name, dx) => {
        return (
          <CollapsedField field={field} group={group} name={name} dx={dx} />
        )
      })}
      {!field.options?.disableAdd && (!field.limit || parseInt(field.limit) > fields.length) && <Box as='button' sx={{
        border: '1px dashed #000',
        borderColor: 'brand.500',
        mb: 3,
        px: 3,
        py: 2,
        width: '100%',
        textTransform: 'capitalize'
      }} onClick={(e) => {
        e.preventDefault()
        fields.push(group ? {} : null)
      }}>+ {t(field.label)}</Box>}
    </Box>
  )
}

import { Form as ReactFinalForm } from 'react-final-form'

const ArrayFieldQuickEntry = ({
  field,
  fields
}) => {
  const quickEntry = field.options?.quickEntry
  const existingCount = fields.length

  return (
    <ReactorModal header='Toplu Giriş' anchorKind='LINK'>
      {({ onClose }) => (
        <ReactFinalForm onSubmit={(data) => {
          const target = quickEntry.target
          data.values.split('\n').forEach((value, dx) => {
            const newVal = { [target]: value }
            if (dx < existingCount) {
              fields.update(dx, newVal)
            } else {
              fields.push(newVal)
            }
          })
          onClose()
        }}>
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <RfTextField name={'values'} multiLine field={{}} />
              <Button mt={3} textTransform='capitalize' colorScheme={'brand'} submit isFullWidth>
                EKLE
              </Button>
            </form>
          )}
        </ReactFinalForm>
      )}
    </ReactorModal>
  )
}

const CollapsedField = ({
  field, name, group, dx
}) => {
  const formState = useFormState()
  const fieldKey = group ? `${name}.${field.fields[0].name}` : name
  return (
    <Box sx={{
      my: 2
    }}>
      {`${dx + 1}. `}<Box as='span' sx={{ fontWeight: 'bold' }}>{field.fields[0].label}</Box>: {getIn(formState.values, fieldKey) || '?'}
    </Box>
  )
}

const ConnectedRfField = ({ field, FieldComponent, ...rest }) => {
  const result = useShowIf(field.showIf, [field.name], field.parentPath, field)
  if (!result) return null
  return (<FieldComponent field={{ ...field, showIf: null }} {...rest} />)

}

const RfGroupField = ({
  field,
  FieldComponent,
  fieldProps,
  sx
}) => {
  const { t } = useTranslation()
  if (field.isMultiple) {
    return <RfArrayField field={field} group FieldComponent={FieldComponent} {...fieldProps} />
  } else {
    let content
    if (field.options?.displayAs === 'hstack') {
      content = (
        <Flex sx={{
          flexWrap: 'wrap',
          mx: -2,
          alignItems: field.options.alignItems || 'center'
        }}>
          {field.fields.map((f, dx) => (
            <Box key={dx} sx={{
              flex: '1 1 40%',
              mx: 2
            }}>
              <FieldComponent field={{ ...f, name: `${field.name}.${f.name}`, parentPath: field.name }} FieldComponent={FieldComponent} {...fieldProps} />
            </Box>
          ))}
        </Flex>
      )
    } else {
      // content = field.fields.map((f, dx) => <FieldComponent field={{ ...f, name: `${field.name}.${f.name}`, parentPath: field.name }} key={dx} FieldComponent={FieldComponent} {...fieldProps} />)
      content = <RfFormFields FieldComponent={FieldComponent} fields={field.fields.map((f, fdx) => {
        return (
          { ...f, name: `${field.name}.${f.name}`, parentPath: field.name }
        )
      })} />
    }

    if (field.displayAsGroup) {
      return (
        <Box sx={{
          ...sx
        }}>
          <Box sx={{
            // fontWeight: 'bold',
            color: 'brand.500',
            mb: 2,
            fontWeight: 'bold',
            ...fieldProps?.labelSx
          }}>
            {t(field.label)}
          </Box>
          {field.description && <RfFieldDescription sx={{ mb: 4 }} position='default' field={field} />}
          <Box sx={{
            pl: 2,
            borderLeft: '1px solid #FFF',
            borderLeftColor: 'brand.100'
          }}>
            {content}
          </Box>
        </Box>
      )
    } else {
      return (
        <Box sx={{
          ...sx
        }}>
          {field.description && <RfFieldDescription sx={{ mb: 4 }} position='default' field={field} />}
          {content}
        </Box>
      )
    }
  }
}

const RfBareField = ({
  field,
  FieldComponent,
  fieldProps,
  autoFocus,
  sx
}) => {
  const ctx = React.useContext(ReactorFormContext)
  const renderer = ctx?.fieldMap?.[field.kind] || customFieldMap[field.kind] || defaultFieldMap[field.kind]

  if (!renderer) {
    console.log(customFieldMap, field.kind)
    console.warn('Field kind unrecognized', field.kind)
  }

  return (
    <Box sx={{
      mb: 3,
      ...sx
    }}>
      {renderer?.(field, autoFocus)}

      {field.actions && (
        <Flex sx={{
          flexDirection: 'row-reverse'
        }}>
          {field.actions}
        </Flex>
      )}
    </Box>
  )
}

const RfDependantField = ({
  field,
  children
}) => {
  const form = useForm()
  const formState = useFormState()
  const values = {}
  field.dependsOn.forEach(name => {
    const nameToCheck = field.parentPath ? `${field.parentPath}.${name}` : name
    values[name] = getIn(formState.values, nameToCheck)
  })
  const key = stringfy(values)
  const prevKeyRef = React.useRef()
  if (!prevKeyRef.current) prevKeyRef.current = key

  React.useEffect(() => {
    const prevKey = prevKeyRef.current
    prevKeyRef.current = key
    if (prevKey != key) {
      const splitName = field.name.split('.')
      const parentName = splitName.slice(0, splitName.length - 1).join('.')
      const fieldName = splitName[splitName.length - 1]
      const parentValue = getIn(formState.values, parentName)
      const newParentValue = setIn(parentValue || {}, fieldName, null)
      if (parentValue === newParentValue) return
      if (!parentValue?.[fieldName]) return
      form.change(parentName, newParentValue)
    }
  }, [key])

  return (
    <Box>
      {children}
    </Box>
  )
}

const RfField = ({ field, FieldComponent = RfField, autoFocus, sx, ...rest }) => {
  const rfField = useField(field.name)

  if (rfField.input.value && !rfField.meta.dirty && field.hiddenWithDefault) return null

  let fieldContent
  if (field.showIf) {
    fieldContent = <ConnectedRfField field={field} autoFocus={autoFocus} FieldComponent={FieldComponent} sx={sx} {...rest} />
  } else if (field.kind === 'group') {
    fieldContent = <RfGroupField field={field} FieldComponent={FieldComponent} fieldProps={rest} sx={sx} />
  } else if (field.isMultiple) {
    fieldContent = <RfArrayField field={field} FieldComponent={FieldComponent} {...rest} />
  } else {
    fieldContent = <RfBareField field={field} FieldComponent={FieldComponent} sx={sx} autoFocus={autoFocus} fieldProps={rest} />
  }

  if (field.dependsOn) {
    return (
      <RfDependantField field={field}>
        {fieldContent}
      </RfDependantField>
    )
  }

  return fieldContent
}

export default RfField
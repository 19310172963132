import React from 'react'

// Vendor
import isHotkey from 'is-hotkey'
import { Editor, Transforms, createEditor, Range } from 'slate'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { withHistory } from 'slate-history'
import { isValidHex } from 'react-color/lib/helpers/color'

// Reactor UI
import { Box, Flex } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import InputLabel from 'reactor-ui/components/InputLabel'


const RichTextInput = ({
  sx,
  value: valueFromProps,
  onChange,
  label
}) => {
  const renderElement = React.useCallback(props => <Element {...props} />, [])
  const renderLeaf = React.useCallback(props => <Leaf {...props} />, [])
  const editor = React.useMemo(() => withHistory(withLinks(withReact(createEditor()))), [])

  const value = valueFromProps || [{
    type: 'paragraph',
    children: [{ text: '' }],
  }]

  return (
    <Box sx={{
      minHeight: 20,
      borderRadius: 4,
      px: 2,
      py: 2,
      border: '1px solid var(--chakra-colors-dark-100)',
      outline: 'none',
      '&:focus': {
        outline: 'none',
        border: '1px solid var(--chakra-colors-brand-500)',
        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
      },
      transition: 'all linear 0.1s',
      position: 'relative',
      ...sx
    }}>
      {label && <InputLabel variant='active'>
        {label}
      </InputLabel>}
      <Slate editor={editor} value={value} onChange={v => {
        onChange(v)
      }}>
        <Box sx={{
          background: '#FFF',
          px: 2,
          py: 2,
          color: '#000'
        }}>
          <Toolbar>
            <MarkButton format="bold" icon="bold" />
            <MarkButton format="italic" icon="italic" />
            <MarkButton format="underline" icon="underline" />
            <ColorButton format='color' icon='palette' />
            <ColorButton format='bgColor' icon='fill' />
            <LinkButton />
            <BlockButton format="alignLeft" icon="paragraph-left" />
            <BlockButton format="alignCenter" icon="paragraph-center" />
            <BlockButton format="alignRight" icon="paragraph-right" />
            {/* <MarkButton format="code" icon="code" /> */}
            {/* <BlockButton format="heading-one" icon="header" /> */}
            {/* <BlockButton format="heading-two" icon="header" />
          <BlockButton format="block-quote" icon="chevron-right" />
          <BlockButton format="numbered-list" icon="list-numbers" />
          <BlockButton format="bulleted-list" icon="list" /> */}
          </Toolbar>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </Box>
      </Slate>
    </Box>
  )
}

const Toolbar = props => (
  <Flex {...props} sx={{
    mx: -1,
    // px: 3,
    // mt: 2,
    mb: 2,
    // boxShadow: '0 3px 4px rgba(0, 0, 0, 0.20)',
    // justifyContent: 'space-around'
    // py: 2,
  }}>
    {props.children}
  </Flex>
)

const Button = props => (
  <Box {...props} sx={{
    border: '1px solid #FFF',
    borderColor: 'brand.500',
    p: 1,
    mx: 1,
    backgroundColor: props.active ? 'brand.500' : 'unset',
    color: props.active ? '#FFF' : 'current-color'
  }} />
)

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const toggleColor = (editor, kind, color) => {
  const isActive = isMarkActive(editor, kind)

  if (isActive) {
    Editor.removeMark(editor, kind)
  } else {
    Editor.addMark(editor, kind, color)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  // return marks ? marks[format] === true : false
  return marks && marks[format]
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon name={icon} />
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon name={icon} />
    </Button>
  )
}

import useMenu from 'reactor-ui/hooks/useMenu'

const ColorButton = ({ format, icon }) => {
  const editor = useSlate()
  const [anchorProps, menuProps, arrowProps, close, open, isForceOpen, setIsForceOpen] = useMenu({ placement: 'top', defaultIsOpen: 'false' })
  return (
    <Box {...anchorProps}>
      <Button
        active={isMarkActive(editor, format)}
        onMouseDown={event => {
          event.preventDefault()
          menuProps.isOpen ? close() : open()
        }}
      >
        <Icon name={icon} />
      </Button>
      <Box {...menuProps} sx={{
        display: menuProps.isOpen ? 'block' : 'none',
      }}>
        {menuProps.isOpen && <ColorPicker editor={editor} onColorChange={(color) => {
          toggleColor(editor, format, color)
        }} />}
      </Box>
    </Box>
  )
}

export const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'alignLeft':
      return <p style={{ textAlign: 'left' }} {...attributes}>{children}</p>
    case 'alignRight':
      return <p style={{ textAlign: 'right' }} {...attributes}>{children}</p>
    case 'alignCenter':
      return <p style={{ textAlign: 'center' }} {...attributes}>{children}</p>
    case 'heading-two':
      return <h3 {...attributes}>{children}</h3>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'link':
      return (
        <Box as='a' {...attributes} href={element.url} sx={{
          color: 'brand.500',
          textDecoration: 'underline'
        }}>
          {children}
        </Box>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.color) {
    children = <span style={{ color: leaf.color }}>{children}</span>
  }

  if (leaf.bgColor) {
    children = <span style={{ backgroundColor: leaf.bgColor }}>{children}</span>
  }

  return <span {...attributes}>{children}</span>
}

var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/
var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

function isUrl(string) {
  if (typeof string !== 'string') {
    return false;
  }

  var match = string.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  var everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
}

const withLinks = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, { match: n => n.type === 'link' })
  return !!link
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}


const LinkButton = () => {
  const editor = useSlate()
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <Icon name='link' />
    </Button>
  )
}

const ColorPicker = ({
  hex,
  rgb,
  onColorChange,
  onChange,
  editor,
}) => {
  const inputRef = React.useRef()
  const selectionRef = React.useRef()
  if (!selectionRef.current) selectionRef.current = editor.selection

  const [value, valueSet] = React.useState(hex)
  console.log('--\n', editor.selection, selectionRef.current)

  const onTextChange = e => {
    const newVal = e.target.value
    valueSet(newVal)
    if (!isValidHex(newVal)) {
      return
    }
    if (!editor.selection && selectionRef.current) Transforms.select(editor, selectionRef.current)
    onColorChange?.(newVal)
    onChange?.({
      hex: newVal
    })
  }

  const onColorSelect = (e, newVal) => {
    e.preventDefault()
    if (!editor.selection && selectionRef.current) Transforms.select(editor, selectionRef.current)
    onColorChange?.(newVal)
    onChange?.({
      hex: newVal
    })
  }

  const palette = ['#000000', '#FFFFFF', 'green', 'red', 'blue']

  return (
    <Flex sx={{
      width: '250px',
      flexWrap: 'wrap',
      mx: -1,
      bg: '#FFF',
      p: 2,
      boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)",
      border: '1px solid #FFF',
      borderColor: 'primary'
    }}>
      {Object.values(palette).map((clr, dx) => {
        return (
          <Box as='a' key={dx} sx={{
            bg: clr,
            width: 30,
            height: 30,
            mx: 1,
            mb: 1,
            borderRadius: 2,
            cursor: 'pointer',
            display: 'block',
            border: '1px solid #000',
          }} onMouseDown={e => onColorSelect(e, clr)}>

          </Box>
        )
      })}
      <Flex as='a' sx={{
        bg: '#FFF',
        width: 30,
        height: 30,
        mx: 1,
        mb: 1,
        borderRadius: 2,
        cursor: 'pointer',
        display: 'block',
        // border: '1px solid #000',
        alignItems: 'center',
        textAlign: 'center'
      }} onMouseDown={e => onColorSelect(e, null)}>
        <Icon name='square-remove' sx={{
          mx: 'auto'
        }} color='brand.500' />
      </Flex>
      <Box as='input' placeholder='#123458' sx={{
        width: '100%',
        p: 2,
        mx: 1,
        mt: 1
      }} ref={inputRef} onBlur={e => {
        // console.log('sel', selection)
        // Transforms.select(editor, selection)
      }} value={value} onMouseDown={e => {
        // Transforms.select(editor, selection)
        // !selection && selectionSet({...editor.selection})
      }} onChange={onTextChange} />
    </Flex>
  )
}

export default RichTextInput
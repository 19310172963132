// Reactor
import { createActions } from 'reactor/core/redux'

export default createActions({
  LOCAL: {
    ready: {},
    subscribe: {},
    unsubscribe: {},

    rsConnect: {},
    rsDisconnect: {},
    rsUpdate: {},
  },
}, 'runicSocket', 'RcApp')

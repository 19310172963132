import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import useLocationFilter from 'reactor-vera/hooks/useLocationFilter'
import Pagination from 'reactor-vera/apps/data/components/Pagination'


const RnxDevicePacReports = props => {
  const reports = [
    // {
    //   codeName: 'gecis-rapor-ozet',
    //   label: 'Geçiş Rapor Özet',
    //   element: <AccessDateReportSummary/>
    // },

    {
      codeName: 'bolge-rapor',
      label: 'Bölge Rapor',
      element: <BolgeRapor depth={null} key='bolge-rapor' label='Bölge Rapor' />
    },

    {
      codeName: 'bolge-gecis-rapor',
      label: 'Bölge Geçiş Rapor',
      element: <BolgeRapor depth={null} key='bolge-gecis-rapor' label='Bölge Rapor' distinct={false} />
    },

    {
      codeName: 'tesis-rapor',
      label: 'Tesis Rapor',
      element: <BolgeRapor depth={3} key='tesis-rapor' label='Tesis Rapor' />
    },

    {
      codeName: 'tesis-gecis-rapor',
      label: 'Tesis Geçiş Rapor',
      element: <BolgeRapor depth={3} key='tesis-gecis-rapor' label='Tesis Rapor' distinct={false}/>
    },

    // {
    //   codeName: 'gecis-rapor-genel',
    //   label: 'Geçiş Rapor',
    //   element: <AccessDateReport />
    // },

    // {
    //   codeName: 'bolge-rapor-dikey',
    //   label: 'Bölge Rapor Dikey',
    //   element: <ReportsGeneralSummary />
    // },

    // {
    //   codeName: 'tesis-rapor-dikey',
    //   label: 'Tesis Rapor Dikey',
    //   element: <ReportsTagVertical />
    // },

    // {
    //   codeName: 'tesis-rapor-dikey-2',
    //   label: 'Tesis Rapor Dikey 2',
    //   element: <ReportsTagVertical2 />
    // },

    // {
    //   codeName: 'bolge-rapor-yatay',
    //   label: 'Bölge Rapor Yatay',
    //   element: <ReportsGeneral />
    // },

    // {
    //   codeName: 'tesis-rapor-yatay',
    //   label: 'Tesis Rapor Yatay',
    //   element: <ReportsTag />
    // }
  ]

  return (
    <Flex>
      <Box sx={{
        flex: '0 0 170px',
        '@media print': {
          display: 'none'
        },
      }}>


        <RV.Card sx={{
        }}>
          <RV.Card.Header>
            Raporlar
          </RV.Card.Header>
          {reports.map(report => <SidebarLink to={report.codeName} key={report.codeName}>{report.label}</SidebarLink>)}
        </RV.Card>

      </Box>

      <Box sx={{
        flex: '1 1 0',
        ml: 4,
        '@media print': {
          ml: 0
        },
      }}>
        <RV.Routes>
          {/* <RV.Route path='/' element={<AccessReportsT />} /> */}
          {reports.map(report => <RV.Route path={report.codeName} key={report.codeName} element={report.element} />)}
          {/* <RV.Route path='bolgelere-gore' element={<ReportsByTag />} /> */}
        </RV.Routes>
      </Box>
    </Flex>
  )
}

const SidebarLink = ({
  to,
  children,
  sx
}) => {
  const params = RV.useParams()
  const activeParam = params['*']
  return (
    <RV.Link sx={{
      display: 'block',
      my: 2,
      fontWeight: activeParam === to ? 'bold' : 'normal',
      fontSize: activeParam === to ? 'm' : 'base',
      transition: 'all 0.2s ease',
      ...sx,
    }} to={to}>{children}</RV.Link>
  )
}

const BolgeRapor = ({
  depth = 1,
  label,
  distinct = true
}) => {
  return (
    <>
      <ReportTable
        name={label}
        codeName='bolgerapor'
        exporter='RcTag.Data.RcTagReportData'
        dataFilters={[
          {
            "componentName": "RcTag.RcTagDynamicFilter",
            "name": "name",
            'label': 'Birim',
            "ctx": {
              "rc_tag_system": 'Birim'
            }
          }
        ]}
        params={{
          'limit': 100,
          'depth': depth,
          'tagSource': { 'kind': 'all' },
          'rcTagGroup': {
            'name': 'Birim'
          }
        }}
        filters={[
          {
            'name': 'dt',
            'label': 'Tarih',
            'kind': 'Date',
            'initialValue': {
              'range': '@today',
              'days': 1
            }
          },
          {
            'name': 'device',
            'label': 'Cihaz',
            'kind': 'Dynamic',
            'options': {
              'model': 'rnxPacAccessPoint',
              'exclude': 'birim'
            }
          }
        ]}
        fields={[
          {
            'label': 'Birim',
            'name': 'birim',
            'path': 'name',
            'kind': 'String',
            'widthKey': 'XLARGE'
          },

          {
            'label': 'Aktif Cihaz',
            'name': 'activeDevice',
            'queryPath': {
              'id': 0,
              'path': 'count'
            },
            'defaultValue': 0,
            'kind': 'Integer'
          },

          {
            'label': 'Pasif Cihaz',
            'name': 'passiveDevice',
            'queryPath': {
              'id': 1,
              'path': 'count'
            },
            'defaultValue': 0,
            'kind': 'Integer'
          },

          {
            'label': 'Kayıtlı Cihaz',
            'name': 'device',
            'queryPath': {
              'id': 2,
              'path': 'count'
            },
            'defaultValue': 0,
            'kind': 'Integer'
          },

          {
            'label': 'T.C. Kimlik Kartı',
            'name': 'idcard',
            'queryPath': {
              'id': 3,
              'path': 'ID_CARD'
            },
            'defaultValue': 0,
            'kind': 'Integer'
          },

          {
            'label': 'Temassız Kart',
            'name': 'rfcard',
            'queryPath': {
              'id': 3,
              'path': 'RF_CARD'
            },
            'defaultValue': 0,
            'kind': 'Integer'
          },

          {
            'label': 'QR Kod',
            'name': 'qrcode',
            'queryPath': {
              'id': 3,
              'path': 'QR_CODE'
            },
            'defaultValue': 0,
            'kind': 'Integer'
          },

          {
            'label': 'Toplam',
            'name': 'total',
            'queryPath': {
              'id': 3,
              'path': '@total'
            },
            'defaultValue': 0,
            'kind': 'Integer'
          },

          {
            'label': 'Günlük T.C. Kimlik Kartı',
            'name': 'dailyidcard',
            'queryPath': {
              'id': 3,
              'path': 'ID_CARD'
            },
            'defaultValue': 0,
            'kind': 'Integer',
            'calculation': [
              {
                'op': '/',
                'value': '@@r.filters.dt.days',
                'round': 2
              }
            ]
          },

          {
            'label': 'Günlük Temassız Kart',
            'name': 'dailyrfcard',
            'queryPath': {
              'id': 3,
              'path': 'RF_CARD'
            },
            'defaultValue': 0,
            'kind': 'Integer',
            'calculation': [
              {
                'op': '/',
                'value': '@@r.filters.dt.days',
                'round': 2
              }
            ]
          },

          {
            'label': 'Günlük QR Kod',
            'name': 'dailyqrcode',
            'queryPath': {
              'id': 3,
              'path': 'QR_CODE'
            },
            'defaultValue': 0,
            'kind': 'Integer',
            'calculation': [
              {
                'op': '/',
                'value': '@@r.filters.dt.days',
                'round': 2
              }
            ]
          },

          {
            'label': 'Günlük Toplam',
            'name': 'dailytotal',
            'queryPath': {
              'id': 3,
              'path': '@total'
            },
            'defaultValue': 0,
            'kind': 'Integer',
            'calculation': [
              {
                'op': '/',
                'value': '@@r.filters.dt.days',
                'round': 2
              }
            ]
          },
        ]}
        queries={[
          {
            'kind': 'count',
            'options': {
              'target': 'rnxDevice',
              'filters': {
                'active': {},
                '@self': '@@r.filters.device',
              },
            }
          },

          {
            'kind': 'count',
            'options': {
              'target': 'rnxDevice',
              'filters': {
                'passive': {},
                '@self': '@@r.filters.device',
              },
            }
          },

          {
            'kind': 'count',
            'options': {
              'target': 'rnxDevice',
              'filters': {
                '@self': '@@r.filters.device',
              },
            }
          },

          {
            'kind': 'groupBySubqueryCount',
            'options': {
              'subquery': {
                'target': 'rnxPacAccessRecord',
                'idField': 'rnx_pac_access_point_id',
                'groupBy': 'provider_kind',
                'field': {
                  'name': 'rnx_contact_id',
                  'distinct': distinct,
                },
                'filters': {
                  'datetime_access': '@@r.filters.dt',
                  'rnx_pac_access_point': '@@r.filters.device'
                },
              },
            }
          }

          // {
          //   'target': 'rnxPacAccessRecord',
          //   'connection': {
          //     'source': 'rnxPacAccessPoint'
          //   },
          //   'filters': {
          //     'datetime_access': '@@r.filters.dt',
          //     'rnx_pac_access_point': '@@r.filters.device'
          //   },
          //   'kind': 'groupByFieldCount',
          //   'groupBy': 'provider_kind',
          //   'query': {
          //     'selected': ['RF_CARD', 'ID_CARD', 'QR_CODE'],
          //     'field': {
          //       'name': 'rnx_contact_id',
          //       'distinct': true,
          //       'kind': 'count'
          //     }
          //   }
          // }
        ]}
      />
    </>
  )
}

const ReportTable = ({
  name,
  codeName,
  exporter,
  params,
  filters,
  fields,
  queries,
  dataFilters
}) => {
  const initialFilters = {}
  filters?.map?.(f => {
    if (f.initialValue) initialFilters[f.name] = f.initialValue
  })
  const filterInstance = useLocationFilter(codeName, initialFilters)
  const dataFilterInstance = useLocationFilter(`${codeName}Data`)

  const resourceVariables = {
    // data: {
    //   params,
    //   filters,
    //   fields,
    //   queries
    // }
    params,
    filters: {
      // 'dt': {
      //   'range': '@today',
      //   'days': 1
      // },
      ...filterInstance.locationFilters,
      // 'device': {}
    },
    dataFilters: {
      ...dataFilterInstance.locationFilters
    },
    fields,
    queries
  }
  const resource = RV.useReactorResource(exporter, resourceVariables)

  return (
    // <RV.QueryLoading result={resource}>
    <>
      <RV.SimpleTable
        result={resource}
        header={name}
        hasTotalRow
        modelActions={(
          <Flex>
            <Pagination graph={resource.data?.result} filterInstance={filterInstance} />
            <ExportLink exporter={exporter} resourceVariables={resourceVariables} />
          </Flex>
        )}
        overTableContent={(
          <>
            <Flex sx={{
              alignItems: 'center',
              mx: -2
            }}>
              {dataFilters?.map((f, dx) => {
                return (
                  <Box key={dx} mx={2}>
                    <RV.FilterInput
                      // onChange={(val) => dispatch(dashboardActions.setFilterValue({
                      //   id: dashboard.id, filterId: filter.id, value: {
                      //     ...filter.value,
                      //     [gf.name]: val
                      //   }
                      // }))}

                      focus={false}
                      direction='horizontal'
                      label={f.label}
                      target={f.name}
                      name={f.name}
                      kind={f.kind}
                      filterInstance={dataFilterInstance}
                      options={f.options}
                      componentName={f.componentName}
                      ctx={f.ctx}
                    // value={f.value}
                    />
                  </Box>
                )
              })}
            </Flex>
            <Flex sx={{
              alignItems: 'center',
              mx: -2
            }}>
              {filters?.map((f, dx) => {
                return (
                  <Box key={dx} mx={2}>
                    <RV.FilterInput
                      // onChange={(val) => dispatch(dashboardActions.setFilterValue({
                      //   id: dashboard.id, filterId: filter.id, value: {
                      //     ...filter.value,
                      //     [gf.name]: val
                      //   }
                      // }))}

                      focus={false}
                      direction='horizontal'
                      label={f.label}
                      target={f.name}
                      kind={f.kind}
                      filterInstance={filterInstance}
                      options={f.options}
                    // value={f.value}
                    />
                  </Box>
                )
              })}
            </Flex>
          </>
        )}
        // actions={[
        //   (item, dx) => <RV.RfActionForm key={dx} initialValues={{ rnxDeviceAppVersionId: params.rnxDeviceAppVersionId, fileId: item.id }} onSuccess={result.refresh} header='Kaldır' actionKey='RnxDevice.DeleteAppVersionFile' iconName='circle-remove' buttonText='Kaldır' />,
        //   (item, dx) => (
        //     <RV.Button tooltip='İndir' size='xs' as='a' iconName='square-empty-download' sx={{
        //       color: 'textMuted',
        //       transition: 'all 0.2s ease',
        //       '&:hover': {
        //         color: 'brand.500',
        //       },
        //       ml: 2
        //     }} href={item.url} target='_blank' download />
        //   )
        // ]}
        // alignLast
        maxHeight={0}
        items={resource.data?.result.items}
        columns={fields.map(f => ({ ...f, key: f.name }))}
      />
    </>
    // </RV.QueryLoading>
  )
  return null
}

import URI from 'urijs'
const ExportLink = ({
  exporter,
  resourceVariables
}) => {
  const url = new URI(`/__reactor/api/resource/${exporter}Export`)
  const query = {
    '@rcTenantId': 1,
    'X-REACTOR-CHANNEL': 'Reactor'
  }
  Object.entries(resourceVariables).forEach(([k, v]) => {
    query[k] = JSON.stringify(v)
  })
  url.setQuery(query)
  return (
    <Box as='a' sx={{
      color: 'primary',
      textDecoration: 'underline'
    }}
      // download
      target='_blank'
      href={url.toString()}>
      <RV.Button iconName='file-spreadsheet' size='xs' />
    </Box>
  )
}

export default RnxDevicePacReports
import defaultTheme from './themes/default'
import auroraTheme from './themes/aurora'

export default {
  name: 'reactor-vera.ui',
  directory: {
    reactorVera: {
      plexUi: {
        themes: {
          Default: defaultTheme,
          Aurora: auroraTheme
        },
      },
    }
  },
}

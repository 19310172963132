import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import ReactorAction from 'reactor-vera/apps/data/components/ReactorAction'
import Button from 'reactor-ui/components/Button'
import QueryLoading from 'reactor-vera/apps/ui/components/QueryLoading'
import ReactorAlert from 'reactor-ui/components/Alert'


const ReactorServerAction = ({
  isLazy,
  ...props
}) => {
  if (isLazy) return <ReactorLazyServerAction {...props} />
  return <ReactorDefaultServerAction {...props} />
}

const ReactorLazyServerAction = (props) => {
  const [loaded, setLoaded] = React.useState(false)
  const { anchor, icon, lazyAnchor } = props

  if (loaded) {
    return <ReactorDefaultServerAction {...props} />
  }

  return (
    <Box onClick={() => setLoaded(true)}>
      {lazyAnchor || anchor || icon && <Button colorScheme='brand' icon={icon} />}
    </Box>
  )
}

const ReactorDefaultServerAction = ({
  identifier,
  initialValues,
  displayAs,
  options = {},
  onSuccess,
  onReset,
  onClose,
  anchor,
  renderer,
  icon,
  anchorProps,
  elements,
  hideIfEmpty = false,
  sx
}) => {
  const result = useReactorQuery({
    'reactorActionList': {
      actions: {
        [identifier]: options
      }
    }
  })

  const action = result.graph?.reactorActionList.actions?.[identifier]

  if (renderer) return renderer({ action })

  return (
    <QueryLoading result={result}>
      {action ? (
        <ReactorAction
          onSuccess={onSuccess}
          onReset={onReset}
          anchor={anchor || icon && <Button colorScheme='brand' icon={icon} />}
          action={action}
          initialValues={initialValues}
          anchorProps={anchorProps}
          elements={elements}
          onClose={onClose}
          ctx={{
            ...options?.ctx
          }}
          sx={sx}
          displayAs={displayAs}
        />
      ) : (
        hideIfEmpty ? (
          hideIfEmpty ? hideIfEmpty : null
        ) : (
          <ReactorAlert>
            Aksiyon bulunamadı.
          </ReactorAlert>
        )
      )}
    </QueryLoading >
  )
}

export default ReactorServerAction
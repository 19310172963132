import BankProviderSettings from './pages/BankProviderSettings'
import BankPayments from './pages/BankPayments'
import PropertyPayments from './pages/PropertyPayments'
import PropertyPaymentProducts from './pages/PropertyPaymentProducts'


export default {
  name: 'Payment',
  components: {
    'BankProviderSettings': {
      component: BankProviderSettings
    },
    'BankPayments': {
      component: BankPayments
    },
    'PropertyPayments': {
      component: PropertyPayments
    },
    'PropertyPaymentProducts': {
      component: PropertyPaymentProducts
    },
  }
}

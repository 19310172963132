import React from 'react'

import renderers from './ModelRenderers'

const ModelField = ({
  fieldSchema,
  entity,
  fieldName
}) => {
  const { valueType, schemaType } = fieldSchema
  const value = entity[fieldSchema.name]

  let renderer
  if (valueType === 'Scalar') {
    renderer = renderers[schemaType]
  } else if (valueType === 'Object') {
    renderer = renderers.Object
  }

  let content = renderer ? renderer(value, fieldSchema, fieldName) : value

  return content !== undefined ? content : null
}

export default ModelField
import React from 'react'

// Riva
import { useDispatch } from 'react-redux'
import useFileSelector from './useFileSelector'
import uploadActions from 'reactor/systems/upload/actions'


const useFileUpload = (options={}) => {
  const { acceptedFileKinds, elementName, actionName, data, callbacks = {}, ...restOptions } = options
  const [openFileDialog, state, reset, start, error, retry, success, progress] = useFileSelector({...restOptions, acceptedFileKinds})
  const {files, rejectedFiles, errorFiles, uploadingFiles} = state
  const dispatch = useDispatch()
  const [uploadResult, uploadResultSet] = React.useState()

  const retryHandler = (file) => {
    retry(file)
    dispatch(uploadActions.retry({file, uploadData: file.uploadData, elementName, actionName}, {
      progress: (data) => {
        callbacks.progress && callbacks.progress(data)
        dispatch(uploadActions.progress(data))
        progress(data.fileId, data.progress)
      },
      error: (err) => {
        callbacks.error && callbacks.error(err)
        error(err.file)
      },
      success: (response) => {
        const { fileId } = response
        success(fileId)
        callbacks.success && callbacks.success(response)
        uploadResultSet(response)
        // reset()
      },
    }))
  }

  React.useEffect(() => {
    if (!files || files.length == 0) return
    dispatch(uploadActions.upload({files: files, elementName, actionName, ...data}, {
      start: (files) => {
        callbacks.start && callbacks.start(files)
        start(files)
      },
      success: (response) => {
        const { fileId } = response
        success(fileId)
        callbacks.success && callbacks.success(response)
        uploadResultSet(response)
        // reset()
      },
      error: (err) => {
        callbacks.error && callbacks.error(err)
        error(err.file)
      },
      progress: (data) => {
        callbacks.progress && callbacks.progress(data)
        dispatch(uploadActions.progress(data))
        progress(data.id, data.progress)
      }
    }))
  }, [files])
  return [openFileDialog, state, retryHandler, uploadResult]
}

export default useFileUpload
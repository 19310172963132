import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import MediaItem from 'elements/rnx_broadcast/rnxBroadcast/components/MediaItem'


const CampaignManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<CampaignHome />} />
      <RV.Route path='campaign/:rnxBrdAdvCampaignId/*' element={<CampaignDetail />} />
    </RV.Routes>
  )
}

const CampaignHome = ({

}) => {
  const { t } = RV.useTranslation()

  return (
    <RV.GraphTable
      modelName={'RnxBrdAdvCampaign'}
      detailLink={'campaign'}
    />
  )
}

const CampaignDetail = ({

}) => {
  return (
    <RV.EntityDetailPage
      modelName='RnxBrdAdvCampaign'
      fields={[
        'name', 'rnxContact', 'activeAdvCount', 'passiveAdvCount'
      ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/' element={(
            <Box key={`${entity.activeAdvCount}-${entity.passiveAdvCount}`}>
              <CampaignAdvertisements header='Aktif' filters={{ byAvailability: true }} refresh={result.refresh} rnxBrdAdvCampaign={entity} />
              <Box my={3} />
              <CampaignAdvertisements header='Pasif' filters={{ byAvailability: false }} refresh={result.refresh} rnxBrdAdvCampaign={entity} />
            </Box>
          )} />
        )
      ]}
    />
  )
}

const CampaignAdvertisements = ({
  refresh,
  filters,
  rnxBrdAdvCampaign,
  header
}) => {
  const result = RV.useReactorQuery({
    rnxBrdAdvAdvertisementList: {
      filters: {
        rnx_brd_adv_campaign_id: rnxBrdAdvCampaign.id,
        ...filters
      },
      itemFields: [
        'name', { 'rnxBroadcastMedia': { fields: ['kind', 'name', 'mediaFile'] } }, 'status', 'dateStart', 'dateFinish'
      ]
    }
  })
  return (
    <RV.QueryLoading result={result}>
      {({ rnxBrdAdvAdvertisementList }) => {
        return (
          <>
            <RV.Card.Header>
              {header}
            </RV.Card.Header>
            {rnxBrdAdvAdvertisementList.items.length === 0 && (
              <RV.Alert status='info'>
                Kayıt yok.
              </RV.Alert>
            )}
            <Flex sx={{
              flexWrap: 'wrap',
              mx: -2,
              mt: 2
              // justifyContent: 'space-evenly'
            }}>
              {rnxBrdAdvAdvertisementList.items.map(a => {
                return (
                  <Box key={a.id}>
                    <MediaItem item={a.rnxBroadcastMedia} showActionMenu={
                      <RV.ActionGroupMenu name={'RnxBrdAdvAdvertisement'} contexts={['Entity']} ctx={{ id: a.id }} callbacks={{ onSuccess: () => refresh() }} />
                    }>
                      <RV.Layout.Row>
                        <RV.Enum value={a.status} />
                        <RV.DateTime data={a.dateStart} />
                        <RV.DateTime data={a.dateFinish} />
                      </RV.Layout.Row>
                    </MediaItem>
                  </Box>
                )
              })}
            </Flex>
          </>
        )
      }}
    </RV.QueryLoading>
  )
}

export default CampaignManagement
import React from 'react'

// Vendor
import { useField } from 'react-final-form'

// Reactor UI
import * as changeCase from 'reactor-ui/util/text'


const processors = {
  setBasedOnField: (processor, field, input, meta) => {
    const targetFieldName = processor.field
    const targetField = useField(targetFieldName)
    const valueRef = React.useRef()
    if (!valueRef.current) valueRef.current = input.value

    React.useEffect(() => {
      if (input.value && input.value != valueRef.current) return
      let newVal
      if (processor.case === 'snake') {
        newVal = changeCase.snake(targetField.input.value)
      }
      input.onChange(newVal)
      valueRef.current = newVal

    }, [targetField.input.value])
  }
}

export default processors
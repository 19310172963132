import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import isObject from 'lodash/isObject'
import Select from 'react-select'
import { useTheme } from '@emotion/react'
import * as changeCase from 'reactor-ui/util/text'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import TextInput from 'reactor-ui/components/TextInput'
import SwitchInput from 'reactor-ui/components/SwitchInput'
import DateInput from 'reactor-ui/components/DateInput'
import SelectInput from 'reactor-ui/components/SelectInput'

import DynamicComponent from 'reactor-vera/apps/blocks/components/DynamicComponent'
import { isString, noop } from 'lodash'


const FilterInput = ({
  label,
  target,
  filterInstance,
  kind,
  schema,
  componentName,
  name,
  ctx,
  focus = true,
  value: valueFromProps,
  direction,
  onChange: onChangeFromProps = () => { },
  options
}) => {
  // const { locationFilters, updateLocationFilters } = filterInstance
  const [value, setValue] = React.useState(valueFromProps || filterInstance?.locationFilters[target] || '')

  const updateLocationFiltersDebounced = React.useCallback(debounce(filterInstance?.updateLocationFilters || noop, 500), [])
  const onChangeDebounced = React.useCallback(debounce(onChangeFromProps, 500), [])

  React.useEffect(() => {
    return () => {
      const targetName = schema?.valueType === 'Object' ? `${target}.name` : target
      filterInstance?.updateLocationFilters({ [targetName]: undefined }, {mode: 'replace'})
    }
  }, [])

  const onChange = React.useCallback((e) => {
    const val = e?.target ? e.target.value : e
    setValue(val)
    onChangeFromProps && onChangeDebounced?.(val)
  }, [])

  React.useEffect(() => {
    const targetName = schema?.valueType === 'Object' ? `${target}.name` : target
    filterInstance && updateLocationFiltersDebounced({ [targetName]: value }, {mode: 'replace'})
  }, [value])

  if (componentName) {
    return <DynamicComponent name={componentName} filterName={name} focus={focus} onChange={onChange} value={value} label={label} ctx={ctx} />
  } else if (kind == 'String') {
    return <TextFilter focus={focus} onChange={onChange} value={value} label={label} />
  } else if (kind === 'Integer') {
    return <TextFilter kind='number' onChange={onChange} value={value} label={label} />
  } else if (kind === 'Option') {
    return <OptionFilter onChange={onChange} value={value} label={label} schema={schema} />
  } else if (schema?.valueType === 'Object') {
    return <TextFilter onChange={onChange} value={value} label={label} schema={schema} />
  } else if (kind === 'Date' || kind == 'DateTime') {
    return <DateTimeFilter direction={direction} onChange={onChange} value={value} label={label} schema={schema} />
  } else if (kind === 'Boolean' || kind === 'Bool') {
    return <BooleanFilter onChange={onChange} value={value} label={label} schema={schema} />
  } else if (kind === 'Dynamic') {
    return <DynamicFilter onChange={onChange} updateLocationFiltersDebounced={updateLocationFiltersDebounced} filterInstance={filterInstance} target={target} value={value} label={label} schema={schema} options={options} />
  } else {
    console.warn('Unknown filter', kind)
    return null
  }
}

export const TextFilter = ({
  onChange,
  value,
  label,
  kind,
  focus
}) => {
  const [textVal, setTextVal] = React.useState(value?.value || kind === 'number' ? null : '')
  const [comparisonVal, setComparisonVal] = React.useState(value?.comparison || null)
  const textOnChange = React.useCallback((e) => {
    const val = e?.target ? e.target.value : e
    setTextVal(val)
  }, [])
  const comparisonOnChange = React.useCallback((e) => {
    setComparisonVal(e.target.checked && 'EQUALS')
  })

  React.useEffect(() => {
    if (textVal) {
      const searchVal = comparisonVal ? ({
        value: textVal,
        comparison: comparisonVal
      }) : (
        textVal
      )

      onChange(searchVal)
    } else {
      onChange(null)
    }
  }, [textVal, comparisonVal])
  return (
    <Flex alignItems='center'>
      <TextInput label={label} type={kind} value={textVal} onChange={textOnChange} focus={focus} requiredLabel={false} />
      {kind != 'number' && (
        <Box>
          <Box fontSize='sm'>
            Tam
          </Box>
          <Box>
            <SwitchInput sx={{ my: 0, py: 0 }} isChecked={comparisonVal} onChange={comparisonOnChange} />
          </Box>
        </Box>
      )}
    </Flex>
  )
}

export const OptionFilter = ({
  onChange,
  value,
  label,
  schema
}) => {
  const { t } = useTranslation()
  const options = []

  schema?.meta?.options.forEach(opt => {
    options.push({
      value: opt,
      label: t(`enum.${opt.toLowerCase()}`)
    })
  })

  return (
    <SelectInput
      onChange={onChange}
      isMulti
      isClearable
      value={value}
      options={options}
      placeholder={label}
    />
  )
}

export const DateTimeFilter = ({
  onChange,
  value,
  label,
  direction,
  schema
}) => {
  const options = [
    {
      value: {
        range: '@today',
        days: 1
      },
      valueKey: '@today',
      label: 'Bugün',
    },
    {
      value: {
        range: '@currentWeek',
        days: 7
      },
      valueKey: '@currentWeek',
      label: 'Bu Hafta',
    },
    {
      value: {
        range: '@currentMonth',
        days: 30
      },
      valueKey: '@currentMonth',
      label: 'Bu Ay',
    },
    {
      value: {
        range: '@currentYear',
        days: 365
      },
      valueKey: '@currentYear',
      label: 'Bu Yıl',
    },
    {
      value: {
        range: '@lastWeek',
        days: 7
      },
      valueKey: '@lastWeek',
      label: 'Geçen Hafta',
    },
    {
      value: {
        range: '@lastMonth',
        days: 30
      },
      valueKey: '@lastMonth',
      label: 'Geçen Ay',
    },
    {
      value: {
        range: '@lastYear',
        days: 365
      },
      valueKey: '@lastYear',
      label: 'Geçen Yıl',
    },
    {
      value: {
        range: '@last7days',
        days: 7
      },
      valueKey: '@last7days',
      label: 'Son 7 gün',
    },
    {
      value: {
        range: '@last30days',
        days: 30
      },
      valueKey: '@last30days',
      label: 'Son 30 gün',
    }
  ]

  const selectInput = (
    <SelectInput
      onChange={onChange}
      isClearable
      value={isObject(value) && value.range ? value.range : null}
      options={options}
      placeholder={label || 'Aralık'}
    />
  )

  const startInput = (
    <DateInput label='Başlangıç' onChange={(val) => {
      if (!isString(val)) {
        val.setHours(0, 0, 0, 0)
      }
      let newVal
      if (isObject(value)) {
        newVal = {
          ...value,
          start: val
        }
      } else {
        newVal = {
          start: val
        }
      }
      onChange(newVal)
    }} value={value?.start} />
  )

  const endInput = (
    <DateInput label='Bitiş' onChange={(val) => {
      if (!isString(val)) {
        val.setHours(23, 59, 59, 0)
      }
      let newVal
      if (isObject(value)) {
        newVal = {
          ...value,
          end: val
        }
      } else {
        newVal = {
          end: val
        }
      }
      onChange(newVal)
    }} value={value?.end} />
  )

  if (direction == 'horizontal') {
    return (
      <Flex mx={-2} alignItems='center'>
        <Box mx={2}>
          {selectInput}
        </Box>

        <Box mx={2}>
          {startInput}
        </Box>

        <Box mx={2}>
          {endInput}
        </Box>
      </Flex>
    )
  }

  return (
    <>
      {selectInput}
      {startInput}
      {endInput}
    </>
  )
}

export const BooleanFilter = ({
  onChange,
  value,
  label
}) => {
  const options = [
    {
      value: true,
      label: 'Evet'
    },
    {
      value: false,
      label: 'Hayır'
    }
  ]

  return (
    <SelectInput
      onChange={onChange}
      isClearable
      value={value}
      options={options}
      placeholder={label}
    />
  )
}

export const DynamicFilter = ({
  options,
  filterInstance,
  target,
  label,
  onChange,
  value,
  updateLocationFiltersDebounced,
  ...props
}) => {
  const loadCountRef = React.useRef()
  if (loadCountRef.current === undefined) loadCountRef.current = 0
  const { t } = useTranslation()
  const queryName = `${options.model}Meta`
  const result = useReactorQuery({
    [queryName]: {
      fields: [
        {'primaryFilters': {
          exclude: options.exclude
        }},
      ],
    }
  })
  const filters = result.graph?.[queryName]?.primaryFilters?.[0]?.filters
  if (!filters) return null

  return (
    <Flex alignItems='center' mx={-2}>
      {filters.map((f, dx) => {
        return (
          <Box key={`${dx}`} sx={{
            mx: 2
          }}>
            <FilterInput
              onChange={(val) => {
                // Hack to prevent flickering
                if (filters.length != loadCountRef.current) {
                  loadCountRef.current += 1
                  return
                }
                const v = {
                  ...value,
                  [f.name]: val
                }
                onChange(v)
              }}
              focus={false}
              direction='horizontal'
              // label={f.label || label}
              label={t(`field.${changeCase.snake(f.name)}`, f.name)}
              name={f.name}
              target={`${target}.${f.name}`}
              kind={f.inputType}
              // filterInstance={filterInstance}
              componentName={f.componentName} ctx={f.ctx}
            />
          </Box>
        )
      })}
    </Flex>
  )
}

export default FilterInput
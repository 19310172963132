import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'
import Alert from 'reactor-ui/components/Alert'
import Icon from 'reactor-ui/components/Icon'
import InfoLine from 'reactor-ui/components/InfoLine'
import useOnClickOutside from 'reactor-ui/hooks/useOnClickOutside'

// Reactor
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import { isFunction } from 'lodash'


const RfInplaceEditForm = ({
  formId,
  actionKey,
  initialValues,
  hasValue,

  onSuccess,
  onError,
  onClose,

  fields,
  anchorKind,
  anchor,

  showIcon = true,
  icon = 'pencil',
  sx,
  isResetEnabled = true,
  fieldElements,

  label,

  children,
  ...rest
}) => {
  const { t } = useTranslation()
  const hasUpload = React.useMemo(() => {
    let hasUpload = false
    fields?.forEach(field => {
      if (field.kind === 'file') hasUpload = true
    })
    return hasUpload
  }, [])
  const [mode, setMode] = React.useState()
  const [formProps, isSubmitting, reset, submit] = useReactorActionForm(
    actionKey,
    {
      hasUpload,
      initialValues,
      onSuccess: (data) => {
        onSuccess?.(data)
        setMode()
      },
      onError,
      id: formId
    }
  )
  const formRef = React.useRef()
  // console.log(formProps)
  const close = () => {
    submit()
    setMode()
  }
  useOnClickOutside(formRef, close, mode === 'EDIT')

  let content

  // if (formProps.phase === 'SUCCESS') {

  if (mode === 'EDIT') {
    content = (
      <Box ref={formRef}>
        <Form {...formProps}>
          {fields?.map(({ label, optional, ...field }, dx) => {
            return (
              <RfFormField disabled={isSubmitting} autoFocus={dx === 0} sx={{ textTransform: 'capitalize' }} required={!optional} optional={optional} label={t(label)} {...field} key={dx} />
            )
          })}
          {/* {fieldElements}
        <RfFormError error={formProps.error} />
        <Button mt={3} textTransform='capitalize' colorScheme={buttonColorScheme} tabIndex={4} submit isLoading={isSubmitting} isFullWidth>
          {t(buttonText)}
        </Button> */}
        </Form>
      </Box>
    )
  } else if (hasValue) {
    content = (
      <>
        <Box as='a' onClick={() => setMode('EDIT')}>
          {showIcon ? (
            <Icon name={icon} size={4} color='brand.500'>
            {children}
            </Icon>
          ) : (
            children
          )}
        </Box>
      </>
    )
  } else {
    content = (
      <>
        <Box as='a' color='brand.500' onClick={() => setMode('EDIT')}>
          Ekle
        </Box>
      </>
    )
  }

  const rendered = (
    <Box sx={sx} >
      {content}
    </Box>
  )

  if (label) {
    return (
      <InfoLine label={label} value={rendered}/>
    )
  } else {
    return rendered
  }
}

export default RfInplaceEditForm
// Vendor
import axios from 'axios'
import { select } from 'redux-saga/effects'


export function* reactorQueryRequest(payload, action) {
  const dataToSend = {...payload}

  if (!dataToSend['@rcTenantId']) {
    dataToSend['@rcTenantId'] = yield select((state) => (state.reactor.activeTenantId))
  }

  const baseAPIUrlFromState = yield select((state) => state.reactor?.config?.baseAPIUrl || '')
  const baseAPIUrl = action?.meta?.req?.baseUrl || baseAPIUrlFromState

  let baseHeaders = {
    'X-REACTOR-CHANNEL': yield select((state) => state.reactor.config.channel)
  }
  if (action?.meta?.headers) {
    baseHeaders = {...baseHeaders, ...action.meta.headers}
  }

  const url = `${baseAPIUrl}/__reactor/api/reactor_query`

  // const url = action?.meta?.req?.url || (action?.meta?.remoteName ? (
  //   `${baseAPIUrl}/_api/runic/remote/${action.meta.remoteName}/graph_query`
  // ) : (
  //   `${baseAPIUrl}/_api/runic/graph_query`
  // ))

  if (typeof window === "undefined") {
    const req = yield select((state) => state.reactor.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.get(`${url}`, {params: dataToSend, headers: {...baseHeaders, ...headers} })
  } else {
    return yield axios.get(`${url}`, {params: dataToSend, headers: baseHeaders})
  }

}

export function* resourceRequest(payload, action) {
  const dataToSend = payload?.variables || {}

  if (!dataToSend['@rcTenantId']) {
    dataToSend['@rcTenantId'] = yield select((state) => (state.reactor.activeTenantId))
  }

  const baseAPIUrlFromState = yield select((state) => state.reactor?.config?.baseAPIUrl || '')
  const baseAPIUrl = action?.meta?.req?.baseUrl || baseAPIUrlFromState

  let baseHeaders = {
    'X-REACTOR-CHANNEL': yield select((state) => state.reactor.config.channel)
  }
  if (action?.meta?.headers) {
    baseHeaders = {...baseHeaders, ...action.meta.headers}
  }

  const url = `${baseAPIUrl}/__reactor/api/resource/${payload.query}`

  // const url = action?.meta?.req?.url || (action?.meta?.remoteName ? (
  //   `${baseAPIUrl}/_api/runic/remote/${action.meta.remoteName}/graph_query`
  // ) : (
  //   `${baseAPIUrl}/_api/runic/graph_query`
  // ))

  if (typeof window === "undefined") {
    const req = yield select((state) => state.reactor.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.get(`${url}`, {params: dataToSend, headers: {...baseHeaders, ...headers} })
  } else {
    return yield axios.get(`${url}`, {params: dataToSend, headers: baseHeaders})
  }

}

export function* reactorActionRequest(actionName, data = {}, kind = 'action', action) {
  const dataToSend = data
  const urlParams = {}

  if (!urlParams['@rcTenantId']) {
    urlParams['@rcTenantId'] = yield select((state) => (state.reactor.activeTenantId))
  }

  const baseAPIUrlFromState = yield select((state) => state.reactor?.config?.baseAPIUrl || '')
  const baseAPIUrl = action?.meta?.req?.baseUrl || baseAPIUrlFromState

  let baseHeaders = {
    'X-REACTOR-CHANNEL': yield select((state) => state.reactor.config.channel)
  }
  if (action?.meta?.headers) {
    baseHeaders = {...baseHeaders, ...action.meta.headers}
  }

  if (action?.meta?.req?.headers) {
    baseHeaders = {...baseHeaders, ...action.meta.req.headers}
  }

  // if (dataToSend._rcTenantId) {
  //   if (dataToSend._rcTenantId !== '__DISABLE') urlParams.rcTenantId = `${dataToSend._rcTenantId}`
  //   else delete dataToSend._rcTenantId
  // } else if (dataToSend.rcTenantCodeName) {
  //   urlParams.rcTenantCodeName = `${data.rcTenantCodeName}`
  //   delete dataToSend.rcTenantCodeName
  // } else {
  //   urlParams.rcTenantId = yield select((state) => (state.reactor.config.isMultiTenant ? state.reactor.activeTenantId : 1))
  // }

  const url = action?.meta?.remoteName ? (
    `${baseAPIUrl}/_api/runic/remote/${action.meta.remoteName}/${kind}/${actionName}`
  ) : (
    `${baseAPIUrl}/__reactor/api/${kind}/${actionName}`
  )

  if (typeof window === "undefined") {
    const req = yield select((state) => state.reactor.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.post(url, dataToSend, {headers: {...baseHeaders, ...headers}, params: urlParams})
  } else {
    return yield axios.post(url, dataToSend, {params: urlParams, headers: baseHeaders})
  }
}

export const callCallbacks = (action, kind, payload) => {
  const _kind = kind.toLowerCase()
  if (action.callbacks && action.callbacks[_kind]) {
    return action.callbacks[_kind](payload)
  }
}

import React from 'react'

// Vendor
import { useDispatch } from 'react-redux'

// Rncui
import runicVeraActions from 'reactor-vera/systems/reactor-vera/actions'

const useRncActivate = (kind, activated, deactivate) => {
  const dispatch = useDispatch()

  let action
  let deactivateAction
  if (kind === 'domain') action = runicVeraActions.rncActivateDomain({ domainName: activated.name })
  else if (kind === 'unit') action = runicVeraActions.rncActivateUnit({ unitName: activated.name })
  else if (kind === 'detail') {
    action = runicVeraActions.rncActivateDetail(activated)
    deactivateAction = runicVeraActions.rncDeactivateDetail()
  }

  React.useEffect(() => {
    dispatch(action)
    if (deactivateAction) return () => dispatch(deactivateAction)
  }, [activated])
}

export default useRncActivate
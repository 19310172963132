import React from 'react'

// Vendor
import dayjs from 'dayjs'
import { useTable } from 'react-table'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import RfActionForm from 'reactor-form/components/RfActionForm'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from 'reactor-ui/components/Table'
import Card from 'reactor-ui/components/Card'

const TemplateProgram = ({
  rnxAsScheduleTemplate
}) => {
  const result = useReactorQuery({
    'rnxAsActivityTemplateList': {
      filters: {
        rnxAsScheduleTemplateId: rnxAsScheduleTemplate.id
      },
      itemFields: [
        'name', 'day', 'timeStart', 'timeFinish', { rnxAsActivityKind: { fields: ['name', 'limit', 'color'] } }
      ],
      limit: 500
    },
  })
  const rnxAsActivityTemplateList = result.graph?.rnxAsActivityTemplateList
  console.log('rnxAsActivityTemplateList', rnxAsActivityTemplateList)
  if (!rnxAsActivityTemplateList) return null


  return (
    <Card mt={4}>
      <TemplateProgramContent rnxAsScheduleTemplate={rnxAsScheduleTemplate} result={result} rnxAsActivityTemplateList={rnxAsActivityTemplateList} />
    </Card>
  )
}


const TemplateProgramContent = ({
  rnxAsScheduleTemplate,
  rnxAsActivityTemplateList,
  result
}) => {
  const scheduleOptions = rnxAsScheduleTemplate.rnxAsSchedule.scheduleOptions
  const startHourSplit = scheduleOptions.start_hour.split(':')
  const endHourSplit = scheduleOptions.end_hour.split(':')

  console.log(rnxAsActivityTemplateList)

  const today = dayjs().startOf('today')
  const start = today.hour(startHourSplit[0]).minute(startHourSplit[1])
  const end = today.hour(endHourSplit[0]).minute(endHourSplit[1])

  const interval = parseInt(scheduleOptions.interval)
  const length = parseInt(scheduleOptions.length)

  const activities = React.useMemo(() => {
    const result = {}
    rnxAsActivityTemplateList.items?.forEach(a => {
      const startHourSplit = a.timeStart.split(':')
      const hour = parseInt(startHourSplit[0])

      if (!result[hour]) result[hour] = {}
      result[hour][a.day] = a
    })
    return result
  }, [rnxAsActivityTemplateList])

  const hours = React.useMemo(() => {
    const hours = []
    while (hours.length == 0 || (hours[hours.length - 1].end.add(interval + length, 'minute') < end)) {
      let prevEnd
      if (hours.length) {
        prevEnd = hours[hours.length - 1].end
      } else {
        prevEnd = start.subtract(interval, 'minute')
      }

      const nextStart = prevEnd.add(interval, 'minute')
      const nextEnd = nextStart.add(length, 'minute')

      const next = {
        start: nextStart,
        end: nextEnd,
        id: nextStart,
        startTime: `${nextStart.hour()}:${nextStart.minute()}`,
        endTime: `${nextEnd.hour()}:${nextEnd.minute()}`,
        ...activities[nextStart.hour()]
      }

      hours.push(next)
    }
    return hours
  }, [rnxAsActivityTemplateList])

  console.log('-hours', hours)

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: 'Saatler',
        id: 'hours',
        // renderer: (cell) => <ActionGroupMenu name={modelName} contexts={['Entity']} ctx={{ id: cell.row.id }} />,
        // alignLast: true,
      },
      {
        Header: 'Pazartesi',
        id: 0,
        accessor: '0'
      },
      {
        Header: 'Salı',
        id: 1,
        accessor: '1',
      },
      {
        Header: 'Çarşamba',
        id: 2,
        accessor: '2',
      },
      {
        Header: 'Perşembe',
        id: 3,
        accessor: '3',
      },
      {
        Header: 'Cuma',
        id: 4,
        accessor: '4',
      },
      {
        Header: 'Cumartesi',
        id: 5,
        accessor: '5',
      },
      {
        Header: 'Pazar',
        id: 6,
        accessor: '6',
      },
    ]
  }, [])

  const tableRows = hours

  const tableInstance = useTable({
    columns: tableColumns,
    data: tableRows,
    getRowId: (row) => row.id
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <Table {...getTableProps()} variant="striped" colorScheme="light" size='sm'>
      <Thead>
        {headerGroups.map(headerGroup => {
          return (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, dx) => {
                const { key, ...headerProps } = column.getHeaderProps()
                return (
                  <Th>
                    {column.Header}
                  </Th>
                )
              })}
            </Tr>
          )
        })}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, dx) => {
          prepareRow(row)
          // console.log(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell, cellDx) => {
                const { key, cellProps } = cell.getCellProps()
                if (cell.column.id === 'hours') {
                  return (
                    <Td key={key}>
                      {row.original.start.format('HH:mm')} - {row.original.end.format('HH:mm')}
                    </Td>
                  )
                }

                const dayId = cell.column.id
                const startTime = row.original.startTime
                const endTime = row.original.endTime

                // const bg = cell.value?.rnxAsActivityKind?.color

                return (
                  <Td sx={{
                    // bg: bg
                  }}>
                    {cell.value ? (
                      <Box sx={{
                        textAlign: 'center',
                        p: 3,
                        bg: cell.value?.rnxAsActivityKind?.color
                      }}>
                        {cell.value.name}
                        {/* {console.log('cell', cell)} */}

                        <RfActionForm name='sil' actionKey='RnxActivitySchedule.DeleteAsActivityTemplate' fields={[

                        ]} buttonText={'btn.delete'} initialValues={{
                          id: cell.value.id
                        }} onSuccess={() => result.refresh()} />
                      </Box>
                    ) : (
                      <Box sx={{
                        textAlign: 'center',
                      }}>
                        <RfActionForm name='ekle' actionKey='RnxActivitySchedule.CreateAsActivityTemplate' fields={[
                          {
                            name: 'rnxAsActivityKind',
                            label: 'Tür',
                            kind: 'entity'
                          },
                          {
                            name: 'name',
                            label: 'isim',
                            optional: true
                          },
                        ]} initialValues={{
                          dayId,
                          startTime,
                          endTime,
                          rnx_as_schedule_template_id: rnxAsScheduleTemplate.id
                        }} onSuccess={() => result.refresh()} />
                      </Box>
                    )}
                  </Td>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default TemplateProgram
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { setIn, getIn } from 'final-form'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Alert from 'reactor-ui/components/Alert'
import Button from 'reactor-ui/components/Button'
import Card from 'reactor-ui/components/Card'
import DateTime from 'reactor-ui/components/DateTime'
import { isArray, isInteger } from 'lodash'
import RefreshButton from 'reactor-vera/apps/data/components/RefreshButton'


const widthMap = {
  MINI: 50,
  SMALL: 30,
  DEFAULT: 100,
  MEDIUM: 200,
  LARGE: 300,
  XLARGE: 500
}

const SimpleTable = ({
  items,
  bottomItems,
  columns,
  actions,
  sx,
  alignLast,
  maxHeight,
  header,
  modelActions,
  sortFn,
  refresh,
  result,
  isFetching,
  hasStripe = true,
  overTableContent,
  hasTotalRow
}) => {
  const { t } = useTranslation()
  if (!items) return null

  const cols = columns.map(({ label, key, widthKey, flexGrow, ...rest }) => {
    if (key == '@dx') {
      if (!widthKey) widthKey = 'MINI'
      if (!flexGrow) flexGrow = 0
    }

    return {
      label,
      key,
      widthKey,
      flexGrow,
      ...rest
    }
  })

  let rows = items.map(item => {
    const val = { ...item }
    cols.forEach(col => {
      if (col.getter) return val[col.key] = col.getter(item)
    })
    return val
  })

  if (sortFn) rows = rows.sort(sortFn)

  if (bottomItems) {
    rows = [...rows, ...bottomItems.map(item => {
      const val = { ...item }
      cols.forEach(col => {
        if (col.getter) return val[col.key] = col.getter(item)
      })
      return val
    })]
  }

  if (hasTotalRow) {
    const totalRow = {}
    rows.forEach(item => {
      const val = { ...item }
      cols.forEach((col, dx) => {
        if (dx === 0) {
          totalRow[col.key] = 'Toplam'
          totalRow['id'] = '@total'
          return
        }
        if (col.getter) val[col.key] = col.getter(item)
        if (!isInteger(val[col.key])) return
        if (totalRow[col.key] === undefined) totalRow[col.key] = 0
        totalRow[col.key] += val[col.key]
      })
    })
    rows.push(totalRow)
  }

  const extraHeaderHeight = 0
  const rowHeight = 40
  let rowCount = rows.length
  const headerHeight = 40 + extraHeaderHeight
  const calculatedHeight = (rowHeight * rowCount)

  const calculatedMaxHeight = maxHeight === undefined ? window.innerHeight - 160 - extraHeaderHeight : maxHeight
  const tableHeight = calculatedMaxHeight ? calculatedHeight < calculatedMaxHeight ? calculatedHeight : calculatedMaxHeight : calculatedHeight

  return (
    <Card sx={{
      ...sx
    }}>
      <AutoSizer disableHeight>
        {({ width }) => (
          <>
            <Flex sx={{
              borderBottom: '1px solid #FFF',
              borderBottomColor: 'background3',
              // height: headerHeight,
              width: width,
              pb: 3,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Flex sx={{
                alignItems: 'center'
              }}>
                <Card.Header>
                  {header}
                </Card.Header>
                {refresh && <Box sx={{
                  ml: 2
                }}>
                  <Button iconName='refresh' iconSize={4} colorScheme='brand' onClick={refresh} isLoading={isFetching} />
                </Box>}
                {result && <RefreshButton result={result} />}
              </Flex>
              <Flex alignItems={'center'}>
                {modelActions}
              </Flex>
            </Flex>

            <Box width={width}>
              {overTableContent}
            </Box>
            {/* {overTableContent && isArray(overTableContent) ? (
              overTableContent.map((c, dx) => {
                return (
                  <Box key={dx}>
                    {c}
                  </Box>
                )
              })
            ) : overTableContent} */}

            <Flex sx={{
              // height: headerHeight,
              width: width,

              borderBottom: '1px solid #FFF',
              borderBottomColor: 'background3',
            }}>
              {cols.map(({ label, key, widthKey, flexGrow }, colDx) => {
                return (
                  <Box sx={{
                    flex: `${flexGrow !== undefined ? flexGrow : 1} 1 ${widthMap[widthKey || 'DEFAULT']}px`,
                    // py: 15,
                    color: 'dark.300',
                    textTransform: 'uppercase',
                    fontSize: 'sm'
                  }} key={key}>
                    {alignLast && colDx === cols.length - 1 ? (
                      <Box sx={{
                        float: 'right',
                        display: 'inline-block',
                      }}>
                        {label}
                      </Box>
                    ) : (
                      label
                    )}
                  </Box>
                )
              })}
            </Flex>
            {result && result?.status?.status === 'ERROR' ? (
              <Box sx={{width: width}}>
                <Alert>
                  {result.status?.error?.kind === 'NOT_AUTHORIZED' ? t('text.unauthorized') : t('text.fetch_error')}

                  <Button ml={3} size='sm' onClick={result.refresh} sx={{
                    textTransform: 'capitalize',
                  }}>{t('op.refresh')}</Button>

                  {/* {result.status?.error?.kind !== 'NOT_AUTHORIZED' && <Button ml={3} size='sm' onClick={result.refresh} sx={{
                textTransform: 'capitalize',
              }}>{t('op.refresh')}</Button>} */}
                </Alert>
              </Box>
            ) : result && result?.status?.status === 'FETCHING' ? (
              <Box sx={{width: width}}>
                Yükleniyor
              </Box>
            ) : (
              <List
                itemData={{ items: rows, columns: cols, actions, count: rows?.length, alignLast, hasStripe }}
                itemKey={(dx, data) => data.items[dx].id}
                itemSize={rowHeight}
                itemCount={rowCount}
                height={tableHeight}
                width={width}
              >
                {Row}
              </List>
            )}
          </>
        )}
      </AutoSizer>
    </Card >
  )
}

const Row = ({
  data,
  index,
  style,
}) => {
  const item = data.items[index]
  const hasStripe = data.hasStripe && index % 2 === 0
  return (
    <Flex style={style} sx={{
      borderBottom: '1px solid #FFF',
      borderBottomColor: 'light.300',
      alignItems: 'center',
      bg: hasStripe ? 'light.100' : null,
      '&:hover': {
        bg: 'light.200',
      }
    }}>
      {data.columns.map(({ label, key, widthKey, flexGrow, kind, renderer }, colDx) => {
        let content
        if (key == '@dx') content = index + 1
        else if (renderer) content = renderer(item, key)
        else if (key == '@actions') content = (
          <Flex alignItems='center'>
            {data.actions?.map((act, dx) => act(item, dx))}
          </Flex>
        )
        else content = getIn(item, key)

        if (kind == 'date') content = <DateTime data={content} />
        return (
          <Box sx={{
            flex: `${flexGrow !== undefined ? flexGrow : 1} 1 ${widthMap[widthKey || 'DEFAULT']}px`,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
            // fontWeight: 'bold'
          }} key={key}>
            {data.alignLast && colDx === data.columns.length - 1 ? (
              <Box sx={{
                float: 'right',
                display: 'inline-block'
              }}>
                {content}
              </Box>
            ) : (
              content
            )}
          </Box>
        )
      })}
    </Flex>
  )
}

export default SimpleTable
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const DisplayManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<DisplayManagementHome />} />
    </RV.Routes>
  )
}

const DisplayManagementHome = ({

}) => {
  return (
    <>
      <RV.GraphTable
        modelName='RnxDisplay'
        primaryFilters={[
          {self: {}}
        ]}
        query={{
          itemFields: [
            'name', {rnxDevice: {fields: ['name', 'serial']}}, 'rnxBroadcast', 'kind',
          ],
          columns: [
            'name', 'rnxDevice.name', 'rnxDevice.serial', 'rnxBroadcast', 'kind'
          ]
        }}
        // detailLink='display'
      />
    </>
  )
}

export default DisplayManagement
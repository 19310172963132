import AuroraThemeZoneLayout from './AuroraThemeZoneLayout'
import AuroraThemeUnitLayout from './AuroraThemeUnitLayout'

import { PlexUiGroupItem } from '../default/sidebar/SidebarComponents'
import { PlexUiComponentItem } from './sidebar/SidebarComponents'

export default {
  components: {
    ZoneLayout: AuroraThemeZoneLayout,
    UnitLayout: AuroraThemeUnitLayout
  },
  sidebar: {
    PlexUiGroupItem: PlexUiGroupItem,
    PlexUiComponentItem: PlexUiComponentItem,
  }
}
import React from 'react'

// Vendor
import dayjs from 'dayjs'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import ReactorModal from 'reactor-ui/components/Modal'

import PropertyMembershipPrograms from '../components/PropertyMembershipPrograms'
import PropertyActivityGroups from '../components/PropertyActivityGroups'
import PropertyCommerceFacilities from '../components/PropertyCommerceFacilities'
import PropertySinglePasses from '../components/PropertySinglePasses'
import PropertyActivityPasses from '../components/PropertyActivityPasses'

import Loader from 'reactor-vera/apps/ui/components/Loader'
import Tooltip from 'reactor-ui/components/Tooltip'

const Home = ({

}) => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<HomeContent />} />
      {PropertyActivityGroups.routes.map(r => r)}
      {PropertyMembershipPrograms.routes.map(r => r)}
      {PropertyCommerceFacilities.routes.map(r => r)}
      {PropertySinglePasses.routes.map(r => r)}
      {PropertyActivityPasses.routes.map(r => r)}
    </RV.Routes>
  )
}


const HomeContent = props => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    rnxProperty: {
      fields: ['name', 'kind', 'status', 'todayCount', 'currentWeekCount', 'currentMonthCount', 'insideCount'],
      filters: {
        id: params.rnxPropertyId
      }
    }
  })
  return (
    <RV.QueryLoading result={result}>
      {({ rnxProperty }) => (
        <Box>
          <RV.Layout.Row>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'xl',
              color: 'brand.500'
            }}>
              <RV.Icon icon='home'>
                {rnxProperty.name}
              </RV.Icon>
            </Box>
            <RV.ActionGroup name={'RnxProperty'} contexts={['Entity']} ctx={{ id: rnxProperty.id }} />
          </RV.Layout.Row>
          <RV.BareTabs
            isLazy
            tabs={[
              {
                name: 'Genel',
                codeName: 'general',
                element: (
                  <RV.Layout.Cols sx={{
                    mt: 4
                  }}>
                    <Box>
                      <PropertyActivityGroups rnxProperty={rnxProperty} />
                      <RV.Box my={4} />
                      <PropertyMembershipPrograms rnxProperty={rnxProperty} />
                      <RV.Box my={4} />
                      <PropertyCommerceFacilities rnxProperty={rnxProperty} />
                      <RV.Box my={4} />
                      <PropertySinglePasses rnxProperty={rnxProperty} />
                      <RV.Box my={4} />
                      <PropertyActivityPasses rnxProperty={rnxProperty} />
                    </Box>

                    <Flex alignItems='center' flexWrap='wrap' mx={-2} my={-2} >
                      <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 2, flex: '1 1 40%', my: 2 }} colorDx={0} label='İçeride Kişi' value={rnxProperty.insideCount} info='Toplam tesisteki kişi sayısı' />
                      <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 2, flex: '1 1 40%', my: 2 }} colorDx={0} label='Bugün Kişi' value={rnxProperty.todayCount} info='Bugün toplam tesiste gelen kişi sayısı' />
                      <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 2, flex: '1 1 40%', my: 2 }} colorDx={0} label='Bu Hafta Kişi' value={rnxProperty.currentWeekCount} info='Bu Hafta toplam tesiste gelen kişi sayısı' />
                      <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 2, flex: '1 1 40%', my: 2 }} colorDx={0} label='Bu Ay Kişi' value={rnxProperty.currentMonthCount} info='Bu Ay toplam tesiste gelen kişi sayısı' />
                    </Flex>
                  </RV.Layout.Cols>
                )
              },

              {
                name: 'Abonelikler',
                codeName: 'subsicrptions',
                element: (
                  <>
                    <RV.GraphTable
                      modelName={'RnxActivityGroupSubscription'}
                      query={{
                        filters: {
                          'rnx_activity_group.owner_entity_key': rnxProperty.id
                        },
                        columns: [
                          'name', 'rnxContact.name', 'rnxContact.officialKey', 'rnxActivityGroupProgram', 'lengthText', 'status', 'datetimeStart', 'datetimeFinish'
                        ],
                        itemFields: [
                          'name', { rnxContact: { fields: ['name', 'officialKey'] } }, 'rnxActivityGroupProgram', 'lengthText', 'status', 'datetimeStart', 'datetimeFinish'
                        ]
                      }}
                    />
                  </>
                )
              },

              {
                name: 'Geçişler',
                codeName: 'passes',
                element: (
                  <>
                    <RV.GraphTable
                      modelName={'RnxPacAccessRecord'}
                      query={{
                        filters: {
                          'rnx_pac_access_point.location_entity_key': rnxProperty.id
                        }
                      }}
                    />
                  </>
                )
              },

              {
                name: 'Rezervasyonlar',
                codeName: 'reservations',
                element: (
                  <>
                    <RV.GraphTable
                      modelName={'RnxActivityGroupActivityReservation'}
                      query={{
                        itemFields: [
                          { 'rnxContact': { fields: ['name', 'officialKey'] } }, 'rnxActivityGroupActivity', 'rnxActivityGroupActivityKind', 'kind', 'datetimeStart', 'status'
                        ],
                        columns: [
                          'rnxContact.name', 'rnxContact.officialKey', 'rnxActivityGroupActivity', 'rnxActivityGroupActivityKind', 'kind', 'datetimeStart', 'status'
                        ],
                        filters: {
                          'rnx_activity_group.owner_entity_key': rnxProperty.id,
                        }
                      }}
                    />
                  </>
                )
              },

              {
                name: 'Günübirlik',
                codeName: 'daypass',
                element: (
                  <>
                    <RV.GraphTable
                      modelName={'RnxResourceTicket'}
                      detailLink='spd'
                      query={{
                        filters: {
                          owner_entity_key: rnxProperty.id
                        }
                      }}
                    />
                  </>
                )
              },

              {
                name: 'Günlük Reservasyon',
                codeName: 'reservation',
                element: (
                  <>
                    <RV.Layout.Cols sx={{ my: 4 }}>
                      <>

                        <TodayReservationActivities activityGroupOwnerEntityKey={rnxProperty.id} />
                        <TodayAllActivities activityGroupOwnerEntityKey={rnxProperty.id} />
                      </>
                      <PropertyActivityPasses rnxProperty={rnxProperty} />
                    </RV.Layout.Cols>
                    <RV.GraphTable
                      modelName={'RnxActivityGroupActivityReservation'}
                      detailLink='spsa'
                      query={{
                        itemFields: [
                          { 'rnxContact': { fields: ['name', 'officialKey'] } }, 'rnxActivityGroupActivity', 'rnxActivityGroupActivityKind', 'kind', 'datetimeStart', 'status'
                        ],
                        columns: [
                          'rnxContact.name', 'rnxContact.officialKey', 'rnxActivityGroupActivity', 'rnxActivityGroupActivityKind', 'kind', 'datetimeStart', 'status'
                        ],
                        filters: {
                          'rnx_activity_group.owner_entity_key': rnxProperty.id,
                          datetimeStart: '@today'
                        },
                        orderBy: 'datetimeStart|asc'
                      }}
                    />
                  </>
                )
              }
            ]} />
        </Box>
      )}
    </RV.QueryLoading>
  )
}

const TodayReservationActivities = ({
  activityGroupOwnerEntityKey
}) => {
  const result = RV.useReactorQuery({
    rnxActivityGroupActivityList: {
      // fields: ['name', 'kind', 'status', 'todayCount', 'currentWeekCount', 'currentMonthCount', 'insideCount'],
      filters: {
        'rnx_activity_group.owner_entity_key': activityGroupOwnerEntityKey,
        datetimeStart: '@today',
      },
      orderBy: 'datetimeStart|asc'
    }
  })

  return (
    <>
      <RV.Card>
        <RV.Layout.Row>
          <RV.Card.Header>
            Günün Rezervasyon Alınan Etkinlikleri
          </RV.Card.Header>
          {result.status?.isFetching ? (
            <Loader size={20} color='dark.500' />
          ) : (
            <Tooltip label='Yenile'>
              <Box as='a' sx={{
                display: 'block',
                ml: 2,
                color: 'dark.100',
                transition: 'all 0.2s ease',
                '&:hover': {
                  color: 'brand.500',
                }
              }} onClick={result.refresh}>
                <RV.Icon name='refresh' size={4} />
              </Box>
            </Tooltip>
          )}
        </RV.Layout.Row>
        <RV.QueryLoading result={result}>
          {({ rnxActivityGroupActivityList }) => {
            console.log(rnxActivityGroupActivityList)
            return rnxActivityGroupActivityList.items?.map(rnxActivityGroupActivity => {
              return (
                <RV.Layout.Row key={rnxActivityGroupActivity.id} my={2} alignItems='center'>
                  <ReactorModal
                    size='xl'
                    autoFocus={true}
                    // header={`${addon.name}`}
                    anchor={<RV.Button size='xs' icon='calendar' />}>
                    {({ onClose, isOpen }) => (
                      <>
                        {isOpen && <ProgramActivityReservations id={rnxActivityGroupActivity.id} />}
                      </>
                    )}
                  </ReactorModal>

                  {rnxActivityGroupActivity.name}

                  <Box>
                    <RV.DateTime format='DD.MM.YYYY H:mm' data={rnxActivityGroupActivity.datetimeStart} />
                  </Box>

                  <Box>
                    <RV.DateTime format='DD.MM.YYYY H:mm' data={rnxActivityGroupActivity.datetimeFinish} />
                  </Box>

                  <Box>
                    R: {rnxActivityGroupActivity.countReservation}/{rnxActivityGroupActivity.capacity}
                  </Box>

                  <Box>
                    <RV.ActionGroupMenu
                      name={'RnxActivityGroupActivity'} callbacks={{
                        onSuccess: result.refresh
                      }} contexts={['Entity']} ctx={{ id: rnxActivityGroupActivity.id }}
                    />
                  </Box>
                </RV.Layout.Row>
              )
            })
          }}
        </RV.QueryLoading>
      </RV.Card>
    </>
  )
}

const TodayAllActivities = ({
  activityGroupOwnerEntityKey
}) => {
  const result = RV.useReactorQuery({
    rnxActivityGroupProgramActivityList: {
      // fields: ['name', 'kind', 'status', 'todayCount', 'currentWeekCount', 'currentMonthCount', 'insideCount'],
      filters: {
        'rnx_activity_group.owner_entity_key': activityGroupOwnerEntityKey,
        day_of_week: dayjs().startOf('today').weekday() + 1,
      },
      orderBy: 'timeStart|asc'
    }
  })

  return (
    <>
      <RV.Card my={2}>
        <RV.Layout.Row>
          <RV.Card.Header>
            Günün Etkinlikleri
          </RV.Card.Header>
          {result.status?.isFetching ? (
            <Loader size={20} color='dark.500' />
          ) : (
            <Tooltip label='Yenile'>
              <Box as='a' sx={{
                display: 'block',
                ml: 2,
                color: 'dark.100',
                transition: 'all 0.2s ease',
                '&:hover': {
                  color: 'brand.500',
                }
              }} onClick={result.refresh}>
                <RV.Icon name='refresh' size={4} />
              </Box>
            </Tooltip>
          )}
        </RV.Layout.Row>
        <RV.QueryLoading result={result}>
          {({ rnxActivityGroupProgramActivityList }) => {

            return rnxActivityGroupProgramActivityList.items?.map(rnxActivityGroupProgramActivity => {
              const startHourSplit = rnxActivityGroupProgramActivity.timeStart.split(':')
              const finishHourSplit = rnxActivityGroupProgramActivity.timeFinish.split(':')

              const today = dayjs().startOf('today')
              const start = today.hour(startHourSplit[0]).minute(startHourSplit[1])
              const finish = today.hour(finishHourSplit[0]).minute(finishHourSplit[1])
              return (
                <RV.Layout.Row key={rnxActivityGroupProgramActivity.id} my={2} alignItems='center'>
                  {rnxActivityGroupProgramActivity.name}

                  <Box>
                    {start.format('HH.mm')} - {finish.format('HH.mm')}
                  </Box>

                  <Box>
                    <RV.ActionGroupMenu
                      name={'RnxActivityGroupProgramActivity'} callbacks={{
                        onSuccess: result.refresh
                      }} contexts={['Entity.Daily']} ctx={{ id: rnxActivityGroupProgramActivity.id }}
                    />
                  </Box>
                </RV.Layout.Row>
              )
            })
          }}
        </RV.QueryLoading>
      </RV.Card>
    </>
  )
}

const ProgramActivityReservations = ({
  id
}) => {
  return (
    <RV.GraphTable
      modelName={'RnxActivityGroupActivityReservation'}
      // detailLink='spsa'
      query={{
        itemFields: [
          { 'rnxContact': { fields: ['name', 'officialKey'] } }, 'rnxActivityGroupActivity', 'rnxActivityGroupActivityKind', 'kind', 'datetimeStart', 'status'
        ],
        columns: [
          'rnxContact.name', 'rnxContact.officialKey', 'status'
        ],
        filters: {
          rnx_activity_group_activity_id: id
        },
        orderBy: 'datetimeStart|asc'
      }}
    />
  )
}

export default Home
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const RnxDeviceProfileManagement = props => {
  const connections = RV.useReactorResource('rnxDevice.profile.profile_connections')
  return (
    <RV.QueryLoading result={connections}>
      <RV.Routes>
        <RV.Route path='/' element={<ProfileHome connections={connections?.data} />} />
        <RV.Route path='device_profile_detail/:rnxDeviceProfileId/*' element={<DeviceProfileDetail />} />
        <RV.Route path='register_profile_detail/:rnxDeviceRegisterProfileId/*' element={<RegisterProfileDetail connections={connections?.data} />} />
      </RV.Routes>
    </RV.QueryLoading>
  )
}

const ProfileHome = ({
  connections
}) => {
  const { t } = RV.useTranslation()
  const tabs = [
    {
      name: 'Cihaz Profil',
      codeName: 'deviceProfile',
      element: (
        <RV.GraphTable
          modelName={'RnxDeviceProfile'}
          detailLink={'device_profile_detail'}
          actionCtx={{
            model: {
              // rnx_device_app: params.rnxDeviceAppId,
            }
          }}
        />
      )
    },

    // {
    //   name: 'Medya Profil',
    //   codeName: 'mediaProfile',
    //   element: (
    //     <RV.GraphTable
    //       modelName={'RnxDisplayMediaProfile'}
    //       detailLink={'media_profile_detail'}
    //       actionCtx={{
    //         model: {
    //           // rnx_device_app: params.rnxDeviceAppId,
    //         }
    //       }}
    //     />
    //   )
    // },

    {
      name: 'Kayıt Profil',
      codeName: 'registerProfile',
      element: (
        <RV.GraphTable
          modelName={'RnxDeviceRegisterProfile'}
          detailLink={'register_profile_detail'}
        />
      )
    }
  ]
  connections && connections.forEach(conn => {
    if (!conn.uiComponent) return
    const namesplit = conn.uiComponent.split('.')
    const codeName = namesplit[1]
    const label = t(`label.${RV.changeCase.snake(codeName)}`)
    tabs.push({
      name: label,
      codeName: codeName,
      element: (
        <RV.DynamicComponent name={conn.uiComponent} />
      )
    })
  })
  return (
    <RV.BareTabs
      isLazy
      tabs={tabs}
    />
  )
}

const DeviceProfileDetail = ({

}) => {
  const { t } = useTranslation()
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rnxDeviceProfile': {
      filters: {
        id: params.rnxDeviceProfileId
      },
      fields: [
        'name', 'kind', 'platform', 'data', 'properties'
      ]
    },
  })

  RV.useBreadcrumb(`rnx_device_app:${result.graph?.rnxDeviceProfile}`, result.graph?.rnxDeviceProfile?.name)
  const rnxDeviceProfile = result.graph?.rnxDeviceProfile
  const meta = result.graph?.rnxDeviceProfileMeta
  if (!rnxDeviceProfile) return null

  const profileData = rnxDeviceProfile.data?.profile

  return (
    <>
      <RV.EntityDetail
        icon={'graphic-tablet-wireless'}
        entity={rnxDeviceProfile}
        schema={result.schema?.rnxDeviceProfile}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
      <RV.Layout.Col3>
        <Box>
          {profileData && <RV.Card sx={{
            my: 3,
          }}>
            <RV.Card.HeaderArea>
              <RV.Card.Header>
                Profil Bilgileri
              </RV.Card.Header>
              <Box>
                <RV.RfActionForm
                  onSuccess={result.refresh}
                  icon='pencil'
                  actionKey='RnxDevice.SetProfileData'
                  fields={rnxDeviceProfile?.kind.attrs.fields}
                  initialValues={{
                    ...profileData,
                    id: rnxDeviceProfile?.id
                  }}
                  buttonText='KAYDET' />
              </Box>
            </RV.Card.HeaderArea>
            {Object.entries(profileData).map(([k, v]) => {
              return (
                <Flex key={k} sx={{
                  my: 2
                }}>
                  <Box sx={{
                    textTransform: 'capitalize',
                    flex: '0 0 80%',
                    color: 'textMuted',
                    fontWeight: 'semiBold'
                  }}>
                    {t(`field.${k}`, k)}
                  </Box>
                  <Box>{v}</Box>
                </Flex>
              )
            })}
          </RV.Card>}

          <RV.Card sx={{
            my: 3,
          }}>
            <RV.Card.HeaderArea>
              <RV.Card.Header>
                Özellikler
              </RV.Card.Header>
              <RV.ReactorServerAction icon='pencil' initialValues={{ properties: rnxDeviceProfile.properties }} identifier='RnxDevice.RnxDeviceProfileUpdateProperties' options={{ ctx: { id: rnxDeviceProfile.id } }} />
            </RV.Card.HeaderArea>

            {rnxDeviceProfile.properties?.map((prop, dx) => {
              return (
                <Box key={dx}>
                  <RV.Layout.Cols>
                    {prop.name}
                    {prop.kind}
                  </RV.Layout.Cols>
                </Box>
              )
            })}
          </RV.Card>
        </Box>

        <RV.GraphTable
          sx={{
            my: 3,
            flex: '1 1 0'
          }}
          modelName={'RnxDevice'}
          query={{
            filters: {
              rnx_device_profile_id: params.rnxDeviceProfileId
            }
          }}
        />

      </RV.Layout.Col3>
    </>
  )
}


const RegisterProfileDetail = ({
  connections
}) => {
  const { t } = useTranslation()
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rnxDeviceRegisterProfile': {
      filters: {
        id: params.rnxDeviceRegisterProfileId
      },
      fields: [
        'name', 'deviceKind', 'platform', { 'rnxDeviceAppProfile': { fields: ['name'] } }, { 'rnxDeviceProfile': { fields: ['name'] } }, 'connections'
      ]
    },
  })

  RV.useBreadcrumb(`rnx_device_app:${result.graph?.rnxDeviceRegisterProfile}`, result.graph?.rnxDeviceRegisterProfile?.name)
  const rnxDeviceRegisterProfile = result.graph?.rnxDeviceRegisterProfile
  const meta = result.graph?.rnxDeviceRegisterProfileMeta
  if (!rnxDeviceRegisterProfile) return null

  let fields = []
  connections?.forEach(conn => fields = fields.concat(conn.fields.map(f => ({ ...f, name: `connections.${f.name}` }))))

  return (
    <>
      <RV.EntityDetail
        icon={'playlist-video'}
        entity={rnxDeviceRegisterProfile}
        schema={result.schema?.rnxDeviceRegisterProfile}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <RV.Card sx={{
        my: 3,
        width: 300
      }}>
        <Flex sx={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <RV.Card.Header>
            Bağlantılar
          </RV.Card.Header>

          {fields && <RV.RfActionForm
            onSuccess={result.refresh}
            iconName='pencil'
            header='Düzenle'
            actionKey='RnxDevice.SetConnections'
            fields={fields}
            initialValues={{
              // data: {...linkData},
              id: rnxDeviceRegisterProfile?.id
            }}
            buttonText='KAYDET' />}
        </Flex>
        {rnxDeviceRegisterProfile.connections && Object.entries(rnxDeviceRegisterProfile.connections).map(([k, v]) => {
          return (
            <Flex key={k} sx={{
              my: 2
            }}>
              <Box sx={{
                textTransform: 'uppercase',
                flex: '1 1 0',
                color: 'textMuted',
                fontWeight: 'semiBold'
              }} >
                {t(`field.${k}`)}
              </Box>
              <Box><RV.Icon name='check' color='brand.500' /></Box>
            </Flex>
          )
        })}
      </RV.Card>
    </>
  )
}

export default RnxDeviceProfileManagement
// Runic
import { createActions } from 'reactor/core/redux'

export default createActions({
  LOCAL: {
    enableSelection: {},
    selectItems: {},
    unselectItems: {},
    clearSelectionGroup: {},
  },
}, 'uiSelection')

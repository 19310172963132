import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Tooltip from 'reactor-ui/components/Tooltip'
import Icon from 'reactor-ui/components/Icon'
import Loader from 'reactor-vera/apps/ui/components/Loader'


const RefreshButton = ({
  result,
  children
}) => {
  const content = result?.status?.isFetching ? (
    <Loader size={20} color='dark.500' />
  ) : (
    <Tooltip label='Yenile'>
      <Box as='a' sx={{
        display: 'block',
        ml: 2,
        color: 'dark.100',
        transition: 'all 0.2s ease',
        '&:hover': {
          color: 'brand.500',
        }
      }} onClick={result.refresh}>
        <Icon name='refresh' size={4} />
      </Box>
    </Tooltip>
  )

  if (children) return (
    <Flex alignItems='center'>
      {children}
      {content}
    </Flex>
  )

  return content
}

export default RefreshButton
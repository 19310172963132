import AccessPlanManagement from './pages/AccessPlanManagement'
import RnxDeviceManagement from './pages/RnxDeviceManagement'
import RnxDevicePacDashboard from './pages/RnxDevicePacDashboard'
import RnxDevicePacReports from './pages/RnxDevicePacReports'


export default {
  name: 'RnxDevicePac',
  components: {
    'AccessPlanManagement': {
      component: AccessPlanManagement
    },
    'RnxDeviceManagement': {
      component: RnxDeviceManagement
    },
    'RnxDevicePacDashboard': {
      component: RnxDevicePacDashboard
    },
    'RnxDevicePacReports': {
      component: RnxDevicePacReports
    },
  }
}
import RnxAccessManagement from './pages/RnxAccessManagement'
import RnxAccessReports from './pages/RnxAccessReports'
import RnxAccessPointManagement from './pages/RnxAccessPointManagement'
import RnxAccessProviderManagement from './pages/RnxAccessProviderManagement'
import RnxAccessProviderRecords from './pages/RnxAccessProviderRecords'
import PacRnxContact from './pages/PacRnxContact'


export default {
  name: 'RnxPac',
  components: {
    'RnxAccessManagement': {
      component: RnxAccessManagement
    },
    'RnxAccessReports': {
      component: RnxAccessReports
    },
    'RnxAccessPointManagement': {
      component: RnxAccessPointManagement
    },
    'RnxAccessProviderManagement': {
      component: RnxAccessProviderManagement
    },
    'RnxAccessProviderRecords': {
      component: RnxAccessProviderRecords
    },
    'PacRnxContact': {
      component: PacRnxContact
    },
  }
}
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import PropertySinglePass from './PropertySinglePass'


const PropertySinglePasses = ({
  rnxProperty
}) => {
  const result = RV.useReactorQuery({
    rnxResourceThingList: {
      // fields: [
      //   'memberships'
      // ],
      filters: {
        owner_entity_key: rnxProperty.id,
        kind: 'RNX_MEMBERSHIP_SINGLE_PASS',
        status: ['ACTIVE', 'PASSIVE']
      }
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ rnxResourceThingList }) => (
        <RV.Card>
          <RV.Card.HeaderArea>
            <RV.Card.Header>
              Günlük Tekil Geçişler
            </RV.Card.Header>

            <RV.ActionGroupMenu
              name={'RnxProperty'} contexts={['Entity.SinglePass']} ctx={{ id: rnxProperty.id }}
            />
          </RV.Card.HeaderArea>

          {(rnxResourceThingList.items.length === 0) && (
            <Box>
              Günlük tekil geçiş yok.
            </Box>
          )}

          {rnxResourceThingList.items.map(item => {
            return (
              <Box key={item.id} my={2}>
                <RV.Layout.Row>
                  <Box flex='0 0 30%'>
                    <RV.Icon name='calendar'>
                      <RV.Link to={`../sp/${item.id}`}>{item.name} - ₺{item.price}</RV.Link>
                    </RV.Icon>
                  </Box>
                  <RV.Enum value={item.status} />
                  <RV.ActionGroupMenu
                    name={'RnxResourceThing'} contexts={['Entity']} ctx={{ id: item.id }}
                  />
                </RV.Layout.Row>
              </Box>
            )
          })}
        </RV.Card>
      )}
    </RV.QueryLoading>
  )
}

import Image, { LightboxImage } from 'reactor-ui/components/Image'

const PropertySinglePassTicket = ({

}) => {
  const params = RV.useParams()

  return (
    <RV.EntityDetailPage
      modelName='RnxResourceTicket'
      fields={['name', 'quantity', 'quantityUsed', 'datetimeCreated', 'status', 'parameters', 'parameterFields']}
      columns={['name', 'quantity', 'quantityUsed', 'datetimeCreated', 'status']}
      routes={[
        entity => {
          console.log('--', entity)
          return (
            <RV.Route path='/' element={(
              <>
                {entity.parameterFields?.fields?.map((f, dx) => {
                  if (f.kind === 'PASSWORD') return null
                  const value = entity.parameters[f.code_name]
                  let valueContent

                  if (!value) {
                    valueContent = ''
                  }
                  else if (f.kind === 'FILE') {
                    valueContent = (
                      <>
                        <Box as='a' color='brand.500' href={value.url} target='_blank'>İndir</Box>
                        <LightboxImage src={value.url} sx={{
                          width: '200px'
                        }} />
                      </>
                    )
                  } else if (f.kind === 'ENTITY') {
                    valueContent = value.name
                  } else if (f.kind === 'SELECT') {
                    valueContent = <Enum value={value} />
                  } else if (f.kind === 'BOOL') {
                    valueContent = <Bool value={value} />
                  } else {
                    valueContent = value
                  }
                  return (
                    <Box key={dx} sx={{
                      my: 2
                    }}>
                      <Box fontWeight='bold'>
                        {f.name}
                      </Box>

                      <Box>
                        {valueContent}
                      </Box>
                    </Box>
                  )
                })}
              </>
            )} />
          )
        }
      ]}
    />
  )
}

PropertySinglePasses.routes = [
  <RV.Route path='sp/:rnxResourceThingId/*' element={<PropertySinglePass />} key='singlepass' />,
  <RV.Route path='spd/:rnxResourceTicketId/*' element={<PropertySinglePassTicket />} key='singlepassticket' />
]

export default PropertySinglePasses
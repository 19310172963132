import React from 'react'

// Vendor
import { ChakraProvider } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'

export { Flex, Box } from "@chakra-ui/react"

const ReactorUi = ({
  theme,
  children,
  globalCss = () => { }
}) => {
  const globalStyles = css`
    ${globalCss(theme)}
  `

  return (
    <ChakraProvider theme={theme} portalZIndex={5000}>
      <Global styles={globalStyles} />
      {children}
    </ChakraProvider>
  )
}

export default ReactorUi
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import BroadcastPlaylist from '../components/BroadcastPlaylist'
import BroadcastSlotDetail from './BroadcastSlotDetail'


const RnxBroadcastManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<BroadcastHome />} />
      <RV.Route path='broadcast/:rnxBroadcastId/*' element={<BroadcastDetail />} />
    </RV.Routes>
  )
}

const BroadcastHome = ({

}) => {
  const { t } = RV.useTranslation()

  return (
    <RV.GraphTable
      modelName={'RnxBroadcast'}
      detailLink={'broadcast'}
    />
  )
}

const BroadcastDetail = ({

}) => {
  return (
    <RV.EntityDetailPage
      modelName='RnxBroadcast'
      fields={[
        'name', 'kind', 'totalFileSizeInMb', 'totalLength', 'items', 'mediaCounts'
      ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/' element={(
            <BroadcastDetailHome refresh={result.refresh} rnxBroadcast={entity}/>
          )} />
        )
      ]}
    />
  )
}

const BroadcastDetailHome = ({
  refresh,
  rnxBroadcast
}) => {
  if (rnxBroadcast.kind.value === 'PLAYLIST') {
    return <BroadcastPlaylist refresh={refresh} rnxBroadcast={rnxBroadcast}/>
  } else if (rnxBroadcast.kind.value === 'ADVERTISING_SLOT') {
    return <BroadcastSlotDetail refresh={refresh} rnxBroadcast={rnxBroadcast}/>
  }

  console.log(rnxBroadcast.kind)
  return null
}

export default RnxBroadcastManagement
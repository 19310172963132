import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import Form, { RfFormField, useForm, useFormState, useField, useFieldArray, RfShowIf } from 'reactor-form/components/RfForm'


const UserAssigmentAccessSetup = ({
  parentFieldName,
  model
}) => {
  return (
    <RfFormField
      name={`${parentFieldName}.kinds`}
      label='Bağlantılı Türü'
      kind='entity'
      options={{
        params: {
          kind: 'USER_ASSIGNMENT_KIND'
        },
        modelName: 'rcRecord',
        isMultiple: true
      }}
    />
  )
}

export default UserAssigmentAccessSetup
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import SelectInput from 'reactor-ui/components/SelectInput'

import useLoader from '../hooks/useLoader'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'

import RfFormField from './RfFormField'


const initialArrayValue = [{}]

const RfFilterGroupField = ({
  field,
}) => {
  const { t } = useTranslation()
  const { fields, ...r } = useFieldArray(field.name, {
    // initialValue: field.defaultCount ? initialArrayValue : undefined
    // initialValue: initialArrayValue
  })
  const [items, setItems] = React.useState()
  const loader = useLoader(field.options?.loader, { parentPath: field.parentPath })
  console.log('--', fields)

  React.useEffect(() => {
    const f = async () => {
      const results = await loader.fetch()
      setItems(results)
    }
    f()
  }, [])

  // FIXME:
  if (!items) return null

  return (
    <Box>
      <Flex justifyContent='space-between'>
        <Box>
          {t(field.label)}
        </Box>

        {/* <Box>
          <Box as='a' sx={{
          }} onClick={() => fields.push({})}><Icon color='brand.500' name={'circle-plus'} /></Box>
        </Box> */}
      </Flex>

      <SelectInput
        onChange={val => {
          if (!val) return
          fields.push({
            name: val.name
          })
        }}
        key={fields.length}
        // useDefaultWidth={useDefaultWidth}
        // minWidth={minWidth}
        // isMulti
        // isClearable
        // value={value}
        options={items.filter(x => !fields.value?.find(y => x.value.name === y.name))}
      // placeholder={'HH:MM'}
      />

      {fields.map((f, dx) => {
        return (
          <Box key={dx} sx={{
            my: 2,
            border: '1px solid #FFF',
            borderColor: 'brand.500',
            p: 3,
            borderRadius: 2
          }}>
            <FilterGroupItem name={f} value={fields.value[dx]} dx={dx} fields={fields} field={items.find(x => x.value.name === fields.value[dx].name).value.field} />
          </Box>
        )
      })}
    </Box>
  )
}

const FilterGroupItem = ({
  name,
  value,
  fields,
  dx,
  field
}) => {
  const { input, meta } = useField(name)
  console.log(input.value, field)
  return (
    <>
      <Flex justifyContent='space-between' alignItems='center'>
        <Flex justifyContent='space-between' alignItems='center'>
          <Box as='a' sx={{
            mx: 1
          }} onClick={() => fields.remove(dx)}><Icon color='brand.500' name='circle-remove' /></Box>
          {value.name}
        </Flex>

        <Box>
          <RfFormField name={`${name}.comparator`} label='Karşılaştırma' kind='select' options={{
            options: [
              {
                value: 'EQUAL',
                label: 'Eşittir'
              },

              {
                value: 'NOT_EQUAL',
                label: 'Eşit Değildir'
              },

              {
                value: 'IN',
                label: 'İçinde'
              },

              {
                value: 'OUT',
                label: 'Dışında'
              }
            ]
          }} />
        </Box>
      </Flex>

      {input.value?.comparator && <FilterGroupItemSelector comparator={input.value?.comparator} name={name} field={field}/>}
    </>
  )
}

const FilterGroupItemSelector = ({
  name,
  comparator,
  field
}) => {
  if (comparator == 'IN')  {
    if (field.kind === 'SELECT') {
      return (
        <RfFormField kind='select' name={`${name}.options.values`} label='Değerler' options={{options: field.options.map(o => ({value: o, label: o})), isMultiple: true}}/>
      )
    } else {
      return (
        <RfFormField kind='textArea' name={`${name}.options.values`} label='Değerler' description='Her satıra 1 tane gelecek şekilde girin.'/>
      )
    }
  }

  else if (comparator == 'OUT')  {
    return (
      <RfFormField kind='textArea' name={`${name}.options.values`} label='Değerler' description='Her satıra 1 tane gelecek şekilde girin.'/>
    )
  }

  else if (comparator == 'EQUAL')  {
    return (
      <RfFormField kind='text' name={`${name}.options.value`} label='Değer'/>
    )
  }

  else if (comparator == 'NOT_EQUAL')  {
    return (
      <RfFormField kind='text' name={`${name}.options.value`} label='Değer'/>
    )
  }
  return null
}

export default RfFilterGroupField
import React from 'react'

// Reactor
import { Routes, Route, useMatch, useResolvedPath, useLocation } from 'reactor-vera/router'
import { useRncIndexRedirect, useRncActivate } from 'reactor-vera/apps/ui/hooks/tree'

import PlexUiUnitRouter from './PlexUiUnitRouter'

const PlexUiDomain = ({
  domain,
  zone,
}) => {
  useRncActivate('domain', domain)

  return (
    <Routes>
      <Route path={':unitName/*'} element={<PlexUiUnitRouter domain={domain} zone={zone} />}/>
    </Routes>
  )
}

export default PlexUiDomain

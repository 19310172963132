import React from 'react'

// Vendor
import loadable from '@loadable/component'

// Reactor
import { Route, Routes, Navigate } from 'reactor-vera/router'
import ReactorAuthCheck from './ReactorAuthCheck'
import useReactor from 'reactor/hooks/useReactor'
import useResourceQuery from 'reactor/hooks/useResourceQuery'

// import RunicTenantSelector from './RunicTenantSelector'
import ReactorTenantIndex from './ReactorTenantIndex'

// import RunicDevBar from 'reactor-vera/apps/dev-bar/components/RunicDevBar'


// const RunicSocketManager = loadable(() => import('./RunicSocketManager'))


const ReactorVeraHome = ({
  TenantIndex,
  beforeRoutes,
  afterRoutes,
  homeElement
}) => {
  const reactor = useReactor()
  const hasSocket = reactor.rConfig.socket?.enabled

  const status = useResourceQuery('rcApp.state.status')
  if (!status) return null

  const TenantIndexComponent = TenantIndex || reactor.rConfig.tenantIndex || ReactorTenantIndex

  return (
    <ReactorAuthCheck redirectTo={homeElement ? '/' : '/auth/login'}>
      {beforeRoutes}
      {/* {hasSocket && <RunicSocketManager/>} */}
      <Routes>
        <Route path="/" element={<Navigate to={`/${status.rcUser?.rcTenantId}`} />} />
        <Route path="home" element={<Navigate to={`/${status.rcUser?.rcTenantId}`} />} />
        <Route path="/:rcTenantId/*" element={<TenantIndexComponent />}/>
      </Routes>
      {afterRoutes}
      {/* <RunicDevBar/> */}
    </ReactorAuthCheck>
  )
}

export default ReactorVeraHome

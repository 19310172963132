import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import { CustomPicker } from 'react-color'
// import { TwitterPicker } from 'react-color'
import { isValidHex } from 'react-color/lib/helpers/color'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Icon from './Icon'


// const ColorInput = ({
//   onChange,
//   onChangeInput
// }) => {
//   return (
//     <TwitterPicker onChangeComplete={onChange} onChange={onChangeInput  }/>
//   )
// }

// export default ColorInput

const ColorInput = ({
  value: valueFromProps,
  // onColorChange,
  onChange,
  label,
  palette: paletteFromProps
}) => {
  const palette = paletteFromProps || ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3',
    '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']

  const inputRef = React.useRef()
  const [value, valueSet] = React.useState(valueFromProps || '')

  const onTextChange = e => {
    const newVal = e.target.value
    valueSet(newVal)
    if (!isValidHex(newVal)) {
      return
    }
    // onColorChange?.(newVal)
    // onChange?.({
    //   hex: newVal
    // })
    onChange?.(newVal)
  }

  const onColorSelect = (e, newVal) => {
    e.preventDefault()
    // console.log(newVal)
    valueSet(newVal)
    // onColorChange?.(newVal)
    // onChange?.({
    //   hex: newVal
    // })
    onChange?.(newVal)
  }
  console.log(value)

  return (
    <>
      <Box sx={{
        fontSize: 'sm',
      }}>
        {label}
      </Box>

      <Flex sx={{
        mx: -1,
        p: 2,
      }}>
        <Box sx={{
          width: '80px',
        }}>
          <Box sx={{
            bg: value,
            width: '56px',
            height: '56px'
          }} />
        </Box>

        <Box sx={{
          flex: '1 1 0'
        }}>

          <Flex sx={{
            flexWrap: 'wrap',
            mx: -1,
            justifyContent: 'space-between#'
          }}>
            {palette.map(p => {
              return (
                <Box as='a' key={p} sx={{
                  width: '28px',
                  height: '28px',
                  bg: p,
                  mx: 1,
                  mb: 2
                }} onMouseDown={e => onColorSelect(e, p)}/>
              )
            })}

            <Box as='input' sx={{
              flex: '1 1 0',
              px: 1,
              mx: 1,
              width: 'auto',
              maxWidth: '130px'
              // fontSize: 'sm'
              // mt: 1
            }} ref={inputRef} onBlur={e => {
              // console.log('sel', selection)
              // Transforms.select(editor, selection)
            }} value={value} onMouseDown={e => {
              // Transforms.select(editor, selection)
              // !selection && selectionSet({...editor.selection})
            }} onChange={onTextChange} />
          </Flex>

        </Box>
        {/* {Object.values(theme.palette).map((clr, dx) => {
        return (
          <Box as='a' key={dx} sx={{
            bg: clr,
            width: 30,
            height: 30,
            mx: 1,
            mb: 1,
            borderRadius: 2,
            cursor: 'pointer',
            display: 'block',
            border: '1px solid #000',
          }} onMouseDown={e => onColorSelect(e, clr)}>

          </Box>
        )
      })} */}

        {/* <Flex as='a' sx={{
        bg: '#FFF',
        width: 30,
        height: 30,
        mx: 1,
        mb: 1,
        borderRadius: 2,
        cursor: 'pointer',
        display: 'block',
        // border: '1px solid #000',
        alignItems: 'center',
        textAlign: 'center'
      }} onMouseDown={e => onColorSelect(e, null)}>
        <Icon name='square-remove' sx={{
          mx: 'auto'
        }} color='brand.500' />
      </Flex> */}
      </Flex>
    </>
  )
}

export default ColorInput
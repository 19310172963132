import { isFunction } from 'lodash'
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import { Alert } from 'reactor-ui/components/Alert'
import Button from 'reactor-ui/components/Button'
import Loader from 'reactor-vera/apps/ui/components/Loader'


const QueryLoading = ({
  result,
  children,
  sx,
  loadingElement
}) => {
  if (result?.status?.status === 'READY') {
    if (isFunction(children)) return children(result.graph || result.data)
    return children || null
  } else if (result?.isFetching === false) {
    if (isFunction(children)) return children(result.graph || result.data)
    return children || null
  } else if (result?.status?.status === 'ERROR') {
    return (
      <Alert status='warning'>
        {result.refresh && <Button colorScheme='brand' icon='refresh' mr={4} onClick={result.refresh}>
          Yenile
        </Button>}
        Hata.
      </Alert>
    )
  }
  return loadingElement || (
    <Box sx={sx}>
      <Loader size={20} color='dark.500'>
        Yükleniyor.
      </Loader>
    </Box>
  )
}

export default QueryLoading
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import StatsBasic from 'reactor-ui/components/StatsBasic'

import RequestData from 'elements/rnx_device/rnxDevice/components/RequestData'


const RnxDeviceManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<RnxDeviceManagementHome />} />
      <RV.Route path='device/:rnxDeviceId' element={<RnxDeviceManagementDetail />} />
    </RV.Routes>
  )
}

const RnxDeviceManagementHome = props => {
  return (
    <>
      <RV.GraphTable
        modelName='RnxDevice'
        detailLink='device'
        primaryFilters={[
          {self: {}}
        ]}
        stats={{
          'countAll': {},
          'countActive': {},
          'countPassive': {},
          'countAlive': {}
        }}
        elements={{
          beforeTable: [
            (result) => {
              const { t } = RV.useTranslation()
              const stats = result.graph?.rnxDeviceList?.stats
              if (!stats) return null
              return (
                <>
                  <RV.Card.Header>Cihazlar</RV.Card.Header>
                  <Flex alignItems='center' flexWrap='wrap' mx={-3} my={2}>
                  <RV.StatBox sx={{ mx: 3, flex: '1 1 0' }} colorDx={0} label='Toplam' value={stats.countAll} />
                  <RV.StatBox sx={{ mx: 3, flex: '1 1 0' }} colorDx={1} label='Canlı' value={stats.countAlive} />
                  <RV.StatBox sx={{ mx: 3, flex: '1 1 0' }} colorDx={2} label='Aktif' value={stats.countActive} />
                  <RV.StatBox sx={{ mx: 3, flex: '1 1 0' }} colorDx={3} label='Pasif' value={stats.countPassive} />
                  </Flex>
                </>
              )
            }
          ]
        }}
        query={{
          orderBy: 'datetime_last_contact|desc'
        }}
      />
    </>
  )
}

const RnxDeviceManagementDetail = ({

}) => {
  return (
    <>
      <RV.EntityDetailPage
        modelName='RnxDevice'
        fields={['name', 'serial', 'datetimeLastContact', 'lastContactAddr', 'currentRnxDeviceAppVersion', 'isOnline', 'rcEntityId', 'deviceStats', 'properties']}
        columns={['name', 'serial', 'datetimeLastContact', 'lastContactAddr', 'currentRnxDeviceAppVersion', 'isOnline', 'rcEntityId']}
        content={
          entity => {
            const deviceStats = entity.deviceStats
            if (!deviceStats) return null
            return (
              // <RV.Card my={3}>
              <StatsBasic.Row sx={{
                flex: '1 1 0',
              }}>
                <StatsBasic.NumberStat card sx={{
                  flex: '1 1 20%',
                }}
                  name='RAM'
                  bar={{ bgBar: true, value: deviceStats.totalRam - deviceStats.freeRam, total: deviceStats.totalRam }}
                  suffix='MB'
                  data={`${deviceStats.totalRam - deviceStats.freeRam}/${deviceStats.totalRam}`}
                  description='Kullanılan RAM / Toplam RAM'
                  dx={0}
                />
                <StatsBasic.NumberStat card sx={{
                  flex: '1 1 20%',
                }}
                  name='Disk' suffix='GB' bar={{ bgBar: true, value: deviceStats.totalDisk - deviceStats.freeDisk, total: deviceStats.totalDisk }} data={`${deviceStats.totalDisk - deviceStats.freeDisk}/${deviceStats.totalDisk}`} description='Kullanılan Disk / Toplam Disk' dx={1} />
                <StatsBasic.NumberStat card sx={{
                  flex: '1 1 20%',
                }}
                  name='CPU Kullanım' bar={{ bgBar: true, value: deviceStats.cpuUsage, total: 100 }} suffix='%' data={deviceStats.cpuUsage} description='CPU Kullanım' dx={2} />
                <StatsBasic.NumberStat card sx={{
                  flex: '1 1 20%',
                }}
                  name='CPU Sıcaklık' data={deviceStats.cpuHeat} description='CPU Sıcaklık' dx={3}
                  bar={{ bgBar: true, value: deviceStats.cpuHeat, total: 100 }}
                />
              </StatsBasic.Row>
              // </RV.Card>
            )
          }
        }
        routes={[
          entity => (
            <RV.Route path='/' element={(
              <RV.Layout.Col3>
                <RV.Card>
                  <RV.Card.Header>
                    Özellikler
                  </RV.Card.Header>

                  {entity.properties && Object.entries(entity.properties).map(([k, v], dx) => {
                    return (
                      <RV.Layout.Cols key={dx}>
                        {k}
                        <RV.Bool value={v}/>
                      </RV.Layout.Cols>
                    )
                  })}
                </RV.Card>
                <RV.BareTabs
                  isLazy
                  tabs={[
                    {
                      name: 'İstekler',
                      codeName: 'request',
                      element: (
                        <RV.Layout.Cols colSize={400}>
                          <RV.GraphTable
                            showModelActions={false}
                            showEntityActions={false}
                            modelName={'RnxDeviceRequestLog'}
                            // detailLink={'point'}
                            query={{
                              filters: {
                                rnx_device_id: entity.id
                              },
                              order_by: 'datetime_contact|desc',
                              itemFields: [
                                'datetimeContact', 'addr', 'kind'
                              ],
                              columns: [
                                'datetimeContact', 'addr', 'kind', 'requestData'
                              ]
                            }}
                            elements={{
                              columns: {
                                requestData: (value) => {
                                  return <RequestData entity={value.row.original} />
                                }
                              }
                            }}
                          />
                        </RV.Layout.Cols>
                      )
                    },

                    {
                      name: 'Geçişler',
                      codeName: 'pass',
                      element: (
                        <RV.Layout.Cols colSize={400}>
                          <RV.GraphTable
                            showModelActions={false}
                            showEntityActions={false}
                            modelName={'RnxPacAccessRecord'}
                            // detailLink={'point'}
                            query={{
                              filters: {
                                'rnx_pac_access_point.device_entity_key': entity.id
                              },
                              order_by: 'datetime_access|desc',
                              // itemFields: [
                              //   'datetimeContact', 'addr', 'kind'
                              // ],
                              // columns: [
                              //   'datetimeContact', 'addr', 'kind', 'requestData'
                              // ]
                            }}
                          />
                        </RV.Layout.Cols>
                      )
                    }
                  ]}
                />
              </RV.Layout.Col3>
            )} />
          )
        ]}
      />
    </>
  )
}

export default RnxDeviceManagement
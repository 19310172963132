import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const RcTagAssignmentManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.GraphTable
          modelName={'RcTagGroup'}
          detailLink={'group'}
        />
      )} />

      <RV.Route path='group/:rcTagGroupId/*' element={(
        <RV.EntityDetailPage
          modelName='RcTagGroup'
          routes={[
            entity => (
              <RV.Route path='/' element={(
                <RV.Layout.Cols colSize={400}>
                  <RV.GraphTable
                    modelName={'RcTagAssignment'}
                    // detailLink={'point'}
                    query={{
                      filters: {
                        rc_tag_group_id: entity.id
                      },
                      itemFields: [
                       'rcTag', 'assigned',
                      ],
                      columns: [
                       'rcTag.fqName', 'assigned', 'assigned.email'
                      ]
                    }}
                    actionCtx={{
                      model: {
                        rc_tag_group_id: entity.id,
                      }
                    }}
                  />

                  <RV.GraphTable
                    modelName={'RcTag'}
                    // detailLink={'point'}
                    query={{
                      filters: {
                        rc_tag_group_id: entity.id
                      }
                    }}
                    actionCtx={{
                      model: {
                        rc_tag_group_id: entity.id,
                      }
                    }}
                  />
                </RV.Layout.Cols>
              )} />
            )
          ]}
        />
      )} />
    </RV.Routes>
  )
}

export default RcTagAssignmentManagement
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// ReactorUI
import { Box, Flex } from 'reactor-ui'
import { Checkbox, CheckboxGroup } from 'reactor-ui/components/Checkbox'
import { HStack } from 'reactor-ui/components/Stack'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'

import useLoader from '../hooks/useLoader'


const RfCheckboxGroupField = ({
  name,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })
  const inputValue = input.value === "" ? [] : input.value

  const [options, setOptions] = React.useState(field.options?.options)
  const loader = useLoader(field.options?.loader, {})
  const [isFetching, setIsFetching] = React.useState(!!loader)
  React.useEffect(() => {
    if (!loader) return
    loader.fetch().then(data => {
      setOptions(data)
      setIsFetching(false)
    })
  }, [])

  if (isFetching) return ('loading')
  if (!options) return null

  return (
    <>
      <Box mb={1} fontSize='sm'>
        {t(field.label)}
      </Box>
      <Box sx={{
        borderLeft: '1px solid #FFF',
        borderLeftColor: 'brand.100',
        pl: 2
      }}>
        <RfFieldDescription field={field} position='default' />
        <CheckboxGroup
          onChange={(values) => {
            if (field.options?.limit && values && values.length > field.options?.limit) {
              return
            }
            input.onChange(values)
          }}
          colorScheme="brand" defaultValue={inputValue} value={inputValue}>
          {/* <HStack wrap='wrap' justify='space-between' shouldWrapChildren spacing={2}> */}
          {options.map((option, dx) => {
            return (
              <Box key={dx}>
                <Checkbox my={1} value={option.value}>{t(option.label)}</Checkbox>
              </Box>
            )
          })}
          {/* </HStack> */}
        </CheckboxGroup>
      </Box>
      <RfFieldAlert meta={meta} />
    </>
  )
}

export default RfCheckboxGroupField
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

//
import RfFormField from './RfFormField'
import useLoader from '../hooks/useLoader'


const RfDynamicField = ({
  field
}) => {
  const loader = useLoader(field.options.loader)
  const [data, setData] = React.useState({})
  React.useEffect(() => {
    loader.fetch().then(loaderData => {
      setData(loaderData)
    })
  }, [])

  if (!data.fields) return null
  return data.fields.map((f, dx) => <RfFormField {...f} name={`${field.name}.${f.name}`} key={dx}/>)
}

export default RfDynamicField
import React from 'react'

// Vendor
import { useFieldArray } from 'react-final-form-arrays'
import Form, { RfFormField, useFormState } from 'reactor-form/components/RfForm'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

import { TextFilter } from 'reactor-vera/apps/data/components/Filters'


const RcTagDynamicFilter = ({
  focus,
  filterName,
  onChange,
  value,
  label,
  ctx
}) => {
  const initialValue = React.useMemo(() => value || initialArrayValue, [])
  return (
    <Form handleSubmit={() => {}} initialValues={{
      [filterName]: initialValue
    }} ctx={{}}>
      <RcTagDynamicFilterField
        name={filterName}
        label={label}
        params={ctx}
        onFilterChange={onChange}
      />
    </Form>
  )
}

const initialArrayValue = [null]

const RcTagDynamicFilterField = ({
  name,
  label,
  params,
  onFilterChange
}) => {
  const { fields } = useFieldArray(name, {
    // initialValue: initialArrayValue
  })

  const formState = useFormState({
    subscription: {
      values: true
    }
  })

  React.useEffect(() => {
    onFilterChange(fields.value?.filter(x => !!x))
  }, [formState.values])

  const onChange = (val, vdx) => {
    if (fields.length > 1) {
      const removeCount = val ? fields.length - (vdx + 1) : fields.length - (vdx)
      if (removeCount > 0) {
        for (let i = 0; i < removeCount; i++) {
          fields.pop()
        }
        fields.push(null)
      }
    }
    if (val && (fields.length - 1 === vdx)) fields.push(null)
  }

  return (
    <Flex>
      {fields.map((fname, dx) => {
        let parentId = dx > 0 ? fields.value?.[dx - 1] : null
        if (typeof parentId === 'object') parentId = null
        return (
          <Box key={fname} sx={{
            width: 200
          }}>
            <RcTagFilter
            placeholder={label || name}
            onChange={onChange}
            name={fname}
            depth={dx}
            parentId={parentId}
            params={params}
            key={parentId} />
          </Box>
        )
      })}
    </Flex>
  )
}

const RcTagFilter = ({
  name,
  depth,
  parentId,
  onChange,
  placeholder,
  params,
}) => {
  return (
    // <RfGraphLookupField name={name} field={{name, params: {depth: depth}, modelName: 'RxTag'}}/>
    <RfFormField
      placeholder={placeholder}
      autoSelectIfOne={true}
      kind='entity'
      onChange={(val) => onChange(val, depth)}
      name={name}
      options={{
      modelName: 'rcTag',
      params: { depth: depth, parent: parentId, ...params }
    }} />
  )
}

export default RcTagDynamicFilter
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// Reactor UI
import { Box, Flex } from 'reactor-ui'

import TextInput from 'reactor-ui/components/TextInput'

import processors from '../processors'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfTextField = ({
  name,
  type,
  multiLine,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
    format: field.format
  })

  field.processors?.forEach(processor => {
    processors[processor.name](processor, field, input, meta)
  })

  let label = t(field.label)
  if (field.labelDescription) {
    label = `${label} ${t(field.labelDescription)}`
  }
  return (
    <Box sx={{
      width: '100%'
    }}>
      <TextInput
        labelSx={{
          textTransform: 'capitalize'
        }}
        tabIndex={field.tabIndex}
        multiline={multiLine}
        requiredLabel={field.requiredLabel}
        placeholder={field.placeholder}
        type={type || field.kind}
        required={field.required}
        label={label}
        wrapperSx={{
          zIndex: 1,
        }}
        sx={{
          width: '100%',
          ...field.sx
        }}
        {...input}
        step={field.step}
        autoFocus={field.autoFocus}
        disabled={field.disabled}
        options={field.options}
      />
      <RfFieldAlert meta={meta} sx={{
        mt: -2,
        zIndex: 0,
        pt: 4,
        pb: 3,
      }}/>
      <RfFieldDescription field={field} sx={{mb: 2}}/>
    </Box>
  )
}

export default RfTextField
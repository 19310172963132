import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import { useNavigate, useParams, Link } from 'reactor-vera/router'
import useReactor from 'reactor/hooks/useReactor'

// Reactor Ui
import { Flex, Box } from "reactor-ui"
import Button from 'reactor-ui/components/Button'
import Card from 'reactor-ui/components/Card'
import Text from 'reactor-ui/components/Text'


const AuthenticationForgot = ({
  loginUrl,
  loginKey,
  forgotAction = 'RcApp.ForgotPassword',
  basePath
}) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)
  const navigate = useNavigate()
  const reactor = useReactor()

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(`/home`)
    }
  }, [isAuthenticated])

  const [formProps, isSubmitting] = useReactorActionForm(
    forgotAction,
    {
      initialValues: {
        // path: `${basePath}/reset`
      },
      // onSuccess: () => window.location.reload()
    }
  )

  if (formProps.phase === 'SUCCESS') {
    return (
      <Flex alignItems='center' justifyContent='center'>
        <Box width={['90%', '50%', '50%', '50%', 400]} mt='20vh' pt='25px' mx='auto'>
          <Card>
            <Form {...formProps}>
              <Box sx={{
                fontWeight: 'bold',
                mb: 3,
                textTransform: 'capitalize'
              }}>
                <Text.Translated>
                  label.forgot_password
                </Text.Translated>
              </Box>
              {/* Belirttiğiniz e-posta adresine şifrenizi yenilemeniz için yapmanız gerekenleri içeren bir e-posta gönderdik. */}
              <Text.Translated>
                text.password_reset_instructions_sent
              </Text.Translated>
              <Flex sx={{
                flexDirection: 'row-reverse',
                mt: 3
              }}>
                <Link to={`./../../`} tabIndex={5}>
                  <Text.Translated capitalize>
                    btn.go_back
                  </Text.Translated>
                </Link>
              </Flex>
            </Form>
          </Card>
        </Box>
      </Flex>
    )
  }

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Box width={['90%', '50%', '50%', '50%', 400]} mt='20vh' pt='25px' mx='auto'>
        <Card>
          <Form {...formProps}>
            <Box sx={{
              fontWeight: 'bold',
              mb: 3
            }}>
              <Text.Translated capitalize>
                label.forgot_password
              </Text.Translated>
            </Box>
            <RfFormField name="email" label={loginKey || reactor?.rConfig?.loginKey || 'E-Posta'} tabIndex={1} schema={yup.string().min(4).required()} required focus />
            <RfFormError error={formProps.error} />
            <Button colorScheme={'brand'} isFullWidth tabIndex={4} submit isLoading={isSubmitting} >
              <Text.Translated capitalize>
                btn.send
              </Text.Translated>
            </Button>
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to={`./../../`} tabIndex={5}>
                <Text.Translated capitalize>
                  btn.go_back
                </Text.Translated>
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
  )
}

export default AuthenticationForgot

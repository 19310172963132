import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const PropertyActivityPass = props => {
  return (
    <RV.EntityDetailPage
      modelName='RnxResourceThing'
      hideDetailOnSubRoutes
      fields={[
        'name', 'price', 'status', 'properties', 'contracts', 'customMembershipParameters', 'linkedActivities'
      ]}
      columns={[
        'name', 'price', 'status',
      ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/*' element={<PropertyActivityPassHome refresh={result.refresh} rnxResourceThing={entity} />} />
        ),
        // (entity, result) => (
        //   <RV.Route path='type/:rnxResourceThingId/*' element={<MembershipTypeDetail rnxMembershipProgram={entity} />} />
        // ),
        // (entity, result) => (
        //   <RV.Route path='application/:rnxMembershipRequestId/*' element={<ApplicationDetail parent={entity} />} />
        // ),
        // (entity, result) => (
        //   <RV.Route path='member/:rnxMembershipId/*' element={<MembershipDetail program={entity} />} />
        // ),
      ]}
    />
  )
}

const PropertyActivityPassHome = ({
  rnxResourceThing,
  refresh
}) => {
  return (
    <>
      <RV.Card>
        <RV.Card.HeaderArea>
          <RV.Card.Header>
            Detaylar
          </RV.Card.Header>
          <RV.ActionGroupMenu
            name={'RnxResourceThing'} contexts={['Entity.SinglePassActivity']} ctx={{ id: rnxResourceThing.id }}
            callbacks={{
              onSuccess: refresh
            }}
          />
        </RV.Card.HeaderArea>

        {rnxResourceThing.properties && (
          <>
            <Box>
              Kaç dakika öncesinden alınabilir?: {rnxResourceThing.properties.buyBeforeActivity}
            </Box>
            <Box>
              Kaç dakika öncesinden giriş yapabilir?: {rnxResourceThing.properties.enterBeforeActivity}
            </Box>
            <Box>
              Aktivite başına limit: {rnxResourceThing.properties.enterBeforeActivity}
            </Box>
          </>
        )}

        <RV.Card.Header mt={4}>
          Aktiviteler
        </RV.Card.Header>
        {rnxResourceThing.linkedActivities?.map(a => {
          return (
            <Box key={a.id}>
              {a.fullName}
            </Box>
          )
        })}

      </RV.Card>

      <PassContracts rnxResourceThing={rnxResourceThing} />
      <PassParameters rnxResourceThing={rnxResourceThing} />
    </>
  )
}

const PassContracts = ({
  rnxResourceThing
}) => {
  // const result = RV.useReactorQuery({
  //   rnxResourceThing: {
  //     fields: [
  //       'contracts'
  //     ],
  //     filters: {
  //       id: rnxResourceThing.id
  //     }
  //   }
  // })

  // const rnxResourceThing = result.graph?.rnxResourceThing
  if (!rnxResourceThing) return null

  return (
    <RV.Card mt={4}>
      <RV.Layout.Row>
        <RV.Card.Header>
          Sözleşmeler
        </RV.Card.Header>
        <Box>
          <RV.ActionGroup name={'RnxResourceThing'} contexts={['Entity.Contract']} kinds={['CREATE']} ctx={{
            id: rnxResourceThing.id,
          }} />
        </Box>
      </RV.Layout.Row>

      <Box>
        {rnxResourceThing.contracts ? (
          Object.entries(rnxResourceThing.contracts).map(([codeName, contract], dx) => {
            return (
              <RV.Layout.Row key={dx} sx={{ my: 2 }}>
                <RV.Icon name='file'>
                  {contract.name}
                </RV.Icon>
                <RV.ActionGroupMenu name={'RnxResourceThing'} contexts={['Entity.Contract']} kinds={['UPDATE', 'DELETE']} ctx={{
                  id: rnxResourceThing.id,
                  codeName: codeName
                }} />
              </RV.Layout.Row>
            )
          })
        ) : (
          <Box>Sözleşme yok.</Box>
        )}
      </Box>
    </RV.Card>
  )
}

const PassParameters = ({
  rnxResourceThing
}) => {

  if (!rnxResourceThing) return null
  return (
    <RV.Card sx={{
      my: 4
    }}>
      <RV.Layout.Row >
        <RV.Card.Header>
          Parametreler
        </RV.Card.Header>
        <RV.ActionGroup name={'RnxResourceThing'} contexts={['Entity.Parameter']} kinds={['UPDATE']} ctx={{
          id: rnxResourceThing.id,
          kind: 'parameter'
        }} />
      </RV.Layout.Row>
      <Box sx={{
        mx: -4,
        mt: 4
      }}>
        <Box sx={{
          flex: '1 1 0',
          mx: 4
        }}>
          <Box>
            {rnxResourceThing.customMembershipParameters?.fields?.map((param, dx) => {
              return (
                <Box key={dx}>
                  {param.name}
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </RV.Card>
  )
}

const CommerceFacilitySales = ({
  rnxResourceThing
}) => {
  return 'sales'
}

const CommerceFacilityProducts = ({
  rnxResourceThing
}) => {
  return (
    <RV.GraphTable
      sx={{
        mt: 4
      }}
      query={{
        filters: {
          owner_entity_key: rnxResourceThing.id
        }
      }}
      modelName='RnxResourceThing'
      // detailLink='application'
      headerText='Ürünler'
      defaultModelActionContext='Model.PrProduct'
      // showEntityActions={false}
      actionCtx={{
        model: {
          rnxPropertyId: rnxResourceThing.id
        }
      }}
    />
  )
}

export default PropertyActivityPass
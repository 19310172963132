import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'

import useLayoutConfig from 'reactor-vera/hooks/useLayoutConfig'
import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'
import usePlexUiTheme from 'reactor-vera/apps/ui/hooks/usePlexUiTheme'

import SidebarUser from './SidebarUser'


const SidebarMenu = ({
  zone,
  domain,
  baseUrl,
  rootUrl,
}) => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig?.sidebar?.extended
  const plexUiTheme = usePlexUiTheme()
  const isSingleDomain = zone.domains.length === 1

  let name

  if (!isSingleDomain) {
    if (isExtended) {
      name = (
        <Icon icon={domain.icon} wrapperSx={{
          color: 'brand.500',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          flex: '1 1 0',
          my: isExtended ? 2 : 2
        }} childSx={{
          ml: 2,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: '80%',
          display: 'inline-block',
        }}>
          {domain.label || domain.name}
        </Icon>
      )
    } else {
      name = (
        <Box sx={{
          textAlign: 'center'
        }}>
          <Icon color='brand.500' inline icon={domain.icon} wrapperSx={{
            textAlign: 'center'
          }} />
          <Box sx={{
            color: 'brand.500',
            fontSize: 'sm',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'inline-block',
          }}>
            {domain.label || domain.name}
          </Box>
          <Box sx={{
            mt: 2
          }} />
        </Box>
      )
    }
  }

  return (
    <>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <ExtensionButton isExtended={isExtended} />
        <Box>
          <SidebarUser/>
        </Box>
      </Flex>
      <MenuWrapper isExtended={isExtended}>
        {name}

        {domain.items.map((item, dx) => {
          const Component = plexUiTheme?.sidebar?.[item.options?.sidebarComponentKey || item.kind]
          if (!Component) {
            console.warn('Sidebar component not found', item.componentKey, plexUiTheme)
          }

          return (
            <Box key={dx}>
              <Component item={item} depth={0} baseUrl={baseUrl} />
            </Box>
          )
        })}
      </MenuWrapper>
    </>
  )
}

const MenuWrapper = ({
  isExtended,
  children
}) => {
  const styles = useStyleConfig('PlexUiSidebarWrapper', {
    variant: isExtended && 'extended', defaultStyleConfig: {
      baseStyle: {
        bg: 'dark.500',
        pr: 2,
        pl: 2,

        py: 2,

        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,

        color: 'light.800',
      },
      variants: {
        extended: {
          pr: 4,
          pl: 4,
        }
      }
    }
  })

  return (
    <Box __css={styles}>
      {children}
    </Box>
  )
}

const ExtensionButton = ({

}) => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig?.sidebar?.extended
  const iconName = isExtended ? 'chevron-left' : 'chevron-right'

  return (
    <Button onClick={() => {
      updateLayoutConfig({
        sidebar: {
          extended: !isExtended
        }
      })
    }} iconColor='dark.500' iconName={iconName} size='xs' sx={{

    }} />
  )
}

export default SidebarMenu
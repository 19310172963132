import RnxDeviceManagement from './pages/RnxDeviceManagement'
import RnxDeviceAppManagement from './pages/RnxDeviceAppManagement'
import RnxDeviceProfileManagement from './pages/RnxDeviceProfileManagement'
import DeviceRegister from './pages/DeviceRegister'
import DeviceSettings from './pages/DeviceSettings'


export default {
  name: 'RnxDevice',
  components: {
    'RnxDeviceManagement': {
      component: RnxDeviceManagement
    },
    'RnxDeviceAppManagement': {
      component: RnxDeviceAppManagement
    },
    'RnxDeviceProfileManagement': {
      component: RnxDeviceProfileManagement
    },
    'DeviceRegister': {
      component: DeviceRegister
    },
    'DeviceSettings': {
      component: DeviceSettings
    },
  }
}
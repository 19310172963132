import React from 'react'

const createInputElement = (ref, callback, accept, multiple) => {
  const inputElement = document.createElement("input")
  inputElement.type = "file"
  inputElement.accept = accept
  inputElement.multiple = multiple

  inputElement.addEventListener("change", (e) => {
    callback(e)
    createInputElement(ref, callback, accept, multiple)
  })
  ref.current = inputElement
}

const useFileInputElement = (accept, callback, options = {}) => {
  const {multiple = false} = options
  const inputRef = React.useRef()
  if (typeof window === "undefined") return

  if (!inputRef.current) {
    createInputElement(inputRef, callback, accept, multiple)
  }

  return inputRef.current
}

export default useFileInputElement
import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

import reactorVeraActions from 'reactor-vera/systems/reactor-vera/actions'

const useLayoutConfig = props => {
  const layoutConfig = useSelector(state => state.reactorVera.config.layout)
  const dispatch = useDispatch()
  const updateConfig = React.useCallback((config) => {
    dispatch(reactorVeraActions.updateConfig({
      layout: config
    }))
  }, [])

  return [layoutConfig, updateConfig]
}

export default useLayoutConfig
import sagas from './sagas'
import actions from './actions'
import reducer from './reducer'

export default {
  name: 'Upload',
  sagas,
  actions,
  reducer
}

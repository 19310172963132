import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import PropertyCommerceFacility from './PropertyCommerceFacility'


const PropertyCommerceFacilities = ({
  rnxProperty
}) => {
  const result = RV.useReactorQuery({
    rnxPropertyList: {
      // fields: [
      //   'memberships'
      // ],
      filters: {
        parent_id: rnxProperty.id
      }
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ rnxPropertyList }) => (
        <RV.Card>
          <RV.Card.HeaderArea>
            <RV.Card.Header>
              Ticari Tesisler
            </RV.Card.Header>

            <RV.ActionGroupMenu
              name={'RnxProperty'} contexts={['Entity.CommerceFacility']} ctx={{ id: rnxProperty.id }}
            />
          </RV.Card.HeaderArea>

          {(rnxPropertyList.items.length === 0) && (
            <Box>
              Ticari tesis yok.
            </Box>
          )}

          {rnxPropertyList.items.map(item => {
            return (
              <Box key={item.id} my={2}>
                <RV.Layout.Row>
                  <Box flex='0 0 30%'>
                    <RV.Icon name='calendar'>
                      <RV.Link to={`./cf/${item.id}`}>{item.name}</RV.Link>
                    </RV.Icon>
                  </Box>
                  <RV.Enum value={item.status} />
                  <RV.ActionGroupMenu
                    name={'RnxProperty'} contexts={['Entity']} ctx={{ id: item.id }}
                  />
                </RV.Layout.Row>
              </Box>
            )
          })}
        </RV.Card>
      )}
    </RV.QueryLoading>
  )
}

PropertyCommerceFacilities.routes = [
  <RV.Route path='cf/:commerceRnxPropertyId/*' element={<PropertyCommerceFacility />} key='commercedetail' />
]

export default PropertyCommerceFacilities
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import { MembershipProgramDetail } from 'elements/rnx_membership/rnxMembership/pages/RnxMembershipManagement'


const PropertyMembershipPrograms = ({
  rnxProperty
}) => {
  const result = RV.useReactorQuery({
    rnxProperty: {
      fields: [
        'memberships'
      ],
      filters: {
        id: rnxProperty.id
      }
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ rnxProperty }) => (
        <RV.Card>
          <RV.Card.HeaderArea>
            <RV.Card.Header>
              Üyelik Programları
            </RV.Card.Header>

            <RV.ActionGroupMenu
              name={'RnxProperty'} contexts={['Entity.Membership']} ctx={{ id: rnxProperty.id }}
            />
          </RV.Card.HeaderArea>

          <RV.Alert status='info' my={2}>
            Tesise sadece seçilen programlarda üyeliği bulunan kullanıcılar giriş yapabilir.
          </RV.Alert>

          {(rnxProperty.memberships.items.length === 0) && (
            <Box>
              Üyelik programı yok.
            </Box>
          )}

          {rnxProperty.memberships.items.map(item => {
            return (
              <Box key={item.id} my={2}>
                <RV.Layout.Row>
                  <Box flex='0 0 30%'>
                    <RV.Icon name='calendar'>
                      <RV.Link to={`./m/${item.id}`}>{item.name}</RV.Link>
                    </RV.Icon>
                  </Box>
                  <RV.Enum value={item.status} />
                  <RV.ActionGroupMenu
                    name={'RnxMembershipProgram'} contexts={['Entity.Property']} ctx={{ id: item.id, rnxPropertyId: rnxProperty.id }}
                  />
                </RV.Layout.Row>
              </Box>
            )
          })}
        </RV.Card>
      )}
    </RV.QueryLoading>
  )
}

PropertyMembershipPrograms.routes = [
  <RV.Route path='m/:rnxMembershipProgramId/*' element={<MembershipProgramDetail />} key='detail' />
]

export default PropertyMembershipPrograms
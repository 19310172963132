// Runic
import { createActions } from 'reactor/core/redux'

const ACTIONS = {
  LOCAL: {
    registerWidget: {},
    unregisterWidget: {},
    setFilterValue: {},
    setFilterValues: {},
    resetFilters: {},
    registerDashboard: {},
    unregisterDashboard: {}
  }
}

export default createActions(ACTIONS, 'reactorDashboard')

import React from 'react'

// Vendor
import { useDispatch } from 'react-redux'

// RunicAura
import { useHref } from 'reactor-vera/router'
import runicVeraActions from 'reactor-vera/systems/reactor-vera/actions'


// const useBreadcrumb =(key, value) => {
//   const dispatch = useDispatch()
//   const url = useHref('')

//   React.useEffect(() => {
//     if (value) {
//       dispatch(runicVeraActions.addBreadcrumb({key, value, url}))
//       return () => dispatch(runicVeraActions.removeBreadcrumb({key}))
//     }
//   }, [key, value === null || value === undefined])
// }

const useBreadcrumb =(key, value) => {
  const dispatch = useDispatch()
  const url = useHref('')
  const brRef = React.useRef()
  const isFirstRenderRef = React.useRef()

  // To hack our way around the useEffect use/removal order
  if (!brRef.current && !isFirstRenderRef.current && value) {
    isFirstRenderRef.current = true
    brRef.current = {key, value}
    dispatch(runicVeraActions.addBreadcrumb({key, value, url}))
  }
  // if (value) console.log('use', key, value)

  React.useEffect(() => {
    if (brRef.current) return () => {
      // console.log('rem  ')
      const keyCurrent = brRef.current.key
      brRef.current = null
      dispatch(runicVeraActions.removeBreadcrumb({key: keyCurrent}))
    }
    // if (value) console.log('use eff', key, value, brRef.current)
    if (value) {
      dispatch(runicVeraActions.addBreadcrumb({key, value, url}))
      return () => {
        brRef.current = null
        dispatch(runicVeraActions.removeBreadcrumb({key}))
      }
    }
  }, [key, value === null || value === undefined])
}

export default useBreadcrumb
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const BackgroundJobs = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.Layout.Col3>
          <BackgroundJobList />
          <>
            <RV.GraphTable
              modelName={'RcBackgroundJob'}
              headerText='Periyodik Görevler'
              query={{
                filters: {
                  activation_kind: 'PERIODIC'
                }
              }}
              detailLink={'group'}
            />

            <RV.GraphTable
              modelName={'RcBackgroundJobTask'}
              headerText='Görevler'
              // query={{
              //   filters: {
              //     activation_kind: 'PERIODIC'
              //   }
              // }}
              detailLink={'group'}
              sx={{
                mt: 3
              }}
            />
          </>
        </RV.Layout.Col3>
      )} />

      <RV.Route path='group/:rcTagGroupId/*' element={(
        <RV.EntityDetailPage
          modelName='RcTagGroup'
          routes={[
            entity => (
              <RV.Route path='/' element={(
                <RV.Layout.Cols colSize={400}>
                  <RV.GraphTable
                    modelName={'RcTagSystem'}
                    // detailLink={'point'}
                    query={{
                      filters: {
                        rc_tag_group_id: entity.id
                      },
                      itemFields: [
                        'name', 'model'
                      ],
                      columns: [
                        'name', 'model'
                      ]
                    }}
                    actionCtx={{
                      model: {
                        rc_tag_group_id: entity.id,
                      }
                    }}
                  />

                  <RV.GraphTable
                    modelName={'RcTag'}
                    // detailLink={'point'}
                    query={{
                      filters: {
                        rc_tag_group_id: entity.id
                      }
                    }}
                    actionCtx={{
                      model: {
                        rc_tag_group_id: entity.id,
                      }
                    }}
                  />
                </RV.Layout.Cols>
              )} />
            )
          ]}
        />
      )} />
    </RV.Routes>
  )
}

const BackgroundJobList = ({

}) => {
  return (
    <RV.ReactorQuery query={{
      'rcBackgroundJobMeta': {
        fields: ['backgroundJobs']
      }
    }}>
      {(result) => (
        <RV.Card>
          <RV.Card.Header>
            İşlem Türleri
          </RV.Card.Header>
          {result.graph?.rcBackgroundJobMeta.backgroundJobs?.map((job, dx) => {
            return (
              <Box key={dx}>
                <RV.Layout.Row>
                  <RV.Icon icon='cloud-check' my={2}>
                    <Box sx={{
                      fontWeight: 'semibold'
                    }}>
                      {job.name}
                    </Box>
                  </RV.Icon>
                  <RV.ActionGroupMenu
                    name={'RcBackgroundJob'} contexts={['Model.Job']} ctx={{ name: job.name }}
                  />
                </RV.Layout.Row>
                <Box ml={8} color='dark.300' fontSize='sm'>
                  {job.description}
                </Box>
              </Box>
            )
          })}
        </RV.Card>
      )}
    </RV.ReactorQuery>
  )
}

export default BackgroundJobs
import CampaignManagement from './pages/CampaignManagement'

export default {
  name: 'RnxBroadcastAdvertisement',
  components: {
    'CampaignManagement': {
      component: CampaignManagement
    },
  }
}

import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const CmrPayments = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.GraphTable
          modelName={'CmrPayment'}
          query={{
            filters: {
              // status: 'DONE'
            }
          }}
          detailLink={'d'}
        // actionCtx={{
        //   model: {
        //     // rnx_device_app: params.rnxDeviceAppId,
        //   }
        // }}
        />
      )} />
      <RV.Route path='d/:cmrPaymentId/*' element={<CmrPaymentDetail />} />
    </RV.Routes>
  )
}

const CmrPaymentDetail = props => {
  return (
    <RV.EntityDetailPage
      modelName='CmrPayment'
      // fields={[
      //   'name', 'codeName', 'isActive', 'primaryZone', 'availableZones'
      // ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/' element={(
            <Box>
              <RV.GraphTable
                modelName={'CmrPaymentResource'}
                query={{
                  filters: {
                    cmr_payment_id: entity.id
                  }
                }}
                // detailLink={'d'}
              // actionCtx={{
              //   model: {
              //     // rnx_device_app: params.rnxDeviceAppId,
              //   }
              // }}
              />
            </Box>
          )} />
        )
      ]}
    />
  )
}

export default CmrPayments
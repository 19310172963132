import React from 'react'

// Vendor
import { FrameContext } from "react-frame-component"

const useOnClickOutside = (ref, handler, useParent, enabled = true, debug) => {
  const frameContext = React.useContext(FrameContext)

  React.useEffect(() => {
    if (!enabled) return
    const doc = frameContext?.document || document
    const listener = event => {
      if (!ref.current) return
      const holder = useParent ? ref.current.parentNode : ref.current
      if (holder.contains(event.target)) return
      debug && console.log('***', 'close', holder, event.target, ref.current)
      handler(event)
    }

    doc.addEventListener('mousedown', listener)
    doc.addEventListener('touchstart', listener)

    return () => {
      doc.removeEventListener('mousedown', listener)
      doc.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, enabled])
}

export default useOnClickOutside
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import PropertyCommerceFacilities from 'elements/rnx_property/rnxProperty/components/PropertyCommerceFacilities'


const CommercialProperties = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<CommercialPropertiesHome />} />
      {PropertyCommerceFacilities.routes.map(r => r)}
    </RV.Routes>
  )
}

const CommercialPropertiesHome = ({

}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    rnxProperty: {
      fields: ['name', 'kind', 'status'],
      filters: {
        id: params.rnxPropertyId
      }
    }
  })
  return (
    <RV.QueryLoading result={result}>
      {({ rnxProperty }) => {
        return <PropertyCommerceFacilities rnxProperty={rnxProperty} />
      }}
    </RV.QueryLoading>
  )
}

export default CommercialProperties
import React from 'react'

// Reactor
import { Route } from 'reactor-vera/router'
import ReactorVeraApp from 'reactor-vera/ReactorVeraApp'

// Project
import storeCreator from './core/store'
import theme from './core/theme'

import rcAppApp from 'elements/rc_app/rcApp'
import rnxProperty from 'elements/rnx_property/rnxProperty'
import rcAuthSimple from 'elements/rc_auth_simple/rcAuthSimple'
import rcTag from 'elements/rc_tag/rcTag'
import rnxDevice from 'elements/rnx_device/rnxDevice'
import rcBackgroundJob from 'elements/rc_background_job/rcBackgroundJob'
import rnxDevicePac from 'elements/rnx_device_pac/rnxDevicePac'
import rnxDeviceDisplay from 'elements/rnx_device_display/rnxDeviceDisplay'
import rnxBroadcast from 'elements/rnx_broadcast/rnxBroadcast'
import rnxPac from 'elements/rnx_pac/rnxPac'
import rnxMembership from 'elements/rnx_membership/rnxMembership'
import rnxActivityGroup from 'elements/rnx_activity_group/rnxActivityGroup'
import prBase from 'elements/pr_base/prBase'
import cmrPayment from 'elements/cmr_payment/cmrPayment'
import rcDomainLog from 'elements/rc_domain_log/rcDomainLog'
import rnxBroadcastAdvertisement from 'elements/rnx_broadcast_advertisement/rnxBroadcastAdvertisement'
import Payment from 'elements/payment/Payment'

const apps = [
  rcAppApp,
  rnxProperty,
  rcAuthSimple,
  rcTag,
  rnxDevice,
  rcBackgroundJob,
  rnxDevicePac,
  rnxDeviceDisplay,
  rnxBroadcast,
  rnxPac,
  rnxMembership,
  rnxActivityGroup,
  prBase,
  cmrPayment,
  rcDomainLog,
  rnxBroadcastAdvertisement,
  Payment
]

import dashboard from 'reactor/systems/dashboard'

const systems = [
  dashboard
]

import PrRegister from 'elements/pr_base/prBase/pages/PrRegister'
import PrPublicSite from 'elements/pr_base/prBase/pages/PrPublicSite'
import PrQr from 'elements/pr_base/prBase/components/PrQr'


const App = () => (
  <ReactorVeraApp
    storeCreator={storeCreator}
    theme={theme}
    apps={apps}
    systems={systems}
    config={{
      channel: 'Reactor',
    }}
    routes={[
      <Route path="register/*" element={<PrRegister />} key='register' />,
      <Route path="site/:rcTenantId/*" element={<PrPublicSite />} key='public' />,
      <Route path="site/site/:rcTenantId/profile/qr/:qrKey/*" element={<PrQr />} key='public' />,
      // <Route path="qr/:qrKey" element={<FmQr/>} key='qr' />,
      // <Route path="member/:rcTenantId/*" element={<MemberHome/>} key='member' />,
      // <Route path="channel/admin/*" element={<ChannelLogin loginKey='E-Posta' channel='Reactor'/>} key='adminLogin' />,
      // <Route path="channel/user/*" element={<ChannelLogin loginKey='TC' channel='ReactorPublic' forgotAction='FmBase.ForgotPassword' basePath='/channel/user'/>} key='userLogin' />,
    ]}
  />
)

export default App

import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

// Reactor
import useReactor from 'reactor/hooks/useReactor'
import { Routes, useParams, Route, useHref } from 'reactor-vera/router'

import usePlexUiTheme from 'reactor-vera/apps/ui/hooks/usePlexUiTheme'
import PlexUiThemeIndex from './PlexUiThemeIndex'


const PlexUiIndex = props => {
  const plexUiTheme = usePlexUiTheme()
  const IndexComponent = plexUiTheme?.components?.Index || PlexUiThemeIndex
  return <IndexComponent/>
}

export default PlexUiIndex
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const PacRnxContact = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<RnxAccessProviderManagementHome />} />
      <RV.Route path='contact/:rnxContactId' element={<RnxAccessProviderManagementDetail />} />
    </RV.Routes>
  )
}

const RnxAccessProviderManagementHome = ({

}) => {
  return (
    <>
      <RV.GraphTable
        modelName='RnxContact'
        detailLink='contact'
        // stats={{
        //   'totalCount': {},
        //   'countsByKind': {}
        // }}
        // elements={{
        //   beforeTable: [
        //     (result) => {
        //       const { t } = RV.useTranslation()
        //       return (
        //         <Flex alignItems='center' flexWrap='wrap' mx={-3} key='stats' my={2}>
        //           <RV.StatBox sx={{ mx: 3, flex: '1 1 0' }} label='Toplam' value={result.graph?.rnxPacAccessProviderList?.stats.totalCount} />
        //           {result.graph?.rnxPacAccessProviderList?.stats.countsByKind?.map((stat, dx) => {
        //             if (!stat.count) return null
        //             return (
        //               <RV.StatBox colorDx={dx + 1} sx={{ mx: 3, flex: '1 1 0' }} label={t(`enum.${stat.kind.toLowerCase()}`)} value={stat.count} />
        //             )
        //           })}
        //         </Flex>
        //       )
        //     }
        //   ]
        // }}
      />
    </>
  )
}

const RnxAccessProviderManagementDetail = ({

}) => {
  return (
    <>
      <RV.EntityDetailPage
        modelName='RnxContact'
        // fields={['name', 'serial', 'datetimeLastContact', 'lastContactAddr', 'currentRnxDeviceAppVersion', 'isOnline', 'rcEntityId', 'deviceStats']}
        // columns={['name', 'serial', 'datetimeLastContact', 'lastContactAddr', 'currentRnxDeviceAppVersion', 'isOnline', 'rcEntityId']}
        routes={[
          entity => (
            <RV.Route path='/' element={(
              <RV.Layout.Cols colSize={400}>
                <RV.GraphTable
                  showModelActions={false}
                  showEntityActions={false}
                  stats={{
                    'totalPassCount': {},
                    'passCountsByProviderKind': {},
                  }}
                  elements={{
                    beforeTable: [
                      (result) => {
                        const { t } = RV.useTranslation()
                        return (
                          <>
                            <RV.Card.Header>Geçiş</RV.Card.Header>
                            <Flex alignItems='center' flexWrap='wrap' mx={-3} key='stats' my={2}>
                            <RV.StatBox sx={{ mx: 3, flex: '1 1 0' }} label='Toplam' value={result.graph?.rnxPacAccessRecordList?.stats.totalPassCount} />
                              {result.graph?.rnxPacAccessRecordList?.stats.passCountsByProviderKind?.map((stat, dx) => {
                                if (!stat.count) return null
                                return (
                                  <RV.StatBox colorDx={dx + 1} sx={{ mx: 3, flex: '1 1 0' }} label={t(`enum.${stat.provider_kind.toLowerCase()}`)} value={stat.count} />
                                )
                              })}
                            </Flex>
                          </>
                        )
                      }
                    ]
                  }}
                  modelName={'RnxPacAccessRecord'}
                  // detailLink={'point'}
                  query={{
                    filters: {
                      rnx_contact_id: entity.id
                    },
                    order_by: 'datetime_access|desc',
                    itemFields: [
                      'rnxPacAccessProvider', 'serial', 'rnxPacAccessPlan', 'rnxPacAccessPoint', 'kind', 'result', 'datetimeAccess'
                    ],
                    columns: [
                      'rnxPacAccessProvider.kind', 'serial', 'rnxPacAccessPlan', 'rnxPacAccessPoint', 'kind', 'result', 'datetimeAccess'
                    ]
                  }}
                />
              </RV.Layout.Cols>
            )} />
          )
        ]}
      />
    </>
  )
}

export default PacRnxContact
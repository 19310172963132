import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import useReactor from 'reactor/hooks/useReactor'


const usePlexUiTheme = (name) => {
  const reactor = useReactor()
  const themeNameFromConfig = useSelector(state => state.reactorVera.uiTheme)
  const uiThemeName = name || themeNameFromConfig || 'default'
  const theme = reactor.directory.reactorVera?.plexUi?.themes?.[uiThemeName]
  if (!theme) {
    console.warn('Theme not found', uiThemeName, themeNameFromConfig, name)
  }
  return theme
}

export default usePlexUiTheme
// Vendor
import merge from 'lodash/merge'

// Riva - Function
import { createReducer } from 'reactor/core/redux'

import dashboardActions from './actions'

const defaultState = {
  dashboards: {}
}

const defaultDashboardState = {
  widgets: {},
  filterIds: [],
  filters: {},
  values: {},
  defaultFilters: {}
}

export default createReducer(defaultState, {
  [dashboardActions.registerDashboard]: (draft, {id, defaultFilters}) => {
    if (!draft.dashboards[id]) {
      draft.dashboards[id] = merge({}, defaultDashboardState)
      if (defaultFilters) {
        draft.dashboards[id].defaultFilters = defaultFilters
        draft.dashboards[id].values = {}
        Object.entries(defaultFilters).forEach(([k,v]) => draft.dashboards[id].values[k] = v.value)
      }
    }
  },
  [dashboardActions.registerWidget]: (draft, {id, widget}) => {
    if (!draft.dashboards[id]) {
      draft.dashboards[id] = merge({}, defaultDashboardState)
    }

    if (!draft.dashboards[id].widgets[widget.id]) {
      draft.dashboards[id].widgets[widget.id] = widget
    }

    widget.filters.forEach(filter => {
      if (draft.dashboards[id].filterIds.indexOf(filter.id) === -1) {
        draft.dashboards[id].filterIds.push(filter.id)
      }

      if (draft.dashboards[id].defaultFilters?.[filter.id]) {
        filter = {...filter, ...draft.dashboards[id].defaultFilters?.[filter.id]}
      }

      if (!draft.dashboards[id].filters[filter.id]) {
        draft.dashboards[id].filters[filter.id] = {
          filter,
          count: 1
        }
      } else {
        draft.dashboards[id].filters[filter.id].count += 1
      }

      if (!draft.dashboards[id].values[filter.id]) {
        draft.dashboards[id].values[filter.id] = {}
        if (draft.dashboards[id].defaultFilters?.[filter.id]) {
          draft.dashboards[id].values[filter.id] = draft.dashboards[id].defaultFilters?.[filter.id]?.value
        }
      }
    })
  },

  [dashboardActions.unregisterWidget]: (draft, {id, widget}) => {
    if (!draft.dashboards[id]) return

    if (!draft.dashboards[id].widgets[widget.id]) return

    widget.filters.forEach(filter => {
      const count = draft.dashboards[id].filters[filter.id].count
      if (count > 1) {
        draft.dashboards[id].filters[filter.id].count -= 1
      } else {
        const dx = draft.dashboards[id].filterIds.indexOf(filter.id)
        if (dx !== -1) {
          draft.dashboards[id].filterIds.splice(dx, 1)
        }

        if (draft.dashboards[id].filters[filter.id]) {
          delete draft.dashboards[id].filters[filter.id]
        }

        if (draft.dashboards[id].values[filter.id]) {
          delete draft.dashboards[id].values[filter.id]
        }
      }
    })
  },

  [dashboardActions.setFilterValue]: (draft, {id, filterId, valueKey, value}) => {
    // const valueKeyToUse = value || 'value'
    // draft.dashboards[id].values[filterId][valueKeyToUse] = value
    if (!draft.dashboards[id]) return
    draft.dashboards[id].values[filterId] = value
  },
  [dashboardActions.setFilterValues]: (draft, {id, values}) => {
    // draft.dashboards[id].values = merge(draft.dashboards[id].values, values)
    draft.dashboards[id].values = values
  },

  [dashboardActions.resetFilters]: (draft, {id}) => {
    draft.dashboards[id].values = {}
  },

  [dashboardActions.unregisterDashboard]: (draft, { id }) => {
    delete draft.dashboards[id]
  },
})

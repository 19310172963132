import React from 'react'

// Vendor
import { io } from "socket.io-client"
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import useReactor from 'reactor/hooks/useReactor'
import streamActions from 'reactor/systems/stream/actions'


const ReactorSocketManager = ({
  namespaceName = 'client'
}) => {
  const reactor = useReactor()
  const dispatch = useDispatch()
  const socketRef = React.useRef()

  React.useEffect(() => {
    // if (!reactor?.rConfig?.socket?.url) return
    const ioOpts = {
      transports: ['websocket', 'polling'],
      path: reactor?.rConfig?.socket?.path || '/_ws/stream'
    }

    socketRef.current = io(`${reactor?.rConfig?.socket?.url || ''}/${namespaceName}`, ioOpts)
    socketRef.current.connect()
    socketRef.current.on('connect', () => {
      console.log('**connect')
      socketRef.current.emit('my_event', {foo: 'bar'})
    })
    dispatch(streamActions.ready({socket: socketRef.current, reactor: reactor, namespaceName}))
  }, [])

  return null
}

export default ReactorSocketManager
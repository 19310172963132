import React from 'react'

// Reactor UI
import * as changeCase from 'reactor-ui/util/text'

// Reactor
import RfActionForm from 'reactor-form/components/RfActionForm'
import DynamicComponent from 'reactor-vera/apps/blocks/components/DynamicComponent'
import { useParams } from 'reactor-vera/router'


const ReactorAction = ({
  action,
  displayAs,
  onClose,
  onSuccess,
  onReset,
  anchorKind,
  anchor,
  initialValues,
  anchorProps,
  elements,
  ctx,
  iconOnly,
  sx,
}) => {
  const params = useParams()

  if (!action.meta.ui) return null

  if (action.meta.ui.component) {
    return <DynamicComponent name={action.meta.ui.component}/>
  }

  const finalInitialValues = {
    ...action.meta.ui.initialValues,
    ...initialValues,
  }

  const actionKey = `${action.elementName}.${changeCase.pascal(action.name)}`

  return (
    <RfActionForm
      actionKey={actionKey}
      {...action.meta.ui}
      initialValues={finalInitialValues}
      displayAs={displayAs || action.meta.ui.displayAs}
      onClose={onClose}
      onSuccess={onSuccess}
      anchorKind={anchorKind}
      anchor={anchor}
      anchorProps={anchorProps}
      anchorIconOnly={iconOnly}
      elements={elements}
      ctx={{
        params,
        ...ctx
      }}
      formComponent={action.meta.ui.formComponent ? <DynamicComponent name={action.meta.ui.formComponent} initialValues={finalInitialValues} onReset={onReset}/> : null}
      displayComponent={action.meta.ui.displayComponent ? <DynamicComponent name={action.meta.ui.displayComponent} initialValues={finalInitialValues} actionKey={actionKey} onReset={onReset}/> : null}
      sx={sx}
    />
  )
}

export default ReactorAction
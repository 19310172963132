import React from 'react'

// Reactor
import * as RV from 'reactor-vera/templates/v1'


const UserSettings = props => {
  return (
    <>
      <RV.BareTabs
        isLazy
        tabs={[
          {
            name: 'Kullanıcılar',
            codeName: 'ops',
            element: (
              <RV.GraphTable
                modelName='RcUser'
                headerText='Kullanıcılar'
                // detailLink='content'
                query={{
                  filters: {
                    state: ['ACTIVE', 'TENANT_ADMIN']
                  },
                  itemFields: [
                    'name', 'email', 'state', 'datetimeLastSeen'
                  ],
                  columns: [
                    'name', 'email', 'state', 'datetimeLastSeen'
                  ]
                }}
                showModelActions={false}
                showEntityActions={false}
                elements={{
                  primaryActions: [
                    <RV.ReactorServerAction identifier='LrBase.InviteOperator' />
                  ]
                }}
              />
            )
          },

          {
            name: 'Davetler',
            codeName: 'invitations',
            element: (
              <RV.GraphTable
                modelName='RcUserInvitation'
                showModelActions={false}
                // detailLink='booth'
                headerText='Davetler'
                query={{
                  filters: {
                    // rnx_event_id: rnxEvent.id,
                    // kind: 'BOOTH',
                    // status: ['WAITING', 'CLAIMED', 'IN_PROGRESS']
                  },
                  // itemFields: ['name', 'status', 'ownerRnxEventAccount'],
                  // columns: ['name', 'status', 'ownerRnxEventAccount'],
                }}
              />
            )
          },
        ]}
      />
    </>
  )
}

export default UserSettings
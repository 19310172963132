// Vendor
import { getIn } from 'final-form'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

const parseValue = (value, parentKey, result, ctx, opts) => {
  if (isArray(value)) {
    if (value.length === 0) {
      result[parentKey] = value
    } else {
      result[parentKey] = []
      const targetResult = result[parentKey]
      value.forEach((value, dx) => {
        parseValue(value, dx, targetResult, ctx)
      })
    }
  } else if (isObject(value)) {
    const keys = Object.keys(value)
    if (keys.length === 0) {
      result[parentKey] = value
    } else {
      keys.forEach(key => {
        const keyValue = value[key]
        if (!result[parentKey]) result[parentKey] = {}
        const targetResult = result[parentKey]
        parseValue(keyValue, key, targetResult, ctx)
      })
    }
  } else {
    if (value?.includes?.('@@r.form.')) {
      const parsedValue = value.replace('@@r.form.', '')
      const resolvedValue = getIn(ctx.form, parsedValue)
      result[parentKey] = resolvedValue
    } else if (value?.includes?.('@@r.formParent.')) {
      const parsedValue = value.replace('@@r.formParent.', '')
      const resolvedValue = getIn(ctx.form, `${opts.parentPath}.${parsedValue}`)
      result[parentKey] = resolvedValue
    } else if (value?.includes?.('@@r.ctx.')) {
      const parsedValue = value.replace('@@r.ctx.', '')
      const resolvedValue = getIn(ctx, parsedValue)
      result[parentKey] = resolvedValue
    } else {
      result[parentKey] = value
    }
  }
}

export const parseValuesFromServer = (values, ctx, opts) => {
  const result = {}

  values && Object.entries(values).forEach(([k, v]) => {
    parseValue(v, k, result, ctx, opts)
  })

  return result
}

export const jsonToForm = (data, parentKey, fullKey, result, files) => {
  if (isArray(data)) {
    if (data.length === 0) {
      result[parentKey] = data
    } else {
      result[parentKey] = []
      const targetResult = result[parentKey]
      data.forEach((value, dx) => {
        const newFullKey = `${fullKey}.${dx}`
        jsonToForm(value, dx, newFullKey, targetResult, files)
      })
    }
  } else if (isObject(data)) {
    if (data instanceof Date) {
      result[parentKey] = data
    } else if (data instanceof File) {
      const fileKey = `${fullKey}`
      files[fileKey] = data
      result[parentKey] = fileKey
    } else {
      const keys = Object.keys(data)
      if (keys.length === 0) {
        result[parentKey] = data
      } else {
        keys.forEach(key => {
          const value = data[key]
          if (!result[parentKey]) result[parentKey] = {}
          const targetResult = result[parentKey]
          const newFullKey = `${fullKey}.${key}`
          jsonToForm(value, key, newFullKey, targetResult, files)
        })
      }
    }
  } else {
    // console.log('val', data, parentKey, fullKey, result)
    result[parentKey] = data
  }
}

export const checkValueForUpload = (data) => {
  if (isArray(data)) {
    // console.log('arr', data, parentKey, fullKey, result)
    if (data.length === 0) {
      return false
    } else {
      let hasUpload = false
      data.forEach(value => {
        if (hasUpload) return
        hasUpload = checkValueForUpload(value)
      })
      return hasUpload
    }
  } else if (isObject(data)) {
    // console.log('obj', data, parentKey, fullKey, result)
    if (data instanceof Date) {
      return false
    } else if (data instanceof File) {
      return true
    } else {
      const keys = Object.keys(data)
      if (keys.length === 0) {
        return false
      } else {
        let hasUpload = false
        keys.forEach(key => {
          const value = data[key]
          if (hasUpload) return
          hasUpload = checkValueForUpload(value)
        })
        return hasUpload
      }
    }
  } else {
    // console.log('val', data, parentKey, fullKey, result)
    return false
  }
}

export const checkDataForUpload = (data) => {
  let hasUpload = false
  Object.entries(data).forEach(([k, v]) => {
    if (hasUpload) return
    hasUpload = checkValueForUpload(v)
  })

  return hasUpload
}

export const getFormData = (data) => {
  let dataToSend = data

  if (checkDataForUpload(data)) {
    const result = {}
    const files = {}
    Object.entries(data).forEach(([k, v]) => {
      jsonToForm(v, k, k, result, files)
    })
    const formData = new FormData()
    formData.append('@data', JSON.stringify(result))
    Object.entries(files).forEach(([name, file]) => {
      formData.append(name, file)
    })
    dataToSend = formData
    // progressCallback = ({ progress }) => {
    //   setProgress(progress)
    // }
  }

  return dataToSend
}
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


const RfFormRow = ({
  alignItems,
  sx,
  children,
  gap = 2
}) => {
  return (
    <Flex sx={{
      flexWrap: 'wrap',
      mx: -gap,
      alignItems: alignItems || 'center',
      ...sx
    }}>
      {React.Children.map(children, (c, dx) => {
        return (
          <Box key={dx} sx={{
            flex: '1 1 0',
            mx: gap
          }}>
            {c}
          </Box>
        )
      })}
    </Flex>
  )
}

export default RfFormRow
import React from 'react';

// Reactor
import {
  Route,
  Routes,
} from 'reactor-vera/router'

// Self
import AuthenticationLogin from './AuthenticationLogin'
import AuthenticationLogout from './AuthenticationLogout'
import AuthenticationForgot from './AuthenticationForgot'
import AuthenticationReset from './AuthenticationReset'
import AuthenticationInvitation from './AuthenticationInvitation'
// import AuthenticationEmailVerify from './AuthenticationEmailVerify'


const AuthenticationBase = ({
  loginUrl = 'login',
  loginKey,
  forgotAction,
  basePath
}) => {

  return(
    <Routes>
      <Route path={loginUrl} element={<AuthenticationLogin loginKey={loginKey}/>}/>
      <Route path={`logout`} element={<AuthenticationLogout/>}/>
      <Route path={`forgot`} element={<AuthenticationForgot loginUrl={loginUrl} loginKey={loginKey} forgotAction={forgotAction} basePath={basePath}/>}/>
      <Route path={`reset/:token`} element={<AuthenticationReset/>}/>
      <Route path={`invitation/:token`} element={<AuthenticationInvitation/>}/>
      {/* <Route path={`login/:rcTenantCode`} element={<AuthenticationLogin/>}/>
      <Route path={`forgot`} element={<AuthenticationForgot/>}/>
      <Route path={`reset/:token`} element={<AuthenticationReset/>}/> */}
      {/*
      <Route path={`verify/:token`} element={<AuthenticationEmailVerify/>}/> */}
    </Routes>
  )
}

export default AuthenticationBase

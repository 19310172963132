import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

// Reactor
import * as RP from 'reactor-vera/templates/v1'


const DeviceRegister = props => {
  return (
    <RP.Routes>
      <RP.Route path='/*' element={<RegisterRequestList />} />
      <RP.Route path='request_detail/:rnxDeviceRegisterRequestId/*' element={<RegisterRequestDetail />} />
    </RP.Routes>
  )
}

const RegisterRequestList = props => {
  return (
    <>
    <RP.ReactorServerAction identifier='RnxDevice.SetAutoRegister'/>
      <RP.BareTabs
        isFitted
        isLazy
        tabs={[
          {
            name: 'Bekleyen',
            codeName: 'other',
            element: (
              <RP.GraphTable
                modelName={'RnxDeviceRegisterRequest'}
                // detailLink={'version_detail'}
                query={{
                  systemQuery: true,
                  filters: {
                    result: ['REQUESTED'],
                  }
                }}
              />
            )
          },

          {
            name: 'Kayıt Olan',
            codeName: 'registered',
            element: (
              <RP.GraphTable
                modelName={'RnxDeviceRegisterRequest'}
                // detailLink={'version_detail'}
                query={{
                  systemQuery: true,
                  filters: {
                    result: 'REGISTERED',
                  }
                }}
              />
            )
          },

          {
            name: 'Reddedilen',
            codeName: 'other',
            element: (
              <RP.GraphTable
                modelName={'RnxDeviceRegisterRequest'}
                // detailLink={'version_detail'}
                query={{
                  systemQuery: true,
                  filters: {
                    result: 'REJECTED',
                  }
                }}
              />
            )
          },

          {
            name: 'Hepsi',
            codeName: 'other',
            element: (
              <RP.GraphTable
                modelName={'RnxDeviceRegisterRequest'}
                // detailLink={'version_detail'}
                query={{
                  systemQuery: true,
                }}
              />
            )
          },
        ]}
      />
    </>
  )
}

const AutoRegister = ({

}) => {
  const source = RV.useRunicSource('RnxDevice.General.auto_register')

  let content

  if (!source.data) content = 'Yükleniyor...'

  if (!source.data?.status) content = 'Kapalı'
  else {
    content = (
      <Box>
        {source.data?.status?.rc_tenant?.name} - {source.data?.status?.rnx_device_register_profile?.name}
      </Box>
    )
  }

  return (
    <Flex sx={{
      mb: 3,
      variant: 'layout.card',
      // mx: -2,
      alignItems: 'center'
    }}>
      <Box mx={2} sx={{
        fontWeight: 'semiBold'
      }}>
        Otomatik Kayıt
      </Box>

      <Box mx={2}>
        {content}
      </Box>

      <Box mx={2}>
        <RV.RfSingleAction
          onSuccess={source.refresh}
          iconName='pencil'
          actionKey='RnxDevice.SetAutoRegister'
          fields={[
            {
              name: 'rcTenantId',
              label: 'Firma',
              kind: 'graphEntity',
              modelName: 'RcTenant',
              systemQuery: true
            },

            {
              name: 'rnxDeviceRegisterProfileId',
              label: 'Kayıt Profili',
              kind: 'graphEntity',
              modelName: 'RnxDeviceRegisterProfile',
              params: { 'rcTenantId': '@@r.form.rcTenantId' },
            }
          ]}
          // initialValues={{
          //   ...linkData,
          //   id: result.graph?.rnx_device_register_profile?.id
          // }}
          buttonText='KAYDET' />
      </Box>
    </Flex>
  )
}

const RegisterRequestDetail = props => {
  const params = RV.useParams()
  const result = RV.useRunicGraphQuery({
    'rnx_device_register_request': {
      fields: [
        'serial', 'datetime_last_contact', 'last_contact_addr', 'request_count', ['current_rnx_device_app_version', { fields: ['name'] }], 'brand', 'model', 'datetime_first_contact', 'result'
      ],
      filters: {
        id: params.rnxDeviceRegisterRequestId
      },
      'systemQuery': true
    }
  })

  RV.useBreadcrumb(`rnx_device_register_request:${result.graph?.rnx_device_register_request.id}`, result.graph?.rnx_device_register_request.name)

  const profileData = result.graph?.rnx_device_register_request?.data?.profile
  console.log(result.graph?.rnx_device_register_request)

  return (
    <>
      {/* <RV.PageHeaderBar header='Yayın'/> */}

      <RV.EntityDetail icon={'book-log'} entity={result.graph?.rnx_device_register_request} refresh={result.refresh} status={result.status} exclude={['data', 'profile_data']} />
    </>
  )

  return (
    <RV.Routes>
      <RV.Route path='/' element={<TagGroupDetail rxTagGroup={result.graph?.rnx_device_register_request} rnxDeviceRegisterRequestId={params.rnxDeviceRegisterRequestId} status={result.status} refresh={result.refresh} />} />
      <RV.Route path='tag_detail/:rxTagId' element={<TagDetail />} />
      <RV.Route path='system_detail/:rxTagSystemId' element={<SystemDetail />} />
    </RV.Routes>
  )
}

export default DeviceRegister
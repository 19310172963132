import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

import uiSelectionActions from './actions'


export const useUiSelection = (groupId) => {
  const selection = useSelector(state => state.uiSelection.selectionGroups[groupId])
  const dispatch = useDispatch()

  const actions = React.useMemo(() => {
    return {
      enableSelection: () => dispatch(uiSelectionActions.enableSelection({
        groupId,
      })),
      selectItem: (id) => dispatch(uiSelectionActions.selectItems({
        groupId,
        ids: [id]
      })),
      selectItems: (ids) => dispatch(uiSelectionActions.selectItems({
        groupId,
        ids
      })),
      unselectItem: (id) => dispatch(uiSelectionActions.unselectItems({
        groupId,
        ids: [id]
      })),
      unselectItems: (ids) => dispatch(uiSelectionActions.unselectItems({
        groupId,
        ids
      })),
      clearSelectionGroup: () => dispatch(uiSelectionActions.clearSelectionGroup({
        groupId,
      })),
    }
  }, [groupId])

  const checkIds = React.useCallback((ids) => {
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index]
      if (!selection?.[id]) return false
    }
    return true
  }, [groupId, selection])

  const getIds = React.useCallback(() => {
    if (!selection) return []
    if (selection.__count === 0) return []
    const ids = []
    Object.entries(selection).forEach(([k, v]) => {
      if (k !== '__count' && v) ids.push(k)
    })
    return ids
  }, [groupId, selection])

  React.useEffect(() => {
    return () => actions.clearSelectionGroup()
  }, [groupId])

  return {selection, actions, id: groupId, isActive: selection !== undefined, count: selection?.__count, checkIds, getIds}
}

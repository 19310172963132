import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

// Reactor
import { Routes, useParams, Route, useHref } from 'reactor-vera/router'

import PlexUiZoneRouter from './PlexUiZoneRouter'


const PlexUiThemeIndex = props => {
  const zones = useSelector(state => state.reactorVera?.zones)
  const href = useHref('')

  return (
    <Routes>
      {Object.values(zones).map((zone, dx) => (
        <Route key={dx} path={`${zone.path}/*`} element={<PlexUiZoneRouter zone={zone} baseUrl={href} />} />
      ))}
    </Routes>
  )
}

export default PlexUiThemeIndex
import AuthorizationManagement from './pages/AuthorizationManagement'
import AuthUserManagement from './pages/AuthUserManagement'

import ManageRoleAccess from './components/ManageRoleAccess'
import GraphConnectionAccessSetup from './components/GraphConnectionAccessSetup'
import UserAssignmentModelSelector from './components/UserAssignmentModelSelector'
import UserAssigmentAccessSetup from './components/UserAssigmentAccessSetup'


export default {
  name: 'RcAuthSimple',
  components: {
    'AuthorizationManagement': {
      component: AuthorizationManagement
    },
    'AuthUserManagement': {
      component: AuthUserManagement
    },
    'ManageRoleAccess': {
      component: ManageRoleAccess
    },
    'GraphConnectionAccessSetup': {
      component: GraphConnectionAccessSetup
    },
    'UserAssigmentAccessSetup': {
      component: UserAssigmentAccessSetup
    },
  }
}

import { addFieldKind } from 'reactor-form/helpers'
addFieldKind('authUserAssignmentModelSelector', UserAssignmentModelSelector)
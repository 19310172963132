import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

// Reactor
import {
  Route,
  Routes,
  useParams,
  useNavigate,
} from 'reactor-vera/router'
import useReactorAction from 'reactor/hooks/useReactorAction'
import DynamicComponent from 'reactor-vera/apps/blocks/components/DynamicComponent'


const AuthenticationInvitation = props => {
  const [data, setData] = React.useState()
  const params = useParams()
  const navigate = useNavigate()

  const dispatchAction = useReactorAction('RcApp.VerifyInvitationToken')
  React.useEffect(() => {
    dispatchAction({token: params.token}, {
      success: d => setData(d)
    })
  }, [])

  if (!data) return (
    <Box sx={{
      mt: 20,
      mx: 'auto',
      width: 'fit-content'
    }}>
      Kontrol ediliyor...
    </Box>
  )

  if (data.component) {
    return (
      <DynamicComponent name={data.component} data={data} token={params.token}/>
    )
  }
  return 'e'
  // return (
  //   <Routes>
  //     <Route path={':token'} element={<AuthenticationLogin loginKey={loginKey}/>}/>
  //   </Routes>
  // )
}

export default AuthenticationInvitation
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const PrSurveys = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<PrSurveyHome />} />
      <RV.Route path='survey/:rnxSimpleSurveyId/*' element={<PrSurveyDetail />} />
    </RV.Routes>
  )
}


const PrSurveyHome = props => {
  const params = RV.useParams()
  return (
    <RV.GraphTable
      modelName='RnxSimpleSurvey'
      detailLink='survey'
      headerText='Anketler'
      defaultModelActionContext='Model.PropertySurvey'
      query={{
        filters: {
          owner_entity_key: params.rnxPropertyId
        }
      }}
      actionCtx={{
        model: {
          rnx_property_id: params.rnxPropertyId
        }
      }}
    />
  )
}

const PrSurveyDetail = ({

}) => {
  return (
    <RV.EntityDetailPage
      modelName='RnxSimpleSurvey'
      fields={[
        'name', 'status', 'countCompleted', 'stats', 'questions'
      ]}
      columns={[
        'name', 'status', 'countCompleted'
      ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/*' element={<PrSurveyStats rnxSimpleSurvey={entity} />} />
        ),
        // (entity, result) => (
        //   <RV.Route path='/surveysession/*' element={<PrSurveySession rnxSimpleSurvey={entity} />} />
        // ),
        // (entity, result) => (
        //   <RV.Route path='type/:rnxMembershipTypeId/*' element={<MembershipTypeDetail rnxMembershipProgram={entity} />} />
        // ),
        // (entity, result) => (
        //   <RV.Route path='application/:rnxMembershipRequestId/*' element={<ApplicationDetail parent={entity} />} />
        // ),
        // (entity, result) => (
        //   <RV.Route path='member/:rnxMembershipId/*' element={<MembershipDetail program={entity} />} />
        // ),
      ]}
    />
  )
}

const PrSurveyStats = ({
  rnxSimpleSurvey
}) => {
  console.log(rnxSimpleSurvey.stats, rnxSimpleSurvey.questions)
  return (
    <>
      {rnxSimpleSurvey.questions.map(q => {
        const stat = rnxSimpleSurvey?.stats?.find(x => x.id == q.id)
        return (
          <RV.Card key={q.id} sx={{
            my: 3
          }}>
            {q.text}

            <Box>
              {q.options?.map(o => {
                const optionStat = stat?.answer_stats[o.id] || 0
                return (
                  <Box key={o.id} sx={{
                    borderLeft: '1px solid #FFF',
                    borderColor: 'brand.500',
                    my: 2,
                    pl: 4
                  }}>
                    <Box>
                      {o.option}
                    </Box>

                    <Box>
                      Seçim: {optionStat}
                    </Box>
                    <Box>
                      %{optionStat ? (optionStat / (stat.count_total_answer) * 100).toFixed(2) : 0}
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </RV.Card>
        )
      })}

      <RV.GraphTable
        modelName='RnxSimpleSurveyAnswer'
        detailLink='surveysession'
        headerText='Anket Cevapları'
        // defaultModelActionContext='Model.PropertySurvey'
        query={{
          filters: {
            rnx_simple_survey_id: rnxSimpleSurvey.id
          }
        }}
        // actionCtx={{
        //   model: {
        //     rnx_property_id: params.rnxPropertyId
        //   }
        // }}
      />
    </>
  )
}

export default PrSurveys
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'



const RnxAccessPointManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.GraphTable
          modelName={'RnxPacAccessPoint'}
          detailLink={'point'}
        // actionCtx={{
        //   model: {
        //     // rnx_device_app: params.rnxDeviceAppId,
        //   }
        // }}
        />
      )} />

      <RV.Route path='point/:rnxPacAccessPointId/*' element={(
        <RV.EntityDetailPage
          modelName='RnxPacAccessPoint'
          routes={[
            entity => (
              <RV.Route path='/' element={(
                <RV.GraphTable
                  modelName={'RnxPacAccessRecord'}
                  // detailLink={'point'}
                  query={{
                    filters: {
                      rnx_pac_access_point_id: entity.id
                    }
                  }}
                />
              )} />
            )
          ]}
        />
      )} />
    </RV.Routes>
  )
}

export default RnxAccessPointManagement
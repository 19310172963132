import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import {
  ReactorPopover
} from 'reactor-ui/components/Popover'
import * as RV from 'reactor-vera/templates/v1'
import Form, { RfFormField, RfFormError, useForm, useFormState, useField, useFieldArray, RfShowIf, useReactorActionForm } from 'reactor-form/components/RfForm'
import { RfRemoteSelectInput } from 'reactor-form/components/RfSelectField'


const ManageRoleAccess = ({
  actionKey,
  initialValues
}) => {
  const result = RV.useReactorQuery({
    'rcAuthSimpleRole': {
      filters: {
        id: initialValues.id
      },
      fields: [
        'resourceAccessMode', 'resourceAccesses'
      ]
    },
  })

  const rcAuthSimpleRole = result.graph?.rcAuthSimpleRole

  if (!rcAuthSimpleRole) return null

  const formInitialValues = {
    ...initialValues,
    accessMode: rcAuthSimpleRole.resourceAccessMode,
    models: rcAuthSimpleRole.resourceAccesses && Object.entries(rcAuthSimpleRole.resourceAccesses).map(([k, v]) => ({
      model: k,
      accessList: v
    })),
  }

  return <ManageRoleAccessForm actionKey={actionKey} initialValues={formInitialValues} />
}

const ManageRoleAccessForm = ({
  actionKey,
  initialValues,
}) => {
  const [formProps, isSubmitting, reset] = useReactorActionForm(
    actionKey,
    {
      initialValues,
      // onSuccess: (data) => {
      //   onSuccess?.(data)
      //   closeOnSuccess && disclosure.onClose()
      // },
      // onError
    }
  )
  const { t } = RV.useTranslation()

  if (formProps.phase === 'SUCCESS') {
    return (
      <Box>
        <RV.Alert status='success' sx={{ textTransform: 'capitalize' }}>
          {t('text.SUCCESS')}
        </RV.Alert>
      </Box>
    )
  }

  return (
    <>
      <Form {...formProps}>
        {({ values }) => (
          <>
            <RfFormField kind='select' name='accessMode' label='Erişim Modu' options={{
              options: [
                {
                  'value': 'all',
                  'label': 'Bütün verilere erişebilir'
                },

                {
                  'value': 'allowList',
                  'label': 'Sadece izin verilen verilere erişebilir'
                },

                {
                  'value': 'selectedAllowList',
                  'label': 'Erişim sınırlandırılanlar harici bütün verilere erişebilir'
                }
              ]
            }} />

            {['allowList', 'selectedAllowList'].includes(values.accessMode) && <RoleModelAccess />}

            <RfFormError error={formProps.error} />
            <RV.Button mt={3} textTransform='capitalize' colorScheme={'brand'} submit isLoading={isSubmitting} isFullWidth>
              Kaydet
            </RV.Button>
          </>
        )}
      </Form>
    </>
  )
}

const initialArrayValue = [{}]

const RoleModelAccess = ({

}) => {
  const { fields } = useFieldArray('models')
  const form = useForm()
  return (
    <>
      <RV.Layout.Row mb={3}>
        <RV.Card.Header>
          Model
        </RV.Card.Header>
        <RV.Button icon='plus' onClick={() => fields.push({})} />
      </RV.Layout.Row>
      {fields.map((field, dx) => {
        return (
          <Box key={dx} sx={{
            my: 4
          }}>
            <RV.Layout.Row>
              <Box flex='1 1 0'>
                <RfFormField sx={{
                  mb: 0
                }} {...{
                  name: `${field}.model`,
                  label: 'Model',
                  onChange: () => {
                    form.change(`${field}.accessList`, null)
                  },
                  kind: 'select',
                  options: {
                    loader: {
                      'kind': 'resource',
                      'options': {
                        'resourceIdentifier': 'RcApp.Reactor.models',
                      }
                    }
                  }
                }} />
              </Box>
              <RV.Button size='lg' ml={3} icon='circle-remove' onClick={() => fields.remove(dx)} />
            </RV.Layout.Row>
            <ModelAccessForm
              name={field}
              model={fields.value[dx]?.model}
            />
            <Box sx={{
              borderTop: '1px dashed #FFF',
              borderTopColor: 'dark.300',
              my: 3
            }} />
          </Box>
        )
      })}
    </>
  )
}

const ModelAccessForm = ({
  name,
  model,
}) => {
  const { fields } = useFieldArray(`${name}.accessList`)
  console.log(fields.value)
  return (
    <RfShowIf conditions={[{
      field: `model`,
      comparison: 'EXISTS'
    }]} fieldNames={[`${name}.accessList`]} parentPath={name}
    >

      <Box>
        <Box mt={4}>
          {fields.map((fname, dx) => {
            const accessKind = fields.value[dx]
            const onRemove = () => fields.remove(dx)
            return <AccessItem onRemove={onRemove} model={model} fname={fname} key={dx} accessKind={accessKind} />
          })}
        </Box>
        {/* {fields.value[dx]?.segment ? (
          'et'
        ) : (
          'ab'
        )} */}

        <ReactorPopover anchor={(
          <Box as='a' sx={{ display: 'block', mt: 2 }}>
            <RV.Icon name='plus'>
              Erişim Türü Ekle
            </RV.Icon>
          </Box>
        )}>
          {({ onClose }) => (
            <ModelAccessField
              model={model}
              onChange={(val, opt) => {
                fields.push({
                  kind: val,
                  label: opt.label,
                  setupUiComponent: opt.setupUiComponent
                })
                onClose()
              }}
            />
          )}
        </ReactorPopover>
      </Box>
    </RfShowIf>
  )
}

const AccessItem = ({
  accessKind,
  onRemove,
  fname,
  model,
}) => {
  const [detailsExpanded, setDetailsExpanded] = React.useState(!accessKind?.options)
  return (
    <Box sx={{
      bg: 'light.100',
      borderRadius: 2,
      px: 3,
      py: 3,
      my: 3
    }}>
      <Box sx={{
        // ml: 5,
        // p: 3,
        position: 'relative'
      }}>
        <RV.Layout.Row>
          <RV.Layout.Row>
            <Box sx={{
              fontWeight: 'bold'
            }}>
              <RV.Icon icon='asterisk'>
                {accessKind.label}
              </RV.Icon>
            </Box>
            <Box>
              <RV.Button size='xs' icon={detailsExpanded ? 'chevron-up' : 'chevron-down'} onClick={() => setDetailsExpanded(v => !v)}/>
            </Box>
          </RV.Layout.Row>

          <RV.Button size='xs' icon='circle-remove' onClick={onRemove} />
        </RV.Layout.Row>

        {detailsExpanded && <Box mt={4}>
          <RV.DynamicComponent name={accessKind.setupUiComponent} parentFieldName={`${fname}.options`} model={model} />
        </Box>}
      </Box>
    </Box>
  )
}

const ModelAccessField = ({
  model,
  onChange
}) => {
  return (
    <RfRemoteSelectInput
      loaderProps={{
        'kind': 'resource',
        'options': {
          'resourceIdentifier': 'RcAuthSimple.Access.resourceAccessKinds',
          'values': {
            'resource': model
          }
        }
      }}
      label='Erişim Türü'
      onChange={onChange}
    />
  )
}

export default ManageRoleAccess
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import { Form, RfFormField, RfFormError, useReactorActionForm } from 'reactor-form/components/RfForm'


export const Settings = ({
  result
}) => {
  return (
    <>
      {result.data.modules.map((module, dx) => {
        return (
          <SettingsModuleBox module={module} key={dx} />
        )
      })}
    </>
  )
}

export const SettingsModuleBox = ({
  module
}) => {
  const { t } = RV.useTranslation()
  return (
    <Box>
      <Box sx={{
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: 'xl',
        mb: 3
      }}>
        {t(`label.${module.name}`)}
      </Box>

      <Flex sx={{
        flexWrap: 'wrap'
      }}>
        {module.items.map((item, dx) => {
          return (
            <SettingsItemBox item={item} key={dx} />
          )
        })}
      </Flex>
    </Box>
  )
}

export const SettingsItemBox = ({
  item
}) => {
  const { t } = RV.useTranslation()
  return (
    <RV.Card as={RV.Link} to={`./${item.name}`} sx={{
      flex: '1 1 0',
      maxWidth: 300
    }}>
      <RV.Icon name='home' color='brand.500'>
        <Box sx={{
          textTransform: 'capitalize',
          fontWeight: 'bold',
          color: 'brand.500'
        }}>
          {t(`label.${item.name}`)}
        </Box>
      </RV.Icon>

      <Box sx={{
        color: 'dark.300',
        my: 2,
        fontSize: 'sm'
      }}>
        {item.description}
      </Box>
    </RV.Card>
  )
}

export const SettingsComponent = ({
  item
}) => {
  const { t } = RV.useTranslation()

  RV.useBreadcrumb(`${item.name}`, t(`label.${item.name}`))

  return (
    <RV.DynamicComponent name={item.component} item={item} />
  )
}

export const SettingsCard = ({
  name,
  description,
  children,
  sx
}) => {
  return (
    <RV.Card sx={{
      p: 4,
      ...sx
    }}>
      <RV.Layout.Cols>
        <Box sx={{
          px: 5,
          // py: 3
        }}>
          <RV.Text.Header>
            {name}
          </RV.Text.Header>

          <RV.Text.Description>
            {description}
          </RV.Text.Description>
        </Box>

        {children}
      </RV.Layout.Cols>

    </RV.Card>
  )
}

export const SettingsForm = ({
  result
}) => {
  return <CustomSettingsForm
    initialValues={{
      name: result.data.name,
      data: result.data.data
    }}
    actionKey={result.data.actionKey}
    fields={result.data.fields}
  />
}

export const CustomSettingsForm = ({
  initialValues: initialValuesFromProps,
  actionKey,
  fields
}) => {
  const { t } = RV.useTranslation()
  const [initialValues, setInitialValues] = React.useState(initialValuesFromProps)
  const [formProps, isSubmitting, reset] = useReactorActionForm(
    actionKey,
    {
      initialValues,
      validationBaseFieldPath: 'data',
      onSuccess: (data) => {
        setInitialValues({
          ...initialValues,
          data
        })
        reset()
      },
      // onError
    }
  )

  return (
    <>
      <Form {...formProps}>
        {({ form }) => {
          const isDirty = form.getState().dirty
          return (
            <>
              {fields.map((field, dx) => {
                return (<FieldItem field={{...field, name: `data.${field.name}`, parentPath: 'data'}} key={dx} />)
              })}

              <RfFormError error={formProps.error} />
              <Flex flexDirection='row-reverse' sx={{
                mx: -2
              }}>
                <RV.Button mx={2} textTransform='capitalize' colorScheme={'brand'} submit isLoading={isSubmitting} disabled={!isDirty}>
                  {t('btn.save')}
                </RV.Button>
                <RV.Button mx={2} textTransform='capitalize' onClick={() => {
                  reset()
                }} colorScheme={'light'} disabled={!isDirty || isSubmitting} sx={{ color: 'dark.300' }}>
                  {t('btn.cancel')}
                </RV.Button>
              </Flex>
            </>
          )
        }}
      </Form>
    </>
  )
}

const FieldItem = ({
  field
}) => {
  return (
    <RfFormField {...field} />
  )
}
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

// Reactor
import * as RV from 'reactor-vera/templates/v1'


const MediaProfileManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.GraphTable
        modelName={'RnxDisplayMediaProfile'}
        detailLink={'mediaProfile'}
        // actionCtx={{
        //   model: {
        //     // rnx_device_app: params.rnxDeviceAppId,
        //   }
        // }}
      />
      )} />
      <RV.Route path='mediaProfile/:rnxDisplayMediaProfileId/*' element={<MediaProfileDetail />} />
    </RV.Routes>
  )
}

const MediaProfileDetail = ({

}) => {
  const { t } = useTranslation()
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rnxDisplayMediaProfile': {
      filters: {
        id: params.rnxDisplayMediaProfileId
      },
      fields: [
        'name', 'displayCount', 'data'
      ]
    },
  })

  RV.useBreadcrumb(`rnx_device_app:${result.graph?.rnxDisplayMediaProfile}`, result.graph?.rnxDisplayMediaProfile?.name)
  const rnxDisplayMediaProfile = result.graph?.rnxDisplayMediaProfile
  const meta = result.graph?.rnxDisplayMediaProfileMeta
  if (!rnxDisplayMediaProfile) return null

  return (
    <>
      <RV.EntityDetail
        icon={'playlist-video'}
        entity={rnxDisplayMediaProfile}
        schema={result.schema?.rnxDisplayMediaProfile}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <RV.Card sx={{
        my: 3
      }}>
        <RV.Card.Header>
          Video Profil
        </RV.Card.Header>

        <Box>
          {rnxDisplayMediaProfile.data.videoProfile ? (
            <Box>
              CRF: {rnxDisplayMediaProfile.data.videoProfile.crf},
              Scale: {rnxDisplayMediaProfile.data.videoProfile.scale}
            </Box>
          ) : (
            'Yok'
          )}
        </Box>

        <RV.Card.Header>
          Görsel Profil
        </RV.Card.Header>

        <Box>
          {rnxDisplayMediaProfile.data.imageProfile ? (
            <Box>
              Max Genişlik: {rnxDisplayMediaProfile.data.imageProfile.maxWidth},
              Max Yükseklik: {rnxDisplayMediaProfile.data.imageProfile.maxHeight},
              Kalite: {rnxDisplayMediaProfile.data.imageProfile.quality}
            </Box>
          ) : (
            'Yok'
          )}
        </Box>
      </RV.Card>
    </>
  )
}


export default MediaProfileManagement
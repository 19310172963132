import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'


const InputLabel = ({
  variant, ...rest
}) => {
  const styles = useStyleConfig("ReactorInputLabel", {
    variant, defaultStyleConfig: {
      baseStyle: {
        pointerEvents: 'none',
        color: 'dark.300',
        position: 'absolute',
        top: '8px',
        left: 3,
        transition: 'all linear 0.1s',
        zIndex: 1,
        cursor: 'text',
        textTransform: 'capitalize'
        // fontSize: 'xl',
        // ...sx
      },
      variants: {
        active: {
          color: 'dark.500',
          top: '-11px',
          left: 'calc(var(--chakra-space-3) - 4px)',
          fontSize: 'sm',
          background: '#FFF',
          py: '2px',
          px: '4px',
          borderRadius: 4,
          // fontWeight: 'bold',
          // color: 'dark6',
          // ...activeSx
        }
      }
    }
  })

  return (
    <Box __css={styles} {...rest}/>
  )
}

export default InputLabel
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import Recharts from 'reactor-ui/components/Recharts'
import useWidget from 'reactor-vera/apps/dashboard/hooks/useWidget'


const DeviceStats = ({
  sx
}) => {
  const { filterValues } = useWidget({
    id: 'deviceStats',
    filters: [
      {
        id: 'rnxDevice',
        kind: 'Group'
      },
    ]
  })

  const result = RV.useReactorQuery({
    'rnxDeviceMeta': {
      fields: [
        'countAll',
        'countActive',
        'countPassive',
        'countAlive',
      ],
      filters: filterValues.rnxDevice
    }
  })

  const meta = result.graph?.rnxDeviceMeta
  if (!meta) return null

  const pieData = [
    { name: 'Aktif', value: meta.countActive },
    { name: 'Pasif', value: meta.countPassive },
  ]

  return (
    <Box sx={sx}>
      <RV.Card.Header mb={2}>
        <RV.RefreshButton result={result}>
          Cihaz
        </RV.RefreshButton>
      </RV.Card.Header>
      <Flex sx={{
        // height: 300,
        mx: -7
      }}>
        <Box sx={{ flex: '1 1 0', mx: 5, my: -5 }}>
          <Flex alignItems='center' flexWrap='wrap' mx={-5} >
            <RV.StatBox maxWidth='50%' sx={{p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={0} label='Toplam' value={meta.countAll} info='Toplam ekran sayısı' />
            <RV.StatBox maxWidth='50%' sx={{p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={1} label='Canlı' value={meta.countAlive} info='Canlı ekran sayısı' />
            <RV.StatBox maxWidth='50%' sx={{p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={2} label='Aktif' value={meta.countActive} info='Aktif ekran sayısı' />
            <RV.StatBox maxWidth='50%' sx={{p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={3} label='Pasif' value={meta.countPassive} info='Pasif ekran sayısı' />
          </Flex>
        </Box>
        <RV.Card sx={{ flex: '1 1 0', mx: 5 }}>
          <Recharts.Donut
            width={300}
            height={300}
            data={pieData} />
        </RV.Card>
      </Flex>
    </Box>
  )
}

export default DeviceStats
import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'

// Reactor
import reactorActions from 'reactor/systems/reactor/actions'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import { Routes, Route, useParams } from 'reactor-vera/router'

import RfActionForm from 'reactor-form/components/RfActionForm'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const PrRegister = props => {
  return (
    <Routes>
      <Route path=':rnxMembershipProgramKey/*' element={<RegisterPage />} />
    </Routes>
  )
}

const RegisterPage = ({

}) => {
  const params = useParams()
  const result = useReactorQuery({
    membershipForm: {
      key: params.rnxMembershipProgramKey
    }
  })

  const membershipForm = result.graph?.membershipForm
  if (!membershipForm) return null
  console.log(membershipForm)

  return <RegisterPageContent membershipForm={membershipForm} rcTenantId={membershipForm.rcTenantId} />
}

export const RegisterPageWithId = ({
  id,
  invitationCode
}) => {
  const result = useReactorQuery({
    membershipForm: {
      id: id,
      invitationCode: invitationCode
    }
  })

  const membershipForm = result.graph?.membershipForm
  if (!membershipForm) return null
  console.log(membershipForm)

  return <RegisterPageContent membershipForm={membershipForm} rcTenantId={membershipForm.rcTenantId}/>
}

const RegisterPageContent = ({
  rcTenantId,
  ...props
}) => {
  const [ready, readySet] = React.useState()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(reactorActions.setup({
      config: {
        channel: 'ReactorPublic',
      },
      rcTenantId: rcTenantId
    }))
    readySet(true)
  }, [])

  if (!ready) return null
  return <FormContent {...props} />
}

const FormContent = ({
  membershipForm
}) => {
  return (
    <Box sx={{
      // width: '50%',
      mx: 'auto',
      // my: 6,
      maxWidth: '400px'
    }}>
      <Box sx={{
        color: 'rgb(220, 13, 21)',
        fontWeight: 'bold',
        fontSize: 'lg',
        textAlign: 'center',
        my: 3
      }}>
        {/* Düzce Gençlik ve Spor İl Müdürlüğü
Kapalı Yüzme Havuzu */}
      </Box>
      <RfActionForm
        actionKey={`PrBase.ApplyForMembershipForType`}
        {...membershipForm.fields}
        name=''
        icon={null}
        displayAs='FORM'
        elements={{
          successMessage: (result) => {
            console.log('res', result)
            return (
              <Box>
                Başvuru numaranız: {result.code}

                <Box>
                  Bu numarayı saklayınız.
                </Box>

                <Box>
                  İşleminizin sonucu SMS olarak bildirilecektir.
                </Box>
              </Box>
            )
          }
        }}
      // displayAs={displayAs || action.meta.ui.displayAs}
      // onClose={onClose}
      // anchorKind={anchorKind}
      // anchor={anchor}
      />
    </Box>
  )
}

export default PrRegister
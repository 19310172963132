import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// Reactor UI
import { Box, Flex } from 'reactor-ui'

import ColorInput from 'reactor-ui/components/ColorInput'

import processors from '../processors'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfColorField = ({
  name,
  type,
  multiLine,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
    format: field.format
  })

  field.processors?.forEach(processor => {
    processors[processor.name](processor, field, input, meta)
  })

  let label = t(field.label)
  if (field.labelDescription) {
    label = `${label} ${t(field.labelDescription)}`
  }

  return (
    <Box sx={{
      width: '100%'
    }}>
      <ColorInput
        labelSx={{
          textTransform: 'capitalize'
        }}
        tabIndex={field.tabIndex}
        required={field.required}
        label={label}
        wrapperSx={{
          zIndex: 1,
        }}
        sx={{
          width: '100%',
          ...field.sx
        }}
        // {...input}
        onChange={input.onChange}
        autoFocus={field.autoFocus}
        disabled={field.disabled}
      />
      <RfFieldAlert meta={meta} sx={{
        mt: -2,
        zIndex: 0,
        pt: 4,
        pb: 3,
      }}/>
      <RfFieldDescription field={field} sx={{mb: 2}}/>
    </Box>
  )
}

export default RfColorField
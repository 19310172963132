import React from 'react'

// Vendor
import { useFormState } from 'react-final-form'

// Reactor
import { parseValuesFromServer } from '../util'
export { parseValuesFromServer } from '../util'

const useParseValuesFromServer = (values, ctx, opts) => {
  const formState = useFormState({
    subscription: {
      values: true
    }
  })

  const parsed = React.useMemo(() => {
    return parseValuesFromServer(values, { form: formState.values, ...ctx }, opts)
  }, [values])

  return parsed
}

export default useParseValuesFromServer
import React from 'react'

// Reactor
import useReactorAction from 'reactor/hooks/useReactorAction'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'


const ActionButton = ({
  actionIdentifier,
  colorScheme = 'brand',
  data,
  content,
  callbacks,
  ...props
}) => {
  const [status, setStatus] = React.useState('WAITING')
  const dispatchAction = useReactorAction(actionIdentifier)
  if (status === 'SUCCESS') {
    return content?.success || null
  }

  return (
    <Box>
    <Button onClick={() => {
      dispatchAction(data, {
        start: () => setStatus('SUBMITTING'),
        error: () => setStatus('ERROR'),
        success: (data) => {
          setStatus('SUCCESS')
          callbacks?.success?.(data)
        }
      })
    }} colorScheme={colorScheme} {...props} isLoading={status === 'SUBMITTING'} />
    {status === 'ERROR' && (
      content?.error
    )}
    </Box>
  )
}

export default ActionButton
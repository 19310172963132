import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import ReactorAppSettings from 'reactor-vera/templates/v1/ReactorAppSettings'


const ReactorSettings = props => {
  return (
    <ReactorAppSettings groups={['ReactorSettings']} />
  )
}

export default ReactorSettings

import React from 'react'


// Reactor
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'

import { RoleDetail } from './AuthorizationManagement'


const AuthUserManagement = ({
  unit
}) => {
  return (
    <RV.Routes>
      <RV.Route path='/*' element={<UserManagementHome disabled={unit.options?.disabled} />} />
      <RV.Route path='user/:rcUserId/*' element={<UserManagementDetail />} />
      <RV.Route path='profile/:rcUserProfileId/*' element={<UserProfileDetail />} />
      <RV.Route path='role/:rcAuthSimpleRoleId/*' element={<RoleDetail />} />
    </RV.Routes>
  )
}

const UserManagementHome = ({
  disabled
}) => {
  const navigate = RV.useNavigate()
  const tabs = [
    {
      name: 'Kullanıcılar',
      codeName: 'users',
      element: (
        <RV.GraphTable
          modelName='RcUser'
          detailLink='user'
          query={{
            itemFields: ['name', 'email', 'rcUserProfile'],
            columns: ['name', 'email', 'rcUserProfile'],
            filters: {
              channel: 1,
              forTenant: {}
            }
          }}
        />
      )
    },

    {
      name: 'Profiller',
      codeName: 'profiles',
      element: (
        <RV.GraphTable
          modelName='RcUserProfile'
          detailLink='profile'
          query={{
            // itemFields: ['name', 'email', 'rcUserProfile'],
            // columns: ['name', 'email', 'rcUserProfile'],
            // filters: {
            //   channel: 1
            // }
          }}
        />
      )
    },

    {
      name: 'Roller',
      codeName: 'roles',
      element: (
        <RV.GraphTable
          modelName='RcAuthSimpleRole'
          detailLink='role'
        />
      )
    },

    {
      name: 'Kullanıcı Bağlantıları',
      codeName: 'userAssignments',
      element: (
        <UserAssignments />
      )
    },
  ]

  if (!disabled?.includes('rcTagAssignment')) {
    tabs.push({
      name: 'Atamalar',
      codeName: 'assignments',
      element: (
        <RV.DynamicComponent name='RcTag.RcTagAssignmentManagement' />
      )
    })
  }
  return (
    <>
      <RV.BareTabs
        onChange={() => {
          navigate('./')
        }}
        isLazy
        tabs={tabs}
      />
    </>
  )
}

const UserAssignments = ({

}) => {
  return (
    <RV.Layout.Col3>
      <>
        <UserAssignmentKinds />
      </>

      <>
        <UserAssignmentList />
      </>
    </RV.Layout.Col3>
  )
}

const UserAssignmentList = ({

}) => {
  return (
    <RV.GraphTable
      modelName='RcLink'
      headerText='Bağlantılar'
      sx={{
        mt: 3
      }}
      query={{
        filters: {
          domain: 'USER_ASSIGNMENT'
        }
      }}
      defaultModelActionContext='Model.UserAssignment'
      defaultEntityActionContext='Entity.UserAssignment'
      // isModelActionIconOnly
      // actionCtx={{
      //   model: {
      //     rcUserProfile: entity.id,
      //   }
      // }}
    />
  )
}

const UserAssignmentKinds = ({

}) => {
  return (
    <RV.GraphTable
      modelName='RcRecord'
      headerText='Bağlantı Türleri'
      sx={{
        mt: 3
      }}
      query={{
        filters: {
          kind: 'USER_ASSIGNMENT_KIND'
        }
      }}
      defaultModelActionContext='Model.UserAssignment'
      defaultEntityActionContext='Entity.UserAssignment'
      isModelActionIconOnly
      // actionCtx={{
      //   model: {
      //     rcUserProfile: entity.id,
      //   }
      // }}
    />
  )
}

const UserProfileDetail = ({

}) => {
  return (
    <RV.EntityDetailPage
      modelName='RcUserProfile'
      fields={[
        'name', 'codeName', 'isActive', 'primaryZone', 'availableZones'
      ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/' element={(
            <RV.Layout.Col3>
              <>
                <RV.Card>
                  <RV.Card.Header>
                    Arayüz
                  </RV.Card.Header>

                  <RV.RfInplaceEditForm
                    actionKey='RcApp.UpdateRcUserProfilePrimaryZone'
                    label='Ana Arayüz'
                    // formId={`RnxContact.UpdateProfileField.${field.name}`}
                    initialValues={{
                      // name: field.name,
                      id: entity.id,
                      zone: entity.primaryZone
                    }}
                    hasValue={!!entity.primaryZone}
                    fields={[
                      {
                        name: 'zone',
                        label: 'Arayüz Alanı',
                        kind: 'select',
                        options: {
                          loader: {
                            'kind': 'resource',
                            'options': {
                              'resourceIdentifier': 'RcApp.PlexUi.zones',
                            }
                          }
                        }
                      }
                    ]}
                  >
                    {entity.primaryZone}
                  </RV.RfInplaceEditForm>

                  <RV.RfInplaceEditForm
                    actionKey='RcApp.UpdateRcUserProfileAvailableZones'
                    label='Diğer Arayüz'
                    // formId={`RnxContact.UpdateProfileField.${field.name}`}
                    initialValues={{
                      // name: field.name,
                      id: entity.id,
                      zones: entity.availableZones
                    }}
                    hasValue={!!entity.availableZones}
                    fields={[
                      {
                        name: 'zones',
                        label: 'Arayüz Alanı',
                        kind: 'select',
                        options: {
                          isMultiple: true,
                          loader: {
                            'kind': 'resource',
                            'options': {
                              'resourceIdentifier': 'RcApp.PlexUi.zones',
                            }
                          }
                        }
                      }
                    ]}
                  >
                    {entity.availableZones?.join(', ')}
                  </RV.RfInplaceEditForm>
                </RV.Card>

                <RV.GraphTable
                  modelName='RcAuthSimpleProfileRole'
                  headerText='Roller'
                  actionCtx={{
                    model: {
                      rcUserProfile: entity.id,
                    }
                  }}
                  sx={{
                    mt: 3
                  }}
                  query={{
                    columns: ['rcAuthSimpleRole'],
                    filters: {
                      rc_user_profile: entity.id
                    }
                  }}
                />

              </>

              <RV.GraphTable
                modelName='RcUser'
                actionCtx={{
                  model: {
                    rcUserProfile: entity.id,
                  }
                }}
                query={{
                  filters: {
                    rc_user_profile: entity.id
                  }
                }}
              />

            </RV.Layout.Col3>
          )} />
        )
      ]}
    />
  )
}

const UserManagementDetail = ({

}) => {
  const params = RV.useParams()
  const result = useModelDetailReactorQuery({
    'rcUser': {
      filters: {
        id: params.rcUserId,
      },

    },
  })

  useBreadcrumb(`rcUserDetail:${result.graph?.rcUser.id}`, result.graph?.rcUser.name)

  const rcUser = result.graph?.rcUser
  const meta = result.graph?.rcUserMeta

  if (!rcUser) return null

  return (
    <>
      <EntityDetail
        icon={'book-open'}
        entity={rcUser}
        schema={result.schema?.rcUser}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
    </>
  )
}

export default AuthUserManagement
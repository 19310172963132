// Vendor
import { eventChannel } from 'redux-saga'
import { call, take, put, select, delay} from 'redux-saga/effects'

// Self
import streamActions from './actions'


function createSocketChannel(socket) {
  return eventChannel(emit => {
    const disconnectHandler = () => {
      emit(streamActions.rsDisconnect())
    }

    const connectHandler = () => {
      emit(streamActions.rsConnect({wsId: socket.id}))
    }

    const streamUpdateHandler = (data) => {
      emit(streamActions.rsUpdate(data))
    }

    socket.on('connect', connectHandler)
    socket.on('disconnect', disconnectHandler)
    socket.on('streamUpdate', streamUpdateHandler)

    const unsubscribe = () => {
      socket.off('connect', connectHandler)
      socket.off('disconnect', disconnectHandler)
      socket.off('streamUpdate', streamUpdateHandler)
    }

    return unsubscribe
  })
}

export function* streamManager() {
  while (true) {
    const action = yield take((action) => action.type == streamActions.ready)
    const { socket, namespaceName, reactor } = action.payload
    const socketChannel = yield call(createSocketChannel, socket)

    reactor.operators.ReactorStream.registerSocket(socket, namespaceName)
    while (true) {
      const action = yield take(socketChannel)
      switch (action.type) {
        case `${streamActions.rsConnect}`:
        case `${streamActions.rsDisconnect}`:
          yield put({
            ...action,
          })
          break
        case `${streamActions.rsUpdate}`:
          const { s: streamName, d: data } = action.payload
          const { kind, ...updateData } = data
          reactor.operators.ReactorStream.handleUpdate(namespaceName, streamName, kind, updateData)
          // yield put({
          //   ...action,
          //   type: action.payload.name,
          //   payload: action.payload.data
          // })
          yield put({
            ...action,
          })
          break
        default:
          console.warn('Unrecognized socket action:', action)
      }
    }
  }
}


export function* subscribe() {
  while (true) {
    const action = yield take((action) => action.type == streamActions.subscribe)
    const { namespaceName, streams, reactor } = action.payload
    const socket = reactor.operators.ReactorStream.getSocket(namespaceName)
    const namespaceState = yield select((state) => state.stream.namespaces[namespaceName])
    const subscribed = namespaceState?.subscribed || {}

    console.log('!!', streams, subscribed)
    let streamNames = []
    Object.entries(streams).forEach(([streamName, streamConfig]) => {
      const { params, handlers } = streamConfig
      reactor.operators.ReactorStream.addStreamHandlers(namespaceName, streamName, handlers)

      streamNames.push(streamName)
      // if (subscribed[streamName]?.count == 1) streamNames.push(streamName)
    })

    if (streamNames.length > 0) socket?.emit('rs.subscribe', streamNames)
  }
}

export function* unsubscribe() {
  while (true) {
    const action = yield take((action) => action.type == streamActions.unsubscribe)
    const { namespaceName, streams, reactor } = action.payload
    const socket = reactor.operators.ReactorStream.getSocket(namespaceName)
    const namespaceState = yield select((state) => state.stream.namespaces[namespaceName])
    const subscribed = namespaceState?.subscribed || {}

    let streamNames = []
    Object.entries(streams).forEach(([streamName, streamConfig]) => {
      const { params, handlers } = streamConfig
      if (subscribed[streamName]?.count == 0) streamNames.push(streamName)
      reactor.operators.ReactorStream.removeStreamHandlers(namespaceName, streamName, handlers)
    })

    socket.emit('rs.unsubscribe', streamNames)
  }
}

export default [streamManager, subscribe, unsubscribe]
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import Dashboard from 'reactor-vera/apps/dashboard/components/Dashboard'
import DashboardFilters from 'reactor-vera/apps/dashboard/components/DashboardFilters'
import Recharts from 'reactor-ui/components/Recharts'
import useWidget from 'reactor-vera/apps/dashboard/hooks/useWidget'


const PropertyReports = props => {
  return (
    <Dashboard defaultFilters={{
      accessDatetimeRange: {
        value: {
          range: '@today'
        },
        kind: 'DateTime'
      },
    }}>
      <DashboardFilters />
      <PaymentStats />
    </Dashboard>
  )
}

const PaymentStats = ({

}) => {
  const params = RV.useParams()
  const { filterValues } = useWidget({
    id: 'useStats',
    filters: [
      {
        id: 'accessDatetimeRange',
        kind: 'DateTime'
      },
    ]
  })

  const result = RV.useReactorQuery({
    'propertyReports': {
      fields: [
        'payments',
        // 'useCountsByProviderKind',
      ],
      id: params.rnxPropertyId,
      filters: {
        datetimeCreated: filterValues.accessDatetimeRange,
      }
    }
  })

  console.log('r', result?.graph?.propertyReports)

  return (
    <RV.QueryLoading result={result}>
      {({ propertyReports }) => {
        if (!propertyReports) return null
        return (
          <>
          <Box sx={{ flex: '1 1 0' }}>
            <Flex alignItems='center' flexWrap='wrap' mx={-5} >
              <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={0} label='Toplam' value={propertyReports.payments.count} info='Toplam ödeme sayısı' />
              <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={1} label='Toplam Tutar' value={propertyReports.payments.sum} info='Toplam ödeme tutarı' suffix='₺' />
              {/* <RV.StatBox maxWidth='50%' sx={{p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={2} label='Aktif' value={meta.countActive} info='Aktif ekran sayısı' />
            <RV.StatBox maxWidth='50%' sx={{p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={3} label='Pasif' value={meta.countPassive} info='Pasif ekran sayısı' /> */}
            </Flex>
          </Box>

          <Box my={4}/>
          <RV.Card.Header>
            Paketler
          </RV.Card.Header>
          <Box sx={{ flex: '1 1 0' }}>
            <Flex alignItems='center' flexWrap='wrap' mx={-5} >
              {Object.entries(propertyReports.payments.membershipTypes).map(([k, v]) => {
                return (
                  <>
                  <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={0} label={v.name} value={v.count} info='Toplam ödeme sayısı' />
                  <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={0} label={v.name} value={v.sum} info='Toplam ödeme tutarı'  suffix='₺'/>
                  </>
                )
              })}
            </Flex>
          </Box>

          <Box my={4}/>
          <RV.Card.Header>
            Ticari
          </RV.Card.Header>
          <Box sx={{ flex: '1 1 0' }}>
            <Flex alignItems='center' flexWrap='wrap' mx={-5} >
              {Object.entries(propertyReports.payments.commercial).map(([k, v]) => {
                return (
                  <>
                  <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={0} label={v.name} value={v.count} info='Toplam ödeme sayısı' />
                  <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={0} label={v.name} value={v.sum} info='Toplam ödeme tutarı'  suffix='₺'/>
                  </>
                )
              })}
            </Flex>
          </Box>

          <Box my={4}/>
          <RV.Card.Header>
            Tekil
          </RV.Card.Header>
          <Box sx={{ flex: '1 1 0' }}>
            <Flex alignItems='center' flexWrap='wrap' mx={-5} >
              {Object.entries(propertyReports.payments.single).map(([k, v]) => {
                return (
                  <>
                  <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={0} label={v.name} value={v.count} info='Toplam ödeme sayısı' />
                  <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={0} label={v.name} value={v.sum} info='Toplam ödeme tutarı'  suffix='₺'/>
                  </>
                )
              })}
            </Flex>
          </Box>
          </>
        )
      }}
    </RV.QueryLoading>
  )
}

export default PropertyReports
import React from 'react'

import RfField from './RfField'


const RfFormField = ({
  sx,
  fieldSx,
  parentName,
  name,
  ...props
}) => {
  const nameToUse = parentName ? `${parentName}.${name}` : name
  return <RfField field={{...props, kind: props.kind || 'text', name: nameToUse, sx: fieldSx}} sx={sx}/>
}

export default RfFormField
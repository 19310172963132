import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import { useFieldArray } from 'react-final-form-arrays'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-final-form'

// Reactor
import useDashboard from '../hooks/useDashboard'
import useLocationFilter from 'reactor-vera/hooks/useLocationFilter'
import FilterInput from 'reactor-vera/apps/data/components/Filters'
import dashboardActions from 'reactor/systems/dashboard/actions'



const DashboardFilters = props => {
  const dashboard = useDashboard()
  const filterInstance = useLocationFilter(dashboard.id)

  const filters = useSelector(state => {
    return state.reactorDashboard.dashboards[dashboard.id].filterIds.map(id => {
      const filter = state.reactorDashboard.dashboards[dashboard.id].filters[id]
      const value = state.reactorDashboard.dashboards[dashboard.id].values[id]
      return {
        filter: filter.filter,
        value
      }
    })
  })
  const dispatch = useDispatch()

  console.log('filters', filters)

  return (
    <Flex sx={{
      mx: -2
    }}>
      {filters.map(filter => {
        if (filter.filter.kind == 'Group') {
          return filter.filter.filters.map((gf, dx) => {
            return (
              <Box key={`${filter.filter.id}-${dx}`} sx={{
                mx: 2
              }}>
                <FilterInput
                  onChange={(val) => dispatch(dashboardActions.setFilterValue({ id: dashboard.id, filterId: filter.filter.id, value: {
                    ...filter.value,
                    [gf.name]: val
                  } }))}
                  focus={false}
                  direction='horizontal'
                  // label={filter.filter.label}
                  // target={filter.filter.id}
                  // kind={filter.filter.kind}
                  // filterInstance={filterInstance}
                  // value={filter.value}
                  label={gf.name} name={gf.name} target={`${filter.name}.${gf.name}`} kind={gf.inputType} filterInstance={filterInstance} componentName={gf.componentName} ctx={gf.ctx}
                />
              </Box>
            )
          })
        }
        return (
          <Box key={filter.filter.id} sx={{
            mx: 2
          }}>
            {/* <FilterInput filter={filter.filter} value={filter.value} /> */}
            <FilterInput onChange={(val) => dispatch(dashboardActions.setFilterValue({ id: dashboard.id, filterId: filter.filter.id, value: val }))} focus={false} direction='horizontal' label={filter.filter.label} target={filter.filter.id} kind={filter.filter.kind} filterInstance={filterInstance} value={filter.value} />
          </Box>
        )
      })}
    </Flex>
  )
}

export default DashboardFilters
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Alert from 'reactor-ui/components/Alert'


const RfInfo = ({
  field
}) => {
  return (
    <Alert status='info' mb={6} sx={{
      textTransform: 'initial',
    }}>
      {field.description}
    </Alert>
  )
}

export default RfInfo
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as changeCase from 'reactor-ui/util/text'

// Vendor
import { useSelector } from 'react-redux'


const GraphEntity = ({
  obj,
  id,
  modelName,
  nameKey = 'name',
  path,
  children,
  sx
}) => {
  const identifier = obj ? `${changeCase.camel(obj.__typename)}:${obj.id}` : `${changeCase.camel(modelName)}:${id}`
  const entity = useSelector(state => state.reactor.graph.types?.[identifier])
  if (!entity) return null
  let content = null
  if (children) content = (children(entity))
  else if (path) content = <SubGraphPathEntity entity={entity} path={path}/>
  else content = entity[nameKey] || null

  if (sx) {
    return <Box sx={sx}>{content}</Box>
  } else {
    return content
  }
}

const SubGraphPathEntity = ({
  entity,
  path
}) => {
  const content = useSelector(state => {
    const pathItems = path.split('.')
    let field = entity
    pathItems.forEach(pathItem => {
      field = field[pathItem]
      if (field.__typename) {
        field = state.reactor.graph.types?.[field.__typename]?.[field.id]
      }
    })
    return field
  })

  return content
}

export const GraphPathEntity = ({
  obj,
  modelName,
  id,
  path,
  sx,
  children
}) => {
  const data = useSelector(state => {
    const pathItems = path.split('.')
    let field = state.reactor.graph.types?.[modelName || obj?.__typename]?.[id || obj?.id]
    pathItems.forEach(pathItem => {
      if (!field[pathItem]) {
        console.log('Field not found', field, pathItem)
        return
      }
      field = field[pathItem]
      if (field.__typename) {
        field = state.reactor.graph.types?.[field.__typename]?.[field.id]
      }
    })
    return field
  })

  let content = null
  if (children) content = (children(data))
  else content = data

  if (sx) {
    return <Box sx={sx}>{content}</Box>
  } else {
    return content
  }
}

export default GraphEntity
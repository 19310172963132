import React from 'react'

import { ReactorDashboardContext } from '../context'

const useDashboard = props => {
  const ctx = React.useContext(ReactorDashboardContext)
  return {
    id: ctx.id
  }
}

export default useDashboard
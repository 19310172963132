import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import useReactor from 'reactor/hooks/useReactor'

import { Route, Routes, useParams } from 'reactor-vera/router'

import runicVeraActions from 'reactor-vera/systems/reactor-vera/actions'

import PlexUiDomainRouter from './PlexUiDomainRouter'

import usePlexUiTheme from 'reactor-vera/apps/ui/hooks/usePlexUiTheme'


const PlexuiZoneRouter = ({
  zone,
  baseUrl
}) => {
  const dispatch = useDispatch()
  const params = useParams()
  const plexUiTheme = usePlexUiTheme()

  React.useEffect(() => {
    dispatch(runicVeraActions.rncActivateZone({ zoneName: zone.name, zoneParams: params }))
  }, [zone])

  if (zone.handler) {
    const ZoneHandler = plexUiTheme?.components?.[zone.handler]
    if (ZoneHandler) return <ZoneHandler baseUrl={baseUrl} zone={zone} zoneParams={params}/>
  }
  const LayoutComponent = plexUiTheme?.components?.ZoneLayout

  if (!LayoutComponent) {
    // FIXME: add message
    console.warn('Theme layout component not found.', plexUiTheme)
    return null
  }

  const zoneContent = (
    <Routes>
      {Object.values(zone.domains).map((domain, dx) => {
        const path = `${domain.path}/*`
        return (
          <Route key={dx} path={path} element={<PlexUiDomainRouter zone={zone} domain={domain} />}/>
        )
      })}
    </Routes>
  )

  return <LayoutComponent baseUrl={baseUrl} zone={zone} zoneContent={zoneContent}/>
}

export default React.memo(PlexuiZoneRouter)

import actions from './actions'
import reducer from './reducer'
import sagas from './sagas'
import operators from './operators'

export default {
  name: 'stream',
  actions,
  reducer,
  sagas,
  operators
}
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

import { Image } from "@chakra-ui/react"
export default Image

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "./Modal"

export const LightboxImage = ({
  onClose: onCloseFromProps, ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <a onClick={onOpen}><Image {...props} /></a>
      <Modal autoFocus={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
        onCloseFromProps?.()
        onClose()
      }} size='full'>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader textTransform='capitalize'>{header}</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            <Image src={props.src} sx={{
              // width: '100%'
            }}/>
          </ModalBody>

          {/* <ModalFooter>
            {footerButtons}
            <Button sx={{ textTransform: 'capitalize' }} variant='ghost' onClick={() => {
              onCloseFromProps?.()
              onClose()
            }}>
              {t('op.close')}
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  )
}
import RnxMembershipManagement from './pages/RnxMembershipManagement'
import UserMembership from './pages/UserMembership'


export default {
  name: 'RnxMembership',
  components: {
    'RnxMembershipManagement': {
      component: RnxMembershipManagement
    },
    'UserMembership': {
      component: UserMembership
    }
  }
}
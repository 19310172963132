import React from 'react'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react"
export {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react"


const ReactorAlert = ({
  status = 'error',
  hasIcon = true,
  title,
  children,
  ...props
}) => {
  return (
    <Alert status={status} {...props}>
      {hasIcon && <AlertIcon />}
      {title && <AlertTitle mr={2}>{title}</AlertTitle>}
      <AlertDescription>{children}</AlertDescription>
      {/* <CloseButton position="absolute" right="8px" top="8px" /> */}
    </Alert>
  )
}

export default ReactorAlert
import React from 'react'


const getVisibility = () => {
  if (typeof document === 'undefined') return true
  return document.visibilityState
}

const useVisibility = () => {
  let [visibility, setVisibility] = React.useState(getVisibility())

  const onChange = () => setVisibility(getVisibility())

  React.useEffect(() => {
    window.addEventListener('visibilitychange', onChange)
    return () => window.removeEventListener('visibilitychange', onChange)
  }, [])

  return visibility
}

export default useVisibility
import React from 'react'

// Reactor
import useReactorReducer from 'reactor/hooks/useReactorReducer'


const useFormReducer = () => {
  const [formState, formActions] = useReactorReducer({
    phase: 'FORM',
    error: null,
    result: null,
    isSubmitting: false,
    key: 0
  }, {
    BEGIN: (draft, payload) => {
      draft.isSubmitting = true
      draft.error = null
    },

    SUCCESS: (draft, payload) => {
      draft.isSubmitting = false
      draft.error = null
      draft.result = payload
      draft.phase = 'SUCCESS'
    },

    ERROR: (draft, payload) => {
      draft.isSubmitting = false
      draft.error = payload
      draft.result = null
      draft.phase = 'ERROR'
    },

    RESET: (draft) => {
      draft.isSubmitting = false
      draft.error = null
      draft.result = null
      draft.phase = 'FORM'
      draft.key += 1
    }
  })

  return [formState, formActions]
}

export default useFormReducer
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor Ui
import { Box, Flex } from 'reactor-ui'
import Alert from 'reactor-ui/components/Alert'


const RfFieldAlert = ({
  meta, sx
}) => {
  const { t } = useTranslation()
  if ((meta.touched || meta.dirty) && (meta.error?.length > 0 || meta.submitError)) {
    let submitErrorMessage = meta.submitError
    let ctx = {}
    if (submitErrorMessage && typeof submitErrorMessage !== 'string') {
      submitErrorMessage = meta.submitError.message
      ctx = meta.submitError.ctx
    }
    return (
      <Alert sx={{
        // my: 2
        borderRadius: 4,
        ...sx
      }}>
        {meta.error?.map?.((err, dx) => (
          <Box key={dx}>
            {t(err)}
          </Box>
        ))}
        {t(submitErrorMessage, ctx)}
      </Alert>
    )
  }

  return null
}

export default RfFieldAlert
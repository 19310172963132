import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const RADIAN = Math.PI / 180
const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, name } = props
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)
  const xDiff = x > cx ? -7 : 18

  return (
    <>
      <text x={props.x} y={props.y} fill={props.fill} textAnchor={props.textAnchor} dominantBaseline={props.dominantBaseline}>
        {percent > 0 && name}
      </text>
      <text x={x + xDiff} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {percent > 0 && `${(percent * 100).toFixed(0)}%`}
      </text>
    </>

  )
}

export const Donut = ({
  data,
  width,
  height
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          isAnimationActive={false}

          // cx={120}
          // cy={200}
          innerRadius={30}
          outerRadius={80}
          fill="#8884d8"
          // paddingAngle={1}

          // cx="50%"
          // cy="50%"
          // outerRadius={80}
          // fill="#8884d8"

          // labelLine={false}
          label={renderCustomizedLabel}
          // label

          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default {
  Donut
}
import TagManagement from './pages/TagManagement'
import RcTagAssignmentManagement from './pages/RcTagAssignmentManagement'

import RcTagDynamicFilter from './components/RcTagDynamicFilter'
import TagAssignmentAccessSetup from './components/TagAssignmentAccessSetup'


export default {
  name: 'RcTag',
  components: {
    'TagManagement': {
      component: TagManagement
    },
    'RcTagDynamicFilter': {
      component: RcTagDynamicFilter
    },
    'TagAssignmentAccessSetup': {
      component: TagAssignmentAccessSetup
    },
    'RcTagAssignmentManagement': {
      component: RcTagAssignmentManagement
    },
  }
}
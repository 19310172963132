import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"

export {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"

import { useDisclosure } from "@chakra-ui/react"
import { isFunction } from 'lodash'
export { useDisclosure } from "@chakra-ui/react"

const ReactorModal = ({
  children,
  header,
  footerButtons,
  defaultIsOpen,
  onClose: onCloseFromProps,
  anchorKind = 'BUTTON',
  anchor: anchorFromProps,
  autoFocus = false,
  size = 'lg',
  sx,
  ...modalProps
}) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen })

  let anchor
  if (anchorFromProps) {
    anchor = React.cloneElement(anchorFromProps, { onClick: onOpen })
  } else {
    if (anchorKind === 'BUTTON') {
      anchor = <Button size='sm' textTransform='capitalize' colorScheme={'brand'} onClick={onOpen}>{header}</Button>
    } else if (anchorKind === 'LINK') {
      anchor = <Button variant='link' textTransform='capitalize' colorScheme={'brand'} onClick={onOpen}>{header}</Button>
    } else if (anchorKind === 'MENUITEM') {
      anchor = (
        <MenuItem onClick={onOpen}>{header}</MenuItem>
      )
    }
  }

  const content = (
    <>
      {anchor}

      <Modal autoFocus={autoFocus} closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
        onClose()
        onCloseFromProps?.()
      }} size={size} {...modalProps}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform='capitalize'>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isFunction(children) ? (
              children({ isOpen, onOpen, onClose })
            ) : (
              children
            )}
          </ModalBody>

          <ModalFooter>
            {footerButtons}
            <Button sx={{ textTransform: 'capitalize' }} variant='ghost' onClick={onClose}>
              {t('op.close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )

  if (sx) {
    return (
      <Box sx={sx}>
        {content}
      </Box>
    )
  } else return content
}

export default ReactorModal
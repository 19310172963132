import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const DeviceSettings = props => {
  const settingsResource = RV.useReactorResource('rnxDevice.settings.system_settings')
  return (
    <RV.QueryLoading result={settingsResource}>
      <RV.Card>
        <RV.Card.Header>
          Ayarlar
        </RV.Card.Header>

        {settingsResource.data?.map((settings, dx) => {
          return (
            <Flex key={dx} sx={{
              mt: 3
            }}>
              <Box sx={{
                mr: 3
              }}>
                <RV.ReactorServerAction options={settings.actionOptions} icon='pencil' identifier={settings.action} onSuccess={settingsResource.refresh}/>
              </Box>

              <Box sx={{
                flex: '1 1 0'
              }}>
                <Box fontWeight='bold' color='primary'>
                  {settings.name}
                </Box>
                <Box>
                  {settings.description}
                </Box>

                <Box sx={{
                  mt: 2
                }}>
                  {settings.value}
                </Box>
              </Box>
            </Flex>
          )
        })}
      </RV.Card>
    </RV.QueryLoading>
  )
}

export default DeviceSettings
import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import { Flex, Box } from 'reactor-ui'
import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'
import useLayoutConfig from 'reactor-vera/hooks/useLayoutConfig'

import SidebarMenu from './sidebar/SidebarMenu'
import SidebarZoneMenu from './sidebar/SidebarZoneMenu'


const AuroraThemeSidebar = ({
  baseUrl
}) => {

  const activeZone = useSelector(state => state.reactorVera.zones[state.reactorVera.activeZoneName])
  const activeDomainName = useSelector(state => state.reactorVera.activeDomainName)
  const activeDomain = activeZone?.domains.find(domain => domain.name == activeDomainName)

  if (!activeZone || !activeDomain) return null
  const rootUrl = `${baseUrl}/${activeZone.path}/${activeDomain.path}`

  return (
    <SidebarWrapper>
      <SidebarHeader label={activeZone.label || activeZone.name} rootUrl={baseUrl} />
      <SidebarMenu zone={activeZone} domain={activeDomain} baseUrl={rootUrl} rootUrl={baseUrl} />
      {/* <SidebarFooter/> */}
      {__DEV__ && <ReactorFooter />}
    </SidebarWrapper>
  )
}

import { ReactorServerAction, useResourceQuery, Layout } from 'reactor-vera/templates/v1'
import i18n from 'i18next'

const ReactorFooter = ({

}) => {
  const data = useResourceQuery('rcApp.state.status')
  const rcUser = data?.rcUser
  if (rcUser?.state != 'PRESIDENT') return null

  return (
    <Flex>
    <ReactorServerAction onSuccess={() => {
      i18n.reloadResources()
    }} icon='plus' identifier='RcApp.UpdateTranslation' />
    <ReactorServerAction onSuccess={() => {
      i18n.reloadResources()
    }} icon='reload' identifier='RcApp.RefreshTranslation' />
    </Flex>
  )
}

const SidebarHeader = ({
  label,
  rootUrl
}) => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig.sidebar.extended
  return (
    <Flex sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      pl: 4,
      pt: 2,

      width: isExtended ? '180px' : '90px',

      alignItems: 'center'
    }}>
      <Box sx={{
        fontSize: 20,
        fontWeight: 'semibold',
        borderRight: '1px solid #FFF',
        borderRightColor: 'dark.100',
        pr: 4,
        mr: 4
      }}>
        R
      </Box>

      {isExtended && <Box sx={{
        flex: '1 0 100%',
      }}>
        {label}
      </Box>}

      <SidebarZoneMenu rootUrl={rootUrl} />
    </Flex>
  )
}

const SidebarWrapper = ({
  children
}) => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const variant = layoutConfig.sidebar.extended ? 'extended' : null
  const topBar = true
  const styles = useStyleConfig('PlexUiSidebarWrapper', {
    variant, defaultStyleConfig: {
      baseStyle: {
        display: ['none', null, null, 'block'],

        position: 'fixed',
        top: 90,

        width: '90px',
        maxHeight: `calc(100vh - ${topBar ? 60 + 20 : 10 + 20}px)`,

        // width: extended ? 'sidebarExtended' : 'sidebar',
        // top: 10,
        color: 'text',
        transition: 'all 0.2s ease',

        overflow: 'auto',
        zIndex: 99,
        '@media print': {
          display: 'none'
        },
        overflowX: 'hidden'
      },
      variants: {
        extended: {
          width: '240px',
        }
      }
    }
  })

  return (
    <Box __css={styles}>
      {children}
    </Box>
  )
}


export default AuroraThemeSidebar
import React from 'react'

// Vendor
import { useField } from 'react-final-form'

// Volcano
import { Box, Flex } from 'reactor-ui'
import { Checkbox } from 'reactor-ui/components/Checkbox'
import Card from 'reactor-ui/components/Card'
import Button from 'reactor-ui/components/Button'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'

import RichTextView from 'reactor-ui/components/RichTextView'
import ReactorModal from 'reactor-ui/components/Modal'


const RfContractField = ({
  name,
  field,
}) => {
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
    // type: 'checkbox'
  })

  return (
    <Box sx={{
      my: 8
    }}>
      <Box sx={{
        fontSize: 'sm'
      }}>
        {field.options?.header}
      </Box>
      {field.options?.text && typeof field.options?.text === 'string' ? (
        <Box as='textarea' sx={{
          width: '95%',
          height: 100,
          mt: 2,
          mx: 'auto',
          // ml: 2
        }} value={field.options?.text} disabled />
      ) : (
        <Card sx={{
          // width: '95%',
          height: 100,
          mt: 2,
          mx: 'auto',
          overflow: 'scroll'
          // ml: 2
        }}>
          <RichTextView value={field.options.text} />
        </Card>
      )}
      <ReactorModal
        size='full'
        autoFocus={true}
        header='Sözleşme metini'
        anchor={<Button icon='list-letters'>Tam sayfa gör</Button>}
      >
        {({ onClose }) => (
          <RichTextView value={field.options.text} />
        )}
      </ReactorModal>
      <Checkbox {...input} onChange={(a) => {
        input.onChange(a.target.checked)
      }} tabIndex={field.tabIndex} required={!field.optional}>{field.label}</Checkbox>
      <RfFieldAlert meta={meta} />
      <RfFieldDescription field={field} position='default' />
    </Box>
  )
}

export default RfContractField
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// ReactorUI
import { Box, Flex } from 'reactor-ui'
import { Checkbox, CheckboxGroup } from 'reactor-ui/components/Checkbox'
import { HStack } from 'reactor-ui/components/Stack'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfCheckboxRadio = ({
  name,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })
  const inputValue = input.value === "" ? [] : [input.value]

  return (
    <>
      <Box mb={1} fontSize='sm'>
        {t(field.label)}
      </Box>
      <Box sx={{
        borderLeft: '1px solid #FFF',
        borderLeftColor: 'brand.100',
        pl: 2
      }}>
        <CheckboxGroup onChange={value => {
          let newVal = null
          if (value.length > 1) newVal = value[value.length - 1]
          else if (value.length === 1) newVal = value[0]
          input.onChange(newVal)
        }} colorScheme="brand" value={inputValue} defaultValue={inputValue}>
          {/* <HStack wrap='wrap' justify='space-between' shouldWrapChildren spacing={2}> */}
          {field.options.options.map((option, dx) => {
            return (
              <Box key={dx}>
                <Checkbox my={1} value={option.value}>{t(option.label)}</Checkbox>
                {option.descriptionKey && (
                  <Box sx={{
                    color: 'dark.400',
                    mt: 1,
                    fontSize: 'sm',
                    textTransform: 'initial',
                    ':first-letter': {
                      textTransform: 'uppercase'
                    },
                    ml: 6
                  }}>
                    {t(option.descriptionKey)}
                  </Box>
                )}
              </Box>
            )
          })}
          {/* </HStack> */}
        </CheckboxGroup>
      </Box>
      <RfFieldAlert meta={meta} />
      <RfFieldDescription field={field} position='default' />
    </>
  )
}

export default RfCheckboxRadio
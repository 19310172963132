import React from 'react'

// Reactor UI
import { Box, Flex } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Portal from 'reactor-ui/components/Portal'
import { Menu, MenuButton, MenuList, MenuItem } from 'reactor-ui/components/Menu'

// Reactor
import useResourceQuery from 'reactor/hooks/useResourceQuery'
import { Link, useNavigate } from 'reactor-vera/router'



const SidebarTenant = props => {
  const navigate = useNavigate()
  const { rcTenant } = useResourceQuery('rcApp.state.tenant_status', null, { mode: 'CACHE_ONLY' })
  if (!rcTenant) return null

  return (
    <Box>
      <Menu isLazy>
        <MenuButton size='sm'
        // rightIcon={<ChevronDownIcon />}
        >
          <Flex alignItems={'center'} sx={{
            fontSize: 'xs',
          }}>
            <Box color='brandLight.500'>
              {rcTenant.name}
            </Box>
            <Icon name='chevron-down' size={'12px'} color='brandLight.500' sx={{
              ml: 1
            }} />
          </Flex>
        </MenuButton>
        <Portal>
        <Box color='brand.500'>
          <MenuList>
            {/* <MenuItem onClick={() => navigate('/auth/logout')}>Çıkış Yap</MenuItem> */}
            <TenantChanger />
          </MenuList>
        </Box>
        </Portal>
      </Menu>
    </Box>
  )
}

const TenantChanger = ({

}) => {
  const result = useResourceQuery('rcApp.user.tenants')
  // if (!rcTenant) return null
  console.log('tenants', result)
  return (
    <Box m={2}>
      Firma değiştir

      {result?.map(tenant => {
        return (
          <MenuItem as={Link} key={tenant.id} to={`/${tenant.id}`}>
            {/* <Link to={`/${tenant.id}`}> */}
            {tenant.name}
            {/* </Link> */}
          </MenuItem>
        )
      })}
    </Box>
  )
}

export default SidebarTenant
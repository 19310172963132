import React from 'react'

// Reactor
import Reactor from 'reactor/reactor'
import reactorActions from 'reactor/systems/reactor/actions'


const useReactorApp = ({
  apps, systems, storeCreator, config
}) => {
  const [reactor, reactorSet] = React.useState(() => new Reactor(config))
  const storeRef = React.useRef()
  const [isReady, setIsReady] = React.useState(false)

  React.useEffect(() => {
    // reactor.registerApp(coreApp)

    apps?.forEach(app => reactor.registerApp(app))
    systems?.forEach(system => reactor.registerSystem(system))
    const unsubscribe = reactor.subscribe('update', reactorSet)
    storeRef.current = storeCreator(reactor)
    storeRef.current.dispatch(reactorActions.setup({config}))
    setIsReady(true)
    return () => unsubscribe()
  }, [])

  return ({
    isReady,
    store: storeRef.current,
    reactor
  })
}

export default useReactorApp
import React from 'react'

// Vendor
import dayjs from 'dayjs'
import { useTable } from 'react-table'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import RfActionForm from 'reactor-form/components/RfActionForm'

import * as RV from 'reactor-vera/templates/v1'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from 'reactor-ui/components/Table'
import Card from 'reactor-ui/components/Card'


const DynamicCalendar = ({
  // rnxAsScheduleTemplate,
  // rnxAsActivityTemplateList,
  // result
  activities = {}
}) => {
  const today = dayjs().startOf('today')
  const start = today.hour(6).minute(0).second(0)
  const end = today.hour(0).minute(0).second(0).add('24', 'hour')

  const interval = parseInt(0)
  const length = parseInt(60)

  const hours = React.useMemo(() => {
    const hours = []
    while (hours.length == 0 || (hours[hours.length - 1].end.add(interval + length, 'minute') <= end)) {
      let prevEnd
      if (hours.length) {
        prevEnd = hours[hours.length - 1].end
      } else {
        prevEnd = start.subtract(interval, 'minute')
      }

      const nextStart = prevEnd.add(interval, 'minute')
      const nextEnd = nextStart.add(length, 'minute')

      const next = {
        start: nextStart,
        end: nextEnd,
        id: nextStart,
        startTime: `${nextStart.hour()}:${nextStart.minute()}`,
        endTime: `${nextEnd.hour()}:${nextEnd.minute()}`,
        ...activities[nextStart.hour()]
      }

      hours.push(next)
    }
    return hours
  }, [])

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: 'Saatler',
        id: 'hours',
        // renderer: (cell) => <ActionGroupMenu name={modelName} contexts={['Entity']} ctx={{ id: cell.row.id }} />,
        // alignLast: true,
      },
      {
        Header: 'Pazartesi',
        id: 0,
        accessor: '0'
      },
      {
        Header: 'Salı',
        id: 1,
        accessor: '1',
      },
      {
        Header: 'Çarşamba',
        id: 2,
        accessor: '2',
      },
      {
        Header: 'Perşembe',
        id: 3,
        accessor: '3',
      },
      {
        Header: 'Cuma',
        id: 4,
        accessor: '4',
      },
      {
        Header: 'Cumartesi',
        id: 5,
        accessor: '5',
      },
      {
        Header: 'Pazar',
        id: 6,
        accessor: '6',
      },
    ]
  }, [])

  const tableRows = hours

  const tableInstance = useTable({
    columns: tableColumns,
    data: tableRows,
    getRowId: (row) => row.id
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <Table {...getTableProps()} colorScheme="light" size='sm'>
      <Thead>
        {headerGroups.map(headerGroup => {
          return (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, dx) => {
                return (
                  <Th {...column.getHeaderProps()} sx={{
                    textAlign: 'center'
                  }}>
                    {column.Header}
                  </Th>
                )
              })}
            </Tr>
          )
        })}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, dx) => {
          prepareRow(row)
          // console.log(row)
          return (
            <Tr {...row.getRowProps()} sx={{
              // height: '60px'
              height: '120px'
            }}>
              {row.cells.map((cell, cellDx) => {
                const { key, cellProps } = cell.getCellProps()
                if (cell.column.id === 'hours') {
                  return (
                    <Td key={key}>
                      {row.original.start.format('HH:mm')} - {row.original.end.format('HH:mm')}
                      {/* {row.original.start.format('HH:mm')} */}
                    </Td>
                  )
                }

                const dayId = cell.column.id
                const cellStart = row.original.start
                const cellEnd = row.original.end
                const cellLength = cellEnd.diff(cellStart, 'minute')
                const dayActivities = activities?.[dayId]
                let content = []
                dayActivities?.forEach(da => {
                  const { start, finish, name, id, menu, isActive } = da
                  const color = da.activity?.color || 'brand.500'
                  const border = isActive ? '5px solid #FFF' : '1px solid #FFF'
                  if (cellStart.isBefore(start) && cellEnd.isAfter(start)) {
                    const startDiff = start.diff(cellStart, 'minute')
                    const diff = (startDiff / cellLength * 100)
                    const activityLength = finish.diff(start, 'minute')
                    // let height = '65px'
                    let height = '130px'
                    if (activityLength <= cellLength) {
                      // height = '30px'
                      height = '60px'
                    }

                    content.push((
                      <Box key={id} sx={{
                        border: border,
                        borderColor: color,
                        borderBottom: 'none',
                        position: 'absolute',
                        zIndex: 9,
                        // top: '0px',
                        // top: `${-diff}px`,
                        width: '90%',
                        top: `${diff}%`,
                        height: height,
                        // pt: 2,
                      }}>
                        <RV.Layout.Row sx={{
                          p: 3
                        }}>
                          <Box sx={{ color: color }}>
                            {name}
                          </Box>
                          {menu}
                        </RV.Layout.Row>
                      </Box>
                    ))
                    // console.log('begin', row.original.startTime, row.original.endTime, activity.timeStart, activity.timeFinish)
                  } else if (cellStart.isAfter(start) && cellEnd.isBefore(finish)) {
                    content.push((
                      <Box key={id} sx={{
                        textAlign: 'center',
                        border: border,
                        borderColor: color,
                        borderBottom: 'none',
                        borderTop: 'none',

                        position: 'absolute',
                        width: '90%',
                        height: '70px',
                        top: 0,

                        // position: 'absolute',
                        // top: 0,
                        // width: '100%',
                        // height: '60px'
                      }}>
                      </Box>
                    ))
                    // console.log('in', row.original.startTime, row.original.endTime, activity.timeStart, activity.timeFinish)
                  } else if (cellStart.isBefore(finish) && cellEnd.isAfter(finish)) {
                    const endDiff = cellEnd.diff(finish, 'minute') + 1
                    const diff = (endDiff / cellLength * 100)
                    const activityLength = finish.diff(start, 'minute')
                    // let height = '65px'
                    let height = '130px'
                    if (activityLength <= cellLength) {
                      // height = '30px'
                      height = '60px'
                    }
                    content.push((
                      <Box key={id} sx={{
                        textAlign: 'center',
                        border: border,
                        borderColor: color,
                        borderTop: 'none',
                        pb: 2,

                        position: 'absolute',
                        width: '90%',
                        bottom: `${diff}%`,
                        height: height,
                      }}>
                      </Box>
                    ))
                    // console.log('end', row.original.startTime, row.original.endTime, activity.timeStart, activity.timeFinish)
                  }
                })
                // const bg = cell.value?.rnxAsActivityKind?.color

                return (
                  <Td key={key} sx={{
                    // bg: bg
                    py: 0,
                    position: 'relative',
                    width: [`${100 / tableColumns.length}%`, null],
                    minWidth: ['150px', null, null, '0px']
                  }}>

                    {content}

                  </Td>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default DynamicCalendar
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const RnxAccessProviderRecords = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<RnxAccessProviderRecordsHome />} />
    </RV.Routes>
  )
}

const RnxAccessProviderRecordsHome = ({

}) => {
  return (
    <>
      <RV.GraphTable
        modelName='RnxPacAccessRecord'
        primaryFilters={[
          {rnxPacAccessPoint: {}}
        ]}
        stats={{
          'totalPassCount': {},
          // 'totalUseCount': {},
          'passCountsByProviderKind': {},
          // 'useCountsByProviderKind': {}
        }}
        elements={{
          beforeTable: [
            (result) => {
              const { t } = RV.useTranslation()
              return (
                result.graph?.rnxPacAccessRecordList?.stats && <>
                  <RV.Card.Header>Geçiş</RV.Card.Header>
                  <Flex alignItems='center' flexWrap='wrap' mx={-3} my={2}>
                  <RV.StatBox sx={{ mx: 3, flex: '1 1 0' }} label='Toplam' value={result.graph?.rnxPacAccessRecordList?.stats.totalPassCount} />
                    {result.graph?.rnxPacAccessRecordList?.stats.passCountsByProviderKind?.map((stat, dx) => {
                      if (!stat.count) return null
                      return (
                        <RV.StatBox colorDx={dx + 1} sx={{ mx: 3, flex: '1 1 0' }} label={t(`enum.${stat.provider_kind.toLowerCase()}`)} value={stat.count} />
                      )
                    })}
                  </Flex>

                  {/* <RV.Card.Header>Kişi</RV.Card.Header>
                  <Flex alignItems='center' flexWrap='wrap' mx={-3} my={2}>
                  <RV.StatBox sx={{ mx: 3, flex: '1 1 0' }} label='Toplam' value={result.graph?.rnxPacAccessRecordList?.stats.totalUseCount} />
                    {result.graph?.rnxPacAccessRecordList?.stats.useCountsByProviderKind?.map((stat, dx) => {
                      if (!stat.count) return null
                      return (
                        <RV.StatBox colorDx={dx + 1} sx={{ mx: 3, flex: '1 1 0' }} label={t(`enum.${stat.provider_kind.toLowerCase()}`)} value={stat.count} />
                      )
                    })}
                  </Flex> */}
                </>
              )
            }
          ]
        }}
      // detailLink='broadcast'
      />
    </>
  )
}

export default RnxAccessProviderRecords
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useTable } from 'react-table'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'
import ActionGroup, { ActionGroupMenu } from 'reactor-vera/apps/data/components/ActionGroup'
import RfActionForm from 'reactor-form/components/RfActionForm'
import Enum from 'reactor-vera/apps/model/components/Enum'
import Bool from 'reactor-vera/apps/model/components/Bool'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import Image, { LightboxImage } from 'reactor-ui/components/Image'


const RnxMembershipManagement = props => {
  const { t } = useTranslation()
  return (
    <RV.Routes>
      <RV.Route path='/' element={<MembershipManagementHome />} />
      <RV.Route path='program/:rnxMembershipProgramId/*' element={<MembershipManagementDetail />} />
    </RV.Routes>
  )
}

const MembershipManagementHome = props => {
  const { t } = useTranslation()
  const params = RV.useParams()
  return (
    <>
      <RV.GraphTable
        modelName='RnxMembershipProgram'
        detailLink='program'
        query={{
          filters: {
            // parentId: null
          }
        }}
      />
    </>
  )
}

export const MembershipProgramDetail = ({

}) => {
  return (
    <RV.EntityDetailPage
      modelName='RnxMembershipProgram'
      hideDetailOnSubRoutes
      // fields={[
      //   'name', 'kind', 'status', 'capacity'
      // ]}
      // columns={[
      //   'name', 'kind', 'status', 'capacity'
      // ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/*' element={<MembershipProgramDetailHome rnxMembershipProgram={entity} />} />
        ),
        (entity, result) => (
          <RV.Route path='type/:rnxMembershipTypeId/*' element={<MembershipTypeDetail rnxMembershipProgram={entity} />} />
        ),
        (entity, result) => (
          <RV.Route path='application/:rnxMembershipRequestId/*' element={<ApplicationDetail parent={entity} />} />
        ),
        (entity, result) => (
          <RV.Route path='member/:rnxMembershipId/*' element={<MembershipDetail program={entity} />} />
        ),
      ]}
    />
  )
}

const MembershipProgramDetailHome = ({
  rnxMembershipProgram
}) => {
  return (
    <RV.BareTabs
      // isFitted
      isLazy
      tabs={[
        {
          name: 'Üyeler',
          codeName: 'members',
          element: <Members rnxMembershipProgram={rnxMembershipProgram} />
        },

        {
          name: 'Başvurular',
          codeName: 'applications',
          element: <Applications rnxMembershipProgram={rnxMembershipProgram} />
        },

        {
          name: 'Üyelik Türleri',
          codeName: 'subprograms',
          element: <MembershipTypes rnxMembershipProgram={rnxMembershipProgram} />
        },

        {
          name: 'Ayarlar',
          codeName: 'settings',
          element: <ProgramSettings rnxMembershipProgram={rnxMembershipProgram} />
        },

        {
          name: 'Bilgiler',
          codeName: 'info',
          element: <ProgramInfo rnxMembershipProgram={rnxMembershipProgram} />
        },

        {
          name: 'Davetler',
          codeName: 'invitations',
          element: <Invitations rnxMembershipProgram={rnxMembershipProgram} />
        },
      ]}
    />
  )
}

const Invitations = ({
  rnxMembershipProgram
}) => {
  return (
    <RV.GraphTable
      sx={{
        mt: 4
      }}
      query={{
        filters: {
          rnx_membership_program_id: rnxMembershipProgram.id
        }
      }}
      modelName='RnxMembershipInvitation'
      // detailLink='application'
      headerText='Davetler' />
  )
}

const ProgramInfo = ({
  rnxMembershipProgram
}) => {
  const result = useModelDetailReactorQuery({
    'rnxMembershipProgram': {
      filters: {
        id: rnxMembershipProgram.id
      },
      fields: [
        'statInfo'
      ]
    },
  })

  return (
    <RV.QueryLoading result={result}>
      <RV.Card>
        <Flex>
          <Box fontWeight='bold' mr='2'>
            Erkek
          </Box>
          <Box>
            {result?.graph?.rnxMembershipProgram.statInfo.maleCount}
          </Box>
        </Flex>

        <Flex>
          <Box fontWeight='bold' mr='2'>
            Kadın
          </Box>
          <Box>
            {result?.graph?.rnxMembershipProgram.statInfo.femaleCount}
          </Box>
        </Flex>
      </RV.Card>
    </RV.QueryLoading>
  )
}

const ApplicationDetail = ({

}) => {
  const params = RV.useParams()
  return (
    <RV.EntityDetailPage
      modelName='RnxMembershipRequest'
      fields={[
        'name', 'rnxMembershipType', 'email', 'phone', 'key', 'info', 'membershipParameters', 'status', 'submembers', 'rnxMembershipInvitation'
      ]}
      columns={[
        // 'name', 'rnxMembershipType.name', 'email', 'phone', 'key', 'info'
        'name', 'status', 'rnxMembershipType', 'email', 'phone', 'key'
      ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/' element={(
            <>
              <RV.Card sx={{
                my: 4
              }}>
                <RV.Card.Header>
                  Başvuru Bilgileri
                </RV.Card.Header>

                {entity.rnxMembershipInvitation && (
                  <RV.Alert status='info'>
                    Bu başvuru aile üyeliği alt başvurusudur.
                  </RV.Alert>
                )}

                <Box sx={{
                  my: 2
                }}>
                  <Box fontWeight='bold'>
                    Konum Doğrulama
                  </Box>

                  <Box>
                    {entity.info?.['locationCheck'] ? (
                      <Box color='success.500'>
                        Sınır içinde
                      </Box>
                    ) : (
                      <Box color='danger.500'>
                        Sınır dışında
                      </Box>
                    )}
                  </Box>
                </Box>

                {entity.membershipParameters?.fields.map((f, dx) => {
                  if (f.kind === 'PASSWORD') return null
                  const value = entity.info?.[f.code_name]
                  let valueContent

                  if (!value) {
                    valueContent = ''
                  }
                  else if (f.kind === 'FILE') {
                    valueContent = (
                      <>
                        <Box as='a' color='brand.500' href={value.url} target='_blank'>İndir</Box>
                        <LightboxImage src={value.url} sx={{
                          width: '200px'
                        }} />
                      </>
                    )
                  } else if (f.kind === 'ENTITY') {
                    valueContent = value.name
                  } else if (f.kind === 'SELECT') {
                    valueContent = <Enum value={value} />
                  } else if (f.kind === 'BOOL') {
                    valueContent = <Bool value={value} />
                  } else {
                    valueContent = value
                  }
                  return (
                    <Box key={dx} sx={{
                      my: 2
                    }}>
                      <Box fontWeight='bold'>
                        {f.name}
                      </Box>

                      <Box>
                        {valueContent}
                      </Box>
                    </Box>
                  )
                })}

                {entity.submembers && (
                  <RV.Card.Header>
                    Ek Üyeler
                  </RV.Card.Header>
                )}
                {entity.submembers?.map((m, dx) => {
                  return (
                    <Box key={dx}>
                      {m.name} - {m.govId} - {m.phoneNumber}
                    </Box>
                  )
                })}
              </RV.Card>
            </>
          )} />
        ),
      ]}
    />
  )
}

const Applications = ({
  rnxMembershipProgram
}) => {
  return (
    <>
      <RV.BareTabs
        sx={{
          mt: 4
        }}
        isFitted
        isLazy
        tabs={[
          {
            name: 'Bekleyen',
            codeName: 'waiting',
            element: (
              <RV.GraphTable
                sx={{
                  mt: 4
                }}
                query={{
                  filters: {
                    status: 'WAITING',
                    rnx_membership_program_id: rnxMembershipProgram.id
                  }
                }}
                modelName='rnxMembershipRequest'
                detailLink='application'
                headerText='Başvurular'
                showEntityActions={false}
              // actionCtx={{
              //   model: {
              //     rnx_membership_program_id: rnxMembershipProgram.id
              //   }
              // }}
              // query={{
              //   filters: {
              //     parentId: null
              //   }
              // }}
              />
            )
          },

          {
            name: 'Onaylanan',
            codeName: 'approved',
            element: (
              <RV.GraphTable
                sx={{
                  mt: 4
                }}
                query={{
                  filters: {
                    status: 'DONE',
                    rnx_membership_program_id: rnxMembershipProgram.id
                  }
                }}
                modelName='rnxMembershipRequest'
                detailLink='application'
                headerText='Başvurular'
                showEntityActions={false}
              // actionCtx={{
              //   model: {
              //     rnx_membership_program_id: rnxMembershipProgram.id
              //   }
              // }}
              // query={{
              //   filters: {
              //     parentId: null
              //   }
              // }}
              />
            )
          },

          {
            name: 'Reddedilen',
            codeName: 'rejected',
            element: (
              <RV.GraphTable
                sx={{
                  mt: 4
                }}
                query={{
                  filters: {
                    status: 'REJECTED',
                    rnx_membership_program_id: rnxMembershipProgram.id
                  }
                }}
                modelName='rnxMembershipRequest'
                detailLink='application'
                headerText='Başvurular'
                showEntityActions={false}
              // actionCtx={{
              //   model: {
              //     rnx_membership_program_id: rnxMembershipProgram.id
              //   }
              // }}
              // query={{
              //   filters: {
              //     parentId: null
              //   }
              // }}
              />
            )
          },
        ]}
      />
    </>
  )
}

const Members = ({
  rnxMembershipProgram
}) => {
  return (
    <>
      <RV.GraphTable
        sx={{
          mt: 4
        }}
        filters={[
          {
            label: 'İsim',
            kind: 'String',
            target: 'rnx_contact.name',
          },

          {
            label: 'T.C Kimlik No',
            kind: 'String',
            target: 'rnx_contact.official_key'
          }
        ]}
        modelName='RnxMembership'
        detailLink='member'
        headerText='Üyeler'
        exporters={[
          {
            identifier: 'RnxMembership.RnxMembershipProgramMemberExporter',
            params: {
              rnxMembershipProgram: rnxMembershipProgram.id
            }
          }
        ]}
        query={{
          filters: {
            rnx_membership_program_id: rnxMembershipProgram.id
          },
          itemFields: [
            'name', 'rnxMembershipProgram', 'rnxMembershipType', 'hasSubscription', 'datetimeStart', 'datetimeFinish', 'status'
          ],
          columns: [
            'name', 'rnxMembershipProgram.name', 'rnxMembershipType.name', 'hasSubscription', 'datetimeStart', 'datetimeFinish', 'status'
          ],
        }}
      />
    </>
  )
}

const MembershipDetail = ({

}) => {
  const params = RV.useParams()
  const result = useModelDetailReactorQuery({
    'rnxMembership': {
      filters: {
        id: params.rnxMembershipId,
      },
      fields: [
        'name', 'parameters', 'rnxMembershipProgram', 'rnxMembershipType',
        'datetimeStart', 'status', 'info', 'description', 'hasSubscription',
        { rnxContact: { fields: ['name', 'officialKey'] } },
      ]
      // fields: [
      //   'name', 'status', 'values', { rcForm: { fields: ['name', 'formFields'] } }
      // ]
    },
  })

  useBreadcrumb(`rnxMembership:${result.graph?.rnxMembership.id}`, result.graph?.rnxMembership.name)

  const rnxMembership = result.graph?.rnxMembership
  const meta = result.graph?.rnxMembershipMeta
  if (!rnxMembership) return null
  return (
    <>
      <EntityDetail
        icon={'book-open'}
        parentName={parent.name}
        entity={rnxMembership}
        schema={result.schema?.rnxMembership}
        fields={[
          'name', 'rnxMembershipProgram', 'rnxMembershipType', 'datetimeStart', 'status', 'rnxContact.officialKey', 'hasSubscription'
        ]}
      />

      <RV.Layout.Cols>

        <Box>

          <RV.GraphTable
            modelName='RnxPacAccessProvider'
            headerText='Kartlar'
            elements={{
              primaryActions: [
                <RfActionForm
                  name='Kart Ekle'
                  actionKey='FmBase.AddCardToMember'
                  fields={[
                    {
                      name: 'serial',
                      label: 'Seri No',
                      kind: 'text',
                      required: true
                    },
                  ]}
                  buttonText={'Kart Ekle'}
                  initialValues={{
                    id: rnxMembership.id
                  }}
                  onSuccess={() => result.refresh()}
                  sx={{
                    mx: 2
                  }}
                  key='add' />,
              ]
            }}
            sx={{
              my: 4
            }}
            // detailLink='program'
            query={{
              filters: {
                rnx_contact_id: rnxMembership.rnxContact.id
              }
            }}
          />

          <RV.GraphTable
            modelName='RnxResourceBalance'
            headerText='Krediler'
            sx={{
              my: 4
            }}
            // detailLink='program'
            query={{
              filters: {
                owner_entity_key: rnxMembership.id
              }
            }}
          />

          <RV.Card>
            <RV.Card.Header>
              BİLGİLER
            </RV.Card.Header>
            {console.log(rnxMembership.parameters)}
            {rnxMembership.parameters?.fields?.map((p, dx) => {
              if (p.kind === 'PASSWORD') return null
              const value = rnxMembership?.info?.[p?.code_name]
              let valueContent
              if (!value) {
                valueContent = ''
              }
              else if (p.kind === 'FILE') {
                valueContent = (
                  <>
                    <Box as='a' color='brand.500' href={value.url} target='_blank'>İndir</Box>
                    <LightboxImage src={value.url} sx={{
                      width: '200px'
                    }} />
                  </>
                )
              } else if (p.kind === 'ENTITY') {
                valueContent = value.name
              } else if (p.kind === 'RADIO') {
                valueContent = <Enum value={value} />
              } else if (p.kind === 'BOOL') {
                valueContent = <Bool value={value} />
              } else {
                valueContent = value
              }
              return (
                <Flex alignItems='center' my={2} key={dx}>
                  <Box mr={4}>
                    {p.name}
                  </Box>

                  <Box>
                    {valueContent}
                  </Box>
                </Flex>
              )
            })}
          </RV.Card>

        </Box>

        <Box>

          <RV.GraphTable
            modelName='BankPaymentOperation'
            sx={{
              my: 4
            }}
            // detailLink='program'
            query={{
              filters: {
                rnx_contact_id: rnxMembership.rnxContact.id
              }
            }}
          />
          <RV.GraphTable
            modelName='CmrPayment'
            sx={{
              my: 4
            }}
            // detailLink='program'
            query={{
              filters: {
                rnx_contact_id: rnxMembership.rnxContact.id
              }
            }}
          />
          <RV.GraphTable
            modelName='RnxPacAccessRecord'
            sx={{
              my: 4
            }}
            // detailLink='program'
            query={{
              filters: {
                rnx_contact_id: rnxMembership.rnxContact.id
              }
            }}
          />

          <MemberDescription rnxMembership={rnxMembership} refresh={result.refresh} />

        </Box>

      </RV.Layout.Cols>

      {/* <RV.Card sx={{
        my: 4
      }}>
        <Box sx={{
          fontWeight: 'bold'
        }}>
          Form Bilgileri
        </Box>
        {rcFormData.rcForm.formFields.map((f, dx) => {
          if (f.kind === 'password') return null
          const value = rcFormData.values[f.name]
          let valueContent

          if (f.kind === 'file') {
            valueContent = <Image src={value.url} sx={{
              width: '200px'
            }} />
          } else if (f.kind === 'entity') {
            valueContent = value.name
          } else {
            valueContent = value
          }
          return (
            <Box key={dx} sx={{
              my: 2
            }}>
              <Box fontWeight='bold'>
                {f.label}
              </Box>

              <Box>
                {valueContent}
              </Box>
            </Box>
          )
        })}
      </RV.Card> */}
    </>
  )
}

const MemberDescription = ({
  rnxMembership,
  refresh
}) => {
  return (
    <RV.Card>
      <RfActionForm
        name='Açıklama Düzenle'
        actionKey='RnxMembership.RnxMembershipUpdateDescription'
        displayAs='FORM'
        showSuccess={false}
        fields={[
          {
            name: 'description',
            label: 'Açıklama',
            kind: 'textArea',
            required: true
          },
        ]}
        buttonText={'Açıklama Düzenle'}
        initialValues={{
          id: rnxMembership.id,
          description: rnxMembership.description
        }}
        onSuccess={refresh}
        sx={{
          mx: 2
        }}
      />
    </RV.Card>
  )
}

const MembershipTypeDetail = ({
  rnxMembershipProgram
}) => {
  return (
    <RV.EntityDetailPage
      modelName='RnxMembershipType'
      hideDetailOnSubRoutes
      fields={[
        'name', 'model', 'status', 'hasSubscriptionPackage', 'hasCreditPackage'
      ]}
      columns={[
        'name', 'model', 'status'
      ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/' element={(
            <RV.BareTabs
              // isFitted
              memoKey={entity}
              isLazy
              tabs={[
                {
                  name: 'Abonelik Paketleri',
                  codeName: 'subscription_packages',
                  element: <MembershipTypeResources rnxMembershipProgram={rnxMembershipProgram} rnxMembershipType={entity} kind='RNX_MEMBERSHIP_ACTIVITY_PACKAGE' />
                },

                {
                  name: 'Kredi Paketleri',
                  codeName: 'credit_packages',
                  element: <MembershipTypeResources rnxMembershipProgram={rnxMembershipProgram} rnxMembershipType={entity} kind='RNX_MEMBERSHIP_ACTIVITY_CREDIT_PACKAGE' />,
                  isPassive: entity.model.value !== 'SINGLE'
                },

                {
                  name: 'Ayarlar',
                  codeName: 'settings',
                  element: <MembershipTypeSettings rnxMembershipProgram={rnxMembershipProgram} rnxMembershipType={entity} />
                },
              ]}
            />
          )} />
        ),
      ]}
    />
  )
}

const MembershipTypeResources = ({
  rnxMembershipProgram,
  rnxMembershipType,
  kind
}) => {
  let defaultModelActionContext
  if (kind === 'RNX_MEMBERSHIP_ACTIVITY_CREDIT_PACKAGE') {
    defaultModelActionContext = 'Model.MembershipActivityCreditPackage'
  } else if (kind === 'RNX_MEMBERSHIP_ACTIVITY_PACKAGE') {
    defaultModelActionContext = 'Model.MembershipActivityPackage'
  }
  return (
    <>
      <RV.GraphTable
        modelName='RnxResourceThing'
        // detailLink='resource'
        headerText='Paketler'
        defaultModelActionContext={defaultModelActionContext}
        actionCtx={{
          model: {
            rnx_membership_type_id: rnxMembershipType.id
          }
        }}
        query={{
          filters: {
            owner_entity_key: rnxMembershipType.id,
            kind: kind
          }
        }}
      />
    </>
  )
}

const MembershipTypePackages = ({
  rnxMembershipProgram,
  rnxMembershipType
}) => {
  return (
    <>
      <RV.GraphTable
        modelName='RnxMembershipTypeActivityGroupPackage'
        // detailLink='resource'
        headerText='Paketler'
        actionCtx={{
          model: {
            rnx_membership_type_id: rnxMembershipType.id
          }
        }}
        query={{
          filters: {
            rnx_membership_type_id: rnxMembershipType.id
          }
        }}
      />
    </>
  )
}

const MembershipTypes = ({
  rnxMembershipProgram
}) => {
  return (
    <RV.GraphTable
      modelName='RnxMembershipType'
      headerText='Üyelik Türleri'
      detailLink='type'
      showEntityActions={false}
      sx={{
        mt: 3
      }}
      // detailLink='program'
      actionCtx={{
        model: {
          rnx_membership_program_id: rnxMembershipProgram.id
        }
      }}
      query={{
        filters: {
          rnx_membership_program_id: rnxMembershipProgram.id
        }
      }}
    />
  )
}

const MembershipTypeSettings = ({
  rnxMembershipType
}) => {
  const formUrl = `/forms/${rnxMembershipType.rcEntityId}`
  return (
    <RV.Layout.Cols>
      {/* <RV.Card>
        Form Url: <a href={formUrl} target='_blank'>{formUrl}</a>
      </RV.Card> */}
      <>
        <MembershipTypeProperties rnxMembershipType={rnxMembershipType} />
        <MembershipTypeParameters rnxMembershipType={rnxMembershipType} />
      </>
      <MembershipTypeContracts rnxMembershipType={rnxMembershipType} />
    </RV.Layout.Cols>
  )
}

const MembershipTypeProperties = ({
  rnxMembershipType
}) => {
  console.log(rnxMembershipType)
  return (
    <RV.Card mt={4}>
      <RV.Layout.Row>
        <RV.Card.Header>
          Özellikler
        </RV.Card.Header>
        <Box>
          <ActionGroup name={'RnxMembershipType'} contexts={['Entity.Properties']} kinds={['UPDATE']} ctx={{
            id: rnxMembershipType.id,
          }} />
        </Box>
      </RV.Layout.Row>

      <Box my={2}>
        <RV.Icon name={rnxMembershipType.hasSubscriptionPackage ? 'check' : 'circle-remove'}>
          Üyelik Paketleri
        </RV.Icon>
      </Box>

      <Box my={2}>
        <RV.Icon name={rnxMembershipType.hasCreditPackage ? 'check' : 'circle-remove'}>
          Kredi Paketleri
        </RV.Icon>
      </Box>
    </RV.Card>
  )
}

const MembershipTypeContracts = ({
  rnxMembershipType
}) => {
  const result = useReactorQuery({
    rnxMembershipType: {
      fields: [
        'contracts'
      ],
      filters: {
        id: rnxMembershipType.id
      }
    }
  })

  const rnxMembershipTypeWithContracts = result.graph?.rnxMembershipType
  if (!rnxMembershipTypeWithContracts) return null

  return (
    <RV.Card mt={4}>
      <RV.Layout.Row>
        <RV.Card.Header>
          Sözleşmeler
        </RV.Card.Header>
        <Box>
          <ActionGroup name={'RnxMembershipType'} contexts={['Entity.Contract']} kinds={['CREATE']} ctx={{
            id: rnxMembershipType.id,
          }} />
        </Box>
      </RV.Layout.Row>

      <Box>
        {rnxMembershipTypeWithContracts.contracts ? (
          Object.entries(rnxMembershipTypeWithContracts.contracts).map(([codeName, contract], dx) => {
            return (
              <RV.Layout.Row key={dx} sx={{ my: 2 }}>
                <RV.Icon name='file'>
                  {contract.name}
                </RV.Icon>
                <ActionGroupMenu name={'RnxMembershipType'} contexts={['Entity.Contract']} kinds={['UPDATE', 'DELETE']} ctx={{
                  id: rnxMembershipType.id,
                  codeName: codeName
                }} />
              </RV.Layout.Row>
            )
          })
        ) : (
          <Box>Sözleşme yok.</Box>
        )}
      </Box>
    </RV.Card>
  )
}

const MembershipTypeParameters = ({
  rnxMembershipType
}) => {
  const result = useReactorQuery({
    rnxMembershipType: {
      fields: [
        'nonCustomMembershipParameters', 'customMembershipParameters', 'renewalMembershipParameters'
      ],
      filters: {
        id: rnxMembershipType.id
      }
    }
  })

  const rnxMembershipTypeWithParameters = result.graph?.rnxMembershipType
  if (!rnxMembershipTypeWithParameters) return null
  return (
    <RV.Card sx={{
      my: 4
    }}>
      <RV.Layout.Row >
        <RV.Card.Header>
          Parametreler
        </RV.Card.Header>
        <ActionGroup name={'RnxMembershipType'} contexts={['Entity.Parameter']} kinds={['UPDATE']} ctx={{
          id: rnxMembershipType.id,
          kind: 'parameter'
        }} />

        <ActionGroup name={'RnxMembershipType'} contexts={['Entity.RenewalParameter']} kinds={['UPDATE']} ctx={{
          id: rnxMembershipType.id,
          kind: 'parameter'
        }} />
      </RV.Layout.Row>
      <Box sx={{
        mx: -4,
        mt: 4
      }}>
        <Box sx={{
          flex: '1 1 0',
          mx: 4
        }}>
          <Box sx={{
            fontWeight: 'bold',
            // fontSize: 'lg',
            mb: 2
          }}>
            Üyelik Tipi Parametreleri
          </Box>

          <Box>
            {rnxMembershipTypeWithParameters.customMembershipParameters?.fields?.map((param, dx) => {
              return (
                <Box key={dx}>
                  {param.name}
                </Box>
              )
            })}
          </Box>
        </Box>

        <Box sx={{
          flex: '1 1 0',
          mx: 4
        }}>
          <Box sx={{
            fontWeight: 'bold',
            // fontSize: 'lg',
            mb: 2
          }}>
            Yenileme Parametreleri
          </Box>

          <Box>
            {rnxMembershipTypeWithParameters.renewalMembershipParameters?.fields?.map((param, dx) => {
              return (
                <Box key={dx}>
                  {param.name}
                </Box>
              )
            })}
          </Box>
        </Box>

        <Box sx={{
          flex: '1 1 0',
          mx: 4
        }}>
          <Box sx={{
            fontWeight: 'bold',
            // fontSize: 'lg',
            mb: 2
          }}>
            Varsayılan Parametreler
          </Box>

          <Box>
            {rnxMembershipTypeWithParameters.nonCustomMembershipParameters?.fields?.map((param, dx) => {
              return (
                <Box key={dx}>
                  {param.name}
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </RV.Card>
  )
}

const ProgramSettings = ({
  rnxMembershipProgram
}) => {
  const formUrl = `/forms/${rnxMembershipProgram.rcEntityId}`
  return (
    <RV.Layout.Cols>
      {/* <RV.Card>
        Form Url: <a href={formUrl} target='_blank'>{formUrl}</a>
      </RV.Card> */}
      <ProgramMembershipParameters rnxMembershipProgram={rnxMembershipProgram} />
      <ProgramContracts rnxMembershipProgram={rnxMembershipProgram} />
    </RV.Layout.Cols>
  )
}

const ProgramContracts = ({
  rnxMembershipProgram
}) => {
  const result = useReactorQuery({
    rnxMembershipProgram: {
      fields: [
        'contracts'
      ],
      filters: {
        id: rnxMembershipProgram.id
      }
    }
  })

  const rnxMembershipProgramWithContracts = result.graph?.rnxMembershipProgram
  if (!rnxMembershipProgramWithContracts) return null
  return (
    <RV.Card mt={4}>
      <RV.Layout.Row>
        <RV.Card.Header>
          Sözleşmeler
        </RV.Card.Header>
        <Box>
          <ActionGroup name={'RnxMembershipProgram'} contexts={['Entity.Contract']} kinds={['CREATE']} ctx={{
            id: rnxMembershipProgram.id,
          }} />
        </Box>
      </RV.Layout.Row>
      {rnxMembershipProgramWithContracts.contracts ? (
        Object.entries(rnxMembershipProgramWithContracts.contracts).map(([codeName, contract], dx) => {
          return (
            <RV.Layout.Row key={dx} sx={{ my: 2 }}>
              <RV.Icon name='file'>
                {contract.name}
              </RV.Icon>
              <ActionGroupMenu name={'RnxMembershipProgram'} contexts={['Entity.Contract']} kinds={['UPDATE', 'DELETE']} ctx={{
                id: rnxMembershipProgram.id,
                codeName: codeName
              }} />
            </RV.Layout.Row>
          )
        })
      ) : (
        <Box>Sözleşme yok.</Box>
      )}
    </RV.Card>
  )
}

const ProgramMembershipParameters = ({
  rnxMembershipProgram
}) => {
  const result = useReactorQuery({
    rnxMembershipProgram: {
      fields: [
        'name', 'defaultMembershipParameters', 'customMembershipParameters'
      ],
      filters: {
        id: rnxMembershipProgram.id
      }
    }
  })

  const rnxMembershipProgramWithMembershipParameters = result.graph?.rnxMembershipProgram
  if (!rnxMembershipProgramWithMembershipParameters) return null

  return (
    <RV.Card sx={{
      my: 4
    }}>
      <RV.Layout.Row >
        <RV.Card.Header>
          Parametreler
        </RV.Card.Header>
        <ActionGroup name={'RnxMembershipProgram'} contexts={['Entity.Parameter']} kinds={['UPDATE']} ctx={{
          id: rnxMembershipProgram.id,
          kind: 'parameter'
        }} />
      </RV.Layout.Row>
      <Box sx={{
        mx: -4,
        mt: 4
      }}>
        <Box sx={{
          flex: '1 1 0',
          mx: 4
        }}>
          <Box sx={{
            fontWeight: 'bold',
            // fontSize: 'lg',
            mb: 2
          }}>
            Program Parametreleri
          </Box>

          <Box>
            {rnxMembershipProgramWithMembershipParameters.customMembershipParameters?.fields?.map((param, dx) => {
              return (
                <Box key={dx}>
                  {param.name}
                </Box>
              )
            })}
          </Box>
        </Box>

        <Box sx={{
          flex: '1 1 0',
          mx: 4
        }}>
          <Box sx={{
            fontWeight: 'bold',
            // fontSize: 'lg',
            mb: 2
          }}>
            Varsayılan Parametreler
          </Box>

          <Box>
            {rnxMembershipProgramWithMembershipParameters.defaultMembershipParameters?.fields?.map((param, dx) => {
              return (
                <Box key={dx}>
                  {param.name}
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </RV.Card>
  )
}

export default RnxMembershipManagement
class ReactorStream {
  namespaces = {}

  getSocket(namespaceName) {
    return this.namespaces[namespaceName]?.socket
  }

  _getOrCreateNamespace(namespaceName) {
    if (!this.namespaces[namespaceName]) {
      this.namespaces[namespaceName] = {
        socket: null,
        streams: []
      }
    }

    return this.namespaces[namespaceName]
  }

  registerSocket(socket, namespaceName) {
    const ns = this._getOrCreateNamespace(namespaceName)

    ns.socket = socket
  }

  addStreamHandlers(namespaceName, streamName, handlers) {
    const ns = this._getOrCreateNamespace(namespaceName)
    if (!ns.streams[streamName]) ns.streams[streamName] = {
      handlers: {}
    }

    Object.entries(handlers).forEach(([handlerName, handler]) => {
      if (!ns.streams[streamName].handlers[handlerName]) {
        ns.streams[streamName].handlers[handlerName] = []
      }

      ns.streams[streamName].handlers[handlerName].push(handler)
    })
  }

  removeStreamHandlers(namespaceName, streamName, handlers) {
    const ns = this._getOrCreateNamespace(namespaceName)
    if (!ns.streams[streamName]) return

    Object.entries(handlers).forEach(([handlerName, handler]) => {
      ns.streams[streamName].handlers[handlerName] = ns.streams[streamName].handlers[handlerName].filter(x => x !== handler)
    })
  }

  handleUpdate(namespaceName, streamName, kind, data) {
    const ns = this.namespaces[namespaceName]
    if (!ns) return
    const handlers = ns.streams[streamName].handlers[kind]
    if (!handlers) return
    handlers.forEach(handler => handler(data))
  }
}

export default {
  ReactorStream
}
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'


import stringfy from 'json-stable-stringify'

// https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
function toBinary(string) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
}


const Pagination = ({
  graph,
  filterInstance,
  perPage = 50
}) => {
  const { t } = useTranslation()
  if (!graph) return null

  const updatePagination = (vals) => {
    const valStr = stringfy(vals)
    const newCursor = toBinary(valStr)
    filterInstance.updateLocationFilters({ '_pgn': newCursor })
  }

  const nextCursor = graph.nextCursor
  const prevCursor = graph.prevCursor
  const p = graph.page || 1
  // console.log(nextCursor, prevCursor)
  // React.useEffect(() => {
  //   if (!prevCursor && filterInstance.locationFilters['pc']) filterInstance.updateLocationFilters({pc: undefined}, {mode: 'REPLACE', keepDx: true, set: false})
  // }, [nextCursor, prevCursor])

  if (graph.count === 0) return null

  return (
    <Flex sx={{
      alignItems: 'center'
    }}>
      {prevCursor && (
        <Button iconName='rewind' onClick={() => updatePagination({ pc: undefined, nc: undefined, p: 0 }, { keepDx: true })} />
      )}
      {prevCursor && (
        <Button iconName='chevron-left' onClick={() => updatePagination({ pc: graph.prevCursor, nc: undefined, p: p - 1 }, { keepDx: true })} />
      )}
      <Flex sx={{
        alignItems: 'center',
        fontSize: 'sm',
        color: 'dark.300',
        mx: 4
      }}>
        <Box sx={{
          mr: 4
        }}>
          {graph.count} {t('label.record')}
        </Box>
        <Box sx={{
          // mr: 4
        }}>
          {(prevCursor || nextCursor) ? `${p} / ${Math.ceil(graph.count / perPage)}` : null}
        </Box>
      </Flex>
      {nextCursor && (
        <Button iconName='chevron-right' onClick={() => updatePagination({ pc: undefined, nc: graph.nextCursor, p: p + 1 }, { keepDx: true })} />
      )}
    </Flex>
  )
}

export default Pagination

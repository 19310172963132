import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'


const Bool = ({value}) => {
  return (
    <Icon name={value ? 'check' : 'circle-remove'} color={value ? 'brand.500' : 'danger.500'} size={6}/>
  )
}

export default Bool
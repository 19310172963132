import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// ReactorUI
import { Checkbox } from 'reactor-ui/components/Checkbox'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfCheckboxField = ({
  name,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
    type: 'checkbox'
  })

  return (
    <>
    <Checkbox {...input} isChecked={input.checked} tabIndex={field.tabIndex} sx={{
      textTransform: 'lowercase',
      '& :first-letter': {
        textTransform: 'capitalize'
      }
    }} required={!field?.optional}>{t(field.label)}</Checkbox>
    <RfFieldAlert meta={meta}/>
    <RfFieldDescription field={field} position='default' sx={{
      ml: 6
    }}/>
    </>
  )
}

export default RfCheckboxField
import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import createKey from 'reactor/util/key'
import reactorActions from 'reactor/systems/reactor/actions'
import useVisibility from './useVisibility'

const MIN_REFETCH_INTERVAL = 2 * 1000

const useReactorResource = (query, variables, options = {}) => {
  const {mode} = options
  const dispatch = useDispatch()
  const queryKey = React.useMemo(() => createKey({s: query, v: variables}), [query, variables])

  const queryStatus = useSelector(state => state.reactor.queries[queryKey])
  const result = useSelector(state => state.reactor.resource[query])
  const visibility = useVisibility()

  const isFetchingRef = React.useRef()
  if (isFetchingRef.current === undefined) isFetchingRef.current = false

  const fetch = React.useCallback(() => {
    if (mode == 'CACHE_ONLY') return
    if (isFetchingRef.current) return
    if (visibility !== 'visible') return
    isFetchingRef.current = true
    dispatch(reactorActions.resourceQuery({
      query,
      variables,
      queryKey,
      callbacks: {
        success: () => {
          isFetchingRef.current = false
        },
        error: () => {
          isFetchingRef.current = false
        }
      }
    }))
  })

  React.useEffect(() => {
    if (isFetchingRef.current) return
    if (queryStatus?.status === 'FETCHING') return
    if (queryStatus?.status === 'ERROR') return
    let fetchRequired = true

    // if (queryStatus?.status !== 'ERROR') {
    //   const lastFetch = queryStatus?.lastFetch
    //   if (lastFetch && new Date() - lastFetch < MIN_REFETCH_INTERVAL) fetchRequired = false
    // }
    const lastFetch = queryStatus?.lastFetch
    if (lastFetch && new Date() - lastFetch < MIN_REFETCH_INTERVAL) fetchRequired = false

    if (fetchRequired) fetch()
  }, [queryStatus, queryKey])

  // React.useEffect(() => {
  //   if (!interval) return
  //   const _interval = setInterval(refresh, interval)
  //   return () => clearInterval(_interval)
  // }, [queryKey, interval])

  // fetch()

  return {
    data: result,
    isFetching: queryStatus?.isFetching,
    refresh: fetch,
    status: queryStatus,
  }
}

export default useReactorResource
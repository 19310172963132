// Runic
import { createReducer, setDraft } from 'reactor/core/redux'

import uiSelectionActions from './actions'


const defaultState = {
  selectionGroups: {
  }
}

export default createReducer(defaultState, {
  [uiSelectionActions.enableSelection]: (draft, {groupId}) => {
    if (!draft.selectionGroups[groupId]) draft.selectionGroups[groupId] = {
      __count: 0
    }
  },

  [uiSelectionActions.selectItems]: (draft, {groupId, ids}) => {
    if (!draft.selectionGroups[groupId]) draft.selectionGroups[groupId] = {
      __count: 0
    }
    ids.forEach(id => {
      if (!draft.selectionGroups[groupId][id]) draft.selectionGroups[groupId].__count += 1
      draft.selectionGroups[groupId][id] = true
    })
  },

  [uiSelectionActions.unselectItems]: (draft, {groupId, ids}) => {
    if (!draft.selectionGroups[groupId]) return
    ids.forEach(id => {
      if (draft.selectionGroups[groupId][id]) draft.selectionGroups[groupId].__count -= 1
      draft.selectionGroups[groupId][id] = false
    })
  },

  [uiSelectionActions.clearSelectionGroup]: (draft, {groupId}) => {
    if (!draft.selectionGroups[groupId]) return
    delete draft.selectionGroups[groupId]
  },
})

import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const PropertyCommerceFacility = props => {
  return (
    <RV.EntityDetailPage
      modelName='RnxProperty'
      paramKey='commerceRnxPropertyId'
      hideDetailOnSubRoutes
      fields={[
        'name', 'kind', 'status', 'contracts'
      ]}
      columns={[
        'name', 'kind', 'status'
      ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/*' element={<PropertyCommerceFacilityHome refresh={result.refresh} rnxProperty={entity} />} />
        ),
        // (entity, result) => (
        //   <RV.Route path='type/:rnxMembershipTypeId/*' element={<MembershipTypeDetail rnxMembershipProgram={entity} />} />
        // ),
        // (entity, result) => (
        //   <RV.Route path='application/:rnxMembershipRequestId/*' element={<ApplicationDetail parent={entity} />} />
        // ),
        // (entity, result) => (
        //   <RV.Route path='member/:rnxMembershipId/*' element={<MembershipDetail program={entity} />} />
        // ),
      ]}
    />
  )
}

const PropertyCommerceFacilityHome = ({
  rnxProperty,
  refresh
}) => {
  return (
    <RV.BareTabs
      isLazy
      memoKey={rnxProperty}
      tabs={[
        {
          name: 'Satışlar',
          codeName: 'sales',
          element: <CommerceFacilitySales rnxProperty={rnxProperty} />
        },

        {
          name: 'Ürünler',
          codeName: 'products',
          element: <CommerceFacilityProducts rnxProperty={rnxProperty} />
        },

        {
          name: 'Ayarlar',
          codeName: 'settings',
          element: <CommerceFacilitySettings key={rnxProperty} rnxProperty={rnxProperty} refresh={refresh} />
        },
      ]}
    />
  )
}

const CommerceFacilitySales = ({
  rnxProperty
}) => {
  return (
    <RV.GraphTable
      sx={{
        mt: 4
      }}
      query={{
        filters: {
          owner_entity_key: rnxProperty.id,
          linked_entity_key: null,
          for_model: 'RnxProperty',
        },
        orderBy: 'id|desc'
      }}
      modelName='RnxResourceTicket'
      // detailLink='application'
      headerText='Satışlar'
      defaultEntityActionContext='Entity.Usage'
      showModelActions={false}
      // actionCtx={{
      //   model: {
      //     rnxPropertyId: rnxProperty.id
      //   }
      // }}
    />
  )
}

const CommerceFacilityProducts = ({
  rnxProperty
}) => {
  return (
    <RV.GraphTable
      sx={{
        mt: 4
      }}
      query={{
        filters: {
          owner_entity_key: rnxProperty.id,
          kind: 'RNX_COMMERCE_PROPERTY_PRODUCT'
        }
      }}
      modelName='RnxResourceThing'
      // detailLink='application'
      headerText='Ürünler'
      defaultModelActionContext='Model.PrProduct'
      // showEntityActions={false}
      actionCtx={{
        model: {
          rnxPropertyId: rnxProperty.id
        }
      }}
    />
  )
}

const CommerceFacilitySettings = ({
  rnxProperty,
  refresh
}) => {
  return (
    <RV.Card mt={4}>
      <RV.Layout.Row>
        <RV.Card.Header>
          Sözleşmeler
        </RV.Card.Header>
        <Box>
          <RV.ActionGroup callbacks={{
            success: refresh
          }} name={'RnxProperty'} contexts={['Entity.Contract']} kinds={['CREATE']} ctx={{
            id: rnxProperty.id,
          }} />
        </Box>
      </RV.Layout.Row>

      <Box>
        {rnxProperty.contracts ? (
          Object.entries(rnxProperty.contracts).map(([codeName, contract], dx) => {
            return (
              <RV.Layout.Row key={dx} sx={{ my: 2 }}>
                <RV.Icon name='file'>
                  {contract.name}
                </RV.Icon>
                <RV.ActionGroupMenu name={'RnxProperty'} contexts={['Entity.Contract']} kinds={['UPDATE', 'DELETE']} ctx={{
                  id: rnxProperty.id,
                  codeName: codeName
                }} />
              </RV.Layout.Row>
            )
          })
        ) : (
          <Box>Sözleşme yok.</Box>
        )}
      </Box>
    </RV.Card>
  )
}

export default PropertyCommerceFacility
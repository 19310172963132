import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

import { Menu, MenuButton, MenuList, MenuItem } from 'reactor-ui/components/Menu'
import Button from 'reactor-ui/components/Button'
import Icon from 'reactor-ui/components/Icon'
import Portal from 'reactor-ui/components/Portal'

import useLayoutConfig from 'reactor-vera/hooks/useLayoutConfig'
import { Link, useLocation, useResolvedPath } from 'reactor-vera/router'

const SidebarZoneMenu = ({
  rootUrl
}) => {
  const zones = useSelector(state => state.reactorVera.zones)
  const activeZone = useSelector(state => state.reactorVera.zones[state.reactorVera.activeZoneName])

  const hasMultipleDomains = activeZone.domains.length > 1
  const hasMultipleZones = Object.keys(zones).length > 1

  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig?.sidebar?.extended

  if (!hasMultipleDomains && !hasMultipleZones) return null

  return (
    <Box>
      <Menu isLazy>
        <MenuButton size='sm'
        // rightIcon={<ChevronDownIcon />}
        >
          <Flex alignItems={'center'} sx={{
            fontSize: 'xs',
          }}>
            <Icon name='chevron-down' size={'12px'} color='brandLight.500' sx={{
              ml: isExtended ? 1 : 0
            }} />
          </Flex>
        </MenuButton>
        {/* <Box color='brandLight.500'> */}
        <Portal>
          <MenuList>
            {/* <MenuItem onClick={() => navigate('/auth/logout')}>Çıkış Yap</MenuItem> */}
            <Box mx={2}>
              <Box sx={{
                fontWeight: 'bold',
              }}>
                Alanlar
              </Box>
              {activeZone.domains.map((domain, dx) => {
                return (
                  <MenuItem as={Link} key={dx} to={`${rootUrl}/${activeZone.path}/${domain.indexPath}`} sx={{
                    display: 'block'
                  }}>
                    {domain.name}
                  </MenuItem>
                )
              })}
            </Box>

            <Box mx={2} mt={2}>
              <Box sx={{
                fontWeight: 'bold',
              }}>
                Uygulamalar
              </Box>
              {Object.values(zones).map((zone, dx) => {
                return (
                  <MenuItem as={Link} key={dx} to={`${rootUrl}/${zone.indexPath}`} sx={{
                    display: 'block'
                  }}>
                    {zone.name}
                  </MenuItem>
                )
              })}
            </Box>
          </MenuList>
        </Portal>
        {/* </Box> */}
      </Menu>
    </Box>
  )
}

export default SidebarZoneMenu
import React from 'react'

// Vendor
import { Flex, Box } from "@chakra-ui/react"
import Icon from './Icon'


const Card = React.forwardRef(({ sx, children, ...rest }, ref) => {
  return (
    <Box ref={ref} sx={{
      px: 4,
      py: 3,
      borderWidth: '1px',
      borderRadius: 4,
      overflow: 'hidden',
      bg: '#FFF',
      ...sx
    }} {...rest}>
      {children}
    </Box>
  )
})

const CardHeader = ({
  children,
  sx,
  uppercase = true,
  icon,
  ...rest
}) => {
  const content = (
    <Box sx={{
      fontWeight: 'bold',
      textTransform: uppercase && 'uppercase',
      fontSize: 'sm',
      color: 'brand.900',
      ...sx
    }} {...rest}>
      {children}
    </Box>
  )

  if (icon) {
    return (
      <Icon color='brand.900' name={icon} sx={{mb: 1}}>
        {content}
      </Icon>
    )
  } else {
    return content
  }
}

Card.Header = CardHeader

const CardHeaderArea = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Flex sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      ...sx
    }} {...rest}>
      {children}
    </Flex>
  )
}

Card.HeaderArea = CardHeaderArea

export default Card
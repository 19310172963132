import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import { useTheme } from '@emotion/react'
import Button from 'reactor-ui/components/Button'
import Card from 'reactor-ui/components/Card'


export const NumberStat = ({
  name, data, description, suffix, sx, dx, bar, card
}) => {
  const theme = useTheme()
  let colorDx = dx > theme.colors.accents.length - 1 ? theme.colors.accents.length % (dx) : dx

  let C
  if (card) C = Card
  else C = React.div
  return (
    <C sx={{
      flex: '1 1 0',
      mx: 2,
      ...sx
    }}>
      <Box sx={{
        variant: 'layout.cardHeader',
        color: `accents.${colorDx}`
      }}>
        {name}
      </Box>
      <Flex sx={{
        alignItems: 'baseline'
      }}>
        <Box sx={{
          fontWeight: 'semiBold',
          fontSize: 'l',
          mt: 2
        }}>
          {data}
        </Box>
        {suffix && (
          <Box sx={{
            color: 'textMuted',
            // fontSize: 's',
            ml: 1
          }}>
            {suffix}
          </Box>
        )}
      </Flex>

      {description && (
        <Box sx={{
          mt: 2,
          color: 'textMuted',
          fontSize: 's'
        }}>
          {description}
        </Box>
      )}

      {bar && (
        <Box sx={{
          mt: 2,
          color: 'textMuted',
          fontSize: 's'
        }}>
          <Bar {...bar} colorDx={colorDx}/>
        </Box>
      )}
    </C>
  )
}

export const NumberStatLine = ({
  name, data, description, suffix, sx, dx
}) => {
  const theme = useTheme()
  let colorDx = dx > theme.colors.accents.length - 1 ? theme.colors.accents.length % (dx) : dx
  // console.log('s', theme.colors.accents[theme.colors.accents.length % (dx + 1)])
  return (
    <Box sx={{
      ...sx,
      my: 3
    }}>
      <Flex sx={{
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          variant: 'layout.cardHeader',
          color: `accents.${colorDx}`
        }}>
          {name}
        </Box>

        {description && (
          <Box sx={{
            color: 'textMuted',
            fontSize: 's'
          }}>
            {description}
          </Box>
        )}
      </Flex>
      <Flex sx={{
        alignItems: 'baseline'
      }}>
        <Box sx={{
          fontWeight: 'semiBold',
          fontSize: 'l',
          mt: 2
        }}>
          {data}
        </Box>
        {suffix && (
          <Box sx={{
            color: 'textMuted',
            // fontSize: 's',
            ml: 1
          }}>
            {suffix}
          </Box>
        )}
      </Flex>
    </Box>
  )
}

export const Row = ({
  sx, children
}) => {
  return (
    <Flex sx={{
      flexWrap: 'wrap',
      mx: -2,
      mb: 3,
      ...sx
    }}>
      {children}
    </Flex>
  )
}

export const Header = ({
  header,
  result
}) => {
  return (
    <>
      <Flex sx={{
        alignItems: 'center'
      }}>
        <Box sx={{
          variant: 'layout.cardHeader',
          my: 2
        }}>
          {header}
        </Box>

        <Box sx={{
          ml: 2
        }}>
          <Button icon='refresh' iconSize={16} color='textMuted' onClick={result.refresh} working={result.status === 'FETCHING'} />
        </Box>
      </Flex>
    </>
  )
}

export const Widget = ({
  header,
  result,
  description,
  kind,
  children,
  sx
}) => {
  return (
    <Box sx={{
    }}>
      <Header header={header} result={result} />
      <Box sx={{
        variant: 'layout.card',
        ...sx
      }}>
        <Flex sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}>
          {description && <Box sx={{
            color: 'textMuted',
            fontSize: 's'
          }}>{description}</Box>}

          {kind && <Box sx={{
            fontWeight: 'semiBold'
          }}>{kind}</Box>}

        </Flex>
        {result.graph && children}
      </Box>
    </Box>
  )
}

export const Bar = ({
  value, total, bgBar, colorDx
}) => {
  return (
    <Flex sx={{
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
      <Box sx={{
        height: '10px',
        bg: bgBar ? 'light.300' : 'none',
        width: '90%',
        borderRadius: '2'
      }}>
        <Box sx={{
          height: '10px',
          width: `${value / total * 100}%`,
          bg: colorDx ? `accents.${colorDx}` : 'brand.500',
          borderRadius: '2'
        }} />
      </Box>

      <Box sx={{
        flex: '0 0 auto',
        textAlign: 'right',
        color: 'dark.300',
        ml: 2
      }}>
        {value}/{total} - {(value / total * 100).toFixed(1)}%
      </Box>
    </Flex>
  )
}

export default {
  NumberStat,
  NumberStatLine,
  Row,
  Header,
  Widget,
  Bar,
}
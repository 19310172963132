import React from 'react'

// Vendor
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'

// // Reactor
// import createKey from 'reactor/util/key'
// import reactorActions from 'reactor/systems/reactor/actions'
// import useVisibility from './useVisibility'

// const MIN_REFETCH_INTERVAL = 2 * 1000

const useResourceFetch = (query, options = {}) => {
  const rcTenantId = useSelector((state) => state.reactor.activeTenantId)
  const baseAPIUrlFromState = useSelector((state) => state.reactor?.config?.baseAPIUrl || '')
  let baseHeaders = {
    'X-REACTOR-CHANNEL': useSelector((state) => state.reactor.config.channel)
  }
  let req
  if (typeof window === "undefined") {
    req = useSelector((state) => state.reactor.req)
  }

  const fetch = (variables) => {
    const dataToSend = { ...variables }

    dataToSend['@rcTenantId'] = rcTenantId

    const baseAPIUrl = options.baseUrl || baseAPIUrlFromState

    if (options.headers) {
      baseHeaders = { ...baseHeaders, ...options.headers }
    }

    const url = `${baseAPIUrl}/__reactor/api/resource/${query}`

    if (typeof window === "undefined") {
      const headers = req && req.headers.cookie ? { Cookie: req.headers.cookie } : {}
      return axios.get(`${url}`, { params: dataToSend, headers: { ...baseHeaders, ...headers } })
    } else {
      return axios.get(`${url}`, { params: dataToSend, headers: baseHeaders })
    }
  }

  return fetch
}

export default useResourceFetch
import React from 'react'

// Reactor
import * as RV from 'reactor-vera/templates/v1'


const Properties = props => {
  const { t } = RV.useTranslation()
  return (
    <RV.Routes>
      <RV.Route path='/' element={<PropertyHome />} />
    </RV.Routes>
  )
}

const PropertyHome = props => {
  return (
    <>
      <RV.GraphTable
        modelName='RnxProperty'
        query={{
          filters: {
            parent: null
          }
        }}
        elements={{
          primaryActions: [
            // <RV.Button key='create' colorScheme='brand' as={Link} to='create'>
            //   Yeni Etkinlik Oluştur
            // </RV.Button>
          ]
        }}
        detailLink={(row) => `./../p/${row.id}`}
      />
    </>
  )
}


export default Properties
// Reactor
import { createReducer } from 'reactor/core/redux'

import streamActions from './actions'

const defaultState = {
  namespaces: {},
  status: null
}

export default createReducer(defaultState, {

  [streamActions.ready]: (draft) => draft.status = 'ready',

  [streamActions.subscribe]: (draft, { namespaceName, streams, date }, state) => {
    if (!state.namespaces[namespaceName]) draft.namespaces[namespaceName] = {
      subscribed: {}
    }

    Object.keys(streams).forEach(streamName => {
      if (!draft.namespaces[namespaceName].subscribed[streamName]) {
        draft.namespaces[namespaceName].subscribed[streamName] = {
          lastEvent: null,
          lastEventAt: null,
          firstSubscribedAt: date,
          lastSubscribedAt: date,
          count: 1
        }
      } else {
        draft.namespaces[namespaceName].subscribed[streamName].count += 1
        draft.namespaces[namespaceName].subscribed[streamName].lastSubscribedAt = date
      }
    })
  },

  [streamActions.unsubscribe]: (draft, { namespaceName, streamName }, state) => {
    if (!state.namespaces[namespaceName]) return

    if (draft.namespaces[namespaceName].subscribed[streamName]) {
      if (draft.namespaces[namespaceName].subscribed[streamName].count === 1) {
        delete draft.namespaces[namespaceName].subscribed[streamName]
      } else {
        draft.namespaces[namespaceName].subscribed[streamName].count -= 1
      }
    }
  },

  [streamActions.rsUpdate]: (draft, { namespaceName, streamName, data, date }, state) => {
    if (!state.namespaces[namespaceName]) return

    if (draft.namespaces[namespaceName].subscribed[streamName]) {
      draft.namespaces[namespaceName].subscribed[streamName].lastEvent = data
      draft.namespaces[namespaceName].subscribed[streamName].lastEventAt = date
    }
  },
})

import React from 'react'

// Vendor
import qs from 'qs'
import URI from 'urijs'

// Rncui
import { useLocation, useNavigate } from 'reactor-vera/router'
import { isString } from 'lodash'

const getInitialFilters = (modelName, query, defaultValues) => {
  const locationFilters = {}
  defaultValues && Object.keys(defaultValues).map(filterKey => {
    locationFilters[filterKey] = defaultValues[filterKey]
  })

  Object.keys(query).map(filterKey => {
    const splitFilterKey = filterKey.split('__')
    const filterBelongsToThisEntityList = splitFilterKey.length > 1 && splitFilterKey[0] == modelName

    if (filterBelongsToThisEntityList) {
      try {
        locationFilters[splitFilterKey[1]] = JSON.parse(query[filterKey])
      } catch {
        locationFilters[splitFilterKey[1]] = query[filterKey]
      }
    }
  })

  return locationFilters
}

const updateUrlWithFilters = (query, locationFilters, modelName, searchSourceOfFiltersRef, navigate, mode = 'push') => {
  const url = new URI(location.pathname)
  const newFilters = {}

  const currentFilters = query
  Object.keys(currentFilters).map(filterKey => {
    const splitFilterKey = filterKey.split('__')
    const filterBelongsToThisEntityList = splitFilterKey.length > 1 && splitFilterKey[0] == modelName

    if (!filterBelongsToThisEntityList) {
      // newFilters[filterKey] = currentFilters[filterKey]
      newFilters[filterKey] = isString(currentFilters[filterKey]) ? currentFilters[filterKey] : JSON.stringify(currentFilters[filterKey])
    }
  })

  const _filters = locationFilters || {}
  Object.keys(_filters).map(filterKey => {
    const filterData = _filters[filterKey]
    if (filterData != null || filterData != undefined) {
      const prefixedFilterKey = `${modelName}__${filterKey}`
      newFilters[prefixedFilterKey] = isString(filterData) ? filterData : JSON.stringify(filterData)
    }
  })

  url.setQuery(newFilters)
  searchSourceOfFiltersRef.current = url.search()
  if (mode === 'push') navigate(url.toString())
  else navigate(url.toString(), {replace: true})

  return url.search()
}

const getSortInfo = (newSortinfo, locationFilters) => {
  const {
    sortBy: newSortBy,
    sortDirection: newSortDirecton
  } = newSortinfo

  if (!newSortBy || !newSortDirecton) {
    return {
      _sort: null,
      _pgn: null
    }
  } else {
    return {
      _sort: `${newSortBy}|${newSortDirecton}`,
      _pgn: null
    }
  }
}

const useLocationFilter = (modelName, defaultValues) => {
  const location = useLocation()
  const navigate = useNavigate()
  const query = qs.parse(location.search.slice(1))

  const [locationFilters, _setLocationFilters] = React.useState(getInitialFilters(modelName, query, defaultValues))

  const currentLocationFiltersRef = React.useRef(locationFilters)
  const prevHasFilterRef = React.useRef(false)
  const searchSourceOfFiltersRef = React.useRef(location.search)

  const setLocationFilters = React.useCallback((filters) => {
    const cleanedFilters = {}
    Object.keys(filters).forEach(key => {
      const val = filters[key]
      const isValidValue = val !== null && val !== undefined && val.length !== 0
      if (isValidValue) cleanedFilters[key] = val
    })

    _setLocationFilters(cleanedFilters)
    currentLocationFiltersRef.current = cleanedFilters
    return cleanedFilters
  }, [])

  const updateLocationFilters = React.useCallback((filters, options = {}) => {
    const { mode = 'push', keepDx = false, set = true } = options
    let newLocationFilters = {...currentLocationFiltersRef.current, ...filters}
    if (!keepDx) {
      newLocationFilters.dx = undefined
      newLocationFilters.pc = undefined
      newLocationFilters.nc = undefined
    }
    if (set) newLocationFilters = setLocationFilters(newLocationFilters)
    const newSearch = updateUrlWithFilters(query, newLocationFilters, modelName, searchSourceOfFiltersRef, navigate, mode)
  }, [currentLocationFiltersRef.current, location.search, modelName])

  const handleSort = React.useCallback((newSortinfo) => {
    const sortFilters = getSortInfo(newSortinfo, locationFilters)
    updateLocationFilters(sortFilters)
  }, [locationFilters])

  React.useEffect(() => {
    if (searchSourceOfFiltersRef.current === location.search) return
    const _filters = {}
    const queryFilters = query
    let hasFilter = false
    Object.keys(queryFilters).map(filterKey => {
      const splitFilterKey = filterKey.split('__')
      const filterBelongsToThisEntityList = splitFilterKey.length > 1 && splitFilterKey[0] == modelName

      if (filterBelongsToThisEntityList) {
        hasFilter = true
        _filters[splitFilterKey[1]] = JSON.parse(queryFilters[filterKey])
      }
    })

    if (hasFilter) {
      setLocationFilters(_filters)
      prevHasFilterRef.current = true
    } else if (prevHasFilterRef.current && !hasFilter) {
      setLocationFilters(_filters)
      prevHasFilterRef.current = false
    }
    searchSourceOfFiltersRef.current = location.search
  }, [location.search, modelName])

  const resetFilters = () => setLocationFilters({})

  const getOrder = React.useCallback(() => {
    const sortData = locationFilters._sort
    if (sortData) {
      const [sortBy, sortDirection] = sortData.split('|')
      return {
        sortBy,
        sortDirection
      }
    } else {
      return {}
    }
  }, [locationFilters])

  React.useEffect(() => {
    return () => resetFilters()
  }, [modelName])

  const instance = React.useMemo(() => ({
    locationFilters,
    updateLocationFilters,
    resetFilters,
    handleSort,
    getOrder,
  }), [locationFilters, modelName])

  return instance
}

export default useLocationFilter
import React from 'react'


class ReactorVeraErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // console.log('Vera error', error)
    console.warn(error)
    return { hasError: true }
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, errorInfo)
  // }

  render() {
    if (this.state.hasError) {
      return <h1>Bir sorun oluştu.</h1>
    }

    return this.props.children || null
  }
}

export default ReactorVeraErrorBoundary
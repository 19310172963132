import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import useDashboard from './useDashboard'

// Runic
import dashboardActions from 'reactor/systems/dashboard/actions'

import { useFormState, useForm } from 'react-final-form'


const useWidget = (widget) => {
  const dashboard = useDashboard()
  if (!dashboard?.id) {
    throw Error('Dashboard context not found. useWidget must be used under <Dashboard>.')
  }

  React.useEffect(() => {
    dispatch(dashboardActions.registerWidget({id: dashboard.id, widget}))
    return () => dispatch(dashboardActions.unregisterWidget({id: dashboard.id, widget}))
  }, [widget.id])

  let filterIds = []

  if (widget.filters) {
    filterIds = widget.filters.map(f => f.id)
  }

  const formState = useFormState({
    subscription: {
      values: true
    }
  })

  // React.useEffect(() => {
  //   dispatch(dashboardActions.setFilterValues({id: dashboard.id, values: formState.values}))
  // }, [formState.values])

  const dispatch = useDispatch()

  const filterValues = useSelector(state => {
    const result = {}
    filterIds.forEach(filterId => {
      const filterData = widget.filters.find(f => f.id === filterId)
      result[filterData.name || filterId] = state.reactorDashboard.dashboards[dashboard.id].values[filterId]
    })
    return result
  })

  return {
    filterValues: widget.filters ? filterValues : {}
  }
}

export default useWidget
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import MediaItem from 'elements/rnx_broadcast/rnxBroadcast/components/MediaItem'


const BroadcastSlotDetail = ({
  refresh,
  rnxBroadcast
}) => {
  const result = RV.useReactorQuery({
    'rnxBroadcastSlotList': {
      filters: {
        rnx_broadcast_id: rnxBroadcast.id
      }
    },
    'rnxBroadcast': {
      filters: {
        id: rnxBroadcast.id,
      },
      fields: [
        'name', 'kind', 'slotCount', 'slotLastCalculated', 'slotValidUntil',
        'slotMedias'
      ]
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ rnxBroadcastSlotList, rnxBroadcast }) => {
        console.log('-', rnxBroadcast)
        return (
          <>
            <RV.ActionGroup name={'RnxBroadcast'} contexts={['Entity.Slot']} ctx={{ id: rnxBroadcast.id }} />
            <Slots rnxBroadcastSlotList={rnxBroadcastSlotList} rnxBroadcast={rnxBroadcast} />
            <Box my={3} />
            <Items rnxBroadcastSlotList={rnxBroadcastSlotList} rnxBroadcast={rnxBroadcast} />
          </>
        )
      }}
    </RV.QueryLoading>
  )
}

const Slots = ({
  rnxBroadcast,
  rnxBroadcastSlotList
}) => {
  const slots = []
  for (let i = 0; i < rnxBroadcast.slotCount; i++) {
    const slot = rnxBroadcastSlotList.items.find(x => x.slotIndex === i + 1)
    console.log(rnxBroadcastSlotList.items)
    slots.push({
      dx: i,
      slot
    })
  }
  return (
    <>
      <RV.Card.Header>
        Slotlar
      </RV.Card.Header>
      <Flex sx={{ flexWrap: 'wrap', mx: -3 }}>
        {slots.map(slot => {
          console.log('-', slot)
          return (
            <Box key={slot.dx} sx={{
              mx: 3,
              flex: '1 1 20%',
              maxWidth: '20%',
              minWidth: '10%'
            }}>
              <RV.Card>
                <RV.Card.Header>
                  {slot.dx + 1} | {slot.slot?.rnxBrdAdvCampaign?.name || '(Boş)'}
                </RV.Card.Header>
              </RV.Card>
            </Box>
          )
        })}
      </Flex>
    </>
  )
}

const Items = ({
  rnxBroadcast,
  rnxBroadcastSlotList
}) => {
  console.log(rnxBroadcast.slotMedias)
  return (
    <>
      <RV.Card.Header>
        Yayın
      </RV.Card.Header>

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -2,
        mt: 2
        // justifyContent: 'space-evenly'
      }}>
        {rnxBroadcast.slotMedias.items.map(item => {
          return (
            <MediaItem
            item={rnxBroadcast.slotMedias.medias[item.id]}
            key={item.id}
            showActionMenu={false}
            // showActionMenu={
            //   <RV.ActionGroupMenu name={'RnxBrdAdvAdvertisement'} contexts={['Entity']} ctx={{ id: a.id }} callbacks={{ onSuccess: () => refresh() }} />
            // }
            >
              {/* <RV.Layout.Row>
            <RV.Enum value={a.status} />
            <RV.DateTime data={a.dateStart} />
            <RV.DateTime data={a.dateFinish} />
          </RV.Layout.Row> */}
            </MediaItem>
          )
        })}
      </Flex>
    </>
  )
  return (
    <>
      <Flex sx={{ flexWrap: 'wrap', mx: -3 }}>
        {slots.map(slot => {
          console.log('-', slot)
          return (
            <Box key={slot.dx} sx={{
              mx: 3,
              flex: '1 1 20%',
              maxWidth: '20%',
              minWidth: '10%'
            }}>
              <RV.Card>
                <RV.Card.Header>
                  {slot.dx + 1} | {slot.slot?.rnxBrdAdvCampaign.name || '(Boş)'}
                </RV.Card.Header>
              </RV.Card>
            </Box>
          )
        })}
      </Flex>
    </>
  )
}

export default BroadcastSlotDetail
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useTable } from 'react-table'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import RfActionForm from 'reactor-form/components/RfActionForm'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from 'reactor-ui/components/Table'
import Card from 'reactor-ui/components/Card'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'
import Divider from 'reactor-ui/components/Divider'
import SwitchInput from 'reactor-ui/components/SwitchInput'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from 'reactor-ui/components/Modal'

const ActivityProgram = ({
  rnxAsSchedule
}) => {
  const [editMode, setEditMode] = React.useState()
  const [open, setOpen] = React.useState()
  const result = useReactorQuery({
    'rnxAsActivityList': {
      filters: {
        rnxAsScheduleId: rnxAsSchedule.id,
        '@dt': {
          field: 'datetime_start',
          value: 'currentWeek'
        }
      },
      order_by: 'datetime_start|asc',
      itemFields: [
        'name', 'datetimeStart', 'datetimeFinish', 'countReservation', 'countAttendee', 'limit', { rnxAsActivityKind: { fields: ['name', 'limit', 'color'] } }
      ],
      limit: 500
    },
  })
  const rnxAsActivityList = result.graph?.rnxAsActivityList
  console.log('rnxAsActivityList', rnxAsActivityList)
  if (!rnxAsActivityList) return null

  return (
    <Card mt={4}>
      Düzenle: <SwitchInput isChecked={editMode} onChange={e => setEditMode(e.target.checked)} />
      <ActivityProgramContent setOpen={setOpen} editMode={editMode} rnxAsSchedule={rnxAsSchedule} result={result} rnxAsActivityList={rnxAsActivityList} />
      {open && <ActivityInfo id={open} onClose={() => setOpen(false)} />}
    </Card>
  )
}

const ActivityInfo = ({
  id,
  onClose
}) => {
  const { t } = useTranslation()
  const result = useReactorQuery({
    'rnxAsActivity': {
      filters: {
        id: id,
      },
      fields: [
        'name', 'datetimeStart', 'datetimeFinish', 'countReservation', 'countAttendee'
      ]
    },
    'rnxAsActivityReservationList': {
      filters: {
        rnx_as_activity_id: id,
      },
      limit: 500,
      itemFields: [
        { rnxContact: { fields: ['name', 'govId'] } },
        { rnxAsActivity: { fields: ['name'] } },
        { rnxAsActivityKind: { fields: ['name'] } },
        'kind',
        'datetimeStart'
      ]
    },
  })

  const rnxAsActivity = result.graph?.rnxAsActivity
  const rnxAsActivityReservationList = result.graph?.rnxAsActivityReservationList

  if (!rnxAsActivityReservationList) return null
  console.log('rnxAsActivity', result.graph)

  const header = rnxAsActivity?.name
  return (
    <Modal autoFocus={false} closeOnOverlayClick={false} isOpen={true} onClose={() => {
      onClose()
    }} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textTransform='capitalize'>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RfActionForm name='ekle' actionKey='FmBase.CreateReservationForUser' fields={[
            {
              name: 'rnxMembershipId',
              label: 'Üye',
              kind: 'entity',
              options: {
                modelName: 'rnxMembership',
                filterField: 'rnx_contact.gov_id'
              }
            },
          ]} initialValues={{
            rnx_as_activity_id: rnxAsActivity.id
          }} onSuccess={() => result.refresh()} />
          <Box>
            Rezervasyon: {rnxAsActivity.countReservation}
          </Box>

          <Box>
            Giriş: {rnxAsActivity.countAttendee}
          </Box>

          {rnxAsActivityReservationList.count == 0 ? (
            'Reservasyon yok.'
          ) : (
            <Box>
              <Card.Header mt={4} mb={2}>Reservasyonlar</Card.Header>
              {rnxAsActivityReservationList.items?.map?.((item, dx) => {
                return (
                  <Box key={dx} sx={{
                    my: 4
                  }}>
                    <Icon icon='user-group'>
                      {item.rnxContact.name} {item.rnxContact.govId}
                      <RfActionForm name='iptal' actionKey='FmBase.CancelReservationForUser' fields={[

                      ]} initialValues={{
                        rnx_as_activity_reservation_id: item.id
                      }} onSuccess={() => result.refresh()} />
                    </Icon>
                  </Box>
                )
              })}
            </Box>
          )}

          <Divider/>

          <Card.Header mt={4} mb={2}>Geçişler</Card.Header>

          <RV.GraphTable
            modelName='RnxPacAccessRecord'
            query={{
              filters: {
                activity_entity_key: rnxAsActivity.id,
                result: 'ALLOW'
              },
              columns: [
                // {rnx_contact: {fields: ['name']}},
                // {rnx_pac_access_point: {fields: ['name']}}
                'rnxContact',
                'rnxPacAccessPoint',
                'datetimeAccess'
              ]
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button sx={{ textTransform: 'capitalize' }} variant='ghost' onClick={onClose}>
            {t('op.close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const ActivityProgramContent = ({
  rnxAsSchedule,
  rnxAsActivityList,
  result,
  editMode,
  setOpen
}) => {
  const scheduleOptions = rnxAsSchedule.scheduleOptions
  const startHourSplit = scheduleOptions.start_hour.split(':')
  const endHourSplit = scheduleOptions.end_hour.split(':')

  const today = dayjs().startOf('today')
  const start = today.hour(startHourSplit[0]).minute(startHourSplit[1])
  const end = today.hour(endHourSplit[0]).minute(endHourSplit[1])

  const interval = parseInt(scheduleOptions.interval)
  const length = parseInt(scheduleOptions.length)

  const activities = React.useMemo(() => {
    const result = {}
    rnxAsActivityList.items?.forEach(a => {
      const start = dayjs(a.datetimeStart).add(3, 'hours')
      const hour = start.hour()
      let day = start.day() - 1
      if (day < 0) day = 6
      if (!result[hour]) result[hour] = {}
      result[hour][day] = a
    })
    return result
  }, [rnxAsActivityList])

  const hours = React.useMemo(() => {
    const hours = []
    while (hours.length == 0 || (hours[hours.length - 1].end.add(interval + length, 'minute') < end)) {
      let prevEnd
      if (hours.length) {
        prevEnd = hours[hours.length - 1].end
      } else {
        prevEnd = start.subtract(interval, 'minute')
      }

      const nextStart = prevEnd.add(interval, 'minute')
      const nextEnd = nextStart.add(length, 'minute')

      const next = {
        start: nextStart,
        end: nextEnd,
        id: nextStart,
        startTime: `${nextStart.hour()}:${nextStart.minute()}`,
        endTime: `${nextEnd.hour()}:${nextEnd.minute()}`,
        ...activities[nextStart.hour()]
      }

      hours.push(next)
    }
    return hours
  }, [rnxAsActivityList])

  console.log('-hours', hours)

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: 'Saatler',
        id: 'hours',
        // renderer: (cell) => <ActionGroupMenu name={modelName} contexts={['Entity']} ctx={{ id: cell.row.id }} />,
        // alignLast: true,
      },
      {
        Header: 'Pazartesi',
        id: 0,
        accessor: '0'
      },
      {
        Header: 'Salı',
        id: 1,
        accessor: '1',
      },
      {
        Header: 'Çarşamba',
        id: 2,
        accessor: '2',
      },
      {
        Header: 'Perşembe',
        id: 3,
        accessor: '3',
      },
      {
        Header: 'Cuma',
        id: 4,
        accessor: '4',
      },
      {
        Header: 'Cumartesi',
        id: 5,
        accessor: '5',
      },
      {
        Header: 'Pazar',
        id: 6,
        accessor: '6',
      },
    ]
  }, [])

  const tableRows = hours

  const tableInstance = useTable({
    columns: tableColumns,
    data: tableRows,
    getRowId: (row) => row.id
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <Table {...getTableProps()} variant="striped" colorScheme="light" size='sm'>
      <Thead>
        {headerGroups.map(headerGroup => {
          return (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, dx) => {
                const { key, ...headerProps } = column.getHeaderProps()
                return (
                  <Th>
                    {column.Header}
                  </Th>
                )
              })}
            </Tr>
          )
        })}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, dx) => {
          prepareRow(row)
          // console.log(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell, cellDx) => {
                const { key, cellProps } = cell.getCellProps()
                if (cell.column.id === 'hours') {
                  return (
                    <Td key={key}>
                      {row.original.start.format('HH:mm')} - {row.original.end.format('HH:mm')}
                    </Td>
                  )
                }

                const dayId = cell.column.id
                const startTime = row.original.startTime
                const endTime = row.original.endTime

                // const bg = cell.value?.rnxAsActivityKind?.color

                return (
                  <Td sx={{
                    // bg: bg
                  }}>
                    {cell.value ? (
                      <Box sx={{
                        textAlign: 'center',
                        p: 3,
                        bg: cell.value?.rnxAsActivityKind?.color
                      }}>
                        <a onClick={() => setOpen(cell.value.id)}>{cell.value.name}</a>
                        {/* {console.log('cell', cell)} */}

                        <Box>
                          R: {cell.value.countReservation}
                        </Box>

                        <Box>
                          G: {cell.value.countAttendee}
                        </Box>

                        {editMode && <RfActionForm name='sil' actionKey='RnxActivitySchedule.DeleteAsActivity' fields={[

                        ]} buttonText={'btn.delete'} initialValues={{
                          id: cell.value.id
                        }} onSuccess={() => result.refresh()} />}
                      </Box>
                    ) : (
                      <Box sx={{
                        textAlign: 'center',
                      }}>
                        {editMode && <RfActionForm name='ekle' actionKey='RnxActivitySchedule.CreateAsActivity' fields={[
                          {
                            name: 'rnxAsActivityKind',
                            label: 'Tür',
                            kind: 'entity'
                          },
                          {
                            name: 'name',
                            label: 'isim',
                            optional: true
                          },
                        ]} initialValues={{
                          dayId,
                          startTime,
                          endTime,
                          rnx_as_schedule_id: rnxAsSchedule.id
                        }} onSuccess={() => result.refresh()} />}
                      </Box>
                    )}
                  </Td>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default ActivityProgram
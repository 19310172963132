import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import * as Settings from 'reactor-vera/templates/v1/Settings'


const PrGeneralSettings = props => {
  const result = RV.useReactorResource('prBase.settings.settings', { name: 'PrGeneralSettings' })
  return (
    <RV.QueryLoading result={result}>
      {() => (
        <>
          <Settings.SettingsCard name={result.data.name} description={result.data.ui?.description}>
            <Settings.SettingsForm result={result} />
          </Settings.SettingsCard>
        </>
      )}
    </RV.QueryLoading>
  )
}

export default PrGeneralSettings
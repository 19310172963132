// Runic
import createKey from 'reactor/util/key'


// const source = (name, payload, fetchKey, callbacks) => ({
//   type: `@@runic/source/${name}`,
//   meta: {
//     status: 'START',
//     name,
//     kind: '@@runic/source',
//     dt: new Date(),
//     fetchKey: fetchKey || createKey({s: name, p: payload})
//   },
//   payload,
//   callbacks,
// })

// source.toString = () => '@@runic/source'

// const modelSource = (name, payload, fetchKey, callbacks) => ({
//   type: `@@runic/modelSource/${name}`,
//   meta: {
//     status: 'START',
//     name,
//     kind: '@@runic/modelSource',
//     dt: new Date(),
//     fetchKey: fetchKey || createKey({s: name, p: payload})
//   },
//   payload,
//   callbacks,
// })

// modelSource.toString = () => '@@runic/modelSource'

// const graphQuery = (payload, fetchKey, callbacks, meta) => ({
//   type: `@@runic/graphQuery/${fetchKey}`,
//   meta: {
//     status: 'START',
//     kind: '@@runic/graphQuery',
//     dt: new Date(),
//     fetchKey: fetchKey,
//     ...meta
//   },
//   payload,
//   callbacks,
// })

// source.toString = () => '@@runic/graphQuery'

// const setup = (payload, callbacks) => ({
//   type: `@@runic/setup`,
//   meta: {
//     kind: 'LOCAL',
//   },
//   payload,
//   callbacks,
// })

// setup.toString = () => '@@runic/setup'

// export default {
//   source,
//   modelSource,
//   graphQuery,
//   setup
// }

const reactorQuery = ({query, queryKey, meta, callbacks}) => ({
  type: `@@reactor/reactorQuery/${queryKey}`,
  meta: {
    status: 'START',
    kind: '@@reactor/reactorQuery',
    dt: new Date(),
    queryKey: queryKey,
    ...meta
  },
  payload: {query},
  callbacks,
})

reactorQuery.toString = () => '@@reactor/reactorQuery'

const resourceQuery = ({query, variables, queryKey, meta, callbacks}) => ({
  type: `@@reactor/resourceQuery/${query}`,
  meta: {
    status: 'START',
    kind: '@@reactor/resourceQuery',
    dt: new Date(),
    queryKey: queryKey,
    ...meta
  },
  payload: {
    query,
    variables,
  },
  callbacks,
})

resourceQuery.toString = () => '@@reactor/resourceQuery'

const setup = (payload, callbacks) => ({
  type: `@@reactor/setup`,
  meta: {
    kind: 'LOCAL',
  },
  payload,
  callbacks,
})

setup.toString = () => '@@reactor/setup'

const reset = (payload, callbacks) => ({
  type: `@@reactor/reset`,
  meta: {
    kind: 'LOCAL',
  },
  payload,
  callbacks,
})

reset.toString = () => '@@reactor/reset'

const activateTenant = (id, callbacks) => ({
  type: `@@reactor/activateTenant`,
  meta: {
    kind: 'LOCAL',
  },
  payload: id,
  callbacks,
})

activateTenant.toString = () => '@@reactor/activateTenant'

export default {
  reactorQuery,
  resourceQuery,
  setup,
  activateTenant,
  reset
}

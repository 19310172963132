  // Recraft - Function
import { createActions } from 'reactor/core/redux'

const ACTIONS = {
  CUSTOM: {
    upload: {hasProgress: true},
    retry: {hasProgress: true},
    uploadInternal: {}
  },
  LOCAL: {
    progress: {},
    clear: {},
    remove: {}
  }
}

export default createActions(ACTIONS, 'upload', 'RxFile')

import React from 'react'

// Reactor UI
import Enum from './Enum'
import Bool from './Bool'
import DateTime from 'reactor-ui/components/DateTime'
import Icon from 'reactor-ui/components/Icon'


const renderers = {
  Option: (value) => <Enum value={value}/>,
  Bool: (value) => <Bool value={value}/>,
  DateTime: (value) => <DateTime data={value} time={true}/>,
  Date: (value) => <DateTime data={value}/>,
  Object: (value, fieldSchema, fieldName) => {
    // FIXME:
    const fieldNameToUse = fieldSchema.name === fieldName ? 'name' : fieldName
    return value?.[fieldNameToUse]
  }
}

export default renderers
import React from 'react'

// Vendor
import ReactJson from 'react-json-view'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import ReactorModal from 'reactor-ui/components/Modal'


const RequestData = ({ entity }) => {
  return (
    <ReactorModal
    size='full'
    autoFocus={true}
    header='Toplu Giriş'
    anchor={<RV.Button icon='list-letters'/>}>
      {({ onClose }) => (
        <RequestDataDetail entity={entity} />
      )}
    </ReactorModal>
  )
}

const RequestDataDetail = ({
  entity
}) => {
  const result = RV.useReactorQuery({
    'rnxDeviceRequestLog': {
      fields: [
        'data'
      ],
      filters: {
        id: entity.id
      },
    }
  })
  console.log(entity)
  const rnxDeviceRequestLog = result.graph?.rnxDeviceRequestLog
  if (!rnxDeviceRequestLog) return null

  return (
    <Box>
      <Box>
        <RV.DateTime data={entity.datetimeContact} time/>
      </Box>
      <Flex>
        <Box flex='0 0 50%'>
          <RV.Card.Header sx={{ mb: 2 }}>
            İstek
          </RV.Card.Header>
          <ReactJson name={false} src={rnxDeviceRequestLog.data.request} />
        </Box>

        <Box flex='0 0 50%'>
          <RV.Card.Header sx={{ mb: 2 }}>
            Cevap
          </RV.Card.Header>
          <ReactJson name={false} src={rnxDeviceRequestLog.data.response} />
        </Box>
      </Flex>
    </Box>
  )
}

export default RequestData
import React from "react"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react"

export {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react"
import { useDisclosure } from "@chakra-ui/react"
export { useDisclosure } from "@chakra-ui/react"

import { Portal } from "@chakra-ui/react"
import { Box, Flex } from '../index'
import { isFunction } from "lodash"


export const ReactorPopover = ({
  defaultIsOpen,
  anchor,
  children
}) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({ defaultIsOpen })

  return (
    <Popover
      isLazy
      returnFocusOnClose={false}
      autoFocus={false}
      closeOnBlur={false}
      isOpen={isOpen}
      matchWidth
    >
      <PopoverTrigger>
        {anchor && React.cloneElement(anchor, { onClick: onToggle })}
      </PopoverTrigger>
      <Portal sx={{
        zIndex: 9999
      }} appendToParentPortal={true}>
        <Box sx={{
          zIndex: 9999
        }}>
          <PopoverContent>
            <PopoverArrow />
            {/* <PopoverHeader>Header</PopoverHeader>
            <PopoverCloseButton /> */}
            <PopoverBody sx={{
              zIndex: 9999
            }}>
              {isFunction(children) ? children({onClose}) : children}
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  )
}
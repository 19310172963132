import React from 'react'

// Reactor UI
import { Flex, Box } from 'reactor-ui'

// Reactor
import useLayoutConfig from 'reactor-vera/hooks/useLayoutConfig'


const DefaultThemeUnitLayout = props => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  return (
    <Box sx={{
      ml: [0, null, null, layoutConfig.sidebar?.extended ? '240px' : '90px'],
      transition: 'all 0.2s ease',
      pl: 6,
      pr: 6,
    }}>
      {props.children}
    </Box>
  )
}

export default DefaultThemeUnitLayout
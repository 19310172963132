import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import useReactorAction from 'reactor/hooks/useReactorAction'
import { useNavigate } from 'reactor-vera/router'
import reactorActions from 'reactor/systems/reactor/actions'


const AuthenticationLogout = props => {
  const dispatchAction = useReactorAction('RcApp.LogoutRcUser')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  React.useEffect(() => {
    dispatchAction(null, {
      success: () => {
        dispatch(reactorActions.reset())
        navigate('/')
      }
    })
  }, [])

  return (null)
}

export default AuthenticationLogout

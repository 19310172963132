// Vendor
import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import { generateColorScale } from 'reactor-ui/util/color'

const colors = {
  brand: generateColorScale('#2E538C'),
  brandLight: generateColorScale('#5D82B9'),
  dark: generateColorScale('#1D212C'),
  light: generateColorScale('#DFDFE7'),
  warning: generateColorScale('#F5A623'),
  danger: generateColorScale('#D0021B'),
  success: generateColorScale('#4BA87E'),
  info: generateColorScale('#F4F5F8'),

  accents: [
    '#0168fa',
    '#00cccc',
    '#f10075',
    '#fd7e14'
  ]
}

const components = {

}

const global = {
  'html, body': {
    fontSize: '14px'
  },
  '#zmmtg-root': {
    display: 'none'
  },
  a: {
    cursor: 'pointer',
  },
  body: {
    bg: '#F4F5F9',
  }
}

const theme = extendTheme({
  colors,
  components,
  styles: {
    global
  },
  // ...withDefaultColorScheme({
  //   colorScheme: 'primary',
  //   components: ['Button'],
  // }),
})

export default theme
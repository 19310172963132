import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import Form, { RfFormField, useForm, useFormState, useField, useFieldArray, RfShowIf } from 'reactor-form/components/RfForm'


const GraphConnectionAccessSetup = ({
  parentFieldName,
  model
}) => {
  return (
    <RfFormField
      name={`${parentFieldName}.connectedModel`}
      label='Bağlantılı Model'
      kind='select'
      options={{
        loader: {
          'kind': 'resource',
          'options': {
            'resourceIdentifier': 'RcApp.Reactor.modelConnections',
            'values': {
              'model': model
            }
          }
        }
      }}
    />
  )
}

export default GraphConnectionAccessSetup
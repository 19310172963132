import React from 'react'

// Vendor
import { applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'

// Project
import createReducer from './reducer'

export const sagaMiddleware = createSagaMiddleware()

const versionMiddleware = store => next => action => {
  if ((action.payload) && (action.payload.data) && (action.payload.data.error)) {
    if (action.payload.data.error == 'VERSION_MISMATCH') {
      store.dispatch({
        type: 'RCR_APP_VERSION_UPDATE',
        payload: {kind: 'MAJOR'},
        meta: {
          kind: 'LOCAL'
        }
      })
    }
  }
  return next(action)
}

// const debounceNotify = debounce(notify => notify())
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  // actionSanitizer: action => action.type === 'FILE_DOWNLOAD_SUCCESS' && action.data ? { ...action, data: '<<LONG_BLOB>>' } : action,
  stateSanitizer: (state) => {
    let result = state.model && state.model.entitySchemasBySnakeCaseName ? { ...state, model: {...state.model, entitySchemasBySnakeCaseName: 'DONE'} } : state
    result = result.model && result.model.entitySelectors ? { ...result, model: {...result.model, entitySelectors: 'DONE'} } : result
    return result
  }
}) : compose

const logger = __DEV__ ? createLogger({
  collapsed: true,
}) : null

const enhancer = __DEV__ ? composeEnhancers(
  // batchedSubscribe(debounceNotify),
  // applyMiddleware(routerMiddleware(history)),
  applyMiddleware(sagaMiddleware),
  applyMiddleware(logger),
  applyMiddleware(versionMiddleware),
) : composeEnhancers(
  // applyMiddleware(routerMiddleware(history)),
  applyMiddleware(sagaMiddleware),
  applyMiddleware(versionMiddleware),
)

const storeCreator = (director) => {
  const store = createStore(createReducer(director), {}, enhancer);
  store.asyncReducers = {}
  function* rootSaga() {
    yield all(director.getSagaList())
  }
  sagaMiddleware.run(rootSaga)

  if (__DEV__) {
    if (module.hot) {
      module.hot.accept('./reducer', () => {
        store.replaceReducer(createReducer(director))
      })
    }
  }

  if (!director.isRunning) {
    director.registerReducerListener('updateOnAsyncLoad', (op, _, reducer) => store.replaceReducer(createReducer(director)))
    director.setSagaRunner(sagaMiddleware.run)
    director.run()
  }

  return store
}

export default storeCreator
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import * as Settings from 'reactor-vera/templates/v1/Settings'


const BankProviderSettings = props => {
  return (
    <RV.GraphTable
      modelName={'BankPaymentProvider'}
      query={{
        filters: {
          // status: 'DONE'
        }
      }}
      detailLink={'d'}
    // actionCtx={{
    //   model: {
    //     // rnx_device_app: params.rnxDeviceAppId,
    //   }
    // }}
    />
  )
}

export default BankProviderSettings
import MediaProfileManagement from './pages/MediaProfileManagement'
import DisplayManagement from './pages/DisplayManagement'


export default {
  name: 'RnxDeviceDisplay',
  components: {
    'MediaProfileManagement': {
      component: MediaProfileManagement
    },
    'DisplayManagement': {
      component: DisplayManagement
    },
  }
}
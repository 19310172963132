import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'
import { Link, useActiveLink, useParams, useMatch, useResolvedPath, useHref } from 'reactor-vera/router'
import useLayoutConfig from 'reactor-vera/hooks/useLayoutConfig'
import Icon from 'reactor-ui/components/Icon'
import * as changeCase from 'reactor-ui/util/text'


export const PlexUiComponentItem = ({
  item,
  // variant,
  depth,
  baseUrl
}) => {
  const to = `${baseUrl}/${item.key}`

  const isActive = useActiveLink({ to })
  const variant = isActive ? 'active' : null
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()

  const styles = useStyleConfig("PlexUiComponentItem", {
    variant, defaultStyleConfig: {
      baseStyle: {
        // fontSize: 'xs',
        textTransform: 'uppercase',
        // fontWeight: 'bold',
        color: 'light.900',
        '&:hover': {
          color: 'brandLight.500'
        },
        transition: 'all 0.2s ease',
        // my: 1,
        display: 'block',
        fontSize: 'sm',
        py: 2,
        px: 2,
        borderRadius: 2,

        ml: -4,
        pl: 4
      },
      variants: {
        active: {
          borderLeft: '3px solid #FFF',
          borderLeftColor: 'brand.500',
          color: 'light.500',
          fontWeight: 'semibold',
          bg: 'dark.800',
          // '&:hover': {
          //   bg: 'brand.400',
          //   color: 'light.800'
          // },
          // color: 'dark.500'
        },
      }
    }
  })

  const { t } = useTranslation()
  let content, sx
  if (layoutConfig.sidebar.extended) {
    content = (
      <Icon icon={item.icon} childSx={{
        ml: 4,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        // width: '80%',
        display: 'inline-block',
        // transition: 'all 0.2s ease',
      }}>
        {t(`label.${changeCase.snake(item.label || item.name)}`)}
      </Icon>
    )
  } else {
    sx = {
      fontSize: 'xs',
      // width: '60px',
      textAlign: 'center',
      px: 0
    }
    content = (
      <>
        <Icon icon={item.icon} wrapperSx={{
          mx: 'auto',
          width: '20px'
        }} size='18px' />
        <Box sx={{
          fontSize: 'xs',
          mt: 1
        }}>
          {t(`label.${changeCase.snake(item.label || item.name)}`)}
        </Box>
      </>
    )
  }

  return (
    <Box as={Link} to={to} __css={styles} sx={sx}>
      {content}
    </Box>
  )
}

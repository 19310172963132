import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

// Reactor
import * as RV from 'reactor-vera/templates/v1'


const AccessPlanManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.GraphTable
        modelName={'RnxPacAccessPlan'}
        // detailLink={'plan'}
        // actionCtx={{
        //   model: {
        //     // rnx_device_app: params.rnxDeviceAppId,
        //   }
        // }}
      />
      )} />
      <RV.Route path='plan/:rnxDeviceProfileId/*' element={<DeviceProfileDetail />} />
    </RV.Routes>
  )
}

const ProfileHome = props => {
  return (
    <RV.BareTabs
      isLazy
      tabs={[
        {
          name: 'Cihaz Profil',
          codeName: 'deviceProfile',
          element: (
            <RV.GraphTable
              modelName={'RnxDeviceProfile'}
              detailLink={'device_profile_detail'}
              actionCtx={{
                model: {
                  // rnx_device_app: params.rnxDeviceAppId,
                }
              }}
            />
          )
        },

        {
          name: 'Medya Profil',
          codeName: 'mediaProfile',
          element: (
            <RV.GraphTable
              modelName={'RnxDisplayMediaProfile'}
              detailLink={'media_profile_detail'}
              actionCtx={{
                model: {
                  // rnx_device_app: params.rnxDeviceAppId,
                }
              }}
            />
          )
        },

        {
          name: 'Kayıt Profil',
          codeName: 'registerProfile',
          element: (
            <RV.GraphTable
              modelName={'RnxDeviceRegisterProfile'}
              detailLink={'register_profile_detail'}
            />
          )
        }
      ]}
    />
  )
}

const DeviceProfileDetail = ({

}) => {
  const { t } = useTranslation()
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rnxDeviceProfile': {
      filters: {
        id: params.rnxDeviceProfileId
      },
      fields: [
        'name', 'kind', 'platform', 'data'
      ]
    },
  })

  RV.useBreadcrumb(`rnx_device_app:${result.graph?.rnxDeviceProfile}`, result.graph?.rnxDeviceProfile?.name)
  const rnxDeviceProfile = result.graph?.rnxDeviceProfile
  const meta = result.graph?.rnxDeviceProfileMeta
  if (!rnxDeviceProfile) return null

  const profileData = rnxDeviceProfile.data?.profile

  return (
    <>
      <RV.EntityDetail
        icon={'graphic-tablet-wireless'}
        entity={rnxDeviceProfile}
        schema={result.schema?.rnxDeviceProfile}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
      <Flex sx={{
        mx: -2
      }}>

        {profileData && <RV.Card sx={{
          flex: '0 0 300px',
          my: 3,
          mx: 2
        }}>
          <Flex sx={{
            justifyContent: 'space-between'
          }}>
            <RV.Card.Header>
              Profil Bilgileri
            </RV.Card.Header>

            {/* <RV.RfSingleAction
              onSuccess={result.refresh}
              iconName='pencil'
              actionKey='RnxDevice.SetProfileData'
              fields={result.graph?.rnx_device_profile?.profile_data.fields}
              initialValues={{
                ...profileData,
                id: result.graph?.rnx_device_profile?.id
              }}
              buttonText='KAYDET' /> */}
          </Flex>
          {Object.entries(profileData).map(([k, v]) => {
            return (
              <Flex key={k} sx={{
                my: 2
              }}>
                <Box sx={{
                  textTransform: 'uppercase',
                  flex: '0 0 80%',
                  color: 'textMuted',
                  fontWeight: 'semiBold'
                }}>
                  {t(`field.${k}`)}
                </Box>
                <Box>{v}</Box>
              </Flex>
            )
          })}
        </RV.Card>}

        <RV.GraphTable
          sx={{
            my: 3,
            mx: 2,
            flex: '1 1 0'
          }}
          modelName={'RnxDevice'}
          query={{
            filters: {
              rnx_device_profile_id: params.rnxDeviceProfileId
            }
          }}
        />

      </Flex>
    </>
  )
}

const MediaProfileDetail = ({

}) => {
  const { t } = useTranslation()
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rnxDisplayMediaProfile': {
      filters: {
        id: params.rnxDisplayMediaProfileId
      },
      fields: [
        'name', 'displayCount', 'data'
      ]
    },
  })

  RV.useBreadcrumb(`rnx_device_app:${result.graph?.rnxDisplayMediaProfile}`, result.graph?.rnxDisplayMediaProfile?.name)
  const rnxDisplayMediaProfile = result.graph?.rnxDisplayMediaProfile
  const meta = result.graph?.rnxDisplayMediaProfileMeta
  if (!rnxDisplayMediaProfile) return null

  return (
    <>
      <RV.EntityDetail
        icon={'playlist-video'}
        entity={rnxDisplayMediaProfile}
        schema={result.schema?.rnxDisplayMediaProfile}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <RV.Card sx={{
        my: 3
      }}>
        <RV.Card.Header>
          Video Profil
        </RV.Card.Header>

        <Box>
          {rnxDisplayMediaProfile.data.videoProfile ? (
            <Box>
              CRF: {rnxDisplayMediaProfile.data.videoProfile.crf},
              Scale: {rnxDisplayMediaProfile.data.videoProfile.scale}
            </Box>
          ) : (
            'Yok'
          )}
        </Box>

        <RV.Card.Header>
          Görsel Profil
        </RV.Card.Header>

        <Box>
          {rnxDisplayMediaProfile.data.imageProfile ? (
            <Box>
              Max Genişlik: {rnxDisplayMediaProfile.data.imageProfile.maxWidth},
              Max Yükseklik: {rnxDisplayMediaProfile.data.imageProfile.maxHeight},
              Kalite: {rnxDisplayMediaProfile.data.imageProfile.quality}
            </Box>
          ) : (
            'Yok'
          )}
        </Box>
      </RV.Card>
    </>
  )
}

export default AccessPlanManagement
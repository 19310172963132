import React from 'react'

// Vendor
import PuffLoader from "react-spinners/PuffLoader"

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import useTheme from 'reactor-ui/hooks/useTheme'


const Loader = ({
  children,
  childSx,
  wrapperSx,
  flexDirection = 'row',
  ...props
}) => {
  const theme = useTheme()

  const loader = <PuffLoader {...props} color={theme.colors.brand[500]} />

  if (children) {
    return (
      <Flex alignItems='center' flexDirection={flexDirection} sx={wrapperSx}>
        {loader}
        {childSx ? <Box sx={childSx}>{children}</Box> : <Box ml={2} sx={{
          fontSize: 'lg',
          color: 'brand.500'
        }}>{children}</Box>}
      </Flex>
    )
  } else {
    return loader
  }
}

export default Loader
import React from 'react'

// Vendor
import SVG from 'react-inlinesvg'

// ReactorUi
import { chakra, useTheme } from "@chakra-ui/react"
import { Flex, Box } from 'reactor-ui'

const Svg = chakra(SVG)



const Icon = React.forwardRef(({
  name,
  url,
  icon,
  color = 'currentColor',
  size,
  children,
  flexDirection = 'row',
  sx,
  childSx,
  wrapperSx,
  inline,
  ...rest
}, ref) => {
  const theme = useTheme()
  const baseAssetUrl = theme.config?.baseAssetUrl || '/public'

  const iconContent = (name || icon || url) ? (
    <Svg
      ref={ref}
      src={url || `${baseAssetUrl}/svg/${name || icon}.svg`}
      // style={{}}
      width={size || '16px'}
      height={size || '16px'}
      flex='0 0 auto'
      sx={{
        display: inline ? 'inline-block' : 'block',
        ...sx,
        verticalAlign: 'middle',
      }}
      fill={color}
      {...rest}
    >
    </Svg>
  ) : null

  if (children) {
    return (
      <Flex alignItems='center' flexDirection={flexDirection} sx={wrapperSx}>
        {iconContent}
        {childSx ? <Box sx={childSx}>{children}</Box> : <Box ml={2}>{children}</Box>}
      </Flex>
    )
  } else {
    if (wrapperSx) {
      return (
        <Box sx={wrapperSx}>
          {iconContent}
        </Box>
      )
    }
    return iconContent
  }
})

export default Icon
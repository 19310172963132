import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import Recharts from 'reactor-ui/components/Recharts'
import useWidget from 'reactor-vera/apps/dashboard/hooks/useWidget'


const PacPassStats = ({
  sx
}) => {
  const { filterValues } = useWidget({
    id: 'passStats',
    filters: [
      {
        id: 'accessDatetimeRange',
        kind: 'DateTime'
      },
      {
        id: 'rnxDevice',
        kind: 'Group'
      },
    ]
  })

  const filters = {}
  filterValues.rnxDevice && Object.entries(filterValues.rnxDevice).forEach(([k,v]) => {
    filters[`rnxPacAccessPoint.${k}`] = v
  })

  const result = RV.useReactorQuery({
    'rnxPacAccessRecordMeta': {
      fields: [
        'totalPassCount',
        'passCountsByProviderKind',
      ],
      filters: {
        datetimeAccess: filterValues.accessDatetimeRange,
        ...filters
      }
    }
  })

  const meta = result.graph?.rnxPacAccessRecordMeta
  if (!meta) return null

  const idCount = meta.passCountsByProviderKind?.find(x => x.provider_kind === 'ID_CARD')?.count || 0
  const rfCount = meta.passCountsByProviderKind?.find(x => x.provider_kind === 'RF_CARD')?.count || 0
  const qrCount = meta.passCountsByProviderKind?.find(x => x.provider_kind === 'QR_CODE')?.count || 0

  const pieData = [
    { name: 'T.C Kimlik Kartı', value: idCount },
    { name: 'Temassız Kart', value: rfCount },
    { name: 'QR Kod', value: qrCount },
  ]

  return (
    <Box sx={sx}>
      <RV.Card.Header mb={2}>
        <RV.RefreshButton result={result}>
          Geçiş
        </RV.RefreshButton>
      </RV.Card.Header>
      <Flex sx={{
        // height: 300,
        mx: -5
      }}>
        <RV.Card sx={{ flex: '1 1 0', mx: 5 }}>
        {meta.totalPassCount > 0 && <Recharts.Donut
            width={200}
            height={200}
            data={pieData} />}
        </RV.Card>
        <Box sx={{ flex: '1 1 0', mx: 5, my: -5 }}>
          <Flex alignItems='center' flexWrap='wrap' mx={-5} >
            <RV.StatBox maxWidth='50%' sx={{p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={0} label='Toplam' value={meta.totalPassCount} info='Toplam kişi sayısı' />

            <RV.StatBox maxWidth='50%' sx={{p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={1} label='T.C Kimlik Kartı' value={idCount} info='Toplam T.C. Kimlik Kartı kişi sayısı' />
            <RV.StatBox maxWidth='50%' sx={{p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={2} label='Temassız Kart' value={rfCount} info='Toplam Temassız Kart kişi sayısı' />
            <RV.StatBox maxWidth='50%' sx={{p: 5, mx: 5, flex: '1 1 40%', my: 5 }} colorDx={3} label='QR Kod' value={qrCount} info='Toplam QR Kod kişi sayısı' />
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default PacPassStats
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// ReactorUI
import { Box, Flex } from 'reactor-ui'
import { Checkbox, CheckboxGroup } from 'reactor-ui/components/Checkbox'
import { HStack } from 'reactor-ui/components/Stack'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const getValue = (inputValue) => {
  const value = []
  if (inputValue) {
    Object.entries(inputValue).forEach(([k, v]) => {
      if(v) value.push(k)
    })
  }
  return value
}

const RfCheckboxMapField = ({
  name,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })
  const [value, setValue] = React.useState(() => getValue(input.value))
  React.useEffect(() => {
    const value = getValue(input.value)
    setValue(value)
  }, [input.value])

  return (
    <>
      <Box mb={1} fontSize='sm' textTransform='capitalize'>
      {t(field.label)}
      </Box>
      <CheckboxGroup onChange={(values) => {
        const newVal = {}
        values?.forEach(v => {
          newVal[v] = true
        })
        input.onChange(newVal)
      }} colorScheme="brand" defaultValue={value}>
        {/* <HStack wrap='wrap' justify='space-between' shouldWrapChildren spacing={2}> */}
          {field.options.options.map((option, dx) => {
            return (
              <Box key={dx}>
              <Checkbox my={1} value={option.value}>{t(option.label)}</Checkbox>
              </Box>
            )
          })}
        {/* </HStack> */}
      </CheckboxGroup>
      <RfFieldAlert meta={meta}/>
      <RfFieldDescription field={field} position='default'/>
    </>
  )
}

export default RfCheckboxMapField
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import ActivityGroupProgramCalendar from 'elements/rnx_activity_group//rnxActivityGroup/components/ActivityGroupProgramCalendar'



const PropertyActivityGroups = ({
  rnxProperty,
  sx
}) => {
  const result = RV.useReactorQuery({
    rnxActivityGroupList: {
      // fields: [
      //   'activitySchedules'
      // ],
      filters: {
        owner_entity_key: rnxProperty.id
      }
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ rnxActivityGroupList }) => (
        <RV.Card sx={sx}>
          <RV.Card.HeaderArea>
            <RV.Card.Header>
              Aktivite Grupları
            </RV.Card.Header>

            <RV.ActionGroupMenu
              name={'RnxProperty'} contexts={['Entity.ActivityGroup']} ctx={{ id: rnxProperty.id }}
            />
          </RV.Card.HeaderArea>

          <RV.Alert status='info' my={2}>
            Tesisdeki program tanımlanan cihazlardan sadece seçilen programlarda randevusu bulunan kullanıcılar giriş yapabilir.
          </RV.Alert>

          {(rnxActivityGroupList.items.length === 0) && (
            <Box>
              Aktivite grubu yok.
            </Box>
          )}

          {rnxActivityGroupList.items.map(item => {
            return (
              <Box key={item.id} my={2}>
                <RV.Layout.Row>
                  <Box flex='0 0 30%'>
                    <RV.Icon name='calendar'>
                      <RV.Link to={`./actg/${item.id}`}>{item.name}</RV.Link>
                    </RV.Icon>
                  </Box>
                  <Box>
                    <RV.Enum value={item.kind} />
                  </Box>
                  <Box>
                    <RV.Enum value={item.status} />
                  </Box>
                  <RV.ActionGroupMenu
                    name={'RnxActivityGroup'} contexts={['Entity.Property']} ctx={{ id: item.id, rnxPropertyId: rnxProperty.id }}
                  />
                </RV.Layout.Row>
              </Box>
            )
          })}
        </RV.Card>
      )}
    </RV.QueryLoading>
  )
}

const PropertyActivityGroupDetail = ({

}) => {
  return (
    <RV.EntityDetailPage
      modelName='RnxActivityGroup'
      hideDetailOnSubRoutes
      fields={[
        'name', 'kind', 'status', 'capacity'
      ]}
      columns={[
        'name', 'kind', 'status', 'capacity'
      ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/' element={<ProgramActivityGroupDetailHome entity={entity} />} />
        ),

        <RV.Route path='p/:rnxActivityGroupProgramId/*' element={<PropertyActivityGroupProgramDetail />} />
      ]}
    />
  )
}

const ProgramActivityGroupDetailHome = ({
  entity
}) => {
  let tabs = []

  if (entity.kind.value === 'RESOURCE_PURCHASE') {
    tabs = [
      {
        name: 'Durum',
        codeName: 'status',
        // element: <Members rnxMembershipProgram={rnxMembershipProgram} />
        element: <ResourcePurchaseActivityGroupStatus rnxActivityGroup={entity} />
      },
    ]
  } else if (entity.kind.value === 'PROGRAM_SUBSCRIPTION') {
    tabs = [
      {
        name: 'Durum',
        codeName: 'status',
        // element: <Members rnxMembershipProgram={rnxMembershipProgram} />
        element: <ProgramSubscriptionActivityGroupStatus rnxActivityGroup={entity} />
      },

      // {
      //   name: 'Başvurular',
      //   codeName: 'applications',
      //   // element: <Applications rnxMembershipProgram={rnxMembershipProgram} />
      //   element: <div>a</div>
      // },

      // {
      //   name: 'Üyelik Türleri',
      //   codeName: 'subprograms',
      //   // element: <SubPrograms rnxMembershipProgram={rnxMembershipProgram} />
      //   element: <div>a</div>
      // },

      // {
      //   name: 'Ayarlar',
      //   codeName: 'settings',
      //   // element: <ProgramSettings rnxMembershipProgram={rnxMembershipProgram} />
      //   element: <div>a</div>
      // },
    ]
  }  else if (entity.kind.value === 'ACTIVITY_RESERVATION') {
    tabs = [
      {
        name: 'Durum',
        codeName: 'status',
        // element: <Members rnxMembershipProgram={rnxMembershipProgram} />
        element: <ProgramSubscriptionActivityGroupStatus rnxActivityGroup={entity} />
      },

      // {
      //   name: 'Başvurular',
      //   codeName: 'applications',
      //   // element: <Applications rnxMembershipProgram={rnxMembershipProgram} />
      //   element: <div>a</div>
      // },

      // {
      //   name: 'Üyelik Türleri',
      //   codeName: 'subprograms',
      //   // element: <SubPrograms rnxMembershipProgram={rnxMembershipProgram} />
      //   element: <div>a</div>
      // },

      // {
      //   name: 'Ayarlar',
      //   codeName: 'settings',
      //   // element: <ProgramSettings rnxMembershipProgram={rnxMembershipProgram} />
      //   element: <div>a</div>
      // },
    ]
  }
  return (
    <Box>
      {tabs.length > 0 && <RV.BareTabs
        // isFitted
        isLazy
        tabs={tabs}
      />}
    </Box>
  )
}

const ProgramSubscriptionActivityGroupStatus = ({
  rnxActivityGroup
}) => {
  console.log(rnxActivityGroup)
  return (
    <>
      <RV.Layout.Cols>
        <Box>
          <RV.GraphTable
            modelName='RnxActivityGroupProgram'
            detailLink='p'
            actionCtx={{
              model: {
                rnx_activity_group_id: rnxActivityGroup.id,
              }
            }}
            query={{
              // itemFields: ['name', 'email', 'rcUserProfile'],
              // columns: ['name', 'email', 'rcUserProfile'],
              filters: {
                rnx_activity_group: rnxActivityGroup.id
              }
            }}
          />
          <Box my={4} />
          <RV.GraphTable
            modelName='RnxActivityGroupActivityKind'
            detailLink='k'
            actionCtx={{
              model: {
                rnx_activity_group_id: rnxActivityGroup.id,
              }
            }}
            query={{
              // itemFields: ['name', 'email', 'rcUserProfile'],
              // columns: ['name', 'email', 'rcUserProfile'],
              filters: {
                rnx_activity_group: rnxActivityGroup.id
              }
            }}
          />
        </Box>

        <Flex alignItems='center' flexWrap='wrap' mx={-2} my={-2} >
          <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 2, flex: '1 1 40%', my: 2 }} colorDx={0} label='İçeride Kişi' value={0} info='Toplam tesisteki kişi sayısı' />
          <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 2, flex: '1 1 40%', my: 2 }} colorDx={0} label='Bugün Kişi' value={0} info='Bugün toplam tesiste gelen kişi sayısı' />
          <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 2, flex: '1 1 40%', my: 2 }} colorDx={0} label='Bu Hafta Kişi' value={0} info='Bu Hafta toplam tesiste gelen kişi sayısı' />
          <RV.StatBox maxWidth='50%' sx={{ p: 5, mx: 2, flex: '1 1 40%', my: 2 }} colorDx={0} label='Bu Ay Kişi' value={0} info='Bu Ay toplam tesiste gelen kişi sayısı' />
        </Flex>
      </RV.Layout.Cols>
    </>
  )
}

const PropertyActivityGroupProgramDetail = ({
  rnxActivityGroupProgram
}) => {
  return (
    <RV.EntityDetailPage
      modelName='RnxActivityGroupProgram'
      // fields={[
      //   'name', 'kind', 'status', 'capacity'
      // ]}
      // columns={[
      //   'name', 'kind', 'status', 'capacity'
      // ]}
      routes={[
        (entity, result) => (
          <RV.Route path='/' element={(
            <Box>
              <RV.Layout.Row sx={{
                my: 4,
                alignItems: 'center'
              }}>
                <RV.Text.Header>
                  Takvim
                </RV.Text.Header>
                <RV.ActionGroup name={'RnxActivityGroupProgramActivity'} contexts={['Model']} ctx={{ rnx_activity_group_program_id: entity.id }} />
              </RV.Layout.Row>
              <ActivityGroupProgramCalendar rnxActivityGroupProgramId={entity.id} />
            </Box>
          )} />
        )
      ]}
    />
  )
}

const ResourcePurchaseActivityGroupStatus = ({
  rnxActivityGroup
}) => {
  return (
    <RV.Layout.Col3 colSize={400}>
      <Box>
        <RV.GraphTable
          modelName='RnxActivityGroupActivityKind'
          detailLink='k'
          actionCtx={{
            model: {
              rnx_activity_group_id: rnxActivityGroup.id,
            }
          }}
          query={{
            // itemFields: ['name', 'email', 'rcUserProfile'],
            // columns: ['name', 'email', 'rcUserProfile'],
            filters: {
              rnx_activity_group: rnxActivityGroup.id
            }
          }}
        />
      </Box>
      <RV.GraphTable
        modelName='RnxActivityGroupStandaloneActivity'
        detailLink='p'
        actionCtx={{
          model: {
            rnx_activity_group_id: rnxActivityGroup.id,
          }
        }}
        query={{
          // itemFields: ['name', 'email', 'rcUserProfile'],
          // columns: ['name', 'email', 'rcUserProfile'],
          filters: {
            rnx_activity_group: rnxActivityGroup.id
          }
        }}
      />
    </RV.Layout.Col3 >
  )
}

PropertyActivityGroups.routes = [
  <RV.Route path='actg/:rnxActivityGroupId/*' element={<PropertyActivityGroupDetail />} key='detail' />
]

export default PropertyActivityGroups
import changeCase from 'change-case'
export * from 'change-case'

export const lowerCaseFirst = str => str.charAt(0).toLowerCase() + str.substr(1)
export const camel = changeCase.camelCase
export const pascal = changeCase.pascalCase
export const title = changeCase.title
export const snake = changeCase.snakeCase


const SUPPORTED_LOCALE = {
  tr: {
    regexp: /[\u0069]/g,
    map: {
      i: "\u0130"
    }
  },
  az: {
    regexp: /[\u0069]/g,
    map: {
      i: "\u0130"
    }
  },
  lt: {
    regexp: /[\u0069\u006A\u012F]\u0307|\u0069\u0307[\u0300\u0301\u0303]/g,
    map: {
      i̇: "\u0049",
      j̇: "\u004A",
      į̇: "\u012E",
      i̇̀: "\u00CC",
      i̇́: "\u00CD",
      i̇̃: "\u0128"
    }
  }
};

export const localeUpperCase = (str, locale) => {
  const lang = SUPPORTED_LOCALE[locale.toLowerCase()]
  if (lang) return upperCase(str.replace(lang.regexp, m => lang.map[m]))
  return upperCase(str)
}

export const upperCase = (str) => {
  return str.toUpperCase()
}

export const truncateOnWord = (str, limit) => {
  var trimmable = '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
  var reg = new RegExp('(?=[' + trimmable + '])');
  var words = str.split(reg);
  var count = 0;
  let result = words.filter(function (word) {
    count += word.length;
    return count <= limit;
  }).join('');
  if (result.length != str.length) result += '...'
  return result
}
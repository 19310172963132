import React from 'react'

// Reactor UI
import { Box, Flex } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Text from 'reactor-ui/components/Text'
import Button from 'reactor-ui/components/Button'
import Portal from 'reactor-ui/components/Portal'
import ReactorModal from 'reactor-ui/components/Modal'
import { Menu, MenuButton, MenuList, MenuItem } from 'reactor-ui/components/Menu'

import { Link, useNavigate } from 'reactor-vera/router'
import { getInitials } from 'reactor-ui/util'

import UserProfile from 'reactor-vera/apps/user/pages/UserProfile'

// Reactor
import useResourceQuery from 'reactor/hooks/useResourceQuery'

// ReactorUi
import useLocalStorage from 'reactor-ui/hooks/useLocalStorage'


const SidebarUser = () => {
  const { rcUser } = useResourceQuery('rcApp.state.status')
  if (!rcUser) return null

  return <UserIcon name={rcUser.name} />
}


const UserIcon = React.forwardRef(({ name, ...rest }, ref) => {
  const navigate = useNavigate()

  return (
    <Menu>
      <MenuButton as={Button} size='sm' colorScheme='bg'
      // rightIcon={<ChevronDownIcon />}
      >
        <Flex alignItems={'center'} sx={{
          fontSize: 'xs',
        }}>
          <Box color='brandLight.500'>
            {getInitials(name)}
          </Box>
          <Icon name='chevron-down' size={'12px'} color='brandLight.500' sx={{
            ml: 1
          }} />
        </Flex>
      </MenuButton>
      <Portal>
        <Box color='brand.500'>
          <MenuList>
            <ReactorModal
              size='3xl'
              autoFocus={false}
              trapFocus={false}
              // returnFocusOnClose={false}
              anchor={(
                <MenuItem sx={{
                  textTransform: 'capitalize',
                }}>
                  <Icon name='user'>
                    <Text.Translated>
                      label.user_profile
                    </Text.Translated>
                  </Icon>
                </MenuItem>
              )}
            >
              {({ isOpen }) => {
                if (!isOpen) return null
                return <UserProfile />
              }}
            </ReactorModal>
            <MenuItem sx={{
              textTransform: 'capitalize',
            }} onClick={() => navigate('/auth/logout')}>
              <Icon name='log-out'>
                <Text.Translated>
                  op.logout
                </Text.Translated>
              </Icon>
            </MenuItem>
            <Tenant />
          </MenuList>
        </Box>
      </Portal>
    </Menu>
  )
})

const Tenant = ({

}) => {
  const { rcTenant } = useResourceQuery('rcApp.state.tenant_status', null, { mode: 'CACHE_ONLY' })
  const result = useResourceQuery('rcApp.user.tenants')
  // if (!rcTenant) return null
  console.log('tenants', result)
  if (!rcTenant) return null

  return (
    <Box m={2}>
      <Box sx={{
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: 'sm',
        color: 'dark.500',
      }}>
        <Text.Translated>
          label.change_account
        </Text.Translated>
      </Box>

      <Box sx={{
        color: 'dark.500',
        ml: 3,
        mt: 2
      }}>
        {rcTenant.name}
      </Box>

      {result?.filter(x => x.id != rcTenant.id).map(tenant => {
        return (
          <MenuItem as={Link} key={tenant.id} to={`/${tenant.id}`}>
            {/* <Link to={`/${tenant.id}`}> */}
            {tenant.name}
            {/* </Link> */}
          </MenuItem>
        )
      })}
    </Box>
  )
}

export default SidebarUser
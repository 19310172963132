import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


export const Col3 = ({
  children,
  colSize = 300,
  gap = 4,
  sx
}) => {
  return (
    <Flex sx={sx}>
      {React.Children.map(children, (c, dx) => {
        const rem = dx % 3
        let sx
        if (rem === 0 || rem === 2) {
          sx = {
            ...sx,
            flex: `0 0 ${colSize}px`,
            maxWidth: colSize
          }
        } else {
          sx = {
            ...sx,
            flex: `1 1 0`,
            mx: gap,
            maxWidth: `calc(100% - ${React.Children.count(children) === 3 ? colSize * 2 + gap * 2 : colSize + gap * 2}px)`
          }
        }
        return (
          <Box sx={{
            ...sx
          }} key={dx}>
            {c}
          </Box>
        )
      })}
    </Flex>
  )
}

export const Cols = ({
  children,
  gap = 4,
  sx
}) => {
  const count = React.Children.count(children)
  return (
    <Flex sx={{
      mx: -gap,
      ...sx
    }}>
      {React.Children.map(children, (c, dx) => {
        let sx = {
          flex: `1 1 0`,
          maxWidth: [null, null, null, `${100/React.Children.count(children)}%`],
          mx: gap
        }
        return (
          <Box sx={{
            ...sx
          }} key={dx}>
            {c}
          </Box>
        )
      })}
    </Flex>
  )
}

export const Row = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Flex sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      ...sx
    }} {...rest}>
      {children}
    </Flex>
  )
}

export default {
  Col3,
  Cols,
  Row
}
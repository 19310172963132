import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

// import { Menu, MenuItem } from 'volcano/components/Menu'
import { LinearProgress } from 'reactor-ui/components/Progress'


import useFileUpload from 'reactor-vera/hooks/useFileUpload'


const UploadMedia = ({
  onSuccess
}) => {
  return (
    <RV.RfFileUploadForm
      actionKey='RnxBroadcast.UploadRnxBroadcastMedia'
      field={{
        name: 'image',
        label: 'Görsel',
        kind: 'file',
        options: {
          acceptedKinds: 'image/*,video/*',
          isMultiple: true
        }
      }}

      onSuccess={onSuccess}
    />
  )
  // const [openFileDialog, state, retryHandler, uploadResult] = useFileUpload({
  //   acceptedFileKinds: 'image/*,video/*',
  //   multiple: true,
  //   elementName: 'RnxBroadcast',
  //   actionName: 'UploadRnxBroadcastMedia',
  //   callbacks: {
  //     success: (response) => {
  //       onSuccess?.()
  //     }
  //   }
  // })

  // return (
  //   <Flex>
  //     <Button sx={{ mx: 2 }} onClick={openFileDialog}>
  //       Medya Yükle
  //     </Button>

  //     <UploadState state={state} retryHandler={retryHandler} />
  //   </Flex>
  // )
}

const UploadState = ({
  state,
  retryHandler
}) => {
  const menuRef = React.useRef()
  // console.log('--', state)

  const hasUpload = state.uploadingFiles.length > 0
  const hasError = state.errorFiles.length > 0
  const hasSuccess = state.uploadedFiles.length > 0
  const total = state.uploadingFiles.length + state.errorFiles.length + state.uploadedFiles.length

  let color
  if (hasError) color = 'warning'
  else if (hasUpload) color = 'primary'
  else if (hasSuccess) color = 'success'

  React.useEffect(() => {
    menuRef.current?.open()
  }, [total])

  if (!hasUpload && !hasError && !hasSuccess) return null

  return (
    <Box ref={menuRef} isDefaultOpen={true} anchor={(
      <Button sx={{ mx: 2 }} color={color} iconName='square-upload'></Button>
    )} placement='bottom-start'>
      <Box sx={{
        p: 3
      }}>
        <>
          {state.uploadedFiles.length > 0 && (
            <Box>
              <Box sx={{ fontWeight: 'semiBold', textTransform: 'uppercase', fontSize: 's' }}>Yüklendi</Box>
              {state.uploadedFiles.map((file, dx) => (
                <Flex key={file.id} p={1} my={2} sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                  <Box>
                    <RV.Icon name='square-upload' color='success' mr={2} />{file.name}
                  </Box>
                  {/* <Box>
                <ButtonIcon iconName={'reload'} variant='flat' onClick={() => retryHandler(file)} />
              </Box> */}
                </Flex>
              ))}
            </Box>
          )}

          {state.uploadingFiles.length > 0 && (
            <Box>
              <Box sx={{ fontWeight: 'semiBold', textTransform: 'uppercase', fontSize: 's' }}>Yükleniyor</Box>
              {state.uploadingFiles.map((file, dx) => (
                <Flex key={file.id} p={1} my={2} sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                  <Box>
                    <Box>
                      <RV.Icon name='square-upload' color='primary' mr={2} />{file.name}
                    </Box>
                    <LinearProgress progress={file?.progress || 0} sx={{ my: 2 }} />
                  </Box>
                  {/* <Box>
                <ButtonIcon iconName={'reload'} variant='flat' onClick={() => retryHandler(file)} />
              </Box> */}
                </Flex>
              ))}
            </Box>
          )}

          {state.errorFiles.length > 0 && (
            <Box>
              <Box sx={{ fontWeight: 'semiBold', textTransform: 'uppercase', fontSize: 's' }}>Hata</Box>
              {state.errorFiles.map((file, dx) => (
                <Flex key={file.id} p={1} my={2} sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                  <Box>
                    <RV.Icon name='triangle-empty-alert' color='danger' mr={2} />{file.name}
                  </Box>
                  <Box>
                    <Button iconName={'reload'} onClick={() => retryHandler(file)} />
                  </Box>
                </Flex>
              ))}
            </Box>
          )}
        </>
      </Box>
    </Box>
  )
}

export default UploadMedia
import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import { Flex, Box } from 'reactor-ui'

import SidebarMenu from './sidebar/SidebarMenu'
import SidebarFooter from './sidebar/SidebarFooter'
import { SidebarWrapper } from './sidebar/SidebarComponents'


const DefaultThemeSidebar = ({
  baseUrl
}) => {
  const layoutConfig = useSelector(state => state.reactorVera.config.layout)

  const activeZone = useSelector(state => state.reactorVera.zones[state.reactorVera.activeZoneName])
  const activeDomainName = useSelector(state => state.reactorVera.activeDomainName)
  const activeDomain = activeZone?.domains.find(domain => domain.name == activeDomainName)

  // const DomainMenu = activeDomain && reactor.directory.reactorVera.menuHandlers?.[activeDomain.kind] || RunicDomainSidebarMenu

  if (!activeZone || !activeDomain) return null

  const baseUrlFix = baseUrl[baseUrl.length - 1] == '/' ? baseUrl : `${baseUrl}/`
  const rootUrl = `${baseUrlFix}${activeZone.path}/${activeDomain.path}`

  return (
    <SidebarWrapper variant={layoutConfig.sidebar?.extended && 'extended'}>
      <SidebarMenu domain={activeDomain} baseUrl={rootUrl} rootUrl={baseUrl}/>
      <SidebarFooter/>
    </SidebarWrapper>
  )
}



export default DefaultThemeSidebar
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


const InfoLine = ({
  label,
  value,
  children,
  vertical,
  distinct
}) => {
  return (
    <Flex sx={{
      my: 1,
      alignItems: vertical ? '' : 'center',
      flexDirection: vertical ? 'column' : 'row'
    }}>
      <Box sx={{
        color: distinct ? 'brand.500' : 'dark.300',
        mr: 4,
        fontWeight: 'semibold',
        fontSize: !distinct && 'sm'
      }}>
        {label}
      </Box>

      <Box sx={{
        // color: 'dark.500'
      }}>
        {value || children}
      </Box>
    </Flex>
  )
}

export default InfoLine
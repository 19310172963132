import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor Ui
import { Flex, Box } from "reactor-ui"
import Button from 'reactor-ui/components/Button'
import Card from 'reactor-ui/components/Card'

// Reactor
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import { useNavigate, useParams, Link } from 'reactor-vera/router'
import useReactor from 'reactor/hooks/useReactor'
import useResourceQuery from 'reactor/hooks/useResourceQuery'


const AuthenticationLogin = ({
  rcTenantId,
  loginKey
}) => {
  const params = useParams()
  const rcTenantCode = params?.rcTenantCode
  const status = useResourceQuery('rcApp.state.status')
  const navigate = useNavigate()
  const reactor = useReactor()

  const initialValues = React.useMemo(() => {
    const data = {}
    if (rcTenantId) data.rcTenantId = rcTenantId
    else if (rcTenantCode) data.rcTenantCode = rcTenantCode
    return data
  }, [rcTenantCode, rcTenantId])


  const [formProps, isSubmitting] = useReactorActionForm(
    'RcApp.LoginRcUser',
    {
      initialValues,
      onSuccess: () => window.location.reload()
    }
  )

  React.useEffect(() => {
    if (status?.rcUser) {
      navigate(`/${status.rcUser.rcTenantId}`)
    }
  }, [status?.rcUser])
  console.log(status)

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Box width={['90%', '50%', '50%', '50%', 400]} mt='20vh' pt='25px' mx='auto'>
        <Card>
          <Form {...formProps}>
            {/* {reactor.rConfig.logoPath && <Box sx={{ textAlign: 'center', mb: 3, mt: 3 }}><Box as='img' src={reactor.rConfig.logoPath} sx={{ width: '80%', mx: 'auto' }} /></Box>} */}
            {/* {reactor.rConfig.isMultiTenant && !rcTenantCode && <RfFormField name="rcTenantCode" label='Hesap' tabIndex={1} required />} */}
            <RfFormField name="email" label={loginKey || reactor?.rConfig?.loginKey || 'E-Posta'} tabIndex={1} schema={yup.string().min(4).required()} required autoFocus />
            <RfFormField name="password" label='Şifre' kind='password' tabIndex={2} required />
            <RfFormField name='rememberMe' label='Beni hatırla' kind='checkbox' tabIndex={3} optional/>
            <RfFormError error={formProps.error} />
            {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
            <Button colorScheme={'brand'} tabIndex={4} submit isLoading={isSubmitting} isFullWidth>
              GİRİŞ
            </Button>
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to='../forgot' tabIndex={5}>
                Şifremi Unuttum
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
  )
}

export default AuthenticationLogin

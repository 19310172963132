import RnxBroadcastManagement from './pages/RnxBroadcastManagement'
import BroadcastMediaManagement from './pages/BroadcastMediaManagement'


export default {
  name: 'RnxBroadcast',
  components: {
    'RnxBroadcastManagement': {
      component: RnxBroadcastManagement
    },
    'BroadcastMediaManagement': {
      component: BroadcastMediaManagement
    },
  }
}
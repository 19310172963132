import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useTable } from 'react-table'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import BareTabs from 'reactor-ui/components/BareTabs'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from 'reactor-ui/components/Table'

import { Link } from 'reactor-vera/router'



const rnxBroadcastManagement = props => {
  const { t } = useTranslation()
  return null
  return (
    <Routes>
      <Route path='/' element={<DeviceManagementHome />} />
      <Route path='broadcast/:rnxBroadcastId/*' element={<DeviceManagementDetail />} />
    </Routes>
  )
}

const DeviceManagementHome = props => {
  const { t } = useTranslation()
  const params = useParams()
  return (
    <>
      <GraphTable
        modelName='RnxBroadcast'
        detailLink='broadcast'
      />
    </>
  )
}

const DeviceManagementDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxBroadcast': {
      filters: {
        id: params.rnxBroadcastId
      }
    },
  })

  useBreadcrumb(`rnxBroadcastDetail:${result.graph?.rnxBroadcast.id}`, result.graph?.rnxBroadcast.name)

  const rnxBroadcast = result.graph?.rnxBroadcast
  const meta = result.graph?.rnxBroadcastMeta

  if (!rnxBroadcast) return null

  return (
    <>
      <EntityDetail
        icon={'book-open'}
        entity={rnxBroadcast}
        schema={result.schema?.rnxBroadcast}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
{/*
      <BareTabs
        isFitted
        isLazy
        tabs={[
          {
            name: 'Program',
            codeName: 'program',
            element: <ActivityProgram rnxBroadcast={rnxBroadcast} />
          },

          {
            name: 'Türler',
            codeName: 'kinds',
            element: <ActivityKinds rnxBroadcast={rnxBroadcast} />
          }
        ]} */}
      />

    </>
  )
}

const ActivityKinds = ({
  rnxBroadcast
}) => {
  return (
    <GraphTable
      modelName='rnxBroadcastActivityKind'
      // detailLink='program'
      actionCtx={{
        model: {
          rnx_program_id: rnxBroadcast.id
        }
      }}
      filters={{
        rnx_program_id: rnxBroadcast.id
      }}
    />
  )
}

const ActivityProgram = ({
  rnxBroadcast
}) => {
  const result = useReactorQuery({
    'rnxBroadcastActivityList': {
      filters: {
        '@dt': {
          field: 'datetime_start',
          value: 'currentWeek'
        }
      },
      order_by: 'datetime_start|asc'
    },
  })
  const rnxBroadcastActivityList = result.graph?.rnxBroadcastActivityList
  console.log('rnxBroadcastActivityList', rnxBroadcastActivityList)
  if (!rnxBroadcastActivityList) return null


  return <ActivityProgramContent rnxBroadcast={rnxBroadcast}/>
}

const ActivityProgramContent = ({
  rnxBroadcast
}) => {
  const today = dayjs().startOf('today')
  const start = today.hour(9).minute(0)
  const end = today.hour(23).minute(59)

  const interval = 15
  const length = 60

  const hours = []

  while (hours.length == 0 || (hours[hours.length - 1].end.add(interval + length, 'minute') < end)) {
    let prevEnd
    if (hours.length) {
      prevEnd = hours[hours.length - 1].end
    } else {
      prevEnd = start.subtract(interval, 'minute')
    }

    const nextStart = prevEnd.add(interval, 'minute')
    const nextEnd = nextStart.add(length, 'minute')

    const next = {
      start: nextStart,
      end: nextEnd,
      id: nextStart
    }
    hours.push(next)
  }
  console.log(hours.length, hours)

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: 'Saatler',
        id: 'hours',
        // renderer: (cell) => <ActionGroupMenu name={modelName} contexts={['Entity']} ctx={{ id: cell.row.id }} />,
        // alignLast: true,
      },
      {
        Header: 'Pazartesi',
        id: 1
      },
      {
        Header: 'Salı',
        id: 2
      },
      {
        Header: 'Çarşamba',
        id: 3
      },
      {
        Header: 'Perşembe',
        id: 4
      },
      {
        Header: 'Cuma',
        id: 5
      },
      {
        Header: 'Cumartesi',
        id: 6
      },
      {
        Header: 'Pazar',
        id: 7
      },
    ]
  }, [])

  // const tableRows = []
  const tableRows = hours

  const tableInstance = useTable({
    columns: tableColumns,
    data: tableRows,
    getRowId: (row) => row.id
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <Table {...getTableProps()} variant="striped" colorScheme="light" size='sm'>
      <Thead>
        {headerGroups.map(headerGroup => {
          return (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, dx) => {
                const { key, ...headerProps } = column.getHeaderProps()
                return (
                  <Th>
                    {column.Header}
                  </Th>
                )
                return (
                  <GraphTableColumnHeader isLast={dx === columnCount - 1} key={key} headerProps={headerProps} column={column} />
                )
              })}
            </Tr>
          )
        })}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, dx) => {
          prepareRow(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell, cellDx) => {
                const { key, cellProps } = cell.getCellProps()
                if (cell.column.id === 'hours') {
                  return (
                    <Td key={key}>
                      {row.original.start.format('HH:mm')} - {row.original.end.format('HH:mm')}
                    </Td>
                  )
                }
                return (<Td/>)
                return <GraphTableCell isLast={cellDx === columnCount - 1} key={key} cellProps={cellProps} cell={cell} />
              })}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default rnxBroadcastManagement
import BackgroundJobs from './pages/BackgroundJobs'


export default {
  name: 'RcBackgroundJob',
  components: {
    'BackgroundJobs': {
      component: BackgroundJobs
    },
  }
}
import produce from 'immer'

export const createRReducer = actionHandlers => (state, action) => (
  produce(state, draft => {
    // console.log(action.kind, action.payload)
    actionHandlers[action.kind](draft, action.payload === undefined ? {} : action.payload, state, action)
  })
)

export const dataFetchReducer = createRReducer({
  FETCH_INIT: draft => {
    draft.isLoading = true
    draft.isError = false
    draft.error = null
  },

  FETCH_SUCCESS: draft => {
    draft.isLoading = false
    draft.isError = false
    draft.isReady = true
    draft.error = null
    draft.filters = null
    draft.opts = null
  },

  FETCH_FAILURE: (draft, payload) => {
    draft.isLoading = false
    draft.isError = true
    draft.error = payload
  },

  REFRESH: (draft, payload) => {
    draft.key += 1
    if (payload.filters) draft.filters = payload.filters
    if (payload.opts) draft.opts = payload.opts
  }
})

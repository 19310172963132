import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import useLocationFilter from 'reactor-vera/hooks/useLocationFilter'
import Pagination from 'reactor-vera/apps/data/components/Pagination'
import FilterInput from 'reactor-vera/apps/data/components/Filters'


const DomainActivities = props => {
  const filterInstance = useLocationFilter('activity', {})
  const result = RV.useReactorQuery({
    'rcDomainActivityList': {
      itemFields: [
        'domain', 'kind', 'datetimeActivity', 'activityData', 'rcUser'
      ],
      filters: {
        ...filterInstance.locationFilters,
      },
      orderBy: 'datetime_activity|desc'
    }
  })
  const { t } = RV.useTranslation()

  return (
    <>
    <Flex sx={{
        alignItems: 'center',
        mb: 2,
        mx: -2,
        my: 4
      }}>
        <Box sx={{
          flex: '1 1 auto',
          mx: 2
        }}>
         <Flex sx={{
        alignItems: 'center',
        mb: 2,
        mx: -2,
        my: 4
      }}>
        <FilterInput label={'Başlangıç Tarih'} target={'datetimeActivity'} filterInstance={filterInstance} kind={'Date'}/>
        <FilterInput label={'Bitiş Tarih'} target={'datetimeActivity'} filterInstance={filterInstance} kind={'Date'}/>
        </Flex>
        </Box>

        <Pagination graph={result.graph?.rcDomainActivityList} filterInstance={filterInstance} />
      </Flex>
    <RV.QueryLoading result={result}>
      {({ rcDomainActivityList }) => {
        return (
          <RV.Card>
            {rcDomainActivityList.items.map(item => {
              return (
                <Box key={item.id}>
                  <Flex alignItems='center' my={2}>
                    <RV.Icon name='asterisk' sx={{ mr: 2 }} color='brand.500'/>
                    <Box sx={{
                      fontWeight: 'bold',
                      color: 'dark.300'
                    }}>
                      <RV.DateTime data={item.datetimeActivity} time />
                    </Box>
                    <Box sx={{
                      ml: 2,
                      fontWeight: 'bold'
                    }}>
                    {t(`event.label.${item.kind.value}`)}
                    </Box>

                    {item.rcUser && (
                      <RV.Icon name='user' sx={{ml: 4}} color='brand.500'>
                        {item.rcUser.name}
                      </RV.Icon>
                    )}
                  </Flex>

                  <Box sx={{
                    fontSize: 'sm',
                    ml: 7
                  }}>
                  {t(`event.text.${item.kind.value}`, { ...item.activityData })}
                  </Box>
                </Box>
              )
            })}
          </RV.Card>
        )
      }}
    </RV.QueryLoading>
    </>
  )
}

export default DomainActivities
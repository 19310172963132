
import React from 'react'

// Vendor
import { useSelector } from 'react-redux'

// RunicAura
import { useNavigate, useLocation } from 'reactor-vera/router'


const ReactorVeraRouteManager = ({
  tenantId
}) => {
  const zones = useSelector(state => state.reactorVera.zones)
  const defaultZone = useSelector(state => state.reactorVera.defaultZone)
  const navigate = useNavigate()
  const location = useLocation()
  React.useEffect(() => {
    const routeParts = location.pathname.split('/')

    if ((routeParts.length == 2) || (routeParts[2].length == 0)) {
      // Tenant Index Page
      if (Object.keys(zones).length == 1 && zones[defaultZone]) {
        navigate(`${zones[defaultZone].indexPath || ''}/`)
      } else if (zones[defaultZone]) {
        navigate(`${zones[defaultZone].indexPath || ''}/`)
      } else {
        console.log('INVALID_ZONE', zones, defaultZone)
      }
    } else if ((routeParts.length == 4) || (routeParts[3].length == 0)) {
      // Zone Index Page
      const zonePath = routeParts[2]
      let selectedZone = null

      Object.values(zones).forEach(_zone => {
        if (_zone.path == zonePath && _zone.domains.length > 0) selectedZone = _zone
      })
      // console.log(selectedZone)
      // return
      if (selectedZone) navigate(`${selectedZone.path}/${selectedZone.domains[0].indexPath || ''}/`)
    }
  }, [tenantId])

  return (null)
}

export default ReactorVeraRouteManager

import Properties from './pages/Properties'
import Home from './pages/Home'
import PropertyContent from './pages/PropertyContent'


export default {
  name: 'RnxProperty',
  components: {
    'Properties': {
      component: Properties
    },
    'Home': {
      component: Home
    },
    'PropertyContent': {
      component: PropertyContent
    },
  }
}
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'
import Alert from 'reactor-ui/components/Alert'
import Icon from 'reactor-ui/components/Icon'
import useOnClickOutside from 'reactor-ui/hooks/useOnClickOutside'

// Reactor
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import { isFunction } from 'lodash'


const RfSingleLineForm = ({
  formId,
  actionKey,
  initialValues,

  onSuccess,
  onError,
  onClose,

  fields,
  anchorKind,
  anchor,

  icon = 'pencil',
  sx,
  isResetEnabled = true,
  fieldElements,

  children,
  ...rest
}) => {
  const { t } = useTranslation()
  const hasUpload = React.useMemo(() => {
    let hasUpload = false
    fields?.forEach(field => {
      if (field.kind === 'file') hasUpload = true
    })
    return hasUpload
  }, [])
  const [formProps, isSubmitting, reset, submit] = useReactorActionForm(
    actionKey,
    {
      hasUpload,
      initialValues,
      onSuccess: (data, {reset}) => {
        onSuccess?.(data)
        reset()
      },
      onError,
      id: formId
    }
  )
  const formRef = React.useRef()
  // console.log(formProps)
  // const close = () => {
  //   submit()
  // }
  // useOnClickOutside(formRef, close, mode === 'EDIT')

  let content
  const isSingleFileField = fields.length === 1 && fields[0].kind === 'file'

  // if (formProps.phase === 'SUCCESS') {

  content = (
    <Box ref={formRef}>
      <Form {...formProps}>
        {fields?.map(({ label, optional, ...field }, dx) => {
          if (isSingleFileField) field.onChange = submit
          return (
            <RfFormField disabled={isSubmitting} sx={{ textTransform: 'capitalize' }} required={!optional} optional={optional} label={t(label)} {...field} key={dx} />
          )
        })}
        <RfFormError error={formProps.error} />
        {!isSingleFileField && <Button mt={3} textTransform='capitalize' colorScheme={'brand'} tabIndex={4} submit isLoading={isSubmitting} isFullWidth>
          {t('btn.update')}
        </Button>}
      </Form>
    </Box>
  )

  return (
    <Box sx={sx}>
      {content}
    </Box>
  )
}

export default RfSingleLineForm
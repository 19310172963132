import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// Reactor UI
import { Box, Flex } from 'reactor-ui'

import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'

import validate from '../util/fieldValidator'

import TimeIntervalInput from 'reactor-ui/components/TimeIntervalInput'
import RfFormRow from './RfFormRow'
import InputLabel from 'reactor-ui/components/InputLabel'


const RfTimeRangeField = ({
  field
}) => {
  const { t } = useTranslation()
  const startField = useField(`${field.name}.start`, {
    validate: (value) => validate(field, value),
    format: field.format
  })

  const endField = useField(`${field.name}.end`, {
    validate: (value) => validate(field, value),
    format: field.format
  })
  // console.log(field.options)
  // return null
  return (
    <Box sx={{
      position: 'relative'
    }}>
    <InputLabel variant='active'>
    {t(field.label)}
    </InputLabel>
    <RfFormRow>
      <TimeIntervalInput {...field.options} {...startField.input}  minWidth={'auto'}/>
      <TimeIntervalInput {...field.options} {...endField.input}  minWidth={'auto'}/>
    </RfFormRow>
    </Box>
  )
}

export default RfTimeRangeField
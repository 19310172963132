import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const PropertyContent = props => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    'rnxProperty': {
      fields: [
        'name', 'logo', 'primaryImage', 'images', 'description', 'calendarImage'
      ],
      filters: {
        id: params.rnxPropertyId
      }
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ rnxProperty }) => {
        console.log(rnxProperty)
        return (
          <>
            <RV.Card>
              <RV.Card.Header>
                Logo
              </RV.Card.Header>

              <Box as='img' src={rnxProperty.logo?.url} sx={{
                // width: '100%',
                // height: '100%'
                objectFit: 'cover',
                maxWidth: '200px'
              }} />

              <RV.RfSingleLineForm
                actionKey='RnxProperty.RnxPropertyUpdateLogo'
                formId={`RnxProperty.RnxPropertyUpdateLogo`}
                onSuccess={result.refresh}
                initialValues={{
                  id: rnxProperty.id,
                }}
                fields={[
                  {
                    name: 'logo',
                    label: 'Fotoğraf',
                    kind: 'file',
                    options: {
                      acceptedKinds: '.jpg,.png,.jpeg'
                    }
                  }
                ]}
              />
            </RV.Card>

            <RV.Card mt={4}>
              <RV.Card.Header>
                Ana Görsel
              </RV.Card.Header>

              <Box as='img' src={rnxProperty.primaryImage?.url} sx={{
                // width: '100%',
                // height: '100%'
                objectFit: 'cover',
                maxWidth: '500px'
              }} />

              <RV.RfSingleLineForm
                actionKey='RnxProperty.RnxPropertyUpdatePrimaryImage'
                formId={`RnxProperty.RnxPropertyUpdatePrimaryImage`}
                onSuccess={result.refresh}
                initialValues={{
                  id: rnxProperty.id,
                }}
                fields={[
                  {
                    name: 'image',
                    label: 'Fotoğraf',
                    kind: 'file',
                    options: {
                      acceptedKinds: '.jpg,.png,.jpeg'
                    }
                  }
                ]}
              />
            </RV.Card>

            <RV.Card mt={4}>
              <RV.Card.Header>
                Takvim Görsel
              </RV.Card.Header>

              <Box as='img' src={rnxProperty.calendarImage?.url} sx={{
                // width: '100%',
                // height: '100%'
                objectFit: 'cover',
                maxWidth: '500px'
              }} />

              <RV.RfSingleLineForm
                actionKey='RnxProperty.RnxPropertyUpdateCalendarImage'
                formId={`RnxProperty.RnxPropertyUpdateCalendarImage`}
                onSuccess={result.refresh}
                initialValues={{
                  id: rnxProperty.id,
                }}
                fields={[
                  {
                    name: 'image',
                    label: 'Fotoğraf',
                    kind: 'file',
                    options: {
                      acceptedKinds: '.jpg,.png,.jpeg'
                    }
                  }
                ]}
              />
            </RV.Card>

            <RV.Card mt={4}>
              <RV.Card.Header>
                Görseller
              </RV.Card.Header>

              <Flex sx={{
                flexWrap: 'wrap',
                mx: -3,
                my: 2
              }}>
                {rnxProperty.images?.map(image => (
                  <Box sx={{
                    position: 'relative'
                  }}>
                    <Box as='img' src={image.url} key={image.id} sx={{
                      // width: '100%',
                      // height: '100%'
                      objectFit: 'cover',
                      maxWidth: '300px',
                      mx: 3,
                      display: 'block'
                    }} />
                    <RV.RfActionForm
                      onSuccess={result.refresh}
                      icon='circle-remove'
                      actionKey='RnxProperty.RnxPropertyRemoveImage'
                      initialValues={{
                        id: rnxProperty.id,
                        imageId: image.id
                      }}
                      buttonText='SİL'
                      sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4
                      }}
                    />
                  </Box>
                ))}
              </Flex>

              <RV.RfSingleLineForm
                actionKey='RnxProperty.RnxPropertyAddImages'
                formId={`RnxProperty.RnxPropertyAddImages`}
                onSuccess={result.refresh}
                initialValues={{
                  id: rnxProperty.id,
                }}
                fields={[
                  {
                    name: 'images',
                    label: 'Fotoğraf',
                    kind: 'file',
                    options: {
                      acceptedKinds: '.jpg,.png,.jpeg',
                      isMultiple: true
                    }
                  }
                ]}
              />
            </RV.Card>

            <RV.Card mt={4}>
              <RV.Card.Header>
                Tanıtım
              </RV.Card.Header>

              <RV.RfSingleLineForm
                sx={{
                  mt: 4
                }}
                actionKey='RnxProperty.RnxPropertyUpdateDescription'
                formId={`RnxProperty.RnxPropertyUpdateDescription`}
                onSuccess={result.refresh}
                initialValues={{
                  id: rnxProperty.id,
                  description: rnxProperty.description
                }}
                fields={[
                  {
                    name: 'description',
                    label: 'Tanım',
                    kind: 'richText',
                  }
                ]}
              />
            </RV.Card>
          </>
        )
      }}
    </RV.QueryLoading>
  )
}

export default PropertyContent
import React from 'react'

// Reactor UI
import * as changeCase from 'reactor-ui/util/text'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'


const useModelDetailReactorQuery = (query) => {
  const reactorQuery = {}
  Object.entries(query).forEach(([name, val]) => {
    reactorQuery[name] = val
    reactorQuery[`${name}Meta`] = {
      fields: [
        'primaryFields'
      ]
    }
  })
  const result = useReactorQuery(reactorQuery)

  return result
}

export default useModelDetailReactorQuery
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import * as changeCase from 'reactor-ui/util/text'
import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'
import Icon from 'reactor-ui/components/Icon'
import Alert from 'reactor-ui/components/Alert'

// Reactor
import { Link, useActiveLink, useParams, useMatch, useResolvedPath, useHref } from 'reactor-vera/router'
import useLayoutConfig from 'reactor-vera/hooks/useLayoutConfig'
import usePlexUiTheme from 'reactor-vera/apps/ui/hooks/usePlexUiTheme'


export const SidebarHeader = ({
  variant,
  ...rest
}) => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const styles = useStyleConfig("SidebarHeader", {
    variant, defaultStyleConfig: {
      baseStyle: {
        fontSize: 'xs',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: 'light.800',
        mb: 1
      },
      variants: {
        // active: {
        //   color: 'dark.500',
        //   top: '-11px',
        //   left: 'calc(var(--chakra-space-3) - 4px)',
        //   fontSize: 'sm',
        //   background: '#FFF',
        //   py: '2px',
        //   px: '4px',
        //   // fontWeight: 'bold',
        //   // color: 'dark6',
        //   ...activeSx
        // }
      }
    }
  })
  if (!layoutConfig.sidebar.extended) return null

  return <Box __css={styles} {...rest} />
}

export const SidebarItem = ({
  item,
  depth = 0,
  baseUrl
}) => {
  const plexUiTheme = usePlexUiTheme()
  const Component = plexUiTheme?.sidebar?.[item.componentKey]

  return (
    <Component item={item} depth={depth} baseUrl={baseUrl} />
  )
}

export const PlexUiGroupItem = ({
  item,
  depth,
  baseUrl
}) => {
  if (depth > 0) return <SidebarSubgroup depth={depth} item={item} baseUrl={baseUrl} />
  if (item.mode === 'entity') return <SidebarEntityGroup item={item} baseUrl={baseUrl} />

  return <SidebarGroupContent item={item} baseUrl={baseUrl} depth={depth} />
}

const SidebarGroupContent = ({
  item,
  depth,
  baseUrl
}) => {
  return (
    <Box sx={{ my: 4 }}>
      <SidebarHeader>
        {item.label || item.name}
      </SidebarHeader>

      {item.items.map((item, dx) => {
        return (
          <Box key={dx} my={0}>
            <SidebarItem item={item} depth={depth + 1} baseUrl={baseUrl} />
          </Box>
        )
      })}
    </Box>
  )
}

export const SidebarEntityGroup = ({
  item,
  depth = 0,
  baseUrl
}) => {
  const { t } = useTranslation()
  const match = useMatch(`${baseUrl}/${item.key}/${item.options.requiredRoutePath}/*`)

  if (!match) {
    if (!item.options.selectMessage) return null
    return (

      // <Box color='brandLight.100'>
      //   {t(item.options.selectMessage)}
      // </Box>
      <Alert status='info'>
        {t(item.options.selectMessage)}
      </Alert>
    )
  }
  // return <SidebarGroupContent item={item} baseUrl={baseUrl} depth={depth}/>
  return (
    <Box sx={{ my: 4 , mb: 8, borderBottom: '1px solid #FFF', borderBottomColor: 'brand.400', pb: 8}}>
      <SidebarHeader>
        {item.name}
      </SidebarHeader>

      {item.items.map((item, dx) => {
        return (
          <Box key={dx}>
            <SidebarItem item={item} depth={depth + 1} baseUrl={match.pathnameBase} />
          </Box>
        )
      })}
    </Box>
  )
}

export const PlexUiComponentItem = ({
  item,
  // variant,
  depth,
  baseUrl
}) => {
  const to = `${baseUrl}/${item.key}`

  const isActive = useActiveLink({ to })
  const variant = isActive ? 'active' : null

  const styles = useStyleConfig("PlexUiComponentItem", {
    variant, defaultStyleConfig: {
      baseStyle: {
        // fontSize: 'xs',
        textTransform: 'uppercase',
        // fontWeight: 'bold',
        color: 'light.800',
        '&:hover': {
          color: 'brandLight.500'
        },
        transition: 'all 0.2s ease',
        my: 1,
        display: 'block',
        fontSize: 'sm',
        py: 2,
        px: 2,
        borderRadius: 2,
      },
      variants: {
        active: {
          bg: 'brand.500',
          '&:hover': {
            bg: 'brand.400',
            color: 'light.800'
          },
          // color: 'dark.500'
        },
      }
    }
  })

  const { t } = useTranslation()
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  let content, sx
  if (layoutConfig.sidebar.extended) {
    content = (
      <Icon icon={item.icon} childSx={{
        ml: 4,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        // width: '80%',
        display: 'inline-block',
        // transition: 'all 0.2s ease',
      }}>
        {t(`label.${changeCase.snake(item.label || item.name)}`)}
      </Icon>
    )
  } else {
    sx = {
      fontSize: 'xs',
      // width: '60px',
      textAlign: 'center',
      px: 0
    }
    content = (
      <>
        <Icon icon={item.icon} wrapperSx={{
          mx: 'auto',
          width: '20px'
        }} size='18px' />
        <Box sx={{
          fontSize: 'xs',
          mt: 1
        }}>
          {t(`label.${changeCase.snake(item.label || item.name)}`)}
        </Box>
      </>
    )
  }

  return (
    <Box as={Link} to={to} __css={styles} sx={sx}>
      {content}
    </Box>
  )
}

export const SidebarSubgroup = ({
  item,
  variant,
  depth,
  baseUrl
}) => {
  const [extended, setExtended] = React.useState(false)
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig.sidebar.extended

  const { t } = useTranslation()
  const styles = useStyleConfig("SidebarSubgroup", {
    variant, defaultStyleConfig: {
      baseStyle: {
        // fontSize: 'xs',
        textTransform: 'uppercase',
        // fontWeight: 'bold',
        color: 'light.800',
        '&:hover': {
          color: 'brandLight.500'
        },
        transition: 'all 0.2s ease',
        my: 3,
        display: 'block',
        fontSize: 'sm',
        ml: layoutConfig.sidebar.extended ? 2 * depth : 0
      },
      variants: {
        // active: {
        //   color: 'dark.500',
        //   top: '-11px',
        //   left: 'calc(var(--chakra-space-3) - 4px)',
        //   fontSize: 'sm',
        //   background: '#FFF',
        //   py: '2px',
        //   px: '4px',
        //   // fontWeight: 'bold',
        //   // color: 'dark6',
        //   ...activeSx
        // }
      }
    }
  })

  let content, sx
  if (layoutConfig.sidebar.extended) {
    content = (
      <Flex alignItems={'center'}>
        <Icon icon={item.icon} wrapperSx={{ flex: '1 1 auto' }} childSx={{
          ml: 4,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: '80%',
          display: 'inline-block',
        }}>
          {t(`label.${changeCase.snake(item.name)}`)}
        </Icon>
        <Box sx={{
          flex: '0 0 auto'
        }}>
          <Icon name={extended ? 'chevron-up' : 'chevron-down'} />
        </Box>
      </Flex>
    )
  } else {
    content = (
      <>
        <Flex alignItems={'center'} flexDirection='column'>
          <Icon icon={item.icon} wrapperSx={{ flex: '1 1 auto' }} />
          <Box sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '80%',
            display: 'inline-block',
          }}>
            {t(`label.${changeCase.snake(item.name)}`)}
          </Box>
          <Box sx={{
            flex: '0 0 auto'
          }}>
            <Icon name={extended ? 'chevron-up' : 'chevron-down'} />
          </Box>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Box __css={styles} as='a' onClick={() => setExtended(v => !v)}>
        {content}
      </Box>

      {extended && (
        <Box sx={{
          pl: layoutConfig.sidebar.extended ? 3 : 0
        }}>
          {item.items.map((item, dx) => {
            return (
              <Box key={dx}>
                <SidebarItem item={item} depth={depth + 1} baseUrl={baseUrl} />
              </Box>
            )
          })}
        </Box>
      )}
    </>
  )
}

export const SidebarWrapper = ({
  variant,
  children
}) => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig.sidebar.extended
  const styles = useStyleConfig('PlexUiSidebarWrapper', {
    variant, defaultStyleConfig: {
      baseStyle: {
        display: ['none', null, null, 'block'],
        bg: 'dark.500',
        width: '90px',
        height: '100vh',
        color: 'light.800',
        pr: isExtended ? 6 : 2,
        pl: isExtended ? 3 : 2,


        borderTopRightRadius: 1,
        borderBottomRightRadius: 1,
        position: 'fixed',
        // width: extended ? 'sidebarExtended' : 'sidebar',
        // top: 10,
        color: 'text',
        transition: 'all 0.2s ease',

        overflow: 'auto',
        zIndex: 99,
        '@media print': {
          display: 'none'
        },
        overflowX: 'hidden'
      },
      variants: {
        extended: {
          width: '240px'
          // color: 'dark.500',
          // top: '-11px',
          // left: 'calc(var(--chakra-space-3) - 4px)',
          // fontSize: 'sm',
          // background: '#FFF',
          // py: '2px',
          // px: '4px',
        }
      }
    }
  })

  return (
    <Box __css={styles}>
      {children}
    </Box>
  )
}
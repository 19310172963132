import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Card from 'reactor-ui/components/Card'
import Icon from 'reactor-ui/components/Icon'
import * as changeCase from 'reactor-ui/util/text'

import ModelField from './ModelField'
import ActionGroup from '../../data/components/ActionGroup'


const EntityDetail = ({
  icon,
  modelName: modelNameFromProps,
  entity,
  schema,
  name,
  fields,

  headerPre,
  parentName,

  elements,

  action,

  showEntityActions = true,
  displayMode
}) => {
  // const name_ = name || entity?.name || entity?.id
  const name_ = name || entity?.name
  const modelName = modelNameFromProps || changeCase.pascal(entity.__typename)
  // console.log(entity, schema, modelName)
  const { t } = useTranslation()
  const ignoredFields = ['name']

  const entityName = (
    <Box sx={{
      // flex: '1 1 auto',
      mx: 2
    }}>
      <Box sx={{
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: 'xl',
        // ml: 6,
        my: 4
      }}>
        {icon ? (
          <Icon icon={icon} color={'brand.500'} size={7}>
            {headerPre} {parentName && (<Box as='span'>{parentName} {'>'} </Box>)} {name_}
          </Icon>
        ) : (
          <>
            {headerPre} {parentName && (<Box as='span'>{parentName} {'>'} </Box>)} {name_}
          </>
        )}
      </Box>
    </Box>
  )

  const entityMenu = (
    <Box mx={2}>
      {showEntityActions && <ActionGroup callbacks={{
        onSuccess: (data) => action?.callbacks?.onSuccess?.(data)
      }} name={modelName} contexts={['Entity']} ctx={{ id: entity.id }} />}
      <Flex>
        {elements?.primaryActions}
      </Flex>
    </Box>
  )

  const entityFields = (
    <Flex sx={{
      mx: -2,
      alignItems: 'center',
      // mb: 3
    }}>
      {fields.map((field, dx) => {
        let fieldSchema, fieldName
        if (field.includes('.')) {
          const fieldSplit = field.split('.')
          fieldSchema = schema[fieldSplit[0]]
          fieldName = fieldSplit[1]
        } else {
          fieldName = field
          fieldSchema = schema[field]
        }
        if (ignoredFields.includes(fieldName)) return null
        if (!fieldSchema) return null
        const value = entity[fieldSchema.name]
        if (value === undefined || value === null) return null
        const header = t(`field.${changeCase.snake(fieldName || fieldSchema.name)}`)

        return (
          <Box key={dx} sx={{
            mx: 2,
            borderRight: '1px dashed #FFF',
            borderRightColor: 'dark.50',
            pr: 4
          }}>
            <Box sx={{
              textTransform: 'uppercase',
              fontSize: 'xs',
              fontWeight: 'bold',
              letterSpacing: '-0.03em',
              // mb: 1
            }}>
              {header}
            </Box>
            <ModelField fieldName={fieldName} fieldSchema={fieldSchema} entity={entity} />
          </Box>
        )

      })}
    </Flex>
  )

  if (displayMode === 'singleLine') {
    return (
      <Box sx={{
        // px: 2,
        mb: 2,
        mt: 1,
        pt: 1,
        borderTop: '1px solid #FFF',
        borderTopColor: 'dark.50',
      }}>
        <Flex sx={{
          alignItems: 'center',
          mb: 2,
          mx: -2
        }}>
          {entityName}

          <Box sx={{
            mx: 4
          }}>
          {entityFields}
          </Box>

          {entityMenu}
        </Flex>

      </Box>
    )
  } else {
    return (
      <Box sx={{
        // px: 2,
        mb: 6,
        mt: 1,
        borderTop: '1px solid #FFF',
        borderTopColor: 'dark.50',
      }}>
        <Flex sx={{
          alignItems: 'center',
          mb: 2,
          mx: -2
        }}>
          {entityName}

          {entityMenu}
        </Flex>

        {entityFields}
      </Box>
    )
  }
}

export default EntityDetail
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import QueryLoading from 'reactor-vera/apps/ui/components/QueryLoading'


const ReactorQuery = ({
  query,
  children
}) => {
  const result = useReactorQuery(query)
  return (
    <QueryLoading result={result}>
      {children}
    </QueryLoading>
  )
}

export default ReactorQuery
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'



const UserManagement = props => {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path='/' element={<UserManagementHome />} />
      <Route path='user/:rcUserId/*' element={<UserManagementDetail />} />
    </Routes>
  )
}

const UserManagementHome = props => {
  const { t } = useTranslation()
  return (
    <>
    <GraphTable
      modelName='RcUser'
      detailLink='user'
      query={{
        systemQuery: true
      }}
    />
    </>
  )
}

const UserManagementDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rcUser': {
      filters: {
        id: params.rcUserId,
      },
      systemQuery: true
    },
  })

  useBreadcrumb(`rcUserDetail:${result.graph?.rcUser.id}`, result.graph?.rcUser.name)

  const rcUser = result.graph?.rcUser
  const meta = result.graph?.rcUserMeta

  if (!rcUser) return null

  return (
    <>
      <EntityDetail
      icon={'book-open'}
      entity={rcUser}
      schema={result.schema?.rcUser}
      fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
    </>
  )
}

export default UserManagement
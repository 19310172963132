import React, { cloneElement } from 'react'

// Reactor UI
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import * as changeCase from 'reactor-ui/util/text'


const AuthorizationManagement = props => {
  const { t } = RV.useTranslation()
  return (
    <RV.Routes>
      <RV.Route path='/' element={<AuthorizationHome />} />
      <RV.Route path='role/:rcAuthSimpleRoleId/*' element={<RoleDetail />} />
    </RV.Routes>
  )
}

const AuthorizationHome = props => {
  const { t } = RV.useTranslation()
  const params = RV.useParams()
  return (
    <>
      <RV.GraphTable
        modelName='RcAuthSimpleRole'
        detailLink='role'
      />
    </>
  )
}


export const RoleDetail = ({

}) => {
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rcAuthSimpleRole': {
      filters: {
        id: params.rcAuthSimpleRoleId
      },
      fields: [
        'name', 'description', 'status', 'resourcePermissions', 'allPermissions', 'resourceAccesses', 'resourceAccessMode'
      ]
    },
  })

  RV.useBreadcrumb(`rcAuthSimpleRoleDetail:${result.graph?.rcAuthSimpleRole.id}`, result.graph?.rcAuthSimpleRole.name)

  const rcAuthSimpleRole = result.graph?.rcAuthSimpleRole
  const meta = result.graph?.rcAuthSimpleRoleMeta

  if (!rcAuthSimpleRole) return null

  return (
    <>
      <RV.EntityDetail
        icon={'lock'}
        entity={rcAuthSimpleRole}
        schema={result.schema?.rcAuthSimpleRole}
        fields={meta.primaryFields}
        key={result?.status?.queryKey}
      // refresh={result.refresh}
      // status={result.status}
      />


      <RV.BareTabs
        isLazy
        memoKey={result.graph}
        tabs={[
          {
            name: 'Yetkiler',
            codeName: 'permissions',
            element: <Permissions rcAuthSimpleRole={rcAuthSimpleRole} />
          },

          {
            name: 'Erişimler',
            codeName: 'accesses',
            element: <Accesses rcAuthSimpleRole={rcAuthSimpleRole} />
          },

          {
            name: 'Kullanıcılar',
            codeName: 'users',
            element: (
              <RV.GraphTable
                modelName='RcAuthSimpleUserRole'
                query={{
                  filters: {
                    'rcAuthSimpleRole.id': rcAuthSimpleRole.id
                  }
                }}
              />
            )
          }
        ]}
      />

    </>
  )
}

const Permissions = ({
  rcAuthSimpleRole
}) => {
  const { t, i18n } = RV.useTranslation()

  return (
    <RV.Card my={4}>
      <RV.Card.Header>Yetkiler</RV.Card.Header>

      {rcAuthSimpleRole.allPermissions && (
        Object.entries(rcAuthSimpleRole.allPermissions).map(([resource, permissions]) => {
          return (
            <Box key={resource} sx={{
              my: 4
            }}>
              <Box sx={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                // ':first-letter': {
                //   textTransform: 'uppercase'
                // },
              }}>
                {t(`label.${changeCase.snake(resource)}`)}
              </Box>

              <Box>
                {permissions.map(p => {
                  return (
                    <Box key={p} my={2}>
                      <RV.Icon color={rcAuthSimpleRole.resourcePermissions?.[resource]?.includes(p) ? 'success.500' : 'danger.500'} name={rcAuthSimpleRole.resourcePermissions?.[resource]?.includes(p) ? 'check' : 'circle-remove'}>
                        {t(`permission.${p}`)}
                      </RV.Icon>
                      <Box sx={{
                        fontSize: 'sm',
                        color: 'dark.300',
                        ml: 7
                      }}>
                        {i18n.exists(`permission.${p}.description`) && t(`permission.${p}.description`)}
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          )
        })
      )}
    </RV.Card>
  )
}

const Accesses = ({
  rcAuthSimpleRole
}) => {
  console.log(rcAuthSimpleRole)
  const { t } = RV.useTranslation()
  return (
    <>
      <RV.Card>
        <RV.InfoLine distinct label='Erişim Modu' value={rcAuthSimpleRole.resourceAccessMode} />

        <Box mt={3} />
        {rcAuthSimpleRole.resourceAccesses && (
          Object.entries(rcAuthSimpleRole.resourceAccesses).map(([name, data]) => {
            return (
              <Box key={name}>
                <RV.Card.Header uppercase={false}>
                  {/* {t(`label.${changeCase.snake(name)}`)} */}
                  {name}
                </RV.Card.Header>

                {data?.map((access, dx) => {
                  return (
                    <Box key={dx} sx={{
                      // fontWeight: 'bold'
                    }}>
                      <RV.Icon icon='asterisk' size={4}>
                        {access.label}
                      </RV.Icon>
                    </Box>
                  )
                })}

              </Box>
            )
          })
        )}
      </RV.Card>
    </>
  )
}

export default AuthorizationManagement
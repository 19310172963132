// Vendor
import changeCase from 'change-case'
import merge from 'lodash/merge'


export default class Reactor {
  constructor(config, apps, systems) {
    this._apps = {}
    this._systems = {}
    this.components = {}
    this._commandHandlers = {}

    this.directory = {}

    this._reducer = {}

    this._operators = {}

    this._sagaRunner = null

    this.isRunning = false
    this.isSetup = false

    this._appChangeListeners = {}
    this._systemChangeListeners = {}
    this._reducerChangeListeners = {}

    this.translations = {}

    this._subscriptions = {}

    this.rConfig = {
      baseAPIUrl: '',
      isMultiTenant: true,
      urlMap: {}
    }

    if (config) this.setupRuntimeConfig(config)

    apps?.forEach(app => this.registerApp(app))
    systems?.forEach(system => this.registerSystem(system))
  }

  getCommandHandler(key) {
    return this._commandHandlers(key)
  }

  addCommandHandler(key, handler) {
    if (this._commandHandlers[key]) {
      console.log('Overriding existing command handler', key, this._commandHandlers[key])
    }
    this._commandHandlers[key] = handler
  }

  addTranslations(translations) {
    this.translations = {...this.translations, ...translations }
  }

  translate(key) {
    if (this.translations[key]) return this.translations[key]
    else {
      console.log(`MISSING TRANSLATION: ${key}`)
      return key
    }
  }

  setupRuntimeConfig(rConfig) {
    if (rConfig) this.rConfig = {...this.rConfig, ...rConfig}
    global._rCacheStorage = this.rConfig.cacheStorage
    return this.rConfig
  }

  finishSetup() {
    Object.values(this._apps).map(app => {
      if (app.callbacks && app.callbacks.afterDirectorSetup) app.callbacks.afterDirectorSetup()
    })
    Object.values(this._systems).map(system => {
      if (system.callbacks && system.callbacks.afterDirectorSetup) system.callbacks.afterDirectorSetup()
    })
  }

  run() {
    // if (this.isRunning) throw new Error('Reactor already running.')
    if (this._sagaRunner == null) throw new Error('Saga runner not set.')

    this.isRunning = true
  }

  setSagaRunner(sagaRunner) {
    // if (this._sagaRunner) throw new Error('Saga runner already set.')
    this._sagaRunner = sagaRunner
  }

  getSagaList() {
    const sagaList = []
    Object.values(this._systems).forEach(system => {
      if (system.sagas && system.sagas.forEach) system.sagas.forEach(saga => sagaList.push(saga()))
    })
    Object.values(this._apps).forEach(app => {
      if (app.sagas && app.sagas.forEach) app.sagas.forEach(saga => sagaList.push(saga()))
    })
    return sagaList
  }

  _registerListener(kind, name, listener) {
    const listenerMap = this[`_${kind}ChangeListeners`]

    if (!listenerMap) throw new Error('Invalid listener kind.')
    if (listenerMap[name]) throw new Error(`${name} already registered as listener.`)

    listenerMap[name] = listener
  }

  _runSaga(saga, system) {
    if (this._sagaRunner != null && this.isRunning) {
      const runningSaga = this._sagaRunner(saga)
      system._runningSagas.push(runningSaga)
    } else {
      throw new Error(`Reactor is not running, can't run saga for ${system.name}.`)
    }
  }

  registerSystem(newSystem) {
    newSystem._runningSagas = []
    // if (this._systems[newSystem.name]) throw new Error(`${newSystem.name} already registered.`)
    if (this._systems[newSystem.name]) {
      // throw new Error(`${newSystem.name} already registered.`)
      console.log(`${newSystem.name} already registered.`)
      return
    }

    if (this.isRunning && newSystem.sagas) newSystem.sagas.forEach(saga => this._runSaga(saga, newSystem))

    this._systems[newSystem.name] = newSystem
    if (newSystem.reducer) this.registerReducer({[changeCase.camelCase(newSystem.name)]: newSystem.reducer})
    if (newSystem.operators) this.registerOperators(newSystem.operators)
    Object.values(this._systemChangeListeners).forEach(listener => listener('ADD', newSystem, {...this._systems}))
  }

  registerApp(newApp) {
    newApp._runningSagas = []
    if (this._apps[newApp.name]) {
      // throw new Error(`${newApp.name} already registered.`)
      console.log(`${newApp.name} already registered.`)
      return
    }
    if (this.isRunning && newApp.sagas) newApp.sagas.forEach(saga => this._runSaga(saga, newApp))

    this._apps[newApp.name] = newApp
    if (newApp.reducer) this.registerReducer({[changeCase.camelCase(newApp.name)]: newApp.reducer})
    Object.values(this._appChangeListeners).forEach(listener => listener('ADD', newApp, {...this._apps}))

    if (newApp.getTranslations) newApp.getTranslations().then(val => this.addTranslations(val))
    if (newApp.components) this.registerComponentsForApp(newApp)
    if (newApp.directory) this.registerDirectoryForApp(newApp)
    if (newApp.operators) this.registerOperators(newApp.operators)
  }

  registerDirectoryForApp(app) {
    merge(this.directory, app.directory)
  }

  registerComponentsForApp(app) {
    Object.keys(app.components).forEach(componentName => {
      const componentData = app.components[componentName]
      this.registerComponent(`${app.name}.${componentName}`, componentData)
    })
  }

  registerComponent(componentName, componentData) {
    let component = componentData.component
    this.components[componentName] = component
  }

  registerReducer(newReducer) {
    this._reducer = {...this._reducer, ...newReducer}
    Object.values(this._reducerChangeListeners).forEach(listener => listener('ADD', newReducer, this.getReducer()))
  }

  getReducer() {
    return {...this._reducer}
  }

  registerReducerListener(name, listener) {
    this._registerListener('reducer', name, listener)
  }

  getComponents() {
    return {...this.components}
  }

  getComponent(name) {
    return this.components[name]
  }

  subscribe(key, cb) {
    if (!this._subscriptions[key]) this._subscriptions[key] = []
    this._subscriptions[key].push(cb)
    return () => this.unsubscribe(key, cb)
  }

  unsubscribe(key, cb) {
    if (!this._subscriptions[key]) return
    const dx = this._subscriptions[key].indexOf(cb)
    if (dx !== -1) this._subscriptions[key].splice(dx, 1)
  }

  notify(key, params) {
    this._subscriptions[key]?.forEach(cb => cb(params))
  }

  registerOperators(operators) {
    Object.entries(operators).forEach(([name, operatorCls]) => {
      if (this._operators[name]) throw Error(`OPERATOR_EXISTS: ${name}`)
      const operator = new operatorCls(this)
      this._operators[name] = operator
    })
  }

  get operators() {
    return this._operators
  }

  // setupAxios(axios, directorConfig) {
  //   let version, csrfToken
  //   if (typeof __VERSION__ !== 'undefined') {
  //     version = __VERSION__
  //   }

  //   if (typeof window !== 'undefined') {
  //     csrfToken = window.csrfToken
  //   }

  //   axios.interceptors.request.use(
  //     config => {
  //       config.headers = {
  //         ...config.headers,
  //         'Content-Type': 'application/json',
  //         // 'X-RUNIC-PLATFORM': this.rConfig.platform
  //       }
  //       if (version) config.headers['X-REALM-VERSION'] = version
  //       if (csrfToken) config.headers['X-REALM-CSRF'] = csrfToken
  //       return config
  //     },
  //   )

  //   if (directorConfig && directorConfig.interceptors && directorConfig.interceptors.error) {
  //     axios.interceptors.response.use(
  //       response => response,
  //       directorConfig.interceptors.error
  //     )
  //   }

  //   // axios.interceptors.response.use(
  //   //   response => response,

  //   //   // error => {
  //   //   //   if (typeof navigator != 'undefined' && navigator.product == 'ReactNative') {
  //   //   //     if (error.response && error.response.status == 401) {

  //   //   //     }
  //   //   //     return Promise.reject(error);
  //   //   //   }
  //   //   // }
  //   //   // error => {
  //   //   //   if (typeof window !== "undefined") {
  //   //   //     if (error.response && error.response.status == 401) {
  //   //   //       if (error && error.response && error.response.data && error.response.data.kind && error.response.data.kind == 'INVALID_TENANT') {
  //   //   //         window.location = '/home';
  //   //   //       } else {
  //   //   //         window.location = '/auth/logout';
  //   //   //       }
  //   //   //     }
  //   //   //     return Promise.reject(error);
  //   //   //   }
  //   //   // }
  //   // )
  // }
}
import React from 'react'

// Volcano
import useFileInputElement from 'reactor-ui/hooks/useFileInputElement'

const useFileDialog = ({
  acceptedFileKinds,
  onAdd,
  multiple = false
}) => {
  const cb = React.useCallback(e => {
    onAdd(e.target.files)
  }, [onAdd])
  const inputElement = useFileInputElement(acceptedFileKinds, cb, {multiple})
  const openFileDialog = React.useCallback((e) => {
    e.preventDefault()
    inputElement.dispatchEvent(new MouseEvent("click"))
  }, [inputElement])

  return openFileDialog
}

export default useFileDialog
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'

import { Link } from 'reactor-vera/router'



const TenantManagement = props => {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path='/' element={<TenantManagementHome />} />
      <Route path='tenant/:rcTenantDetailId/*' element={<TenantManagementDetail />} />
    </Routes>
  )
}

const TenantManagementHome = props => {
  const { t } = useTranslation()
  const params = useParams()
  return (
    <>
    <GraphTable
      modelName='RcTenant'
      detailLink='tenant'
      elements={{
        entityActions: {
          header: 'actions',
          items: [
            ({entity}) => (
              params.rcTenantId != entity.id && <Link sx={{
                color: 'primary',
                textDecoration: 'underline',
              }} to={`/${entity.id}`} key={`switch${entity.id}`}>
                {t('op.switch_tenant')}
              </Link>
            )
          ]
        }
      }}
      query={{
        systemQuery: true
      }}
    />
    </>
  )
}

const TenantManagementDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rcTenant': {
      filters: {
        id: params.rcTenantDetailId
      }
    },
  })

  useBreadcrumb(`rcTenantDetail:${result.graph?.rcTenant.id}`, result.graph?.rcTenant.name)

  const rcTenant = result.graph?.rcTenant
  const meta = result.graph?.rcTenantMeta

  if (!rcTenant) return null

  return (
    <>
      <EntityDetail
      icon={'book-open'}
      entity={rcTenant}
      schema={result.schema?.rcTenant}
      fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
    </>
  )
}

export default TenantManagement
import React from 'react'

import {
  Button as ChakraButton
} from "@chakra-ui/react"


import Icon from './Icon'
import Tooltip from './Tooltip'

const Button = React.forwardRef(({
  submit,
  iconName,
  icon,
  iconColor,
  iconSize,
  children,
  size,
  tooltip,
  as,
  ...rest
}, ref) => {
  let type = submit ? 'submit' : 'button'
  if (as) type = undefined
  const content = (
    <ChakraButton ref={ref} type={type} size={size || ((iconName || icon) && 'sm')} as={as} {...rest}>
      {(iconName || icon) ? <Icon color={iconColor} size={iconSize} name={iconName || icon}>{children}</Icon> : children}
    </ChakraButton>
  )

  if (tooltip) {
    return (
      <Tooltip label={tooltip}>
        {content}
      </Tooltip>
    )
  }
  return content

})

export default Button
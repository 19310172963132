import React from 'react'

// Vendor
import dayjs from 'dayjs'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import Dashboard from 'reactor-vera/apps/dashboard/components/Dashboard'
import DashboardFilters from 'reactor-vera/apps/dashboard/components/DashboardFilters'

import DeviceStats from 'elements/rnx_device/rnxDevice/components/DeviceStats'
import PacUseStats from 'elements/rnx_pac/rnxPac/components/PacUseStats'
import PacPassStats from 'elements/rnx_pac/rnxPac/components/PacPassStats'


const RnxDevicePacDashboard = props => {
  const result = RV.useReactorQuery({
    'rnxDeviceMeta': {
      fields: [
        'primaryFilters',
      ],
    }
  })
  const rnxDevicePrimaryFilters = result.graph?.rnxDeviceMeta?.primaryFilters
  if (!rnxDevicePrimaryFilters) return null
  return (
    <Dashboard defaultFilters={{
      accessDatetimeRange: {
        value: {
          range: '@today'
        },
        kind: 'DateTime'
      },
      rnxDevice: {
        kind: 'Group',
        filters: rnxDevicePrimaryFilters?.[0]?.filters
      }
    }}>
      <DashboardFilters />
      <PacUseStats />
      <PacPassStats sx={{ mt: 8 }} />
      <DeviceStats sx={{ mt: 8 }} />
    </Dashboard>
  )
}

export default RnxDevicePacDashboard
import React from 'react'

// Vendor
import stringfy from 'json-stable-stringify'

const quickHash = (str) => {
    let result = 0
    if (str.length == 0) return result
    for (let i = 0; i < str.length; i++) {
      let char = str.charCodeAt(i)
      result = ((result << 5) - result) + char
      result = result & result
    }
    return result
}

const useFetchKey = (...data) => {
  const keyInfo = {}
  data.forEach((dataItem, dx) => keyInfo[dx] = dataItem)
  return React.useMemo(() => quickHash(stringfy(keyInfo)), data)
}

export const createFetchKey = (...data) => {
  const keyInfo = {}
  data.forEach((dataItem, dx) => keyInfo[dx] = dataItem)
  return quickHash(stringfy(keyInfo))
}

export default useFetchKey
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const PropertyPaymentProducts = props => {
  const params = RV.useParams()
  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.GraphTable
          modelName={'BankPaymentResource'}
          query={{
            filters: {
              for_property: {
                property_id: params.rnxPropertyId
              }
            },
            itemFields: [
              {bankPaymentOperation: {fields: ['name', 'status', 'contactName', 'maskedCardNumber', 'datetimeCreated']}},
              {rnxResource: {fields: ['name']}},
              {rnxResourceThing: {fields: ['name']}},
              'quantity',
              'priceTotal'
            ],
            columns: [
              'bankPaymentOperation.id',
              'bankPaymentOperation.name',
              'bankPaymentOperation.status',
              'bankPaymentOperation.contactName',
              'bankPaymentOperation.maskedCardNumber',
              'bankPaymentOperation.datetimeCreated',
              'rnxResource.name',
              'rnxResourceThing.name',
              'quantity',
              'priceTotal',
            ]
          }}
          detailLink={'d'}
        />
      )} />
      <RV.Route path='d/:bankPaymentResourceId/*' element={<BankPaymentDetail />} />
    </RV.Routes>
  )
}

const BankPaymentDetail = props => {
  return (
    <RV.EntityDetailPage
      modelName='BankPaymentResource'
      // routes={[
      //   (entity, result) => (
      //     <RV.Route path='/' element={(
      //       <Box>
      //         <RV.GraphTable
      //           modelName={'BankPaymentResource'}
      //           query={{
      //             filters: {
      //               bank_payment_operation_id: entity.id
      //             }
      //           }}
      //         />
      //       </Box>
      //     )} />
      //   )
      // ]}
    />
  )
}

export default PropertyPaymentProducts
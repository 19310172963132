import RnxProgramManagement from './pages/RnxProgramManagement'


export default {
  name: 'RnxActivitySchedule',
  components: {
    'RnxProgramManagement': {
      component: RnxProgramManagement
    },
  }
}
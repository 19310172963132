import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "reactor-ui/components/Modal"


const MediaItem = React.forwardRef(({
  item,
  // onPreview,
  showActionMenu = true,
  sx,
  children,
  ...rest
}, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onPreview = onOpen

  let preview
  if (item.kind.value === 'IMAGE') {
    preview = (
      <Box onClick={() => onPreview?.(item)} sx={{
        // border: '1px solid #FFF',
        // borderColor: 'dark.100',
        width: '100%',
        position: 'relative',
        borderRadius: 1,
        ':before': {
          content: "''",
          display: 'block',
          pb: '56%'
        },
      }}>
        <Box as='img' src={item.mediaFile.url} sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        }} />
      </Box>
    )
  } else {
    preview = (
      <Box onClick={() => onPreview?.(item)} sx={{
        // border: '1px solid #FFF',
        // borderColor: 'dark.100',
        width: '100%',
        position: 'relative',
        borderRadius: 1,
        ':before': {
          content: "''",
          display: 'block',
          pb: '56%'
        },
        ":hover": {
          background: `url(${item.mediaFile.url}) center center`,
          backgroundSize: 'cover',
        }
      }}>
        <RV.Icon name='video-play-empty' color='primary' size={'64px'} sx={{
          position: 'absolute',
          top: 'calc(50% - (64px / 2))',
          left: 'calc(50% - (64px / 2))'
        }} />
      </Box>
    )
  }
  return (
    <RV.Card key={item.id} sx={{
      mx: 2,
      minWidth: '15vw',
      width: '15vw',
      maxWidth: '15vw',
      mb: 3,
      p: 0,
      ...sx
    }} ref={ref} {...rest}>

      <Modal
        closeOnOverlayClick={true}
        isOpen={isOpen}
        onClose={onClose}
        size={'full'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {isOpen && <ItemPreview item={item} />}
          </ModalBody>
        </ModalContent>
      </Modal>

      {preview}

      <Flex sx={{
        pt: 3,
        justifyContent: 'space-between',
        p: 3
      }}>
        <Box sx={{
          fontWeight: 'bold',
          fontSize: 'sm',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}>
          <RV.Tooltip label={(
            <Box>
              <Box>
                {item.name}
              </Box>
              <RV.Icon name='clock'>
                <RV.DateTime data={item.datetimeCreated} />
              </RV.Icon>
              <Box>
                {(item.mediaFile.size / 1024 / 1024).toFixed(2)} mb
              </Box>
            </Box>
          )}>
            {item.name}
          </RV.Tooltip>
        </Box>

        {showActionMenu && (showActionMenu === true ? (
          <RV.ActionGroupMenu name={'RnxBroadcastMedia'} contexts={['Entity']} ctx={{ id: item.id }} />
        ) : (
          showActionMenu
        ))}

        <RV.Button tooltip='İndir' size='xs' as='a' iconName='square-empty-download' sx={{
          color: 'textMuted',
          transition: 'all 0.2s ease',
          '&:hover': {
            color: 'brand.500',
          },
          ml: 2
        }} href={item.mediaFile.url} target='_blank' download/>
      </Flex>
      <Box p={3}>
      {children}
      </Box>
    </RV.Card>
  )
})

const ItemPreview = ({
  item
}) => {
  let preview
  if (item.kind.value === 'IMAGE') {
    preview = (
      <Box as='img' src={item.mediaFile.url} sx={{
        objectFit: 'contain',
        width: '100%',
        height: '100%'
      }} />
    )
  } else {
    preview = (
      <Box as='video' controls src={item.mediaFile.url} sx={{
        width: '100%',
        height: '100%'
        // maxHeight: '100%'
      }} />
    )
  }
  return (
    <Box sx={{
      mx: 20,
      // my: 20,
      height: '90vh'
    }}>
      {preview}
    </Box>
  )
}

export default MediaItem
import React from 'react'

// Vendor
import dayjs from 'dayjs'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import SelectInput from './SelectInput'


const TimeIntervalInput = ({
  value,
  onChange,
  interval,
  start,
  finish,
  useDefaultWidth,
  minWidth
}) => {
  const options = React.useMemo(() => {
    const result = []

    const startHourSplit = start.split(':')
    const endHourSplit = finish.split(':')

    const today = dayjs().startOf('today')
    const startTime = today.hour(startHourSplit[0]).minute(startHourSplit[1])
    let endTime = today.hour(endHourSplit[0]).minute(endHourSplit[1])
    if (endTime.hour() == 0 && endTime.minute() == 0) endTime = today.hour(0).minute(0).add('24', 'hour')

    while (result.length == 0 || (result[result.length - 1].time.add(interval, 'minute') <= endTime)) {
      let prevEnd
      if (result.length) {
        prevEnd = result[result.length - 1].time
      } else {
        prevEnd = startTime.subtract(interval, 'minute')
      }

      const nextStart = prevEnd.add(interval, 'minute')

      const next = {
        time: nextStart,
        value: {
          hour: nextStart.hour(),
          minute: nextStart.minute(),
        },
        label: `${nextStart.format('HH:mm')}`,
      }

      result.push(next)
    }

    return result
  }, [interval, start, finish])

  return (
    <SelectInput
      onChange={onChange}
      useDefaultWidth={useDefaultWidth}
      minWidth={minWidth}
      // isMulti
      // isClearable
      value={value}
      options={options}
      placeholder={'HH:MM'}
    />
  )
}

export default TimeIntervalInput
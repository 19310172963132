import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import RfFormField from './RfFormField'
import RfFormRow from './RfFormRow'


const RfFormFields = ({
  fields,
  FieldComponent,
  fieldSx
}) => {
  const rows = []
  const rowData = {}
  fields?.forEach(({ label, optional, rowKey, ...field }, dx) => {
    const fieldComponent = (
      <RfFormField
        sx={{ textTransform: 'capitalize', ...fieldSx }}
        required={!optional}
        optional={optional}
        // label={t(label)}
        autoFocus={dx === 0}
        label={label}
        {...field}
        key={dx}
        FieldComponent={FieldComponent}
      />
    )
    if (rowKey) {
      if (!rows.find(x => x.key === rowKey)) rows.push({key: rowKey})
      if (!rowData[rowKey]) rowData[rowKey] = []
      rowData[rowKey].push(fieldComponent)
    } else {
      rows.push({c: fieldComponent})
    }
  })

  return (
    <>
    {rows.map((row, rowDx) => {
      if (row.key) {
        return (
          <RfFormRow key={`row${rowDx}`}>
            {rowData[row.key]}
          </RfFormRow>
        )
      }
      return row.c
    })}
    </>
  )
}

export default RfFormFields
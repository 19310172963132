import PrBaseContentManagement from './pages/PrBaseContentManagement'
import PrSettings from './pages/PrSettings'
import PrGeneralSettings from './pages/PrGeneralSettings'
import IntegrationSettings from './pages/IntegrationSettings'
import AddonSettings from './pages/AddonSettings'
import CommercialProperties from './pages/CommercialProperties'
import PrSurveys from './pages/PrSurveys'
import PropertyReports from './pages/PropertyReports'


export default {
  name: 'PrBase',
  components: {
    'PrBaseContentManagement': {
      component: PrBaseContentManagement
    },
    'PrSettings': {
      component: PrSettings
    },
    'PrGeneralSettings': {
      component: PrGeneralSettings
    },
    'IntegrationSettings': {
      component: IntegrationSettings
    },
    'AddonSettings': {
      component: AddonSettings
    },
    'CommercialProperties': {
      component: CommercialProperties
    },
    'PrSurveys': {
      component: PrSurveys
    },
    'PropertyReports': {
      component: PropertyReports
    },
  }
}
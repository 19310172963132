import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import uniqueId from 'lodash/uniqueId'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'
import Alert from 'reactor-ui/components/Alert'
import Icon from 'reactor-ui/components/Icon'
import useOnClickOutside from 'reactor-ui/hooks/useOnClickOutside'

// Reactor
import { getFormData } from '../util'
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import useReactorAction from 'reactor/hooks/useReactorAction'
import { isFunction } from 'lodash'

import useFileDialog from 'reactor-ui/hooks/useFileDialog'

import Portal from 'reactor-ui/components/Portal'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure
} from 'reactor-ui/components/Popover'


const RfFileUploadForm = ({
  formId,
  actionKey,
  initialValues,

  onSuccess,
  onError,
  onClose,

  field,
  anchorKind,
  anchor,

  icon = 'pencil',
  sx,
  isResetEnabled = true,
  fieldElements,

  children,
  ...rest
}) => {
  const tagRef = React.useRef()
  if (!tagRef.current) tagRef.current = uniqueId('reactor/fileUpload')
  const dispatchAction = useReactorAction(actionKey, { tag: uniqueId(`reactor/fileUpload/${actionKey}`) })

  const openFileDialog = useFileDialog({
    acceptedFileKinds: field.options?.acceptedKinds,
    onAdd: (files) => {
      if (!files || !files.length) return
      Array.from(files).forEach(f => {
        const dataToSend = getFormData({
          [field.name]: f,
          ...initialValues
        })
        dispatchAction(dataToSend, null, { name: f.name })
      })
    },
    multiple: field.options?.isMultiple
  })




  let content

  // if (formProps.phase === 'SUCCESS') {

  content = (
    <>
      <Button isFullWidth onClick={openFileDialog}>
        <Icon name='cloud-upload'>
          {field.label}
        </Icon>
      </Button>
    </>
  )

  return (
    <Flex sx={sx}>
      {content}
      <ActionStatus actionKey={actionKey} currentTag={tagRef.current} dispatchAction={dispatchAction} />
    </Flex>
  )
}

const ActionStatus = ({
  actionKey,
  currentTag,
  dispatchAction
}) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({ defaultIsOpen: true })
  const actions = useSelector(state => state.reactor.actions[actionKey])
  React.useEffect(() => {
    actions && onOpen()
  }, [actions])
  if (!actions) return null

  return (
    <Popover
      isLazy
      returnFocusOnClose={false}
      autoFocus={false}
      closeOnBlur={false}
      isOpen={isOpen}
      matchWidth
    >
      <PopoverTrigger>
        <Button onClick={onToggle} icon='square-upload' sx={{
          // color: '#000',
        }} />
      </PopoverTrigger>
      <Portal sx={{
        zIndex: 9999
      }} appendToParentPortal={true}>
        <Box sx={{
          zIndex: 9999
        }}>
          <PopoverContent>
            <PopoverArrow />
            {/* <PopoverHeader>Header</PopoverHeader>
          <PopoverCloseButton /> */}
            <PopoverBody sx={{
              zIndex: 9999
            }}>
              {Object.entries(actions).map(([key, data]) => {
                let status
                if (data.status === 'ERROR') status = <Icon name='triangle-alert' color='danger.500' />
                else if (data.status === 'SUCCESS') status = <Icon name='check' color='brand.500' />
                else if (data.status === 'START') status = <Icon name='sync' color='brand.500' />

                return (
                  <Flex sx={{
                    alignItems: 'center'
                  }}>
                    <Box sx={{
                      fontSize: 'sm',
                      flex: '1 1 0'
                    }}>
                      {data.actionMeta.name}
                    </Box>
                    <Flex sx={{
                      maxWidth: 100,
                      alignItems: 'center'
                    }}>
                      {status}
                      {data.status === 'ERROR' && (
                        <Button size='xs' icon='refresh' ml={2} onClick={() => {
                          dispatchAction(data.actionPayload, null, { name: data.actionMeta.name, uid: data.actionMeta.uid })
                        }}/>
                      )}
                    </Flex>
                  </Flex>
                )
              })}
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  )
}

export default RfFileUploadForm
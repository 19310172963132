import React from 'react'

// Reactor
import useReactor from 'reactor/hooks/useReactor'


const DynamicComponent = ({
  name,
  ...rest
}) => {
  const reactor = useReactor()
  const Component = reactor.components[name]

  if (!Component) {
    console.error('Component not found', name, reactor.components)
    return null
  }
  return <Component {...rest}/>
}

export default DynamicComponent
import React from 'react'

// Vendor
import isFunction from 'lodash/isFunction'

// Reactor
import * as RV from 'reactor-vera/templates/v1'


const EntityDetailPage = ({
  modelName: modelNameFromProps,
  filters: filtersFromParams,
  paramKey,
  fields,
  columns,
  routes,
  content,
  hideDetailOnSubRoutes = false,
  displayMode = 'default',
  icon
}) => {
  const { t } = RV.useTranslation()
  const modelName = RV.changeCase.camel(modelNameFromProps)
  const params = RV.useParams()
  const filters = filtersFromParams || {
    id: params[paramKey || `${modelName}Id`]
  }
  const result = RV.useModelDetailReactorQuery({
    [modelName]: {
      filters,
      fields
    },
  })

  RV.useBreadcrumb(`${modelName}Detail:${result.graph?.[modelName].id}`, result.graph?.[modelName].name || `${t(`label.${RV.changeCase.snake(modelName)}`)} #${result.graph?.[modelName].id}`)

  const entity = result.graph?.[modelName]
  const meta = result.graph?.[`${modelName}Meta`]

  if (!entity) return null

  let detailContent = (
    <>
      <RV.EntityDetail
        icon={icon}
        entity={entity}
        schema={result.schema?.[modelName]}
        fields={columns || meta.primaryFields}
        displayMode={displayMode}
      // refresh={result.refresh}
      // status={result.status}
      />
      {content ? isFunction(content) ? content(entity, result) : content : null}
    </>
  )

  if (hideDetailOnSubRoutes) {
    detailContent = (
      <RV.Routes>
        <RV.Route path='/' element={detailContent} />
      </RV.Routes>
    )
  }

  return (
    <>
      {detailContent}

      {routes && (
        <RV.Routes>
          {routes?.map((route, dx) => {
            if (isFunction(route)) return route(entity, result)
            else return route
          })}
        </RV.Routes>
      )}

      {/* <RV.Routes>
        <RV.Route path='/' element={(
          <GraphTable
            modelName='RnxQuizSession'
            detailLink='session'
            query={{
              filters: {
                'rnx_quiz_id': rnxQuiz.id
              },
              columns: ['rnxContact', 'datetimeStart']
            }}
          />
        )} />
      </RV.Routes> */}

    </>
  )
}

export default EntityDetailPage
import React from 'react'

import useResourceFetch from 'reactor/hooks/useResourceFetch'
import useParseValuesFromServer from './useParseValuesFromServer'
import { ReactorFormContext } from '../components/RfForm'


const useLoader = (loader, opts) => {
  const ctx = React.useContext(ReactorFormContext)
  if (!loader) return
  let result
  if (loader.kind === 'resource') result = useResourceLoader(loader.options, ctx, opts)
  else throw Error(`INVALID_LOADER: ${loader.kind}`)
  return result
}

export const useResourceLoader = (loader, ctx, opts) => {
  const { resourceIdentifier, values: valuesFromOptions } = loader
  const values = useParseValuesFromServer(valuesFromOptions, ctx, {
    parentPath: opts?.parentPath
  })
  const resourceFetch = useResourceFetch(resourceIdentifier)

  const fetch = async (value) => {
    const result = await resourceFetch({ ...values, name: value })
    let data = result.data
    if (opts?.parseOption) data = data.map(opt => opts.parseOption(opt))
    return data
  }

  return { fetch, values }
}

export default useLoader
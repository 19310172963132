import React from 'react'

// Vendor
import { useDispatch } from 'react-redux'
import i18n from 'i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import reactorActions from 'reactor/systems/reactor/actions'

import useLocalStorage from 'reactor-ui/hooks/useLocalStorage'

const Tenant = ({
  id,
  channel = 'Reactor',
  children,
  locale
}) => {
  const [selectedLang, setSelectedLang] = useLocalStorage('reactor.opts.lang')
  console.log('loc', locale)
  React.useEffect(() => {
    console.log('--lc', locale, selectedLang)
    if (locale) {
      setSelectedLang(locale)
      if (locale !== selectedLang) {
        i18n.changeLanguage(locale);
        console.log('change lang', locale)
      }
      // if (locale !== selectedLang) window.location.reload()
    }
  }, [locale])
  const [ready, readySet] = React.useState()
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!id) return
    dispatch(reactorActions.setup({
      config: {
        channel: channel,
      },
      rcTenantId: id
    }))
    readySet(true)
  }, [id])

  if (!ready) return null
  return children
}

export default Tenant
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// Volcano
import { Box, Flex } from 'reactor-ui'
import { Radio, RadioGroup } from 'reactor-ui/components/Radio'
import { Stack, HStack } from 'reactor-ui/components/Stack'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfRadioField = ({
  name,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
    // type: 'checkbox'
  })

  return (
    <>
      <Box mb={1} fontSize='sm' textTransform='capitalize'>
      {t(field.label)}
      </Box>
      <RadioGroup required={field.required} value={`${input.value}`} onChange={v => {
        console.log(v)
        input.onChange(v)
      }} colorScheme="brand">
        {/* <HStack wrap='wrap' justify='space-between' shouldWrapChildren spacing={2}> */}
        <Flex flexWrap='wrap' sx={{
          mx: -3
        }}>
          {field.options.options.map((option, dx) => {
            return (
              <Box key={dx} sx={{
                mx: 3,
                flex: '1 1 0',
                minWidth: '25%',
                my: 1,
                ...field.options?.optionSx,
              }} >
              <Radio value={`${option.value}`}>{option.element || t(option.label)}</Radio>
              </Box>
            )
          })}
        </Flex>
        {/* </HStack> */}
      </RadioGroup>
      <RfFieldAlert meta={meta} />
      <RfFieldDescription field={field} />
    </>
  )
}

export default RfRadioField
import React from 'react'

// Reactor UI
import { Box, Flex } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'
import Portal from 'reactor-ui/components/Portal'
import { Menu, MenuButton, MenuList, MenuItem } from 'reactor-ui/components/Menu'

import { useNavigate } from 'reactor-vera/router'
import { getInitials } from 'reactor-ui/util'

// Reactor
import useResourceQuery from 'reactor/hooks/useResourceQuery'

// ReactorUi
import useLocalStorage from 'reactor-ui/hooks/useLocalStorage'


const SidebarUser = () => {
  const { rcUser } = useResourceQuery('rcApp.state.status')
  if (!rcUser) return null

  return <UserIcon name={rcUser.name} />
}


const UserIcon = React.forwardRef(({ name, ...rest }, ref) => {
  const navigate = useNavigate()

  return (
    <Menu>
      <MenuButton as={Button} size='sm' colorScheme='dark'
      // rightIcon={<ChevronDownIcon />}
      >
        <Flex alignItems={'center'} sx={{
          fontSize: 'xs',
        }}>
          <Box color='brandLight.500'>
            {getInitials(name)}
          </Box>
          <Icon name='chevron-down' size={'12px'} color='brandLight.500' sx={{
            ml: 1
          }} />
        </Flex>
      </MenuButton>
      <Portal>
        <Box color='brand.500'>
          <MenuList>
            <MenuItem onClick={() => navigate('/user')}>Profil</MenuItem>
            <MenuItem onClick={() => navigate('/auth/logout')}>Çıkış Yap</MenuItem>
          </MenuList>
        </Box>
      </Portal>
    </Menu>
  )
})

export default SidebarUser
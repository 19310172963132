import React from 'react'

// Vendor
import { useTheme } from '@emotion/react'
import Select from 'react-select'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


const SelectInput = ({
  value,
  options,
  placeholder,
  onChange,
  isClearable,
  isMulti,
  isOptionDisabled,
  styles = {},
  useDefaultWidth,
  minWidth = 170
}) => {
  const theme = useTheme()

  const selectValue = isMulti ? value?.map?.(v => ({ label: v, value: v })) : options?.find(x => x.value === value || (x.valueKey && value && x.valueKey === value))

  return (
    <Select
      onChange={(props) => {
        isMulti ? onChange(props.map(p => p.value)) : onChange(props?.value, props)
      }}
      isClearable={isClearable}
      isOptionDisabled={isOptionDisabled}
      isMulti={isMulti}
      value={selectValue}
      options={options}
      placeholder={placeholder}
      menuPortalTarget={document.body}
      styles={{
        control: (provided, state) => ({
          ...provided,
          borderColor: theme.colors.brand[500],
          paddingTop: 0,
          paddingBottom: 0,
          width: useDefaultWidth ? 'fit-content': 'auto',
          ...styles.control
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? theme.colors.dark[200] : '#000',
          ...styles.option
        }),
        menu: (provided, state) => ({
          ...provided,
          zIndex: 9,
          ...styles.menu
        }),
        menuPortal: (provided, state) => ({
          ...provided,
          zIndex: 999999,
          ...styles.menuPortal
        }),
        container: (provided, state) => ({
          ...provided,
          minWidth: minWidth,
          ...styles.container
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          ...styles.valueContainer
        }),
        singleValue: (provided, state) => ({
          ...provided,
          ...styles.singleValue
        }),
      }}
    />
  )
}

export default SelectInput
import CmrPaymentSettings from './pages/CmrPaymentSettings'
import CmrPayments from './pages/CmrPayments'
import GatewayConnect from './pages/GatewayConnect'


export default {
  name: 'CmrPayment',
  components: {
    'CmrPaymentSettings': {
      component: CmrPaymentSettings
    },
    'CmrPayments': {
      component: CmrPayments
    },
    'GatewayConnect': {
      component: GatewayConnect
    },
  }
}
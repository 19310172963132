import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
// import MediaItemList from '../components/MediaItemList'
import MediaItem from '../components/MediaItem'
import UploadMedia from '../components/UploadMedia'


const BroadcastMediaManagement = props => {
  const result = RV.useReactorQuery({
    'rnxBroadcastMediaList': {
      itemFields: [
        'name', 'kind', 'datetimeCreated', 'mediaFile'
      ]
    }
  })
  console.log('result', result.graph?.rnxBroadcastMediaList)
  return (
    <Box>
      <Flex sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Box>
          <RV.Card.Header>
            Medya
          </RV.Card.Header>
        </Box>

        <UploadMedia onSuccess={result.refresh} />
      </Flex>

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -2,
        mt: 2
        // justifyContent: 'space-evenly'
      }}>
        {result.graph?.rnxBroadcastMediaList?.items?.map(item => {
          return <MediaItem item={item} key={item.id} />
        })}
        {/* <MediaItemList items={result.graph?.rnxBroadcastMediaList?.items}/> */}
      </Flex>
    </Box>
  )
}

export default BroadcastMediaManagement
import React from 'react'

// Vendor
import QRCode from "react-qr-code"

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import PrQr from '../components/PrQr'


const PrUserProfile = props => {
  const result = RV.useReactorQuery({
    publicMember: {
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ publicMember }) => {
        return (
          <Box my={4}>
            <RV.Card>
              <RV.Card.Header>
                {publicMember.name}
              </RV.Card.Header>

              <Flex mx={-4} mt={4}>
                <Box sx={{
                  mx: 4
                }}>
                  <Box sx={{
                    fontWeight: 'bold',
                    fontSize: 'sm'
                  }}>
                    E-Posta
                  </Box>
                  {publicMember.email || 'Yok'}
                </Box>

                <Box sx={{
                  mx: 4
                }}>
                  <Box sx={{
                    fontWeight: 'bold',
                    fontSize: 'sm'
                  }}>
                    Telefon
                  </Box>
                  {publicMember.phone || 'Yok'}
                </Box>
              </Flex>
            </RV.Card>

            <RV.Routes>
              <RV.Route path='/' element={(
                <>
                  {publicMember.memberships.length > 0 ? (
                    <RV.Card mt={4}>
                      <RV.Card.Header>
                        Üyelikler
                      </RV.Card.Header>

                      <Flex mx={-4} mt={4} flexDirection={['column', null, null, 'row']}>
                        {publicMember.memberships.map(m => {
                          return (
                            <RV.Link to={`./membership/${m.id}`} key={m.id} sx={{
                              flex: '1 1 0',
                              maxWidth: ['100%', null, null, '200px'],
                              mx: 4,
                              border: '1px solid #FFF',
                              borderColor: 'brand.500',
                              borderRadius: 2,
                              p: 4,
                              textAlign: 'center',
                              display: 'block',
                              my: [2, null, null, 0]
                            }}>
                              <Box sx={{ textAlign: 'left', mb: 3 }}>
                                <RV.Icon name='user-group'>
                                  {m.program.name}
                                </RV.Icon>
                              </Box>
                              <RV.Enum value={m.status} />
                              {m.status === 'PASSIVE' && (
                                <RV.Alert status='warning'>
                                  Ödeme yaparak aktif hale getirmek için tıklayın.
                                </RV.Alert>
                              )}
                            </RV.Link>
                          )
                        })}
                      </Flex>
                    </RV.Card>
                  ) : (
                    'Aktif üyelik yok.'
                  )}

                  <RV.GraphTable
                    modelName={'RnxResourceTicket'}
                    sx={{
                      my: 4
                    }}
                    headerText='Biletler'
                    query={{
                      filters: {
                        rnx_contact_id: publicMember.id
                      }
                    }}
                  />

                  <RV.SimpleTable
                    header='Ödemeler'
                    sx={{
                      mt: 4
                    }}
                    maxHeight={0}
                    items={publicMember.payments}
                    columns={[
                      {
                        label: 'İsim',
                        key: 'name',
                      },

                      {
                        label: 'Miktar',
                        key: 'amountTotal',
                      },

                      {
                        label: 'Tarih',
                        key: 'datetimeFinalized',
                        renderer: (item) => <RV.DateTime data={item.datetimeFinalized} />
                      },
                    ]}
                  />

                  <RV.GraphTable
                    modelName={'RnxActivityGroupSubscription'}
                    sx={{
                      my: 4
                    }}
                    headerText='Program Üyelikleri'
                    query={{
                      filters: {
                        rnx_contact_id: publicMember.id
                      }
                    }}
                  />

                  <RV.GraphTable
                    modelName={'RnxActivityGroupActivityReservation'}
                    sx={{
                      my: 4
                    }}
                    headerText='Aktivite Rezervasyonları'
                    query={{
                      filters: {
                        rnx_contact_id: publicMember.id
                      }
                    }}
                  />

                  <RV.Card sx={{
                    flex: '1 1 0',
                    my: 4
                  }}>
                    <RV.Card.Header>
                      QR Kod
                    </RV.Card.Header>
                    <Box as='a' sx={{
                      display: 'block',
                      my: 4,
                      color: 'brand.500'
                    }} href={`./profile/qr/${publicMember.govId}`}>
                      QR Kod Link
                    </Box>
                    <QRCode value={JSON.stringify({
                      id: publicMember.govId
                    })} />
                  </RV.Card>

                </>
              )} />
              <RV.Route path='membership/:rnxMembershipId/*' element={<ProfileMembership />} />
              <RV.Route path='qr/:qrKey/*' element={<PrQr />} />
            </RV.Routes>
          </Box>
        )
      }}
    </RV.QueryLoading>
  )
}

const ProfileMembership = ({

}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    publicMembership: {
      id: params.rnxMembershipId
    }
  })
  return (
    <RV.QueryLoading result={result}>
      {({ publicMembership }) => {
        return (
          <>
            <RV.Card mt={4}>
              <RV.Layout.Row>
                <RV.Card.Header>
                  <Box>
                    {publicMembership.programName} - {publicMembership.typeName}
                  </Box>
                </RV.Card.Header>
                <RV.Enum value={publicMembership.status} />
              </RV.Layout.Row>

              <RV.Routes>
                <RV.Route path='/' element={(
                  <>
                    {publicMembership.status === 'PASSIVE' ? (
                      <MembershipActivation publicMembership={publicMembership} />
                    ) : (
                      <ActiveMembershipDetails publicMembership={publicMembership} />
                    )}
                  </>
                )} />

                <RV.Route path='p/:packageId/*' element={<ProfilePackageForm publicMembership={publicMembership} />} />
                <RV.Route path='cp/:packageId/*' element={<ProfileCreditPackageForm publicMembership={publicMembership} />} />
                <RV.Route path='program/:programId/*' element={<MembershipActivityGroupProgram publicMembership={publicMembership} />} />
              </RV.Routes>
            </RV.Card>
          </>
        )
      }}
    </RV.QueryLoading>
  )
}

const MembershipActivation = ({
  publicMembership
}) => {
  if (publicMembership.isInvited) {
    return <InvitedMembershipActivation publicMembership={publicMembership} />
  }

  return (
    <>
      <RV.Alert status='info'>
        Abonelik veya kredi satın alarak üyeliğinizi aktif hale getirebilirsiniz.
      </RV.Alert>

      <RV.Text.Header mt={4}>
        Abonelik paketleri
      </RV.Text.Header>

      <Flex mx={-4} mt={2} flexWrap='wrap'>
        {publicMembership.subscriptionPackages.map(p => {
          return (
            <RV.Link to={`./p/${p.id}`} key={p.id} sx={{
              display: 'block',
              my: 2,
              border: '1px solid #FFF',
              borderColor: 'brand.500',
              borderRadius: 2,
              p: 3,
              textAlign: 'center',
              flex: '1 1 0',
              maxWidth: '250px',
              mx: 4
            }}>
              {p.name}

              <Box textAlign='center' sx={{
                fontSize: 'lg',
                color: 'brand.500',
                fontWeight: 'bold'
              }}>
                ₺{p.price}
              </Box>
            </RV.Link>
          )
        })}
      </Flex>

      <RV.Text.Header mt={4}>
        Kredi paketleri
      </RV.Text.Header>

      <Flex mx={-4} mt={2} flexWrap='wrap'>
        {publicMembership.creditPackages.map(p => {
          return (
            <RV.Link to={`./cp/${p.id}`} key={p.id} sx={{
              display: 'block',
              my: 2,
              border: '1px solid #FFF',
              borderColor: 'brand.500',
              borderRadius: 2,
              p: 3,
              textAlign: 'center',
              flex: '1 1 0',
              maxWidth: '250px',
              mx: 4
            }}>
              {p.name}

              <Box textAlign='center' sx={{
                fontSize: 'lg',
                color: 'brand.500',
                fontWeight: 'bold'
              }}>
                ₺{p.price}
              </Box>
            </RV.Link>
          )
        })}
      </Flex>
    </>
  )
}

const InvitedMembershipActivation = ({
  publicMembership
}) => {
  const result = RV.useReactorQuery({
    publicInvitedMembershipActivation: {
      id: publicMembership.id
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ publicInvitedMembershipActivation }) => {
        return (
          <>
            {/* <Box mt={4}>
              <RV.Card.Header>
                {publicMembershipPackage.name}
              </RV.Card.Header>
            </Box> */}

            <MembershipActivateForm membershipId={publicMembership.id} isReservationRequired={publicInvitedMembershipActivation.isReservationRequired} properties={publicInvitedMembershipActivation.properties} />

            <ProgramDynamicCalendar calendar={publicInvitedMembershipActivation.calendar} />
          </>
        )
      }}
    </RV.QueryLoading>
  )
}

const MembershipActivateForm = ({
  membershipId,
  properties,
  isReservationRequired
}) => {
  const [formProps, isSubmitting] = useReactorActionForm(
    'PrBase.ActivateInvitedMembership',
    {
      initialValues: {
        id: membershipId
      },
      onSuccess: () => {
        window.location.reload()
      }
    }
  )

  if (formProps.phase === 'SUCCESS') {
    return (
      <RV.Alert status='info'>
        Üywliğiniz aktifleşti.
      </RV.Alert>
    )
  }

  const showDaySelection = !isReservationRequired

  return (
    <Form {...formProps}>
      {/* {showDaySelection && <RfFormField
        name="dayOfWeek"
        label={'Günler'}
        description={`En fazla ${properties.interval_limit} gün seçebilirsiniz.`}
        tabIndex={1}
        required
        kind='checkboxGroup'
        options={{
          isMultiple: true,
          limit: properties.interval_limit,
          // translate: true,
          options: [
            {
              value: 'MONDAY',
              label: 'Pazartesi'
            },

            {
              value: 'TUESDAY',
              label: 'Salı'
            },

            {
              value: 'WEDNESDAY',
              label: 'Çarşamba'
            },

            {
              value: 'THURSDAY',
              label: 'Perşembe'
            },

            {
              value: 'FRIDAY',
              label: 'Cuma'
            },

            {
              value: 'SATURDAY',
              label: 'Cumartesi'
            },

            {
              value: 'SUNDAY',
              label: 'Pazar'
            },
          ]
        }} />} */}
      <RfFormError error={formProps.error} />
      <RV.Button isFullWidth colorScheme='brand' submit my={4} isLoading={isSubmitting}>
        AKTİFLEŞTİR
      </RV.Button>
    </Form>
  )
}

const ActiveMembershipDetails = ({
  publicMembership
}) => {
  const result = RV.useReactorQuery({
    publicMembershipSubscriptions: {
      id: publicMembership.id
    }
  })

  return (
    <>
      <RV.QueryLoading result={result}>
        {({ publicMembershipSubscriptions }) => {
          if (publicMembershipSubscriptions.activeSubscriptions.length > 0) {
            return (
              <Box>
                <Box sx={{
                  my: 4
                }}>
                  <RV.Card.Header>
                    Aktif Abonelikleriniz
                  </RV.Card.Header>
                  {publicMembershipSubscriptions.activeSubscriptions.map(s => {
                    return (
                      <Box key={s.id} sx={{
                        border: '1px solid #FFF',
                        borderColor: 'brand.500',
                        p: 2,
                        borderRadius: 2,
                        my: 2
                      }}>
                        #{s.id} Bitiş: <RV.DateTime data={s.datetimeFinish} />
                      </Box>
                    )
                  })}
                </Box>

                {publicMembershipSubscriptions.hasReservation && <MembershipActivityGroup publicMembership={publicMembership} />}
              </Box>
            )
          } else {
            return (
              <>
                {publicMembership.hasCredit && <PropertyActivityGroup publicMembership={publicMembership} />}

                <RV.Alert status='info'>
                  Aktif aboneliğiniz yok. Abonelik veya kredi almak istediğiniz paketi seçerek devam edebilirsiniz.
                </RV.Alert>

                <RV.Text.Header mt={4}>
                  Abonelik paketleri
                </RV.Text.Header>

                <Flex mx={-4} mt={2} flexWrap='wrap'>
                  {publicMembership.subscriptionPackages.map(p => {
                    return (
                      <RV.Link to={`./p/${p.id}`} key={p.id} sx={{
                        display: 'block',
                        my: 2,
                        border: '1px solid #FFF',
                        borderColor: 'brand.500',
                        borderRadius: 2,
                        p: 3,
                        textAlign: 'center',
                        flex: '1 1 0',
                        maxWidth: '250px',
                        mx: 4
                      }}>
                        {p.name}

                        <Box textAlign='center' sx={{
                          fontSize: 'lg',
                          color: 'brand.500',
                          fontWeight: 'bold'
                        }}>
                          ₺{p.price}
                        </Box>
                      </RV.Link>
                    )
                  })}
                </Flex>

                <RV.Text.Header mt={4}>
                  Kredi paketleri
                </RV.Text.Header>

                <Flex mx={-4} mt={2} flexWrap='wrap'>
                  {publicMembership.creditPackages.map(p => {
                    return (
                      <RV.Link to={`./cp/${p.id}`} key={p.id} sx={{
                        display: 'block',
                        my: 2,
                        border: '1px solid #FFF',
                        borderColor: 'brand.500',
                        borderRadius: 2,
                        p: 3,
                        textAlign: 'center',
                        flex: '1 1 0',
                        maxWidth: '250px',
                        mx: 4
                      }}>
                        {p.name}

                        <Box textAlign='center' sx={{
                          fontSize: 'lg',
                          color: 'brand.500',
                          fontWeight: 'bold'
                        }}>
                          ₺{p.price}
                        </Box>
                      </RV.Link>
                    )
                  })}
                </Flex>
              </>
            )
          }
        }}
      </RV.QueryLoading>
    </>
  )
}

const MembershipActivityGroup = ({
  publicMembership
}) => {
  const result = RV.useReactorQuery({
    publicMembershipActivityGroupPrograms: {
      id: publicMembership.id
    }
  })
  return (
    <>
      <RV.QueryLoading result={result}>
        {({ publicMembershipActivityGroupPrograms }) => {
          return (
            <>
              <RV.Alert status='info'>
                Rezervasyon almak istediğiniz programı seçin.
              </RV.Alert>
              {publicMembershipActivityGroupPrograms.items?.map(p => {
                return (
                  <RV.Link sx={{
                    display: 'block',
                    my: 2,
                    border: '1px solid #FFF',
                    borderColor: 'brand.500',
                    p: 2,
                    borderRadius: 2
                  }} key={p.id} to={`./program/${p.id}`}>
                    {p.name}
                  </RV.Link>
                )
              })}
            </>
          )
        }}
      </RV.QueryLoading>
    </>
  )
}

const PropertyActivityGroup = ({
  publicMembership
}) => {
  const result = RV.useReactorQuery({
    publicPropertyActivityGroupPrograms: {
      id: publicMembership.id
    }
  })
  return (
    <>
      <RV.QueryLoading result={result}>
        {({ publicPropertyActivityGroupPrograms }) => {
          return (
            <>
              <RV.Alert status='info'>
                Rezervasyon almak istediğiniz programı seçin..
              </RV.Alert>
              {publicPropertyActivityGroupPrograms.items?.map(p => {
                return (
                  <RV.Link sx={{
                    display: 'block',
                    my: 2,
                    border: '1px solid #FFF',
                    borderColor: 'brand.500',
                    p: 2,
                    borderRadius: 2
                  }} key={p.id} to={`./program/${p.id}`}>
                    {p.name}
                  </RV.Link>
                )
              })}
            </>
          )
        }}
      </RV.QueryLoading>
    </>
  )
}

const MembershipActivityGroupProgram = ({
  publicMembership
}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    publicMembershipActivityGroupProgram: {
      id: params.programId
    }
  })
  return (
    <>
      <RV.QueryLoading result={result}>
        {({ publicMembershipActivityGroupProgram }) => {
          return (
            <>
              <RV.Link sx={{
                display: 'block',
                my: 2,
                border: '1px solid #FFF',
                borderColor: 'brand.500',
                p: 2,
                borderRadius: 2
              }} to={`./../../`}>
                {'<'} Geri Dön
              </RV.Link>
              <ProgramActivityListForReservation reservationMode={publicMembershipActivityGroupProgram.reservationMode} refresh={result.refresh} calendar={publicMembershipActivityGroupProgram.calendar} reservations={publicMembershipActivityGroupProgram.reservations} />
            </>
          )
        }}
      </RV.QueryLoading>
    </>
  )
}

const ProgramActivityListForReservation = ({
  calendar,
  refresh,
  reservations,
  reservationMode
}) => {
  if (!calendar) return null
  console.log('--')
  const activities = React.useMemo(() => {
    const resp = []
    calendar.activities?.forEach(a => {
      const startHourSplit = a.timeStart.split(':')
      const finishHourSplit = a.timeFinish.split(':')

      const now = dayjs()
      const today = dayjs().startOf('today')
      const aWeekDay = today.weekday(a.dayOfWeek.key - 1)
      const start = aWeekDay.hour(startHourSplit[0]).minute(startHourSplit[1])
      const finish = aWeekDay.hour(finishHourSplit[0]).minute(finishHourSplit[1])

      const isToday = today.weekday() + 1 === a.dayOfWeek.key
      const isAvailable = start.add(1, 'hour').isAfter(now)
      // res = reservations.find()
      const isActive = reservations.find(x => x.weekday === a.dayOfWeek.key && x.timeStart === a.timeStart)
      console.log(a.name, a.dayOfWeek, isActive, reservations)

      let canAdd = isToday && isAvailable

      if (reservationMode === 'WEEKLY') {
        if (isToday) {
          canAdd = isAvailable
        } else if (today.weekday() + 1 < a.dayOfWeek.key) {
          canAdd = true
        } else {
          canAdd = false
        }
      }

      if (canAdd) {
        resp.push({
          start, finish, activity: a, name: a.name, isActive
        })
      }
    })
    return resp
  }, [calendar.activities])

  return (
    <Box sx={{

    }}>

      {activities.length === 0 ? (
        <>
          <RV.Alert status='info'>
            Rezervasyon alabileceğiniz aktivite bulunamamıştır.
          </RV.Alert>
        </>
      ) : (
        <>
          <RV.Alert status='info'>
            Rezervasyon alabileceğiniz aktiviteler listelenmiştir. Kapasite dolu olması durumunda rezervasyon alamayabilirsiniz.
          </RV.Alert>

          {activities.map(a => {
            const action = a.isActive ? (
              <RV.ReactorServerAction isLazy lazyAnchor={<RV.Button colorScheme='brand' isFullWidth>Rezervasyon Kontrol Et</RV.Button>} hideIfEmpty={(<RV.Alert status='warning'>Bu aktiviteye rezervasyon yapamazsınız.</RV.Alert>)} anchorProps={{ isFullWidth: true }} options={{ ctx: { id: a.activity.id } }} onSuccess={refresh} initialValues={{ id: a.activity.id }} identifier='RnxActivityGroup.CancelRnxActivityGroupProgramActivityReservation' />
            ) : (
              <RV.ReactorServerAction isLazy lazyAnchor={<RV.Button colorScheme='brand' isFullWidth>Rezervasyon Kontrol Et</RV.Button>} hideIfEmpty={(<RV.Alert status='warning'>Bu aktiviteye rezervasyon yapamazsınız.</RV.Alert>)} anchorProps={{ isFullWidth: true }} options={{ ctx: { id: a.activity.id } }} onSuccess={refresh} initialValues={{ id: a.activity.id }} identifier='RnxActivityGroup.CreateRnxActivityGroupProgramActivityReservation' />
            )
            return (
              <Box sx={{
                border: `${a.isActive ? 3 : 1}px solid #FFF`,
                borderColor: a.activity?.color || 'brand.500',
                color: a.activity?.color || 'brand.500',
                p: 2,
                my: 2,
                borderRadius: 2,
                textAlign: 'center'
              }}>
                <Box sx={{
                  fontWeight: 'bold'
                }}>
                  {a.start.format('DD.MM.YYYY')} | {a.start.format('HH:mm')} - {a.finish.format('HH:mm')}
                </Box>
                <Box sx={{
                  my: 2,
                  fontSize: 'lg'
                }}>
                  {a.name}
                </Box>
                <Box>
                  {action}
                </Box>
              </Box>
            )
          })}
        </>
      )}


      <RV.Alert status='info'>
        Program takvimini inceleyebilirsiniz.
      </RV.Alert>

      <ProgramDynamicCalendarForReservation
        calendar={calendar}
        refresh={refresh}
        reservations={reservations}
      />
    </Box>
  )
}

const ProgramDynamicCalendarForReservation = ({
  calendar,
  refresh,
  reservations
}) => {
  if (!calendar) return null
  const activities = React.useMemo(() => {
    const resp = {}
    calendar.activities?.forEach(a => {
      const startHourSplit = a.timeStart.split(':')
      const finishHourSplit = a.timeFinish.split(':')

      const today = dayjs().startOf('today')
      const start = today.hour(startHourSplit[0]).minute(startHourSplit[1])
      const finish = today.hour(finishHourSplit[0]).minute(finishHourSplit[1])
      // res = reservations.find()
      const isActive = reservations.find(x => x.weekday === a.dayOfWeek.key && x.timeStart === a.timeStart)

      // const menu = isActive ? (
      //   <RV.ReactorServerAction options={{ctx: {id: a.id}}} identifier='RnxActivityGroup.CreateRnxActivityGroupProgramActivityReservation'/>
      // ) : (
      //   <RV.ReactorServerAction options={{ctx: {id: a.id}}} identifier='RnxActivityGroup.CancelRnxActivityGroupProgramActivityReservation'/>
      // )

      const menu = (
        <RV.ActionGroupMenu
          name={'RnxActivityGroupProgramActivity'} callbacks={{
            onSuccess: refresh
          }} contexts={['Entity.ReservationForUser']} ctx={{ id: a.id }}
        />
      )

      if (!resp[a.dayOfWeek.key - 1]) resp[a.dayOfWeek.key - 1] = []
      resp[a.dayOfWeek.key - 1].push({
        start, finish, activity: a, name: a.name, menu, isActive
      })

    })
    return resp
  }, [calendar.activities])
  console.log(activities)
  return (
    <Box sx={{
      overflow: 'scroll'
    }}>
      <DynamicCalendar activities={activities} />
    </Box>
  )
}

const ProfilePackageForm = ({
  publicMembership
}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    publicMembershipPackage: {
      id: params.packageId
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ publicMembershipPackage }) => {
        console.log(publicMembershipPackage)
        return (
          <Box>
            <RV.Link to='../'>Paketlere geri dön</RV.Link>

            <Box mt={4}>
              <RV.Card.Header>
                {publicMembershipPackage.name}
              </RV.Card.Header>
            </Box>

            <RV.Routes>
              <RV.Route path='/' element={(
                <>
                  <RV.Button as={RV.Link} to='./register' isFullWidth colorScheme='brand' my={4}>
                    ABONE OL
                  </RV.Button>

                  <ProgramDynamicCalendar calendar={publicMembershipPackage.calendar} />
                </>
              )} />

              <RV.Route path='register' element={(
                <PackageRegister id={params.packageId} membershipId={publicMembership.id} publicMembershipPackage={publicMembershipPackage} />
              )} />
            </RV.Routes>
          </Box>
        )
      }}
    </RV.QueryLoading>
  )
}

const ProfileCreditPackageForm = ({
  publicMembership
}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    publicMembershipPackage: {
      id: params.packageId
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ publicMembershipPackage }) => {
        console.log(publicMembershipPackage)
        return (
          <Box>
            <RV.Link to='../'>Paketlere geri dön</RV.Link>

            <Box mt={4}>
              <RV.Card.Header>
                {publicMembershipPackage.name}
              </RV.Card.Header>
            </Box>

            <RV.Routes>
              <RV.Route path='/' element={(
                <>
                  <CreditBuyForm id={params.packageId} membershipId={publicMembership.id} publicMembershipPackage={publicMembershipPackage} />

                  <ProgramDynamicCalendar calendar={publicMembershipPackage.calendar} />
                </>
              )} />
            </RV.Routes>
          </Box>
        )
      }}
    </RV.QueryLoading>
  )
}

import DynamicCalendar from 'elements/rnx_activity_group/rnxActivityGroup/components/DynamicCalendar'
// Vendor
import dayjs from 'dayjs'

const ProgramDynamicCalendar = ({
  calendar
}) => {
  if (!calendar) return null
  const activities = React.useMemo(() => {
    const resp = {}
    calendar.activities?.forEach(a => {
      const startHourSplit = a.timeStart.split(':')
      const finishHourSplit = a.timeFinish.split(':')

      const today = dayjs().startOf('today')
      const start = today.hour(startHourSplit[0]).minute(startHourSplit[1])
      const finish = today.hour(finishHourSplit[0]).minute(finishHourSplit[1])

      if (!resp[a.dayOfWeek.key - 1]) resp[a.dayOfWeek.key - 1] = []
      resp[a.dayOfWeek.key - 1].push({
        start, finish, activity: a, name: a.name
      })

    })
    return resp
  }, [calendar.activities])
  return (
    <Box sx={{
      overflow: 'scroll'
    }}>
      <DynamicCalendar activities={activities} />
    </Box>
  )
}

import { Form, RfFormError, RfFormField, useReactorActionForm, RfFormRow } from 'reactor-form/components/RfForm'


const PackageRegister = ({
  id,
  membershipId,
  publicMembershipPackage
}) => {
  // return (
  //   <PackageRegisterForm id={id} membershipId={membershipId} publicMembershipPackage={publicMembershipPackage} />
  // )
  const dispatchAction = RV.useReactorAction('PrBase.CheckRenewal')
  const [status, setStatus] = React.useState(null)
  React.useEffect(() => {
    dispatchAction({
      id,
      membershipId
    }, {
      success: data => {
        setStatus(data)
      }
    })
  }, [])

  console.log('st', status)

  if (status?.ok === true) {
    return (
      <PackageRegisterForm id={id} membershipId={membershipId} publicMembershipPackage={publicMembershipPackage} />
    )
  }

  if (status?.ok === false) {
    if (!status.parameters) {
      return 'Yenileme isteğiniz değerlendiriliyor.'
    }
    return (
      <>
      Aboneliğinizi yenilemeniz gerekiyor.

      <RV.RfActionForm
        actionKey={`PrBase.ApplyForMembershipRenewal`}
        fields={status.parameters}
        initialValues={{
          membershipId
        }}
        buttonText='Başvur'
        name=''
        icon={null}
        displayAs='FORM'
        elements={{
          successMessage: (result) => {
            console.log('res', result)
            return (
              <Box>
                Başvuru numaranız: {result.code}

                <Box>
                  Bu numarayı saklayınız.
                </Box>

                <Box>
                  İşleminizin sonucu SMS olarak bildirilecektir.
                </Box>
              </Box>
            )
          }
        }}
      // displayAs={displayAs || action.meta.ui.displayAs}
      // onClose={onClose}
      // anchorKind={anchorKind}
      // anchor={anchor}
      />
      </>
    )
  }

  return null
}


const PackageRegisterForm = ({
  id,
  membershipId,
  publicMembershipPackage
}) => {
  const [formProps, isSubmitting] = useReactorActionForm(
    'PrBase.PreparePaymentForPackage',
    {
      initialValues: {
        id,
        membershipId
      },
      onSuccess: () => { }
    }
  )

  if (formProps.phase === 'SUCCESS') {
    if (formProps.result?.paymentStatus == 'COMPLETED') {
      return (
        'Aboneliğiniz aktifleştirildi, sayfayı yenileyin.'
      )
    } else {
      return (
        <CreditCardForm operationId={formProps.result.id} />
      )
    }
  }

  return (
    <Form {...formProps}>
      <RV.Link to='../'>Takvime geri dön</RV.Link>
      {/* {!publicMembershipPackage.isReservationRequired && <RfFormField
        name="data.dayOfWeek"
        label={'Günler'}
        description={`En fazla ${publicMembershipPackage.properties.interval_limit} gün seçebilirsiniz.`}
        tabIndex={1}
        required
        kind='checkboxGroup'
        options={{
          isMultiple: true,
          limit: publicMembershipPackage.properties.interval_limit,
          // translate: true,
          options: [
            {
              value: 'MONDAY',
              label: 'Pazartesi'
            },

            {
              value: 'TUESDAY',
              label: 'Salı'
            },

            {
              value: 'WEDNESDAY',
              label: 'Çarşamba'
            },

            {
              value: 'THURSDAY',
              label: 'Perşembe'
            },

            {
              value: 'FRIDAY',
              label: 'Cuma'
            },

            {
              value: 'SATURDAY',
              label: 'Cumartesi'
            },

            {
              value: 'SUNDAY',
              label: 'Pazar'
            },
          ]
        }} />} */}
      <RfFormError error={formProps.error} />
      <RV.Button isFullWidth colorScheme='brand' submit my={4} isLoading={isSubmitting}>
        ÖDEME AŞAMASINA GEÇ
      </RV.Button>
    </Form>
  )
}

import * as cardHelpers from '../cardHelpers'

const CreditCardForm = ({
  operationId
}) => {
  const [nextStep, setNextStep] = React.useState()
  const [formProps, isSubmitting] = useReactorActionForm(
    'PrBase.MakeCreditCardPayment',
    {
      initialValues: {
        id: operationId,
      },
      onSuccess: (data) => {
        setNextStep(data.nextStep)
      }
    }
  )

  if (nextStep) {
    if (nextStep.name === 'CONFIRM_3D_FORM_POST') return <Payment3DPostForm paymentStep={nextStep} />
  }

  return (
    <Box>
      <Form {...formProps}>
        <RfFormField label='Kart Sahibi' name='cc.cardHolderName' />
        <RfFormField name='cc.number' kind='text' format={cardHelpers.formatCreditCardNumber} placeholder='1234 1234 1234 1234' sx={{
          m: 0
        }} />
        <RfFormRow gap={0} sx={{
          mb: 4,
        }}>
          <RfFormField name='cc.validUntil' kind='text' format={cardHelpers.formatExpirationDate} placeholder='AA / YY' sx={{
            m: 0
          }} />
          <RfFormField name='cc.securityCode' kind='text' format={cardHelpers.formatCVC} label='CVC' sx={{
            m: 0
          }} />
        </RfFormRow>
        <RfFormError error={formProps.error} />
        <RV.Button isFullWidth colorScheme='brand' submit my={4} isLoading={isSubmitting}>
          ÖDEME YAP
        </RV.Button>
      </Form>
    </Box>
  )
}

const Payment3DPostForm = ({
  paymentStep
}) => {
  const formRef = React.useRef()
  React.useEffect(() => {
    if (!formRef.current) return null
    formRef.current.submit()
  }, [formRef.current])
  // console.log(paymentStep)
  // return null

  return (
    <Box>
      <RV.Alert status='info' mt={6} sx={{
        textTransform: 'initial',
      }}>
        3D Doğrulama sayfasına yönlendiriliyorsunuz.
      </RV.Alert>
      <form ref={formRef} action={paymentStep.step_data.url} method='POST'>
        {Object.entries(paymentStep.step_data.formData).map(([k, v]) => {
          return (
            <input type='hidden' name={k} value={v} key={k} />
          )
        })}
        {/* <button type='submit'>submit</button> */}
      </form>
    </Box>
  )
}

const CreditBuyForm = ({
  id,
  membershipId,
  publicMembershipPackage
}) => {
  const [formProps, isSubmitting] = useReactorActionForm(
    'PrBase.PreparePaymentForCreditPackage',
    {
      initialValues: {
        id,
        membershipId
      },
      onSuccess: () => { }
    }
  )

  if (formProps.phase === 'SUCCESS') {
    return (
      <Box>
        <RV.Alert status='info'>
          Ödemenizi tamamladıktan sonra randevu almanız gerekiyor. Randevu almadığınız taktirde giriş yapamazsınız. Lütfen ödeme işleminden sonra sistem üzerinden gideceğiniz seans için randevu alınız.
        </RV.Alert>
        <>
        <TodayReservationActivities activityGroupId={publicMembershipPackage.activityGroupId}/>
        </>
        <CreditCardForm operationId={formProps.result.id} />
      </Box>
    )
  }

  return (
    <Form {...formProps}>
      <RfFormError error={formProps.error} />
      <RV.Button isFullWidth colorScheme='brand' submit my={4} isLoading={isSubmitting}>
        ÖDEME AŞAMASINA GEÇ
      </RV.Button>
    </Form>
  )
}

const TodayReservationActivities = ({
  activityGroupId
}) => {
  const result = RV.useReactorQuery({
    rnxActivityGroupActivityList: {
      // fields: ['name', 'kind', 'status', 'todayCount', 'currentWeekCount', 'currentMonthCount', 'insideCount'],
      filters: {
        'rnx_activity_group.id': activityGroupId,
        datetimeStart: '@today',
      },
      orderBy: 'datetimeStart|asc'
    }
  })

  return (
    <>
      <RV.Card my={4}>
        <RV.Layout.Row>
          <RV.Card.Header>
            Günün Rezervasyon Alınan Etkinlikleri
          </RV.Card.Header>
          {/* {result.status?.isFetching ? (
            <Loader size={20} color='dark.500' />
          ) : (
            <Tooltip label='Yenile'>
              <Box as='a' sx={{
                display: 'block',
                ml: 2,
                color: 'dark.100',
                transition: 'all 0.2s ease',
                '&:hover': {
                  color: 'brand.500',
                }
              }} onClick={result.refresh}>
                <RV.Icon name='refresh' size={4} />
              </Box>
            </Tooltip>
          )} */}
        </RV.Layout.Row>
        <RV.QueryLoading result={result}>
          {({ rnxActivityGroupActivityList }) => {
            console.log(rnxActivityGroupActivityList)
            return rnxActivityGroupActivityList.items?.map(rnxActivityGroupActivity => {
              return (
                <RV.Layout.Row key={rnxActivityGroupActivity.id} my={2} alignItems='center'>
                  {rnxActivityGroupActivity.name}

                  <Box>
                    <RV.DateTime format='DD.MM.YYYY H:mm' data={rnxActivityGroupActivity.datetimeStart} />
                  </Box>

                  <Box>
                    <RV.DateTime format='DD.MM.YYYY H:mm' data={rnxActivityGroupActivity.datetimeFinish} />
                  </Box>

                  <Box>
                    Açık kapasite: {rnxActivityGroupActivity.capacity - rnxActivityGroupActivity.countReservation}
                  </Box>

                </RV.Layout.Row>
              )
            })
          }}
        </RV.QueryLoading>
      </RV.Card>
    </>
  )
}

export default PrUserProfile
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Card from './Card'
import Text from 'reactor-ui/components/Text'


const StatBox = ({
  label,
  value,
  description,
  info,
  suffix,
  sx,
  colorDx = 0,
  maxWidth = '20%'
}) => {
  return (
    <Card sx={{
      maxWidth: maxWidth,
      borderColor: `accents.${colorDx}`,
      ...sx,
    }}>
      <Flex sx={{
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Card.Header sx={{
          variant: 'layout.cardHeader',
          color: `accents.${colorDx}`
        }}>
          <Text.Translated>
          {label}
          </Text.Translated>
        </Card.Header>

        {description && (
          <Box sx={{
            color: 'textMuted',
            fontSize: 's'
          }}>
            {description}
          </Box>
        )}
      </Flex>
      <Flex sx={{
        alignItems: 'baseline'
      }}>
        <Box sx={{
          fontWeight: 'semibold',
          fontSize: 'lg',
          mt: 2
        }}>
          {value}
        </Box>
        {suffix && (
          <Box sx={{
            color: 'dark.300',
            // fontSize: 's',
            ml: 1
          }}>
            {suffix}
          </Box>
        )}
      </Flex>

      {info && (
        <Box sx={{
          color: 'dark.300',
          fontSize: 'sm',
          mt: 3
        }}>
          {info}
        </Box>
      )}
    </Card>
  )
}

export default StatBox
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import RichTextInput from 'reactor-ui/components/RichTextInput'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfRichTextField = ({
  name,
  field
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
    format: field.format
  })

  return (
    <Box>
      <RichTextInput {...input} label={t(field.label)}/>
      <RfFieldAlert meta={meta} sx={{
        mt: -2,
        zIndex: 0,
        pt: 4,
        pb: 3,
      }} />
      <RfFieldDescription field={field} sx={{ mb: 2 }} />
    </Box>
  )
}

export default RfRichTextField
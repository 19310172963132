import React from 'react'

// Vendor
import { useField, useFormState } from 'react-final-form'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import { ReactorFormContext } from 'reactor-form/context'
import RfFormField from 'reactor-form/components/RfFormField'
import * as changeCase from 'reactor-ui/util/text'


const UserAssignmentModelSelector = ({
  field,
  name
}) => {
  const state = useFormState()
  const kind = state.values.kind

  const formContext = React.useContext(ReactorFormContext)
  const data = formContext.entityMap.rcRecord[kind].data
  return (
    <RfFormField kind='entity' name={field.name} options={{modelName: changeCase.camel(data.model), nameField: 'fqName'}}/>
  )
}

export default UserAssignmentModelSelector
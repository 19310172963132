import React from 'react'

// Vendor
import { BrowserRouter as Router } from 'react-router-dom'

// Volcano
import ReactorUi from 'reactor-ui'

// Reactor
import ReactorVeraAppBase from './ReactorVeraAppBase'

import uiApp from 'reactor-vera/apps/ui'
// import modelApp from 'reactor-vera/apps/model'

import stream from 'reactor/systems/stream'
import uiSelection from 'reactor/systems/uiSelection'

import ReactorVera from 'reactor-vera/systems/reactor-vera'

import ReactorVeraIndex from 'reactor-vera/components/ReactorVeraIndex'

import ReactorSocketManager from 'reactor/ReactorSocketManager'

const defaultApps = [
  uiApp,
  // modelApp,
]

const defaultSystems = [
  ReactorVera,
  stream,
  uiSelection
]

// import RfFormProvider from 'reactor-form/components/RfFormProvider'

const ReactorVeraApp = ({
  theme,
  globalCss,
  uiConfig,
  apps = [],
  systems = [],
  storeCreator,
  config,
  homeElement,
  indexComponent,
  children,
  routes,
  lang
}) => {
  return (
    <ReactorVeraAppBase
      apps={[...defaultApps, ...apps]}
      systems={[...defaultSystems, ...systems]}
      storeCreator={storeCreator}
      config={config}
      theme={theme}
      globalCss={globalCss}
      uiConfig={uiConfig}
      lang={lang}
    >
      <Router>
        <ReactorSocketManager />
          {/* <RfFormProvider theme={theme} ctx={{
            lookupBaseUrl: '/_api/reactor/source/',
            remoteLookupBaseUrl: '/_api/reactor/remote/',
            mediaUploadUrl: '/_api/reactor/upload/RxStorage.UploadMedia',
            // mediaGalleryUrl: '/_api/reactor/source/RxFile.Media.image_list',
            entityMap: {},
            headers: { 'X-RUNIC-PLATFORM': 'RUNIC_MAIN' },
          }}> */}
          {indexComponent || <ReactorVeraIndex uiConfig={uiConfig} runicConfig={config} homeElement={homeElement} routes={routes} />}
          {children}
          {/* </RfFormProvider> */}
      </Router>
    </ReactorVeraAppBase>
  )
}

export default ReactorVeraApp
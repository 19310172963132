import React from 'react'

// Vendor
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import MediaItem from './MediaItem'
import TextInput from 'reactor-ui/components/TextInput'

import useLocationFilter from 'reactor-vera/hooks/useLocationFilter'
import Pagination from 'reactor-vera/apps/data/components/Pagination'


const BroadcastPlaylist = ({
  rnxBroadcast,
  refresh
}) => {
  const [isEditing, setIsEditing] = React.useState(false)
  const [items, itemsSet] = React.useState(rnxBroadcast?.items?.map(x => ({
    id: `r${x.id}`,
    displayTime: x.display_time
  })) || [])

  if (isEditing) return (
    <BroadcastPlaylistEdit refresh={() => {
      refresh()
      setIsEditing(false)
    }} rnxBroadcast={rnxBroadcast} items={items} itemsSet={itemsSet} setIsEditing={setIsEditing} />
  )
  return (
    <RV.Card>
      <Flex alignItems='center' justifyContent='space-between'>
        <RV.Card.Header>
          Medyalar
        </RV.Card.Header>
        <RV.Button iconName='pencil' onClick={() => setIsEditing(v => !v)}>Güncelle</RV.Button>
      </Flex>

      <BroadcastPlaylistItems rnxBroadcast={rnxBroadcast} />
    </RV.Card>
  )
}

const BroadcastPlaylistItems = ({
  rnxBroadcast,
}) => {
  const result = RV.useReactorQuery({
    'rnxBroadcastMediaList': {
      filters: {
        id: rnxBroadcast.items?.map(x => x.id)
      },
      itemFields: [
        'name', 'kind', 'datetimeCreated', 'mediaFile'
      ]
    }
  })

  if (!result.graph?.rnxBroadcastMediaList?.items) return null
  return (
    <Flex sx={{
      flexWrap: 'wrap',
      mx: -2
      // justifyContent: 'space-between'
    }}>
      {rnxBroadcast?.items?.map((itemData) => {
        return (
          <RV.GraphEntity key={itemData.id} id={itemData.id} modelName='RnxBroadcastMedia'>
            {entity => <MediaItem key={entity.id}
              showActionMenu={false}
              item={entity} refresh={result.refresh}>
              <Box sx={{
                fontSize: 's',
                textTransform: 'uppercase',
                color: 'textMuted',
                mt: 2
              }}>
                <RV.Icon name='timer'>
                  {itemData.display_time} saniye
                </RV.Icon>
              </Box>
            </MediaItem>}
          </RV.GraphEntity>
        )
      })}
    </Flex>
  )
}

const BroadcastPlaylistEdit = ({
  rnxBroadcast,
  setIsEditing,
  items,
  itemsSet,
  refresh,
}) => {
  return (
    <>
      <Flex alignItems='center' justifyContent='space-between'>
        <RV.Card.Header>
          Medyalar
        </RV.Card.Header>
        <Flex alignItems='center'>
          <RV.Button iconName='pencil' onClick={() => setIsEditing(v => !v)}>Vazgeç</RV.Button>
          <RV.ActionButton actionIdentifier='RnxBroadcast.UpdatePlaylist' iconName='save' data={{ items, id: rnxBroadcast.id }} callbacks={{ success: () => refresh() }}>Kaydet</RV.ActionButton>
        </Flex>
      </Flex>

      <BroadcastPlaylistEditContent items={items} itemsSet={itemsSet} />
    </>
  )
}

const BroadcastPlaylistEditContent = ({
  items,
  itemsSet
}) => {

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      // return
      const newItems = [...items]
      newItems.splice(result.source.index, 1)
      itemsSet(newItems)
      return
    }

    if (result.destination.droppableId !== 'playlist') {
      return
    }

    if (result.destination.droppableId === result.source.droppableId) {
      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index
      )
      itemsSet(newItems)
      return
    }

    itemsSet((v) => {
      const newItems = reorder(
        [{
          id: `r${result.draggableId}`,
          displayTime: 10
        }, ...v],
        0,
        result.destination.index
      )
      return newItems
    })
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="playlist" direction="horizontal">
        {(provided, snapshot) => (
          <Flex
            ref={provided.innerRef}
            sx={{
              backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'light4',
              borderRadius: 2,
              border: '1px solid #FFF',
              borderColor: 'primary',
              padding: 2,
              // pt: 3,
              py: 4,
              overflow: 'auto',
              minHeight: 100,
            }}
            {...provided.droppableProps}
          >
            {items.map((itemData, index) => {
              const itemRId = itemData.id
              const item = itemRId.replace('r', '')
              return (
                <Draggable key={`${item}-${index}`} draggableId={`${item}-${index}`} index={index}>
                  {(provided, snapshot) => (
                    <RV.GraphEntity id={item} modelName='RnxBroadcastMedia'>
                      {entity => <MediaItem
                        showActionMenu={false}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        item={entity} refresh={null} sx={{
                          background: snapshot.isDragging ? 'lightgreen' : '#FFF',
                          userSelect: 'none',
                          ...provided.draggableProps.style,
                        }}>
                        <RV.Button isFullWidth sx={{
                          mt: 2,
                          mb: 3
                        }} onClick={() => {
                          const newItems = [...items]
                          newItems.splice(index, 1)
                          itemsSet(newItems)
                        }}>
                          KALDIR
                        </RV.Button>

                        <TextInput requiredLabel={false} label={'Gösterim Süresi'} value={itemData.displayTime} onChange={(e) => {
                          const newItems = [...items]
                          newItems.splice(index, 1, {
                            ...itemData,
                            displayTime: e.target.value
                          })
                          itemsSet(newItems)
                        }} type='number' />
                      </MediaItem>}
                    </RV.GraphEntity>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>

      <MediaDroppable />
    </DragDropContext>
  )
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const MediaDroppable = ({

}) => {
  const [filters, filtersSet] = React.useState()

  const filterInstance = useLocationFilter('media', {})
  const result = RV.useReactorQuery({
    'rnxBroadcastMediaList': {
      filters: {
        ...filters,
        ...filterInstance.locationFilters,
      },
      itemFields: [
        'name', 'kind', 'datetimeCreated', 'mediaFile'
      ],
    }
  })

  return (
    <>
      <Flex sx={{
        alignItems: 'center',
        mb: 2,
        mx: -2,
        my: 4
      }}>
        <Box sx={{
          flex: '1 1 auto',
          mx: 2
        }}>

        </Box>

        <Pagination graph={result.graph?.rnxBroadcastMediaList} filterInstance={filterInstance} />
      </Flex>
      <Droppable droppableId="images" direction="horizontal" isDropDisabled>
        {(provided, snapshot) => (
          <Flex
            ref={provided.innerRef}
            sx={{
              padding: 2,
              overflow: 'auto',
              minHeight: 100,
              mt: 3,
              flexWrap: 'wrap',
              // justifyContent: 'space-between'
            }}
            {...provided.droppableProps}
          >
            {result.graph?.rnxBroadcastMediaList?.items?.map((item, index) => {
              return (
                <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                  {(provided, snapshot) => (
                    <MediaItem
                      showActionMenu={false}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      item={item} refresh={result.refresh} sx={{
                        background: snapshot.isDragging ? 'lightgreen' : '#FFF',
                        userSelect: 'none',
                        ...provided.draggableProps.style,
                      }} />
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>
      <Flex sx={{
        alignItems: 'center',
        mb: 2,
        mx: -2,
        my: 4
      }}>
        <Box sx={{
          flex: '1 1 auto',
          mx: 2
        }}>

        </Box>

        <Pagination graph={result.graph?.rnxBroadcastMediaList} filterInstance={filterInstance} />
      </Flex>
    </>
  )
}

export default BroadcastPlaylist
export default (field, value) => {
  let errors = []

  defaultValidators.forEach(validator => {
    const result = validator(field, value)
    if (result) errors.push(result)
  })

  field.validators?.forEach(validator => {
    const result = validator(field, value)
    if (result) errors.push(result)
  })

  return errors
}

export const requiredValidator = (field, value) => {
  if ((field?.required || field.optional === false) && (
    value === undefined ||
    value === null ||
    value === ''
  )) {
    return 'error.REQUIRED'
  }
}

export const defaultValidators = [
  requiredValidator
]
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const PrBaseContentManagement = props => {
  const result = RV.useReactorQuery({
    'prPublicContent': {
      fields: [
        'content'
      ],
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ prPublicContent }) => {
        return (
          <>
            <RV.Card>
              <RV.Card.Header>
                Logo
              </RV.Card.Header>

              <Box as='img' src={prPublicContent.content.logo_data?.url} sx={{
                // width: '100%',
                // height: '100%'
                objectFit: 'cover',
                maxWidth: '200px'
              }} />

              <RV.RfSingleLineForm
                actionKey='PrBase.UpdateContent'
                formId={`PrBase.UpdateContent.Logo`}
                onSuccess={result.refresh}
                initialValues={{
                }}
                fields={[
                  {
                    name: 'logo',
                    label: 'Fotoğraf',
                    kind: 'file',
                    options: {
                      acceptedKinds: '.jpg,.png'
                    }
                  }
                ]}
              />
            </RV.Card>

            <RV.Card mt={4}>
              <RV.Card.Header>
                İsim
              </RV.Card.Header>

              <RV.RfSingleLineForm
                sx={{
                  mt: 4
                }}
                actionKey='PrBase.UpdateContent'
                formId={`PrBase.UpdateContent.Name`}
                onSuccess={result.refresh}
                initialValues={{
                  name: prPublicContent.content.name
                }}
                fields={[
                  {
                    name: 'name',
                    label: 'İsim',
                    kind: 'text',
                  }
                ]}
              />
            </RV.Card>

            <RV.Card mt={4}>
              <RV.Card.Header>
                Tanıtım
              </RV.Card.Header>

              <RV.RfSingleLineForm
                sx={{
                  mt: 4
                }}
                actionKey='PrBase.UpdateContent'
                formId={`PrBase.UpdateContent.Description`}
                onSuccess={result.refresh}
                initialValues={{
                  description: prPublicContent.content.description
                }}
                fields={[
                  {
                    name: 'description',
                    label: 'Tanım',
                    kind: 'richText',
                  }
                ]}
              />
            </RV.Card>
          </>
        )
      }}
    </RV.QueryLoading>
  )
}

export default PrBaseContentManagement
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import isArray from 'lodash/isArray'

// Reactor UI
import * as changeCase from 'reactor-ui/util/text'
import Badge from 'reactor-ui/components/Badge'


const Enum = ({
  value,
  asText,
  color
}) => {
  const { t } = useTranslation()
  if (!value) return null
  if (isArray(value)) {
    return value.map((subVal, dx) => {
      const val = t(`enum.${changeCase.snake(subVal.value || subVal)}`)
      if (asText) return val
      return (
        <Badge sx={{height: 'fit-content'}} mx={2} key={dx} colorScheme={color || subVal.attrs?.color} variant='solid' px={2} py={1}>{val}</Badge>
      )
    })
  } else {
    const val = t(`enum.${changeCase.snake(value.value || value)}`)
    if (asText) return val
    return (
      <Badge sx={{height: 'fit-content'}} colorScheme={color || value.attrs?.color} variant='solid' px={2} py={1}>{val}</Badge>
    )
  }
}

export default Enum
import DomainActivities from './pages/DomainActivities'


export default {
  name: 'RcDomainLog',
  components: {
    'DomainActivities': {
      component: DomainActivities
    },
  }
}
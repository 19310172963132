import React from 'react'

// Reactor UI
import { Box, Flex } from 'reactor-ui'

// Reactor
import AuroraThemeSidebar from './AuroraThemeSidebar'


const AuroraThemeZoneLayout = ({
  baseUrl,
  zoneContent
}) => {
  return (
    <>
      <AuroraThemeSidebar baseUrl={baseUrl} />

      <Box sx={{
        // width: 1,
        // marginLeft: layoutConfig.sidebar.extended ? 'contentWithExtendedSidebar' : 'contentWithSidebar',
        transition: 'all 0.2s ease',
        '@media print': {
          ml: 0,
          mt: 5
        },
      }}>
        {zoneContent}
      </Box>
    </>
  )
}

export default AuroraThemeZoneLayout
import React from 'react'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'

// Reactor
import * as RV from 'reactor-vera/templates/v1'


const RnxDeviceAppManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<AppList />} />
      <RV.Route path='app_detail/:rnxDeviceAppId/*' element={<AppDetailManagement />} />
    </RV.Routes>
  )
}

const AppList = props => {
  return (
    <RV.Content>
      {/* <RV.Content.Header>
        Uygulama Yönetimi
      </RV.Content.Header> */}

      <RV.GraphTable
        modelName={'RnxDeviceApp'}
        detailLink={'app_detail'}
        renderEmpty={() => {
          return (
            <Flex sx={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              color: 'textMuted'
            }}>
              Henüz uygulama oluşturulmamış. Buradan oluşturabilirsin. <Icon icon='hand-pointing-up' color='primary' size={24} />
            </Flex>
          )
        }} />
    </RV.Content>
  )
}

const AppDetailManagement = props => {
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rnxDeviceApp': {
      filters: {
        id: params.rnxDeviceAppId
      }
    },
  })

  RV.useBreadcrumb(`rnx_device_app:${result.graph?.rnxDeviceApp}`, result.graph?.rnxDeviceApp?.name)
  console.log(result.graph?.rnxDeviceApp)

  return (
    <RV.Routes>
      <RV.Route path='/' element={<AppDetail result={result} />} />
      <RV.Route path='version_detail/:rnxDeviceAppVersionId' element={<VersionDetail />} />
      {/* <RV.Route path='profile_detail/:rnxDeviceAppProfileId' element={<AppProfileDetail />} /> */}
    </RV.Routes>
  )
}

const AppDetail = ({
  result
}) => {
  const params = RV.useParams()
  const rnxDeviceApp = result.graph?.rnxDeviceApp
  const meta = result.graph?.rnxDeviceAppMeta
  if (!rnxDeviceApp) return null
  return (
    <>
      <RV.EntityDetail
        icon={'graphic-tablet-wireless'}
        entity={rnxDeviceApp}
        schema={result.schema?.rnxDeviceApp}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <RV.BareTabs
        isFitted
        isLazy
        tabs={[
          {
            name: 'Versiyonlar',
            codeName: 'versions',
            element: (
              <RV.GraphTable
                modelName={'RnxDeviceAppVersion'}
                detailLink={'version_detail'}
                actionCtx={{
                  model: {
                    rnx_device_app: params.rnxDeviceAppId,
                  }
                }}
                query={{
                  filters: {
                    rnxDeviceAppId: params.rnxDeviceAppId,
                  }
                }}
              />
            )
          },

          {
            name: 'Profiller',
            codeName: 'profiles',
            element: (
              <RV.GraphTable
                modelName={'RnxDeviceAppProfile'}
                // detailLink={'profile_detail'}
                actionCtx={{
                  model: {
                    rnx_device_app: params.rnxDeviceAppId,
                  }
                }}
                query={{
                  filters: {
                    rnxDeviceAppId: params.rnxDeviceAppId,
                  }
                }}
              />
            )
          },

          {
            name: 'Cihazlar',
            codeName: 'devices',
            element: (
              <RV.GraphTable
                modelName={'RnxDevice'}
                showModelActions={false}
                query={{
                  filters: {
                    current_rnx_device_app_id: params.rnxDeviceAppId,
                  }
                }}
              />
            )
          }
        ]}
      />
    </>
  )
}

const VersionDetail = ({

}) => {
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rnxDeviceAppVersion': {
      filters: {
        id: params.rnxDeviceAppVersionId
      },
      fields: ['name', 'files']
    },
  })

  RV.useBreadcrumb(`rnxDeviceAppVersion:${result.graph?.rnxDeviceAppVersion?.name}`, result.graph?.rnxDeviceAppVersion.name)

  const rnxDeviceAppVersion = result.graph?.rnxDeviceAppVersion
  const meta = result.graph?.rnxDeviceAppVersionMeta
  if (!rnxDeviceAppVersion) return null

  console.log('result', result)

  return (
    <>
      {/* <RV.PageHeaderBar header='Yayın'/> */}

      <RV.EntityDetail
        icon={'graphic-tablet-wireless'}
        entity={rnxDeviceAppVersion}
        schema={result.schema?.rnxDeviceAppVersion}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <RV.SimpleTable sx={{
        my: 3
      }}
        header='Dosyalar'
        modelActions={[
          <RV.RfActionForm upload key={'add'} fields={[
            {
              name: 'files',
              label: 'Dosyalar',
              kind: 'file',
              options: {
                isMultiple: true
              }
            }
          ]} initialValues={{ rnxDeviceAppVersion: params.rnxDeviceAppVersionId }} onSuccess={result.refresh} header='Dosya Ekle' actionKey='RnxDevice.AddFileToAppVersion' iconName='plus' buttonText='EKLE' />
        ]}
        actions={[
          (item, dx) => <RV.RfActionForm key={dx} initialValues={{ rnxDeviceAppVersionId: params.rnxDeviceAppVersionId, fileId: item.id }} onSuccess={result.refresh} header='Kaldır' actionKey='RnxDevice.DeleteAppVersionFile' iconName='circle-remove' buttonText='Kaldır' />,
          (item, dx) => (
            <RV.Button tooltip='İndir' size='xs' as='a' iconName='square-empty-download' sx={{
              color: 'textMuted',
              transition: 'all 0.2s ease',
              '&:hover': {
                color: 'brand.500',
              },
              ml: 2
            }} href={item.url} target='_blank' download />
          )
        ]}
        alignLast
        maxHeight={0} items={rnxDeviceAppVersion?.files || []} columns={[
          {
            key: '@dx',
            label: '',
          },
          {
            key: 'name',
            label: 'Dosya İsmi'
          },
          {
            key: '@actions',
            label: 'İşlemler'
          }
        ]} />
    </>
  )
}


// const AppProfileDetail = ({

// }) => {
//   const params = RV.useParams()
//   const result = RV.useRunicGraphQuery({
//     'rnx_device_app_profile': {
//       // fields: [
//       //   'name', 'kind', 'platform', 'datetime_created', 'data', 'profile_data'
//       // ],
//       filters: {
//         id: params.rnxDeviceAppProfileId
//       }
//     }
//   })

//   RV.useBreadcrumb(`rnx_device_app_profile:${result.graph?.rnx_device_app_profile?.name}`, result.graph?.rnx_device_app_profile.name)

//   console.log('result', result)

//   return (
//     <>
//       {/* <RV.PageHeaderBar header='Yayın'/> */}

//       <RV.EntityDetail icon={'graphic-tablet-wireless'} entity={result.graph?.rnx_device_app_profile} refresh={result.refresh} status={result.status} exclude={['data', 'profile_data']} />

//       <Flex sx={{
//         mx: -2
//       }}>

//         <Box sx={{
//           flex: '0 0 300px',
//           my: 3,
//           mx: 2
//         }}>
//           {result.graph?.rnx_device_app_profile && <AppCard refresh={result.refresh} profile={result.graph?.rnx_device_app_profile} />}
//         </Box>

//         <GraphTable sx={{
//           my: 3,
//           mx: 2,
//           flex: '1 1 0'
//         }}
//         maxHeight={0}
//         modelName={'RnxDevice'}
//         linkDetail={false}
//         query={{
//           filters: {
//             rnx_device_app_profile_id: params.rnxDeviceAppProfileId
//           }
//         }} />

//       </Flex>

//     </>
//   )
// }

// const AppCard = ({
//   profile,
//   refresh
// }) => {
//   const params = RV.useParams()
//   const result = RV.useRunicGraphQuery({
//     'rnx_device_app_version': {
//       // fields: [
//       //   'name', 'kind', 'platform', 'datetime_created', 'data', 'profile_data'
//       // ],
//       filters: {
//         id: profile.rnx_device_app_version.id
//       }
//     }
//   })

//   const onSuccess = () => {
//     result.refresh()
//     refresh()
//   }

//   return (
//     <Box sx={{
//       variant: 'layout.card'
//     }}>
//       <Icon name='code'>
//         <Box sx={{
//           fontWeight: 'bold',
//           ml: 2
//         }}>
//           {profile.rnx_device_app.name}
//         </Box>
//       </Icon>

//       <Icon name='list-numbers' wrapperSx={{
//         mt: 3
//       }}>
//         <Box sx={{
//           fontWeight: 'bold',
//           ml: 2
//         }}>
//           {profile.rnx_device_app_version.name}
//         </Box>
//       </Icon>

//       <Box sx={{
//         mt: 3,
//         mb: 2
//       }}>
//         <Box sx={{
//           textTransform: 'uppercase',
//           color: 'primary',
//           fontSize: 's',
//         }}>Dosyalar</Box>
//         <Flex sx={{
//           fontSize: 's',
//           mt: 2
//         }}>
//           {result.graph?.rnx_device_app_version?.data?.files?.map(f => f.file_name).join(', ')}
//         </Flex>
//       </Box>

//       <RV.RfSingleAction fields={[
//         {
//           name: 'rnxDeviceAppId',
//           label: 'Uygulama',
//           kind: 'relationship',
//           source: 'RnxDevice.RnxDeviceApp.Typeahead',
//           required: true
//         },

//         {
//           name: 'rnxDeviceAppVersionId',
//           label: 'Versiyon',
//           kind: 'relationship',
//           source: 'RnxDevice.RnxDeviceAppVersion.Typeahead',
//           params: {
//             rnxDeviceAppId: {
//               _kind: 'dynamic',
//               filter: true,
//               valueSource: 'form',
//               valueKey: 'rnxDeviceAppId'
//             }
//           },
//           showIf: [
//             {
//               field: 'rnxDeviceAppId',
//               comparison: 'EXISTS',
//             }
//           ],
//           required: true
//         },

//         // {
//         //   name: 'description',
//         //   label: 'Açıklama',
//         //   kind: 'text',
//         // }
//       ]} anchorVariant={'border'} anchorProps={{ fullWidth: true }} anchorSx={{
//         my: 1,
//       }} iconName='' anchorText='Uygulama Değiştir' onSuccess={onSuccess} actionKey='RnxDevice.SetAppForProfile' initialValues={{
//         rnxDeviceAppProfileId: profile.id
//       }} header='Yeni Versiyon' buttonText='KAYDET' />

//       <RV.RfSingleAction fields={[
//         {
//           name: 'rnxDeviceAppVersionId',
//           label: 'Versiyon',
//           kind: 'relationship',
//           source: 'RnxDevice.RnxDeviceAppVersion.Typeahead',
//           params: {
//             filters: {
//               rnxDeviceAppId: profile.rnx_device_app.id
//             }
//           },
//           required: true
//         },

//         // {
//         //   name: 'description',
//         //   label: 'Açıklama',
//         //   kind: 'text',
//         // }
//       ]} anchorVariant={'border'} anchorProps={{ fullWidth: true }} anchorSx={{
//         my: 1,
//       }} iconName='' anchorText='Versiyon Değiştir' onSuccess={onSuccess} actionKey='RnxDevice.SetAppForProfile' initialValues={{
//         rnxDeviceAppProfileId: profile.id,
//         rnxDeviceAppId: profile.rnx_device_app.id
//       }} header='Yeni Versiyon' buttonText='KAYDET' />

//       <RV.RfSingleAction fields={[
//         {
//           name: 'name',
//           label: 'İsim',
//           kind: 'text',
//           required: true
//         },

//         {
//           name: 'description',
//           label: 'Açıklama',
//           kind: 'text',
//         },

//         {
//           name: 'files',
//           label: 'Dosyalar',
//           kind: 'file',
//           required: true
//         },
//       ]} upload anchorVariant={'border'} anchorProps={{ fullWidth: true }} anchorSx={{
//         my: 1,
//       }} iconName='' anchorText='Yeni Versiyon Oluştur' onSuccess={onSuccess} actionKey='RnxDevice.CreateVersionAndSetForProfile' initialValues={{
//         rnxDeviceAppProfileId: profile.id,
//         rnxDeviceAppId: profile.rnx_device_app.id
//       }} header='Yeni Versiyon' buttonText='KAYDET VE AKTİFLEŞTİR' />

//     </Box>
//   )
// }

export default RnxDeviceAppManagement
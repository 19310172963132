import React from 'react'

// Vendor
import dayjs from 'dayjs'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import ReactorModal from 'reactor-ui/components/Modal'
import DynamicCalendar from 'elements/rnx_activity_group/rnxActivityGroup/components/DynamicCalendar'


const ActivityGroupProgramCalendar = ({
  rnxActivityGroupProgramId
}) => {
  const result = RV.useReactorQuery({
    'rnxActivityGroupProgramActivityList': {
      filters: {
        rnx_activity_group_program_id: rnxActivityGroupProgramId,
        status: 'ACTIVE'
      },
      limit: 300
    }
  })

  const activities = React.useMemo(() => {
    if (!result.graph?.rnxActivityGroupProgramActivityList) return

    console.log(result.graph.rnxActivityGroupProgramActivityList)
    const resp = {}
    result.graph.rnxActivityGroupProgramActivityList.items?.forEach(a => {
      const startHourSplit = a.timeStart.split(':')
      const finishHourSplit = a.timeFinish.split(':')

      const today = dayjs().startOf('today')
      const start = today.hour(startHourSplit[0]).minute(startHourSplit[1])
      const finish = today.hour(finishHourSplit[0]).minute(finishHourSplit[1])

      if (!resp[a.dayOfWeek.key - 1]) resp[a.dayOfWeek.key - 1] = []
      resp[a.dayOfWeek.key - 1].push({
        start, finish, activity: a, name: a.name, menu: (
          <Flex>
            <RV.ActionGroupMenu
              name={'RnxActivityGroupProgramActivity'} contexts={['Entity']} ctx={{ id: a.id }}
            />
            <ReactorModal
              size='xl'
              autoFocus={true}
              // header={`${addon.name}`}
              anchor={<RV.Button size='xs' icon='calendar' />}>
              {({ onClose, isOpen }) => (
                <>
                  {isOpen && <ProgramActivityReservations id={a.id} />}
                </>
              )}
            </ReactorModal>
          </Flex>
        )
      })

    })
    return resp
  }, [result.graph?.rnxActivityGroupProgramActivityList])

  return (
    <RV.QueryLoading result={result}>
      <DynamicCalendar activities={activities} />
    </RV.QueryLoading>
  )
}

const ProgramActivityReservations = ({
  id
}) => {
  return (
    <RV.GraphTable
      modelName={'RnxActivityGroupActivityReservation'}
      // detailLink='spsa'
      query={{
        itemFields: [
          { 'rnxContact': { fields: ['name', 'officialKey'] } }, 'rnxActivityGroupActivity', 'rnxActivityGroupActivityKind', 'kind', 'datetimeStart', 'status'
        ],
        columns: [
          'rnxContact.name', 'rnxContact.officialKey', 'status'
        ],
        filters: {
          for_program_activity: id
        },
        orderBy: 'datetimeStart|asc'
      }}
    />
  )
}

export default ActivityGroupProgramCalendar
import React from 'react'

// Reactor UI
import * as changeCase from 'reactor-ui/util/text'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useLocationFilter from 'reactor-vera/hooks/useLocationFilter'
import { useUiSelection } from 'reactor/systems/uiSelection/hooks'


const useModelListReactorQuery = ({
  modelName,
  filterKey,
  query = {},
  view,
  stats,
  primaryFilters
}) => {
  const graphKey = `${changeCase.camel(modelName)}List`
  const filterInstance = useLocationFilter(filterKey || modelName)

  const { itemFields, filters, ...rest } = query
  const graphFilters = {
    ...filters,
    ...filterInstance.locationFilters,
  }
  let extraFields = []
  if (stats) {
    extraFields.push({stats: {fields: stats}})
  }

  if (primaryFilters) {
    extraFields.push({primaryFilters: {fields: primaryFilters}})
  }

  const queryObj = {
    [graphKey]: {
      itemFields: itemFields,
      filters: graphFilters,
      ...rest,
      view,
    }
  }

  if (extraFields.length) {
    queryObj[graphKey].extraFields = extraFields
  }

  const result = useReactorQuery(queryObj)

  const selection = useUiSelection(result?.status?.queryKey)

  return [result, graphKey, filterInstance, queryObj, selection]
}

export default useModelListReactorQuery
// Vendor
import stringfy from 'json-stable-stringify'
import * as changeCase from "change-case"
import { takeEvery, call, put, select, cancel, delay, fork } from 'redux-saga/effects'

// Reactor
// import { runicSourceRequest, runicActionRequest, callCallbacks, runicMultiSourceRequest, runicUploadRequest, reactorQueryRequest } from 'reactor/core/saga'
import { callCallbacks, reactorQueryRequest, resourceRequest, reactorActionRequest } from 'reactor/core/saga'
import createKey from 'reactor/util/key'



function* reactorQueryHandler(action) {
  const payload = action.payload

  callCallbacks(action, 'start', payload)

  try {
    const apiResponse = yield call(reactorQueryRequest, payload, action)

    yield put({
      type: action.type,
      payload: apiResponse.data,
      meta: {
        ...action.meta,
        status: 'SUCCESS'
      }
    })

    callCallbacks(action, 'success', payload)

  } catch (error) {
    if (error.response) {
      const meta = {
        ...action.meta,
        status: 'ERROR',
      }

      const callbackPayload = { ...error.response }

      // if (error.response.data && error.response.data.kind && error.response.data.kind == 'NOT_AUTHORIZED') {
      //   const _payload = error.response.data.payload || {}
      //   meta.permissionError = _payload
      //   callbackPayload.permissionError = _payload
      // }

      callCallbacks(action, 'error', callbackPayload)

      yield put({
        type: action.type,
        payload: error.response.data,
        meta
      })
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }

  }
}

function* resourceQueryHandler(action) {
  const payload = action.payload

  callCallbacks(action, 'start', payload)

  try {
    const apiResponse = yield call(resourceRequest, payload, action)

    yield put({
      type: action.type,
      payload: apiResponse.data,
      meta: {
        ...action.meta,
        query: payload.query,
        status: 'SUCCESS'
      }
    })

    callCallbacks(action, 'success', payload)

  } catch (error) {
    if (error.response) {
      const meta = {
        ...action.meta,
        status: 'ERROR',
      }

      const callbackPayload = { ...error.response }

      // if (error.response.data && error.response.data.kind && error.response.data.kind == 'NOT_AUTHORIZED') {
      //   const _payload = error.response.data.payload || {}
      //   meta.permissionError = _payload
      //   callbackPayload.permissionError = _payload
      // }

      callCallbacks(action, 'error', callbackPayload)

      yield put({
        type: action.type,
        payload: error.response.data,
        meta
      })
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }

  }
}

function* actionHandler(action) {
  let actionPayload = action.payload
  let meta

  // if (action.type.includes('/')) {
  //   const actionTypeSplit = action.type.split('/')
  //   const actionName = actionTypeSplit[actionTypeSplit.length - 1]
  //   actionCamelName = changeCase.pascalCase(actionName)
  // } else {
  //   actionCamelName = changeCase.pascalCase(action.type)
  // }
  // actionPath = `${actionComponent}.${actionCamelName}`
  const actionPath = action.meta.identifier

  callCallbacks(action, 'start', actionPayload)

  try {
    const apiResponse = yield call(reactorActionRequest, actionPath, actionPayload, action.meta.actionKind || 'action', action)
    let payload = apiResponse.data, status = 'SUCCESS'

    const callbackResponse = callCallbacks(action, 'afterRequest', payload)
    if (callbackResponse === false) {
      return
    }

    // if (apiResponse.data && apiResponse.data._rc_entity) {
    //   const entitySchemasBySnakeCaseName = yield select((state) => state.model.entitySchemasBySnakeCaseName)

    //   const entityData = normalize(apiResponse.data._rc_entity, entitySchemasBySnakeCaseName)
    //   delete payload._rc_entity
    //   payload['rcEntity'] = entityData
    // }

    // if (apiResponse.data && apiResponse.data._rc_entity_deleted) {
    //   payload['deletedRcEntity'] = apiResponse.data._rc_entity_deleted
    // }

    // if (apiResponse.data && apiResponse.data._rcr_sub_actions) {
    //   payload = {
    //     rcrSubActions: apiResponse.data._rcr_sub_actions
    //   }
    //   status = 'ERROR'
    // }

    yield put({
      type: action.type,
      payload: payload,
      meta: {
        ...action.meta,
        ...meta,
        status: status
      }
    })

    callCallbacks(action, status, payload)
  } catch (error) {
    if (error.response) {
      callCallbacks(action, 'error', error.response.data)

      yield put({
        type: action.type,
        payload: {
          ...actionPayload,
          _error: error.response.data
        },
        meta: {
          ...action.meta,
          status: 'ERROR'
        }
      })
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }
  }
}

// function* genericActionHandler(action) {
//   let actionPayload = action.payload
//   let meta

//   callCallbacks(action, 'start', actionPayload)

//   try {
//     const apiResponse = yield call(action.meta.handler, actionPayload, action)
//     let payload = apiResponse.data, status = 'SUCCESS'

//     const callbackResponse = callCallbacks(action, 'afterRequest', payload)
//     if (callbackResponse === false) {
//       return
//     }

//     yield put({
//       type: action.type,
//       payload: payload,
//       meta: {
//         ...action.meta,
//         ...meta,
//         status: status
//       }
//     })

//     callCallbacks(action, status, payload)
//   } catch (error) {
//     if (error.response) {
//       callCallbacks(action, 'error', error.response.data)

//       yield put({
//         type: action.type,
//         payload: {
//           ...actionPayload,
//           _error: error.response.data
//         },
//         meta: {
//           ...action.meta,
//           status: 'ERROR'
//         }
//       })
//     } else {
//       // FIXME: log error and continue in PROD
//       throw error
//     }
//   }
// }

export function* watchReactorQuery() {
  yield takeEvery((action) => action?.meta?.status == 'START' && action?.meta?.kind == '@@reactor/reactorQuery', reactorQueryHandler)
}

export function* watchResourceQuery() {
  yield takeEvery((action) => action?.meta?.status == 'START' && action?.meta?.kind == '@@reactor/resourceQuery', resourceQueryHandler)
}

export function* watchAction() {
  yield takeEvery((action) => action?.meta?.status == 'START' && action.meta.kind == 'RCR_ACTION', actionHandler)
}

// export function* watchGenericAction() {
//   yield takeEvery((action) => action?.meta?.status == 'START' && action.meta.kind == 'ACTION', genericActionHandler)
// }


export default [
  watchReactorQuery,
  watchResourceQuery,
  watchAction
]
import React from 'react'

// Vendor
import { useNavigate } from "reactor-vera/router"

// Reactor
import useResourceQuery from 'reactor/hooks/useResourceQuery'


const ReactorAuthCheck = ({
  children,
  redirectTo
}) => {
  const status = useResourceQuery('rcApp.state.status')
  const user = status?.rcUser
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!user) {
      navigate(redirectTo)
    }
  }, [user])

  return user ? children : null

}

export default ReactorAuthCheck

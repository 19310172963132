import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


const Text = ({
  children
}) => {
  return children
}

const Translated = ({
  children,
  text,
  capitalize,
  args
}) => {
  const { t } = useTranslation()
  const sx = {}
  if (capitalize) {
    sx['textTransform'] = 'capitalize'
  }
  return (
    <Box as='span' sx={sx}>
      {args ? t(text || children, ...args) : t(text || children)}
    </Box>
  )
}

const Header = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Box sx={{
      fontSize: 'lg',
      color: 'brand.500',
      fontWeight: 'semibold',
      ...sx
    }} {...props}>
      {children}
    </Box>
  )
}

const Description = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Box sx={{
      color: 'dark.400',
      ...sx
    }} {  ...props}>
      {children}
    </Box>
  )
}

const Info = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Box sx={{
      color: 'dark.400',
      fontSize: 'sm',
      ...sx
    }} {...props}>
      {children}
    </Box>
  )
}

Text.Header = Header
Text.Description = Description
Text.Info = Info
Text.Translated = Translated

export default Text
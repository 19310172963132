// Vendor
import stringfy from 'json-stable-stringify'


// export const quickHash = (str) => {
//   let result = 0
//   if (str.length == 0) return result
//   for (let i = 0; i < str.length; i++) {
//     let char = str.charCodeAt(i)
//     result = ((result << 5) - result) + char
//     result = result & result
//   }
//   return result
// }

export const quickHash = (str) => {
  for(var ret = 0, i = 0, len = str.length; i < len; i++) {
    ret = (31 * ret + str.charCodeAt(i)) << 0
  }
  return ret
}

const createKey = (params) => {
  return quickHash(stringfy(params))
}

export default createKey
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import BareTabs from 'reactor-ui/components/BareTabs'



const RnxDeviceManagement = props => {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path='/' element={<DeviceManagementHome />} />
      <Route path='device/:rnxDeviceId/*' element={<DeviceManagementDetail />} />
    </Routes>
  )
}

const DeviceManagementHome = props => {
  const { t } = useTranslation()
  const params = useParams()
  return (
    <>
      <GraphTable
        modelName='RnxDevice'
        detailLink='device'
      />
    </>
  )
}

const DeviceManagementDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxDevice': {
      filters: {
        id: params.rnxDeviceId
      }
    },
  })

  useBreadcrumb(`rnxDeviceDetail:${result.graph?.rnxDevice.id}`, result.graph?.rnxDevice.name)

  const rnxDevice = result.graph?.rnxDevice
  const meta = result.graph?.rnxDeviceMeta

  if (!rnxDevice) return null

  return (
    <>
      <EntityDetail
        icon={'book-open'}
        entity={rnxDevice}
        schema={result.schema?.rnxDevice}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
{/*
      <BareTabs
        isFitted
        isLazy
        tabs={[
          {
            name: 'Program',
            codeName: 'program',
            element: <ActivityProgram rnxDevice={rnxDevice} />
          },

          {
            name: 'Türler',
            codeName: 'kinds',
            element: <ActivityKinds rnxDevice={rnxDevice} />
          }
        ]}
      />*/}

    </>
  )
}

export default RnxDeviceManagement
import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import * as Settings from 'reactor-vera/templates/v1/Settings'


const PrSettings = props => {
  const result = RV.useReactorResource('prBase.settings.settings_modules')
  return (
    <RV.QueryLoading result={result}>
      {() => (
        <RV.Routes>
          <RV.Route path='/' element={(
            <Settings.Settings result={result} />
          )} />

          {result.data.modules.map((module, dx) => {
            return module.items.map((item, dx) => {
              return (
                <RV.Route key={dx} path={`${item.name}/*`} element={(
                  <Settings.SettingsComponent item={item} />
                )} />

              )
            })
          })}
        </RV.Routes>
      )}
    </RV.QueryLoading>

  )
}



export default PrSettings
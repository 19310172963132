import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const GatewayConnect = props => {
  return (
    <RV.ReactorServerAction identifier='CmrPayment.SetupGatewayConnect'/>
  )
}

export default GatewayConnect
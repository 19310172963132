import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

import reactorVeraActions from 'reactor-vera/systems/reactor-vera/actions'

const useUiConfig = props => {
  const uiConfig = useSelector(state => state.reactorVera.config)
  const dispatch = useDispatch()
  const updateConfig = React.useCallback((config) => {
    dispatch(reactorVeraActions.updateConfig(config))
  }, [])

  return [uiConfig, updateConfig]
}

export default useUiConfig
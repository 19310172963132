import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import Form, { RfFormField, useForm, useFormState, useField, useFieldArray, RfShowIf } from 'reactor-form/components/RfForm'


const TagAssignmentAccessSetup = ({
  parentFieldName,
  model
}) => {

  return (
    <>
      {model !== 'RcTag' ? (
        <RfFormField
        name={`${parentFieldName}.rcTagSystem`}
        label='Etiket Sistemi'
        kind='entity'
        options={{
          params: {
            'model': model
          }
        }}
      />
      ) : (
        <RfFormField
        name={`${parentFieldName}.rcTagGroup`}
        label='Etiket Grubu'
        kind='entity'
      />
      )}

      <RfFormField
        name={`${parentFieldName}.level`}
        label='Erişim Seviyesi'
        kind='radio'
        options={{
          options: [
            {
              'value': 'DIRECT',
              'label': 'Atanan'
            },

            {
              'value': 'DOWN',
              'label': 'Atanan ve Aşağısı'
            }
          ]
        }}
      />
    </>
  )
}

export default TagAssignmentAccessSetup
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { useTheme } from '@emotion/react'
import { setIn, getIn } from 'final-form'
import * as changeCase from "change-case"
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import { useFormState, useForm } from 'react-final-form'
import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'
import InputLabel from 'reactor-ui/components/InputLabel'

import RfTextField from './RfTextField'

//
import useLoader from '../hooks/useLoader'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfSelectField = ({
  name,
  field,
}) => {
  if (field.options?.loader) return <RfRemoteSelectField name={name} field={field} />
  return <RfLocalSelectField name={name} field={field} />
}


const RfSelectValue = React.forwardRef(({
  data,
  innerProps,
  selectProps,
  ...rest
}, ref) => {
  const { t } = useTranslation()
  let content = (selectProps?.selectProps?.field?.options?.translate) ? (
    <Box textTransform={'capitalize'}>{t(data?.label)}</Box>
  ) : (
    data?.label
  )

  if (!selectProps?.selectProps?.field?.options?.create && content === data?.label && data.value === data.label) {
    content = selectProps.options.find(x => x.value === data.label)?.label
  }

  // if (data.label === 'enum.image') console.log(field, content, data, selectProps, rest)
  return (
    <Box {...innerProps} ref={ref} sx={{
      p: 1,
      px: 2,
      gridArea: '1/1/2/3',
      color: selectProps.isDisabled ? 'dark.300' : null
    }}>
      {content}
    </Box>
  )
})

const components = {
  MultiValueLabel: RfSelectValue,
  SingleValue: RfSelectValue
}

const RfLocalSelectField = ({
  name,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })
  const theme = useTheme()

  // const values = useParseValuesFromServer(field.options.values, ctx)

  let finalOptions = field.options.options

  const selectValue = field.options?.isMultiple ? input.value?.map?.(v => ({ label: v, value: v })) : field.options?.options?.find(x => x.value == input.value)

  // console.log(options, input.value, selectValue)

  if (field.options?.translate) {
    finalOptions = finalOptions.map(opt => ({
      ...opt,
      label: changeCase.title(t(`${opt.label}`) || opt.label)
    }))
  }
  // console.log('sel', selectValue, field)
  if (field.options.create) {
    return (
      <Box sx={{
        position: 'relative',
      }}>
        <InputLabel variant='active'>
          {t(field.label)}
        </InputLabel>
        <CreatableSelect
          cacheOptions
          defaultOptions
          onChange={(props) => {
            const val = field.options.isMultiple ? props?.map(x => x.value) : props?.value
            input.onChange(val)
            field.onChange?.(val, props)
          }}
          value={selectValue}
          selectProps={{ field }}
          // menuPortalTarget={typeof window !== "undefined" && document.getElementById('modal')}
          // menuPortalTarget={document.body}
          isMulti={field.options.isMultiple}
          isOptionDisabled={(option) => field.options?.isMultiple && selectValue && selectValue.length >= field.options.limit}
          isValidNewOption={(option) => option?.length >= (field.options?.createMinLength || 2) && !(field.options?.isMultiple && selectValue && selectValue.length >= field.options.limit)}
          placeholder={field.placeholder || t(field.label)}
          isClearable={field.isClearable !== undefined ? field.isClearable : true}
          closeMenuOnSelect={!field.options.isMultiple}
          onSelectResetsInput={!field.options.isMultiple}
          components={components}
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderColor: theme.colors.brand[500],
              paddingTop: 4,
              paddingBottom: 4,
              ...field?.styles?.control,
            }),
            option: (provided, state) => ({
              ...provided,
              color: '#000'
            }),
            menu: (provided, state) => ({
              ...provided,
              zIndex: 9
            }),
            menuPortal: (provided, state) => ({
              ...provided,
              zIndex: 999999
            }),
          }}
        />
        <RfFieldAlert meta={meta} />
        <RfFieldDescription field={field} />
      </Box>
    )
  } else {
    return (
      <Box sx={{
        position: 'relative',
      }}>
        {selectValue && <InputLabel variant='active'>
          {t(field.label)}
        </InputLabel>}
        <Select
          onChange={(props) => {
            const val = field.options?.isMultiple ? props?.map(x => x.value) : props?.value
            input.onChange(val)
            field.onChange?.(val, props)
          }}
          selectProps={{ field }}
          value={selectValue}
          options={finalOptions}
          components={components}
          // menuPortalTarget={typeof window !== "undefined" && document.getElementById('modal')}
          menuPortalTarget={document.body}
          isMulti={field.options?.isMultiple}
          isOptionDisabled={(option) => field.options?.isMultiple && selectValue && selectValue.length >= field.options.limit}
          placeholder={field.placeholder || t(field.label)}
          isClearable={field.isClearable !== undefined ? field.isClearable : true}
          closeMenuOnSelect={!field.options.isMultiple}
          onSelectResetsInput={!field.options.isMultiple}
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderColor: theme.colors.primary,
              paddingTop: 4,
              paddingBottom: 4,
              ...field?.styles?.control,
            }),
            option: (provided, state) => ({
              ...provided,
              color: '#000'
            }),
            menu: (provided, state) => ({
              ...provided,
              zIndex: 9
            }),
            menuPortal: (provided, state) => ({
              ...provided,
              zIndex: 999999
            }),
          }} />

        {field.hasOther && input.value === field.hasOther.value && (
          <RfTextField placeholder={field.hasOther.placeholder} name={`${name}${field.hasOther.label}`} field={{ ...field, kind: 'text' }} />
        )}

        <RfFieldAlert meta={meta} />
        <RfFieldDescription field={field} />
      </Box>
    )
  }
}

const RfRemoteSelectField = ({
  name,
  field
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })

  return (
    <RfRemoteSelectInput
      field={field}
      loaderProps={field.options.loader}
      loaderOptions={{
        parseOption: field.options.translate ? (opt) => ({
          ...opt,
          label: changeCase.title(t(`${opt.label}`) || opt.label)
        }) : null,
        parentPath: field.parentPath
      }}
      label={field.label}
      isMultiple={field.options.isMultiple}
      limit={field.options.limit}
      value={input.value}
      create={field.options.create}
      onChange={(val, props) => {
        input.onChange(val)
        field.onChange?.(val, props)
      }}
      selectProps={{ translate: field.options?.translate }}
      placeholder={field.placeholder}
      isClearable={field.isClearable !== undefined ? field.isClearable : true}
      styles={{
        control: {
          ...field?.styles?.control
        }
      }}
      alert={<RfFieldAlert meta={meta} />}
      description={<RfFieldDescription field={field} />}
    />
  )
}

export const RfRemoteSelectInput = ({
  loaderProps,
  loaderOptions = {},
  label,
  create,
  value: valueFromProps,
  onChange,
  selectProps,
  isMultiple,
  limit,
  placeholder,
  isClearable,
  alert,
  description,
  styles,
  field
}) => {
  const { t } = useTranslation()

  const [value, valueSet] = React.useState()
  const theme = useTheme()

  const loader = useLoader(loaderProps, loaderOptions)

  React.useEffect(() => {
    if (!valueFromProps) valueSet(null)
  }, [valueFromProps])

  React.useEffect(() => {
    if (valueFromProps) {
      if (isArray(valueFromProps)) {
        const newValues = valueFromProps.map(i => ({
          value: i,
          label: i
        }))
        valueSet(newValues)
      } else if (isObject(valueFromProps)) {
        valueSet(valueFromProps)
      } else {
        valueSet({
          value: valueFromProps,
          label: valueFromProps
        })

        // const selected = field.options?.options.find(x => x.value == valueFromProps)
        // console.log(selected, field.options?.options)
        // valueSet({
        //   value: selected?.value || valueFromProps,
        //   label: selected?.value || valueFromProps
        // })
      }
    }
  }, [])

  const commonProps = {
    cacheOptions: true,
    defaultOptions: true,
    loadOptions: loader.fetch,
    selectProps,
    onChange: (props) => {
      const val = isMultiple ? props?.map(x => x.value) : props?.value
      valueSet(props)
      onChange?.(val, props)
    },
    value,
    // menuPortalTarget: typeof window !== "undefined" && document.getElementById('modal'),
    // menuPortalTarget: document.body,
    isMulti: isMultiple,
    isOptionDisabled: (option) => isMultiple && value?.length >= limit,
    placeholder: <Box textTransform={'capitalize'}>{placeholder || t(label)}</Box>,
    isClearable,
    components,
    styles: {
      control: (provided, state) => ({
        ...provided,
        borderColor: theme.colors.brand[500],
        paddingTop: 4,
        paddingBottom: 4,
        ...styles?.control,
      }),
      option: (provided, state) => ({
        ...provided,
        color: '#000'
      }),
      menu: (provided, state) => ({
        ...provided,
        zIndex: 9
      }),
      menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 999999
      }),
    }
  }

  if (create) {
    return (
      <Box sx={{
        position: 'relative',
      }}>
        {commonProps.value && <InputLabel variant='active'>
          {field?.label}
        </InputLabel>}
        <AsyncCreatableSelect
          {...commonProps}
        />
        {alert}
        {description}
      </Box>
    )
  } else {
    return (
      <Box sx={{
        position: 'relative',
      }}>
        {commonProps.value && <InputLabel variant='active'>
          {t(field?.label)}
        </InputLabel>}
        <AsyncSelect
          {...commonProps}
          closeMenuOnSelect={!isMultiple}
          onSelectResetsInput={!isMultiple}
        />
        {alert}
        {description}
      </Box>
    )
  }
}

export default RfSelectField
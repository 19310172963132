import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'

import Breadcrumb from 'reactor-vera/apps/ui/components/Breadcrumb'
import { Form, RfFormField, RfFormError, useReactorActionForm, RfFormFields } from 'reactor-form/components/RfForm'


const ReactorAppSettings = ({
  groups
}) => {
  const { t } = RV.useTranslation()
  const result = RV.useReactorQuery({
    reactorAppSettings: {
      groups
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ reactorAppSettings }) => {
        console.log(reactorAppSettings)
        return (
          <RV.Routes>
            <RV.Route path='/' element={(
              <ReactorAppSettingsHome reactorAppSettings={reactorAppSettings} />
            )} />

            {reactorAppSettings.groups.map((g, dx) => {
              return g.modules.map((m, mdx) => {
                return (
                  <RV.Route key={mdx} path={`${m.path}/*`} element={(
                    <ReactorAppSettingsModule group={g} mdl={m} reactorAppSettings={reactorAppSettings} />
                  )} />
                )
              })
            })}
          </RV.Routes>
        )
      }}
    </RV.QueryLoading>
  )
}

const ReactorAppSettingsHome = ({
  reactorAppSettings
}) => {
  const { t } = RV.useTranslation()
  return (
    <>
      {reactorAppSettings.groups.map((g, dx) => {
        return (
          <Box key={dx} sx={{
            mb: 12
          }}>
            {g.label && (
              <RV.Card.Header sx={{
                textTransform: 'capitalize',
                '&:first-letter': {
                  textTransform: 'capitalize'
                },
                my: 2,
                fontSize: 'lg'
              }} icon={g.icon}>
                {t(g.label)}
              </RV.Card.Header>
            )}

            <Flex sx={{
              flexWrap: 'wrap',
              mx: -2
            }}>
              {g.modules.map((m, mdx) => {
                return (
                  <RV.Card key={mdx} sx={{
                    mx: 2,
                    flex: '1 1 25%',
                    maxWidth: '25%'
                  }}>
                    <RV.Card.Header sx={{
                      textTransform: 'capitalize',
                      '&:first-letter': {
                        textTransform: 'capitalize'
                      }
                    }} icon={m.icon}>
                      {t(m.label)}
                    </RV.Card.Header>

                    {m.description && (
                      <RV.Text.Description sx={{
                        textTransform: 'lowercase',
                        '&:first-letter': {
                          textTransform: 'capitalize'
                        },
                        my: 1
                      }}>
                        {t(m.description)}
                      </RV.Text.Description>
                    )}

                    <Box mt={2}>
                      {m.pages.map((p, pdx) => {
                        return (
                          <RV.Link to={`${m.path}/${p.path}`} key={pdx} sx={{
                            color: 'brand.500',
                            display: 'block',
                            my: 1,
                            textTransform: 'lowercase',
                            '&:first-letter': {
                              textTransform: 'capitalize'
                            }
                          }}>
                            {t(p.linkLabel || p.label)}
                          </RV.Link>
                        )
                      })}
                    </Box>
                  </RV.Card>
                )
              })}
            </Flex>
          </Box>
        )
      })}
    </>
  )
}

const ReactorAppSettingsModule = ({
  reactorAppSettings,
  mdl,
  group
}) => {
  return (
    <RV.Routes>
      {mdl.pages.map((p, pdx) => {
        return (
          <RV.Route key={pdx} path={`${p.path}/*`} element={(
            <ReactorAppSettingsPage group={group} mdl={mdl} page={p} reactorAppSettings={reactorAppSettings} />
          )} />
        )
      })}
    </RV.Routes>
  )
}

const ReactorAppSettingsPage = ({
  group,
  mdl,
  page
}) => {
  const { t } = RV.useTranslation()
  RV.useBreadcrumb(`${page.name}SettingsPage`, t(page.label || page.linkLabel))

  return (
    <>
      {mdl.options?.showBreadcrumb !== false && <Breadcrumb depth={1} sx={{
        my: 1,
        mb: 2
      }} />}
      {page.items.map((item, idx) => {
        console.log('--', item)
        if (item.displayMode === 'RECORD_LIST') {
          return (
            <ReactorAppSettingsItemAsList item={item} key={idx} />
          )
        } else if (item.displayMode === 'COMPONENT') {
          return (
            <ReactorAppSettingsComponent item={item} key={idx} />
          )
        }
        return (
          <ReactorAppSettingsItem item={item} key={idx} />
        )
      })}
    </>
  )
}

const ReactorAppSettingsItem = ({
  item
}) => {
  const { t } = RV.useTranslation()
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    'reactorAppSettingsItem': {
      name: item.name,
      ctx: {
        ...params
      }
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ reactorAppSettingsItem }) => {
        console.log(item, reactorAppSettingsItem)

        return (
          <RV.Layout.Col3 colSize={450} sx={{
            // px: 5,
            mt: 10,
            mb: 10
          }}>
            <Box sx={{
              // pt: 10,
              // pl: 20,
              bg: 'light.200',
              px: 8,
              py: 5,
              mr: 4,
              borderRadius: 2,
              border: '1px solid #FFF',
              borderColor: 'light.300',
            }}>
              <Box sx={{
                textTransform: 'capitalize',
                '&:first-letter': {
                  textTransform: 'capitalize'
                },
                fontSize: 'lg',
                fontWeight: 'bold',
                color: 'brand.500'
              }}>
                {t(item.label)}
              </Box>

              {item.description && (
                <Box sx={{
                  mt: 1
                }}>
                  {t(item.description)}
                </Box>
              )}
            </Box>

            {item.formComponentKey ? (
              <RV.DynamicComponent name={item.formComponentKey} item={item} reactorAppSettingsItem={reactorAppSettingsItem} refresh={result.refresh} />
            ) : (
              <RV.Card sx={{
                px: 12,
                py: 8,
                maxWidth: 550
              }}>
                <Box sx={{
                  // maxWidth: 300,
                  mx: 'auto',
                }}>
                  <ReactorAppSettingsForm
                    actionKey={reactorAppSettingsItem.actionKey}
                    fields={reactorAppSettingsItem.fields}
                    initialValues={{
                      itemName: item.name,
                      data: reactorAppSettingsItem.data || { ...reactorAppSettingsItem.params }
                    }}
                  />
                </Box>
              </RV.Card>
            )}
          </RV.Layout.Col3>
        )

        return (
          <RV.Card sx={{
            my: 4
          }}>
            <RV.Card.Header>
              {t(item.label)}
            </RV.Card.Header>

            {item.description && (
              <Box>
                {t(item.description)}
              </Box>
            )}

            <Box sx={{
              maxWidth: 450,
              // mx: 'auto',
              ml: '25%'
            }}>
              <ReactorAppSettingsForm
                actionKey={reactorAppSettingsItem.actionKey}
                fields={reactorAppSettingsItem.fields}
                initialValues={{
                  itemName: item.name,
                  data: reactorAppSettingsItem.data || {}
                }}
              />
            </Box>
          </RV.Card>
        )

      }}
    </RV.QueryLoading>
  )
}

const ReactorAppSettingsItemAsList = ({
  item
}) => {
  const { t } = RV.useTranslation()
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    'reactorAppSettingsItem': {
      name: item.name,
      ctx: {
        ...params
      }
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ reactorAppSettingsItem }) => {
        console.log(item, reactorAppSettingsItem)

        return (
          <RV.Layout.Col3 colSize={450} sx={{
            // px: 5,
            mt: 10,
            mb: 10
          }}>
            <Box sx={{
              // pt: 10,
              // pl: 20,
              bg: 'light.200',
              px: 8,
              py: 5,
              mr: 4,
              borderRadius: 2,
              border: '1px solid #FFF',
              borderColor: 'light.300',
            }}>
              <Box sx={{
                textTransform: 'capitalize',
                '&:first-letter': {
                  textTransform: 'capitalize'
                },
                fontSize: 'lg',
                fontWeight: 'bold',
                color: 'brand.500'
              }}>
                {t(item.label)}
              </Box>

              {item.description && (
                <Box sx={{
                  mt: 1
                }}>
                  {t(item.description)}
                </Box>
              )}
            </Box>

            {item.formComponentKey ? (
              <RV.DynamicComponent name={item.formComponentKey} item={item} reactorAppSettingsItem={reactorAppSettingsItem} refresh={result.refresh} />
            ) : (
              <RV.Card sx={{
                px: 12,
                py: 8,
                maxWidth: 550
              }}>
                <Box sx={{
                  // maxWidth: 300,
                  mx: 'auto',
                }}>
                  {reactorAppSettingsItem.data?.map((listItem, dx) => {
                    return (
                      <Flex key={dx} sx={{
                        border: '1px solid #FFF',
                        borderColor: 'brand.500',
                        borderRadius: 2,
                        my: 2,
                        py: 2,
                        px: 3,
                        alignItems: 'center'
                      }}>
                        <Box sx={{
                          flex: '1 1 0'
                        }}>
                          {listItem.name}
                        </Box>

                        <RV.ReactorModal
                          size='xl'
                          anchor={
                            <RV.Button size='sm' icon='pencil' isFullWidth colorScheme='brand'/>
                          }
                        >
                          {({ isOpen, onClose }) => {
                            if (!isOpen) return null

                            return (
                              <ReactorAppSettingsForm
                                actionKey={reactorAppSettingsItem.updateActionKey}
                                fields={reactorAppSettingsItem.fields}
                                updateDefaultsOnSuccess={false}
                                onSuccess={() => {
                                  onClose()
                                  result.refresh()
                                }}
                                initialValues={{
                                  itemName: item.name,
                                  id: listItem.id,
                                  data: listItem
                                }}
                              />
                            )
                          }}
                        </RV.ReactorModal>
                      </Flex>
                    )
                  })}

                  <RV.ReactorModal
                    size='xl'
                    anchor={
                      <RV.Button isFullWidth colorScheme='brand' mt={2}>
                        <RV.Text.Translated>
                          btn.create_new
                        </RV.Text.Translated>
                      </RV.Button>
                    }
                  >
                    {({ isOpen, onClose }) => {
                      if (!isOpen) return null

                      return (
                        <ReactorAppSettingsForm
                          actionKey={reactorAppSettingsItem.actionKey}
                          fields={reactorAppSettingsItem.fields}
                          updateDefaultsOnSuccess={false}
                          onSuccess={() => {
                            onClose()
                            result.refresh()
                          }}
                          initialValues={{
                            itemName: item.name,
                            data: reactorAppSettingsItem.params
                          }}
                        />
                      )
                    }}
                  </RV.ReactorModal>

                </Box>
              </RV.Card>
            )}
          </RV.Layout.Col3>
        )

        return (
          <RV.Card sx={{
            my: 4
          }}>
            <RV.Card.Header>
              {t(item.label)}
            </RV.Card.Header>

            {item.description && (
              <Box>
                {t(item.description)}
              </Box>
            )}

            <Box sx={{
              maxWidth: 450,
              // mx: 'auto',
              ml: '25%'
            }}>
              <ReactorAppSettingsForm
                actionKey={reactorAppSettingsItem.actionKey}
                fields={reactorAppSettingsItem.fields}
                initialValues={{
                  itemName: item.name,
                  data: reactorAppSettingsItem.data || {}
                }}
              />
            </Box>
          </RV.Card>
        )

      }}
    </RV.QueryLoading>
  )
}

const ReactorAppSettingsComponent = ({
  item
}) => {
  return <RV.DynamicComponent name={item.componentKey} item={item}  />
}

const ReactorAppSettingsForm = ({
  initialValues: initialValuesFromProps,
  actionKey,
  fields,
  onSuccess,
  updateDefaultsOnSuccess = true
}) => {
  const { t } = RV.useTranslation()
  const [initialValues, setInitialValues] = React.useState(initialValuesFromProps)
  const [isSaved, setIsSaved] = React.useState(false)
  const [formProps, isSubmitting, reset] = useReactorActionForm(
    actionKey,
    {
      initialValues,
      // validationBaseFieldPath: 'data',
      onStart: () => {
        setIsSaved(false)
      },
      onSuccess: (data) => {
        console.log(initialValues, data, initialValuesFromProps)
        updateDefaultsOnSuccess && setInitialValues({
          ...initialValues,
          data
        })
        setIsSaved(true)
        reset()
        onSuccess?.()
      },
      // onError
    }
  )

  return (
    <>
      <Form {...formProps}>
        {({ form }) => {
          const isDirty = form.getState().dirty
          return (
            <>
              <RfFormFields fields={fields} />

              <RfFormError error={formProps.error} />
              <Flex sx={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Box>
                  {isSaved && t('settings.text.settings_updated')}
                </Box>

                <Flex flexDirection='row-reverse' sx={{
                  mx: -2
                }}>
                  <RV.Button mx={2} textTransform='capitalize' colorScheme={'brand'} submit isLoading={isSubmitting} disabled={!isDirty}>
                    {t('btn.save')}
                  </RV.Button>
                  <RV.Button mx={2} textTransform='capitalize' onClick={() => {
                    reset()
                  }} colorScheme={'light'} disabled={!isDirty || isSubmitting} sx={{ color: 'dark.300' }}>
                    {t('btn.cancel')}
                  </RV.Button>
                </Flex>
              </Flex>

            </>
          )
        }}
      </Form>
    </>
  )
}

export default ReactorAppSettings
import React from 'react'

// ReactorUi
import { useSelector, useDispatch } from 'react-redux'
import uniqueId from 'lodash/uniqueId'

// Reactor
import dashboardActions from 'reactor/systems/dashboard/actions'
import Form from 'reactor-form/components/RfForm'


import { ReactorDashboardContext } from '../context'


const Dashboard = ({
  children,
  id: idFromProps,
  defaultFilters
}) => {
  const idRef = React.useRef()
  if (!idRef.current) idRef.current = idFromProps || uniqueId()
  const id = idRef.current
  const ctx = React.useMemo(() => ({
    id
  }), [id])

  const dispatch = useDispatch()

  React.useEffect(() => {
    return () => dispatch(dashboardActions.unregisterDashboard({id}))
  }, [id])

  const dashboard = useSelector(state => state.reactorDashboard.dashboards[id])
  React.useEffect(() => {
    if (!dashboard) dispatch(dashboardActions.registerDashboard({id, defaultFilters}))
  }, [dashboard, id])
  if (!dashboard) return null

  return (
    <ReactorDashboardContext.Provider value={ctx}>
      <Form handleSubmit={() => {}} initialValues={defaultFilters}>
        {children}
      </Form>
    </ReactorDashboardContext.Provider>
  )
}

export default Dashboard
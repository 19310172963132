import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


const Content = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Box {...props} sx={sx}>
      {children}
    </Box>
  )
}

const Header = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Box {...props} sx={{
      fontWeight: 'bold',
      fontSize: 'sm',
      ...sx,
    }}>
      {children}
    </Box>
  )
}

const P = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Box as='p' {...props} sx={{
      // fontWeight: 'bold',
      // fontSize: 'sm',
      ...sx,
      my: 2
    }}>
      {children}
    </Box>
  )
}

Content.Header = Header
Content.P = P

export default Content
import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// Reactor UI
import { Box, Flex } from 'reactor-ui'

import TextInput from 'reactor-ui/components/TextInput'
import SelectInput from 'reactor-ui/components/SelectInput'

import * as changeCase from 'reactor-ui/util/text'

import processors from '../processors'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'

import RfFormField from './RfFormField'


const RfRecurrenceField = ({
  name,
  field,
}) => {
  const { t } = useTranslation()
  // const { input, meta } = useField(name, {
  //   validate: (value) => validate(field, value)
  // })

  // field.processors?.forEach(processor => {
  //   processors[processor.name](processor, field, input, meta)
  // })

  return (
    <Box sx={{
      width: '100%'
    }}>
      <RfFormField
        kind='select'
        name={`${name}.interval`}
        options={{
          options: ['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY'].map(o => ({
            value: o,
            label: t(`enum.${changeCase.snake(o)}`)
          })),
          translate: true,
        }}
        label='label.interval'
      />

      {/* <RfFormField
        name={`${name}.intervalCount`}
        label='label.intervalCount'
        showIf={[
          {
            field: '`${name}.interval`'
            value:
          }
        ]}
      /> */}

      {/* <RfFieldAlert meta={meta} sx={{
        mt: -2,
        zIndex: 0,
        pt: 4,
        pb: 3,
      }} />
      <RfFieldDescription field={field} sx={{ mb: 2 }} /> */}
    </Box>
  )
}

export default RfRecurrenceField
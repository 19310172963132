import { SystemStyleObject } from "@chakra-ui/styled-system"
import {
  filterUndefined,
  memoizedGet as get,
  mergeWith,
  runIfFn,
  omit,
  Dict,
} from "@chakra-ui/utils"
import { useRef } from "react"
import isEqual from "react-fast-compare"
import { useChakra } from "@chakra-ui/system"

// Vendor
import merge from 'lodash/merge'


export function useStyleConfig(themeKey, props = {}, opts = {}) {
  const { styleConfig: styleConfigProp, defaultStyleConfig, ...rest } = props

  const { theme, colorMode } = useChakra()
  const themeStyleConfig = get(theme, `components.${themeKey}`)
  // const styleConfig = styleConfigProp || themeStyleConfig || defaultStyleConfig
  const styleConfig = merge({},
    defaultStyleConfig,
    themeStyleConfig,
    styleConfigProp,
  )

  const mergedProps = mergeWith(
    { theme, colorMode },
    styleConfig?.defaultProps ?? {},
    filterUndefined(omit(rest, ["children"])),
  )

  /**
   * Store the computed styles in a `ref` to avoid unneeded re-computation
   */
  const stylesRef = useRef({})

  if (styleConfig) {
    const baseStyles = runIfFn(styleConfig.baseStyle ?? {}, mergedProps)

    const variants = runIfFn(
      styleConfig.variants?.[mergedProps.variant] ?? {},
      mergedProps,
    )

    const sizes = runIfFn(
      styleConfig.sizes?.[mergedProps.size] ?? {},
      mergedProps,
    )

    const styles = mergeWith({}, baseStyles, sizes, variants)

    if (opts?.isMultiPart && styleConfig.parts) {
      styleConfig.parts.forEach((part) => {
        styles[part] = styles[part] ?? {}
      })
    }

    const isStyleEqual = isEqual(stylesRef.current, styles)

    if (!isStyleEqual) {
      stylesRef.current = styles
    }
  }

  return stylesRef.current
}

export function useMultiStyleConfig(themeKey, props) {
  return useStyleConfig(themeKey, props, { isMultiPart: true })
}
import React from 'react'

// Reactor
import { createRReducer } from "../util/reducer"


const useReactorReducer = (initialState, actions) => {
  const dataFetchReducer = React.useMemo(() => {
    return createRReducer(actions)
  }, [])

  const [reducerState, reducerDispatch] = React.useReducer(dataFetchReducer, initialState)
  const reducerActions = React.useMemo(() => {
    const result = {}
    Object.keys(actions).forEach(actionKey => {
      result[actionKey] = (payload) => reducerDispatch({kind: actionKey, payload})
    })
    return result
  })

  return [reducerState, reducerActions]
}

export default useReactorReducer

import DefaultThemeZoneLayout from './DefaultThemeZoneLayout'
import DefaultThemeUnitLayout from './DefaultThemeUnitLayout'

import { PlexUiGroupItem, PlexUiComponentItem } from './sidebar/SidebarComponents'

export default {
  components: {
    ZoneLayout: DefaultThemeZoneLayout,
    UnitLayout: DefaultThemeUnitLayout,
  },
  sidebar: {
    PlexUiGroupItem: PlexUiGroupItem,
    PlexUiComponentItem: PlexUiComponentItem,
  }
}